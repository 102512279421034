import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
    OwnOrLease,
    ownOrLeaseText,
    QrsRigDetailsFormValue,
    QuoteRigSetFormValue,
    RigStorageMethod,
    rigStorageMethodText,
    VehicleUse,
    vehicleUseText,
} from '@backend-types/quote-flow-ens';
import { RocRigOwnedTenure, rocRigOwnedTenureText } from '@backend-types/roc-lib';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { AnalyticsService, AssertionService, HotKeysService, ToastService } from '@common/services';
import { validYears } from '@data/vehicle-years.data';
import { QuoteRigSetFormService } from '@modules/quote/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { Subscription, tap } from 'rxjs';

const MIN_YEAR = 1990;
const MAX_YEAR = parseInt(format(new Date(), 'yyyy'), 10) + 1;

@Component({
    selector: 'sbf-quote-rig-set-form-rig-details-quick',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-rig-set-form-rig-details-quick.component.html',
    styleUrls: ['quote-rig-set-form-rig-details-quick.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteRigSetFormRigDetailsQuickComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteRigSetFormRigDetailsQuickComponent,
        },
    ],
})
export class QuoteRigSetFormRigDetailsQuickComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @Input() formOnly = false;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();
    @Output() rigDetailsFormValid = new EventEmitter<boolean>();

    minYear = MIN_YEAR;
    maxYear = MAX_YEAR;

    validYears = validYears;

    rigStorageMethodText = rigStorageMethodText;
    rocRigOwnedTenureText = rocRigOwnedTenureText;
    ownOrLeaseText = ownOrLeaseText;
    vehicleUseText = vehicleUseText;

    subscription: Subscription = new Subscription();

    activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;
    vinModalOpen = false;

    rigDetailsForm: ModelFormGroup<QrsRigDetailsFormValue> = this.fb.group({
        year: new FormControl<number | null>(null, [
            Validators.required,
            Validators.min(MIN_YEAR),
            Validators.max(MAX_YEAR),
        ]),
        make: new FormControl<string | null>(null, [Validators.required]),
        model: new FormControl<string | null>(null, [Validators.required]),
        trim: new FormControl<string | null>(null, [Validators.required]),
        yearsOwned: new FormControl<RocRigOwnedTenure | null>(null, [Validators.required]),
        ownOrLease: new FormControl<OwnOrLease | null>(null, [Validators.required]),
        vehicleUse: new FormControl<VehicleUse | null>(null, [Validators.required]),
        storageMethod: new FormControl<RigStorageMethod | null>(null, [Validators.required]),
        vin: new FormControl<string | null>(null, []),
        forQuickQuote: new FormControl<boolean | null>(true, []),
    });

    onTouched: () => unknown = () => {};
    onChange = (rigDetails: ModelFormValue<QrsRigDetailsFormValue> | null) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private changeDetectorRef: ChangeDetectorRef,
        private ngbModalService: NgbModal,
        private toastService: ToastService,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Rig Details');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_rig_details',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.rigDetailsForm.setValue({
                    year: 2000,
                    make: 'MAKE',
                    model: 'MODEL',
                    trim: 'TRIM',
                    yearsOwned: RocRigOwnedTenure.fiveYearsOrMore,
                    ownOrLease: OwnOrLease.own,
                    vehicleUse: VehicleUse.pleasure,
                    storageMethod: RigStorageMethod.garage,
                    vin: null,
                    forQuickQuote: true,
                });
                this.rigDetailsForm.markAllAsTouched();
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.quoteRigSetFormService
                .getActiveQuoteRigSetForm$()
                .subscribe((activeQuoteRigSetForm) => {
                    this.activeQuoteRigSetForm = activeQuoteRigSetForm;
                    this.changeDetectorRef.detectChanges();
                })
        );

        // this.setupVehicleDetailsSubs();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (rigDetails: ModelFormValue<QrsRigDetailsFormValue> | null) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.rigDetailsForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.rigDetailsForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        this.rigDetailsFormValid.emit(this.rigDetailsForm.valid);
                        const rigDetails = this._rigDetailsFormValue();
                        onChange(rigDetails);
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.rigDetailsForm.disable();
        } else {
            this.rigDetailsForm.enable();
        }
    }

    writeValue(value: QrsRigDetailsFormValue | null) {
        if (value === null) {
            this.rigDetailsForm.reset();
        }
        if (value) {
            this.rigDetailsForm.setValue(value, { emitEvent: false });
            this.changeDetectorRef.detectChanges();
            this.rigDetailsFormValid.emit(this.rigDetailsForm.valid);
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.rigDetailsForm?.invalid) {
            return { rigDetailsFormInvalid: true };
        }

        return null;
    }

    private _rigDetailsFormValue(): ModelFormValue<QrsRigDetailsFormValue> {
        const {
            year,
            make,
            model,
            trim,
            yearsOwned,
            ownOrLease,
            vehicleUse,
            storageMethod,
            vin,
            forQuickQuote,
        } = this.rigDetailsForm.value;

        this.assertionService.isDefinedOrThrow(year);
        this.assertionService.isDefinedOrThrow(make);
        this.assertionService.isDefinedOrThrow(model);
        this.assertionService.isDefinedOrThrow(trim);
        this.assertionService.isDefinedOrThrow(yearsOwned);
        this.assertionService.isDefinedOrThrow(ownOrLease);
        this.assertionService.isDefinedOrThrow(vehicleUse);
        this.assertionService.isDefinedOrThrow(storageMethod);

        this.assertionService.isNotUndefinedOrThrow(vin);
        this.assertionService.isNotUndefinedOrThrow(forQuickQuote);

        return {
            year: parseInt(year as unknown as string, 10),
            make,
            model,
            trim,
            yearsOwned,
            ownOrLease,
            vehicleUse,
            storageMethod,
            vin,
            forQuickQuote,
        };
    }

    /* Accessor Methods */

    get vehicleUseControl() {
        return this.rigDetailsForm.get('vehicleUse') as FormControl;
    }

    get vehicleUseControlValid() {
        return this.vehicleUseControl.value && !this.vehicleUseControl.hasError('required');
    }

    get vehicleUseControlInvalid() {
        return this.vehicleUseControl.touched && this.vehicleUseControl.hasError('required');
    }
}
