import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EarlyAccessPayload } from '@backend-types/site';
import { EnvService } from '@common/services';
import { Observable } from 'rxjs';

@Injectable()
export class SignUpService {
    constructor(
        private http: HttpClient,
        private envService: EnvService
    ) {}

    earlyAccess$(earlyAccessPayload: EarlyAccessPayload): Observable<void> {
        return this.http.post<void>(
            `${this.envService.config.backendURL}/api/latest/site/early-access`,
            earlyAccessPayload
        );
    }
}
