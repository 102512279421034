import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUtilsService, OverlayService, UtilityService, UtmService } from '@common/services';
import { AuthService } from '@modules/auth/services';
import { QuoteEnsFormService } from '@modules/quote/services';
import { combineLatest, Subscription, take } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-payment-confirmed',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-payment-confirmed.component.html',
    styleUrls: ['quote-ens-payment-confirmed.component.scss'],
})
export class QuoteEnsPaymentConfirmedComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();

    constructor(
        private utilityService: UtilityService,
        private overlayService: OverlayService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private quoteEnsFormService: QuoteEnsFormService,
        private authUtilsService: AuthUtilsService,
        private utmService: UtmService,
        private title: Title
    ) {
        this.title.setTitle('Tredder Quote - Payment Confirmed');
    }

    ngOnInit() {
        // INFO: Get this first before we delete it
        const activeQuoteEnsUserId =
            this.utilityService.localStorage.getItem('ActiveQuoteEnsUserId');

        if (!activeQuoteEnsUserId) {
            // TODO
            console.log('ActiveQuoteEnsUserId not found');
            return;
        }

        this.overlayService.show('Almost there');

        // WARN: Need to wait until cached form is loaded beflore clearing cache
        this.subscription.add(
            combineLatest([
                this.quoteEnsFormService.quoteEnsFlowFormValuePure$.pipe(take(1)),
                this.authService.isNewUser$({ userID: activeQuoteEnsUserId }),
            ]).subscribe(([quoteEnsFlowFormValuePure, isNewUserResponse]) => {
                // INFO: Clear Cache First
                this.quoteEnsFormService.reset();
                this.utmService.clear();

                // INFO: New User Code
                if (isNewUserResponse.isNewUser) {
                    this.router.navigate(['.'], {
                        relativeTo: this.route,
                        queryParams: {
                            'quote-ens-form-step': 'password',
                            'password-reset-token': isNewUserResponse.passwordResetToken,
                            'user-id': activeQuoteEnsUserId,
                        },
                    });
                    this.overlayService.hide();
                } else {
                    if (this.authUtilsService.isLoggedIn) {
                        this.router.navigate(['/account/insurance/purchase-success-redirect']);
                        this.overlayService.hide();
                    } else {
                        this.router.navigate(['/quote/purchase-success']);
                        this.overlayService.hide();
                    }
                }
            })
        );
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
