import { Injectable } from '@angular/core';
import { PaymentPlanName } from '@backend-types/payment-plan';
import { QuoteEnsFormStep, QuoteRigSetFormStep } from '@modules/quote/models';

@Injectable()
export class QuoteSharedService {
    constructor() {}

    queryParamToQuoteEnsFormStep(queryParamValue: string | null): QuoteEnsFormStep | null {
        if (queryParamValue === null) {
            return null;
        }
        const quoteEnsFormStepValues = Object.values(QuoteEnsFormStep) as string[];
        if (quoteEnsFormStepValues.includes(queryParamValue)) {
            return queryParamValue as QuoteEnsFormStep;
        }
        throw new Error(`${queryParamValue} is not a QuoteEnsFormStep`);
    }

    queryParamToQuoteRigSetFormStep(queryParamValue: string | null): QuoteRigSetFormStep | null {
        if (queryParamValue === null) {
            return null;
        }
        const quoteRigSetFormStepValues = Object.values(QuoteRigSetFormStep) as string[];
        if (quoteRigSetFormStepValues.includes(queryParamValue)) {
            return queryParamValue as QuoteRigSetFormStep;
        }
        throw new Error(`${queryParamValue} is not a QuoteRigSetFormStep`);
    }

    queryParamToPaymentPlanName(queryParamValue: string | null): PaymentPlanName | null {
        if (queryParamValue === null) {
            return null;
        }
        const paymentPlanNames = Object.values(PaymentPlanName) as string[];
        if (paymentPlanNames.includes(queryParamValue)) {
            return queryParamValue as PaymentPlanName;
        }
        throw new Error(`${queryParamValue} is not a PaymentPlanName`);
    }
}
