import { DriverLicenseStatus, DriverYearsLicensed } from '@backend-types/driver';
import { CurrentInsurance } from '@backend-types/insurance-carriers';
import { Rc2ScenarioButtons, Rc2Scenarios } from '@common/models';

export const rc2Scenarios: Rc2Scenarios = {
    common: {
        buttonText: 'Common',
        testId: Rc2ScenarioButtons.common,
        formValues: {
            licenseStatus: DriverLicenseStatus.valid,
            yearsLicensed: DriverYearsLicensed.threeYearsOrMore,
            currentInsurance: CurrentInsurance.progressive,
            numberOfResidents: 1,
            overTwoViolations: false,
            overTwelveLiftKit: false,
        },
    },
    invalidLicense: {
        buttonText: 'Invalid License',
        testId: Rc2ScenarioButtons.invalidLicense,
        formValues: {
            licenseStatus: DriverLicenseStatus.expired,
            yearsLicensed: DriverYearsLicensed.threeYearsOrMore,
            currentInsurance: CurrentInsurance.progressive,
            numberOfResidents: 1,
            overTwoViolations: false,
            overTwelveLiftKit: false,
        },
    },
    overTwoViolations: {
        buttonText: 'Over Two Violations',
        testId: Rc2ScenarioButtons.overTwoViolations,
        formValues: {
            licenseStatus: DriverLicenseStatus.valid,
            yearsLicensed: DriverYearsLicensed.threeYearsOrMore,
            currentInsurance: CurrentInsurance.progressive,
            numberOfResidents: 1,
            overTwoViolations: true,
            overTwelveLiftKit: false,
        },
    },
    overTwelveLiftKit: {
        buttonText: 'Over Twelve Lift Kit',
        testId: Rc2ScenarioButtons.overTwelveLiftKit,
        formValues: {
            licenseStatus: DriverLicenseStatus.valid,
            yearsLicensed: DriverYearsLicensed.threeYearsOrMore,
            currentInsurance: CurrentInsurance.progressive,
            numberOfResidents: 1,
            overTwoViolations: false,
            overTwelveLiftKit: true,
        },
    },
};
