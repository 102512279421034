import { Injectable } from '@angular/core';
import { QuoteEnsFlowFormValue, QuoteRigSetFormValue } from '@backend-types/quote-flow-ens';
import { ModelFormValue } from '@common/models/forms.model';
import { LogService, UtilityService } from '@common/services';

@Injectable({
    providedIn: 'root',
})
export class QuoteCacheService {
    constructor(
        private utilityService: UtilityService,
        private logService: LogService
    ) {}

    // QuoteEnsFlowFormValue
    get cachedQuoteEnsFlowFormValue(): ModelFormValue<QuoteEnsFlowFormValue> | null {
        const cachedQuoteEnsFlowFormValue = this.utilityService.getStoredObject<
            ModelFormValue<QuoteEnsFlowFormValue>
        >('cachedQuoteEnsFlowFormValue');
        return cachedQuoteEnsFlowFormValue || null;
    }

    set cachedQuoteEnsFlowFormValue(
        quoteFlowFormValue: ModelFormValue<QuoteEnsFlowFormValue> | null
    ) {
        if (quoteFlowFormValue === null) {
            this.utilityService.removeObject('cachedQuoteEnsFlowFormValue');
            return;
        }
        if (Object.values(quoteFlowFormValue).every((keyValue) => keyValue === null)) {
            this.logService.debug('NOT_CACHING_QUOTE_ENS_FLOW_FORM_VALUE_WITH_ALL_NULL_VALUES');
            return;
        }

        this.utilityService.storeObject('cachedQuoteEnsFlowFormValue', quoteFlowFormValue);
    }

    deleteCachedQuoteEnsFlowFormValue() {
        this.utilityService.removeObject('cachedQuoteEnsFlowFormValue');
    }

    // QuoteRigSetFormValue
    get cachedQuoteRigSetFormValue(): ModelFormValue<QuoteRigSetFormValue> | null {
        const cachedQuoteRigSetFormValue = this.utilityService.getStoredObject<
            ModelFormValue<QuoteRigSetFormValue>
        >('cachedQuoteRigSetFormValue');
        return cachedQuoteRigSetFormValue || null;
    }

    set cachedQuoteRigSetFormValue(
        quoteFlowFormValue: ModelFormValue<QuoteRigSetFormValue> | null
    ) {
        if (quoteFlowFormValue === null) {
            this.utilityService.removeObject('cachedQuoteRigSetFormValue');
            return;
        }
        this.utilityService.storeObject('cachedQuoteRigSetFormValue', quoteFlowFormValue);
    }

    deleteCachedQuoteRigSetFormValue() {
        this.utilityService.removeObject('cachedQuoteRigSetFormValue');
    }

    // QuoteRigSetFormValueValueArray
    get cachedQuoteRigSetFormValuesArray(): ModelFormValue<QuoteRigSetFormValue>[] | null {
        const cachedQuoteRigSetFormValue = this.utilityService.getStoredObject<
            ModelFormValue<QuoteRigSetFormValue>[]
        >('cachedQuoteRigSetFormValuesArray');
        return cachedQuoteRigSetFormValue || null;
    }

    set cachedQuoteRigSetFormValuesArray(
        quoteFlowFormValuesArray: ModelFormValue<QuoteRigSetFormValue>[] | null
    ) {
        if (quoteFlowFormValuesArray === null) {
            this.utilityService.removeObject('cachedQuoteRigSetFormValuesArray');
            return;
        }
        this.utilityService.storeObject(
            'cachedQuoteRigSetFormValuesArray',
            quoteFlowFormValuesArray
        );
    }

    deleteCachedQuoteRigSetFormValuesArray() {
        this.utilityService.removeObject('cachedQuoteRigSetFormValuesArray');
    }

    clearQuoteEnsLocalStorageCache() {
        // TODO: cachedQuoteEnsFlowFormValue isn't going away...
        this.utilityService.removeObject('cachedQuoteEnsFlowFormValue');
        this.utilityService.removeObject('cachedQuoteRigSetFormValuesArray');
        this.utilityService.removeObjectsWithPrefix('EnsQuoteCache');
        this.utilityService.localStorage.removeItem('ActiveQuoteEnsId');
        this.utilityService.localStorage.removeItem('ActiveQuoteEnsUserId');
    }

    clearQuoteEnsLocalStorageCacheForRefresh() {
        this.utilityService.removeObjectsWithPrefix('EnsQuoteCache');
    }

    // get submittedQuoteID(): UUID | null {
    //     return this.utilityService.localStorage.getItem('submittedQuoteID');
    // }

    // set submittedQuoteID(quoteID: UUID | null) {
    //     if (quoteID === null) {
    //         this.utilityService.localStorage.removeItem('submittedQuoteID');
    //         return;
    //     }
    //     this.utilityService.localStorage.setItem('submittedQuoteID', quoteID);
    // }
}
