/* eslint-disable simple-import-sort/imports */
import { NgModule, ModuleWithProviders } from '@angular/core';

/* Services */
import * as quoteServices from './services';

@NgModule({
    imports: [],

    declarations: [],
    exports: [],
})
export class QuoteServicesModule {
    static forRoot(): ModuleWithProviders<QuoteServicesModule> {
        return {
            ngModule: QuoteServicesModule,
            providers: [...quoteServices.services],
        };
    }
}
