import { EnvConfig, logLevelValues } from '@common/models';

// NOTE: These are all public key values. DO NOT put private key values here.
export const developEnvConfig: EnvConfig = {
    facebookClientID: '673817013642861',
    githubClientID: '5e41d2bb5a0bdb86b218',
    googleClientID: '702982947710-3g3rkq2rsltinejhljide464emj7eevm.apps.googleusercontent.com',
    recaptchaSiteKey: '6LexS7kmAAAAAGJX_pSoKTIhLuqFZ2UuGNMmORpH',
    googlePlacesAPIKey: 'AIzaSyBcOPlFoM5T3uz9yuWwseJbQfAqOYaLuJw',
    stripePublishableKey:
        'pk_test_51NiMDEHu8ViMSJQxBceqLRMB8F3SIn38s9NfnKkzR3svwW6wQgvXtaCnWTeM1ImcwDNMLIZyj220sUNysLTx2FHD00a5q5S9hD',
    backendURL: 'https://develop-api.tredder.com',
    backendWSURL: 'wss://develop-api.tredder.com',
    frontendURL: 'https://develop.tredder.com',
    logLevel: logLevelValues['info'],
    canopyConnectAlias: 'tredder10',
};
