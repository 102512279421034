import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    TokenResponse,
    TwitterRequestTokenResponse,
    UserForTokenTyped,
} from '@backend-types/authorization';
import { TwitterAuthPayload } from '@backend-types/social';
import { AnalyticsService, AuthUtilsService, EnvService, UtilityService } from '@common/services';
import { TwitterOAuthReturnedParamters } from '@modules/auth/models';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SocialTwitterService {
    constructor(
        private http: HttpClient,
        private utilityService: UtilityService,
        private router: Router,
        private authUtilsService: AuthUtilsService,
        private analyticsService: AnalyticsService,
        private envService: EnvService
    ) {}

    twitterOAuthInit(fromAccount = false): void {
        if (fromAccount) {
            this.utilityService.localStorage.setItem(`authOrigination`, 'account');
        }
        this.http
            .get<TwitterRequestTokenResponse>(
                `${this.envService.config.backendURL}/api/latest/social/twitter-request-token`
            )
            .pipe(
                take(1),
                tap((twitterRequestTokenResponse) => {
                    this.utilityService.window.location.href =
                        `https://api.twitter.com/oauth/authenticate` +
                        `?oauth_token=${twitterRequestTokenResponse.requestToken}`;
                }),
                catchError((error: Error) => throwError(() => error))
            )
            .subscribe();
    }

    twitterOAuthReturn(params: TwitterOAuthReturnedParamters) {
        const authOrigination = this.utilityService.localStorage.getItem(`authOrigination`);
        this.utilityService.localStorage.removeItem(`authOrigination`);

        const postBody: TwitterAuthPayload = {
            oauth_verifier: params.oauth_verifier,
            oauth_token: params.oauth_token,
        };

        this.http
            .post<TokenResponse>(
                `${this.envService.config.backendURL}/api/latest/social/twitter-auth`,
                postBody
            )
            .pipe(
                take(1),
                switchMap(
                    (loginResults): Observable<UserForTokenTyped> =>
                        this.authUtilsService.processToken$(loginResults.token)
                ),
                switchMap((user) => {
                    this.analyticsService.sendEventLogin('twitter');
                    if (authOrigination === 'account') {
                        return this.authUtilsService.navigateToAccountAuth();
                    }
                    return this.authUtilsService.navigateAfterAuth(user, '/auth/social');
                }),
                catchError((error: Error) => throwError(() => error))
            )
            .subscribe();
    }
}
