import type { PaymentPlan, Prisma } from './_prisma';
import type { CalculateRateEnsOnlyParams } from './quote-ens';
import type {
    ENSGearRateResponse,
    ENSRigRateResponse,
    ENSSpecialRateResponse,
    ENSTermRateKey,
} from './roc-lib';
import type { State } from './states';
import type {
    DynamicFeesAndTaxes,
    ENSStateFee,
    ENSStateTax,
    ENSTredderFee,
    InvoiceLineItemCategory,
    InvoiceLineItemKind,
    StaticFeesAndTaxes,
} from './taxes-fees';

export type PaymentPlanForResults = Prisma.PaymentPlanGetPayload<{
    // select: {
    //     id: true;
    //     name: true;
    // };
}>;

export interface CreatePaymentPlanPayload {
    planName: PaymentPlanName;
    paymentPlanSource: PaymentPlanSource;
    dailyRate: DailyRate;
    timeZone: string;
    quoteEnsId: UUID;
    policyEnsId: UUID;
}

export interface ReadMultiplePaymentPlansPayload {
    paymentPlanIDs: string[];
}

export interface DeleteMultiplePaymentPlansPayload {
    paymentPlanIDs: string[];
}

export interface UpdatePaymentPlanPayload {
    planName?: PaymentPlanName;
    paymentPlanSource?: PaymentPlanSource;
    dailyRate?: DailyRate;
    timeZone?: string;
    quoteEnsId?: UUID;
    policyEnsId?: UUID;
}

export type AllPaymentPlansErrorCodes = 'ERROR_FINDING_PAYMENT_PLANS' | 'PAYMENT_PLANS_NOT_FOUND';
export type CreatePaymentPlansErrorCodes =
    | 'PAYMENT_PLAN_NAME_IN_USE'
    | 'ERROR_CREATING_PAYMENT_PLAN';
export type DeleteMultiplePaymentPlansErrorCodes =
    | 'PAYMENT_PLANS_NOT_FOUND'
    | 'ERROR_FINDING_PAYMENT_PLANS'
    | 'ERROR_DELETING_PAYMENT_PLANS';
export type DeletePaymentPlanErrorCodes =
    | 'PAYMENT_PLAN_NOT_FOUND'
    | 'ERROR_FINDING_PAYMENT_PLAN'
    | 'ERROR_DELETING_PAYMENT_PLAN';
export type FindPaymentPlanErrorCodes = 'ERROR_FINDING_PAYMENT_PLAN' | 'PAYMENT_PLAN_NOT_FOUND';
export type FindPaymentPlansErrorCodes = 'ERROR_FINDING_PAYMENT_PLANS' | 'NO_PAYMENT_PLANS_FOUND';
export type SavePaymentPlanErrorCodes = 'ERROR_SAVING_PAYMENT_PLAN';
export type UpdatePaymentPlanErrorCodes = 'ERROR_UPDATING_PAYMENT_PLAN' | 'PAYMENT_PLAN_NOT_FOUND';

export interface CalculatePaymentPlansParameters {
    calculateRateEnsOnlyParams: CalculateRateEnsOnlyParams;
    totals: CalculatedTotals;
    taxesAndFees: CalculatedTaxesAndFees;
}

export interface CalculatePremiumBreakdownParameters {
    calculateRateEnsOnlyParams: CalculateRateEnsOnlyParams;
    term: ENSTermRateKey;
    rigRates: ENSRigRateResponse[];
    gearRate: ENSGearRateResponse | null;
    specialRates: ENSSpecialRateResponse;
    totals: CalculatedTotals;
    taxesAndFees: CalculatedTaxesAndFees;
}

export interface CalculatedTotals {
    total: string;
    premiumTotal: string;
    stateTaxesTotal: string;
    stateFeesTotal: string;
    tredderFeesTotal: string;
}

export interface CalculatedTaxesAndFees {
    stateTaxes: ENSStateTax[];
    stateFees: ENSStateFee[];
    tredderFees: ENSTredderFee[];
}

export interface PreCalculatedValuesForInstallments {
    effectiveDate: string;
    expirationDate: string;
    underwritingEndDate: string;
    state: State;
    timeZone: string;
    termDays: number;
    dailyRate: DailyRate;
    staticFeesAndTaxes: StaticFeesAndTaxes[];
    dynamicFeesAndTaxes: DynamicFeesAndTaxes[];
    staticFeesAndTaxesTotal: string;
    dynamicFeesAndTaxesTotal: string;
    installmentFee?: string;
}

export interface DailyRate {
    dailyTotal: string;
    dailyPremium: string;
    dailyDynamicFeesAndTaxes: string;
}

export interface PaymentPlanSource extends PreCalculatedValuesForInstallments {
    name: PaymentPlanName;
    credit: string | null;
    debit: string | null;
    installments: PaymentPlanInstallment[];
    doNotUse?: boolean; // INFO: In case inital payment is more than full payment.
}

export interface PaymentPlanInstallment {
    dueDate: string;
    credit: string | null;
    debit: string | null;
    installmentLineItems: InstallmentLineItem[];
}

export interface InstallmentLineItem {
    invoiceLineItemCategory: InvoiceLineItemCategory;
    invoiceLineItemKind: InvoiceLineItemKind;
    state: State | null;
    credit: string | null;
    debit: string | null;
    note?: string;
}

export enum PaymentPlanName {
    full = 'full',
    twentySixteen = 'twentySixteen',
}

export enum InvoiceStatus {
    notDue = 'notDue',
    due = 'due',
    paid = 'paid',
    paymentFailure = 'paymentFailure',
    processing = 'processing',
    overDue = 'overDue',
    canceled = 'canceled',
    endorsed = 'endorsed',
}

export enum PaymentKind {
    premium = 'premium',
    refund = 'refund',
}

export interface CreditDebit {
    credit: string | null;
    debit: string | null;
}

export interface PaymentPlanTyped extends Omit<PaymentPlan, 'paymentPlanSource' | 'dailyRate'> {
    planName: PaymentPlanName;
    paymentPlanSource: PaymentPlanSource;
    dailyRate: DailyRate;
}
