import { NgModule } from '@angular/core';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';

import { bootstrapIcons } from './icons.bootstrap';

@NgModule({
    imports: [NgxBootstrapIconsModule.pick(bootstrapIcons)],
    exports: [NgxBootstrapIconsModule],
})
export class IconsModule {
    constructor() {}
}
