import { Injectable } from '@angular/core';

@Injectable()
export class AssertionService {
    isDefinedOrThrow<T>(value: T): asserts value is NonNullable<T> {
        if (value === undefined || value === null) {
            throw new Error(`VALUE_IS_NOT_DEFINED`);
        }
    }

    isNotUndefinedOrThrow<T>(value: T | null): asserts value is NonNullable<T> | null {
        if (value === undefined) {
            throw new Error(`VALUE_IS_UNDEFINED`);
        }
    }

    hasNoNull<T>(values: (T | null)[]): asserts values is T[] {
        if (!values.length) {
            throw new Error(`VALUE_HAS_NO_LENGTH`);
        }
        values.forEach((value) => {
            if (value === null) {
                throw new Error('VALUE_IS_NULL');
            }
        });
    }

    isDefined<T>(value: T): boolean {
        if (value === undefined || value === null) {
            return false;
        }
        return true;
    }

    orNA = <T>(value?: T): T | string => {
        if (!value && value !== 0) {
            return 'N/A';
        }
        return value;
    };

    orNULL = <T>(value?: T): T | null => {
        if (!value && value !== 0) {
            return null;
        }
        return value;
    };

    orNULLBoolean = <T>(value?: T): T | null => {
        if (value === undefined) {
            return null;
        }
        return value;
    };

    orUNDEFINED = <T>(value?: T): NonNullable<T> | undefined => {
        if (value === null) {
            return undefined;
        }
        if (!value && value !== 0) {
            return undefined;
        }
        return value;
    };

    orZERO = <T>(value?: T): T | 0 => {
        if (!value && value !== 0) {
            return 0;
        }
        return value;
    };

    orEMPTY = (value?: string | null): string => {
        if (!value) {
            return '';
        }
        return value;
    };
}
