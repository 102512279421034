import { Location } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { QuoteEnsRetrievalService } from '@modules/quote/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-payment-error',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-payment-error.component.html',
    styleUrls: ['quote-ens-payment-error.component.scss'],
})
export class QuoteEnsPaymentErrorComponent implements OnInit, OnDestroy {
    activeEnsQuote!: EnsResponseWithEnsOnly | null;

    subscription: Subscription = new Subscription();

    constructor(
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private location: Location,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title
    ) {
        this.title.setTitle('Tredder Quote - Payment Error');
    }

    ngOnInit() {
        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuote$.subscribe((activeEnsQuote) => {
                this.activeEnsQuote = activeEnsQuote;
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    tryAnotherCard() {
        this.location.back();
    }
}
