import { DynamicRouteData } from '@modules/navigation/models';

import { sharedMetaTags } from './_shared-meta-tags';

export const quote: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Quote',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const quoteConfigs = {
    quote,
};
