import { Injectable } from '@angular/core';
import type { ImageCompressionOptions } from '@common/models';
import {
    CanopyConnectCreateOptionFunctions,
    CanopyConnectHandler,
} from '@common/models/canopy-connect.model';
import type { FuseResult, IFuseOptions } from 'fuse.js';
import { combineLatest, from, Observable, of, switchMap, take } from 'rxjs';

import { EnvService, ScriptService, UtilityService } from '.';

@Injectable()
export class DynamicExternalService {
    constructor(
        private utilityService: UtilityService,
        private scriptService: ScriptService,
        private envService: EnvService
    ) {}

    async imageCompression(image: File, options: ImageCompressionOptions): Promise<File> {
        await this.scriptService.loadScript('imageCompression');
        return this.utilityService.window.imageCompression(image, options);
    }

    async fuseSearch<T>(
        list: ReadonlyArray<string>,
        searchValue: string,
        options?: IFuseOptions<string>
    ): Promise<FuseResult<T>[]> {
        await this.scriptService.loadScript('fuse');
        return new this.utilityService.window.Fuse(list, options).search(searchValue);
    }

    canopyConnectHandler$(
        quoteEnsId?: UUID,
        policyEnsId?: UUID,
        callbackFns?: CanopyConnectCreateOptionFunctions
    ): Observable<CanopyConnectHandler> {
        let canopyConnectHandler: CanopyConnectHandler;
        return combineLatest([
            from(this.scriptService.loadScript('canopyConnect')),
            this.envService.runEnv$.pipe(take(1)),
        ]).pipe(
            switchMap(([loaded, runEnv]) => {
                canopyConnectHandler = this.utilityService.window.CanopyConnect.create({
                    publicAlias: this.envService.config.canopyConnectAlias,
                    pullMetaData: {
                        quoteEnsId,
                        policyEnsId,
                        env: this.envService.currentEnv,
                        ...runEnv,
                    },
                    ...callbackFns,
                });
                return of(canopyConnectHandler);
            })
        );
    }
}
