import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
// import { MetaTag, MetaTags } from '@common/models';
import { DynamicRouteData } from '@modules/navigation/models';
import { camelCase } from 'change-case';
import { routeData } from 'data/route-data';

import { EnvService, LogService } from '.';

@Injectable()
export class MetaService {
    private renderer2: Renderer2;
    constructor(
        private meta: Meta,
        private titleService: Title,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private rendererFactory: RendererFactory2,
        private envService: EnvService,
        private logService: LogService
    ) {
        this.renderer2 = this.rendererFactory.createRenderer(null, null);
    }

    // eslint-disable-next-line complexity
    process(url: string): DynamicRouteData | undefined {
        const data = this.getDataForURL(url);
        if (!data) return;

        this.titleService.setTitle(data.title || 'Tredder');

        // this._doCanonical(url);

        // INFO: OpenGraph does not work with dynamic meta tags when rendered client side. only ssr.
        // const dynamicTags = this.meta.getTags('data-dynamic=true');

        // let dynamicTag: HTMLMetaElement;
        // for (dynamicTag of dynamicTags) {
        //     this.meta.removeTagElement(dynamicTag);
        // }

        // if (!data.metaTags) return;

        // let metaTag: MetaTag;

        // for (metaTag in data.metaTags) {
        //     if (data.metaTags[metaTag]?.attribute === 'name') {
        //         this.meta.addTag({
        //             name: MetaTags[metaTag],
        //             content: data.metaTags[metaTag]?.value as string,
        //             'data-dynamic': 'true',
        //         });
        //     }
        //     if (data.metaTags[metaTag]?.attribute === 'property') {
        //         this.meta.addTag({
        //             property: MetaTags[metaTag],
        //             content: data.metaTags[metaTag]?.value as string,
        //             'data-dynamic': 'true',
        //         });
        //     }
        // }
        return data;
    }

    // private _doCanonical(url: string) {
    //     url = url.replace(/#.*/, '');
    //     url = url.replace(/\?.*$/, '');
    //     url = `${this.envService.config.frontendURL}${url}`;
    //     const head = this.document.getElementsByTagName('head')[0];
    //     const currentCanonical: HTMLLinkElement | null =
    //         this.document.querySelector(`link[rel='canonical']`) || null;
    //     if (currentCanonical) {
    //         this.renderer2.setAttribute(currentCanonical, 'href', url);
    //         return;
    //     }
    //     const newCanonical = this.renderer2.createElement('link');
    //     newCanonical.rel = 'canonical';
    //     newCanonical.href = url;
    //     this.renderer2.appendChild(head, newCanonical);
    // }

    getDataForURL(url = this.currentURL): DynamicRouteData | false {
        url = url.replace(/#.*/, '');
        url = url.replace(/\?.*$/, '');
        if (url === '/' || url === '') {
            url = 'home';
        }
        url = url.replace(/\?.*$/, '');
        url = url.replace(/^\//, '');
        url = camelCase(url);
        const data = routeData[url];
        if (!data) {
            this.logService.warn(`No DynamicRouteData for ${url}`);
            return false;
        }
        return data;
    }

    isPublic(url: string) {
        const data = this.getDataForURL(url);
        if (!data) return;
        return !!data.isPublic;
    }

    get currentURL() {
        return this.router.routerState.snapshot.url;
    }
}
