import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MarkedService } from '@common/services';

@Component({
    selector: 'sbw-markdown-html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './markdown-html.component.html',
    styleUrls: ['markdown-html.component.scss'],
})
export class MarkdownHTMLComponent implements OnInit, OnChanges {
    safeHTML!: SafeHtml;

    @Input() md!: string;
    constructor(
        private markedService: MarkedService,
        private sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    async ngOnInit() {
        await this.markedService.init();
        const compiledMD = await this.markedService.compile(this.md);
        this.safeHTML = this.sanitizer.bypassSecurityTrustHtml(compiledMD);
        this.changeDetectorRef.detectChanges();
    }
    async ngOnChanges(changes: SimpleChanges) {
        if (changes.md.firstChange) {
            return;
        }
        const compiledMD = await this.markedService.compile(this.md);
        this.safeHTML = this.sanitizer.bypassSecurityTrustHtml(compiledMD);
        this.changeDetectorRef.detectChanges();
    }
}
