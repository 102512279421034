import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import {
    QuoteEnsFlowFormValue,
    SpecialCoverageAdjustmentsFormValue,
} from '@backend-types/quote-flow-ens';
import {
    CampsiteLiabilityLimit,
    campsiteLiabilityLimitText,
    EmergencyExpenseLimit,
    emergencyExpenseLimitText,
    TowRoadsideLimit,
    towRoadsideLimitText,
} from '@backend-types/roc-lib';
import { ModelFormGroup } from '@common/models';
import { QuoteEnsFormService, QuoteEnsRetrievalService } from '@modules/quote/services';
import compare from 'just-compare';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-coverage-adjustments-form-extras',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './coverage-adjustments-form-extras.component.html',
    styleUrls: ['coverage-adjustments-form-extras.component.scss'],
})
export class CoverageAdjustmentsFormExtrasComponent implements OnInit, OnDestroy {
    quoteEnsFlowForm!: ModelFormGroup<QuoteEnsFlowFormValue>;
    quoteEnsFlowFormValue!: QuoteEnsFlowFormValue;
    activeEnsQuote!: EnsResponseWithEnsOnly | null;

    eCampsiteLiabilityLimitText = campsiteLiabilityLimitText;
    eEmergencyExpenseLimitText = emergencyExpenseLimitText;
    eTowRoadsideLimitText = towRoadsideLimitText;

    subscription: Subscription = new Subscription();

    specialCoveragesAdjustmentsForm: ModelFormGroup<SpecialCoverageAdjustmentsFormValue> =
        this.fb.group({
            campsiteLiabilityEnabled: new FormControl<boolean | null>(null, Validators.required),
            campsiteLiabilityLimit: new FormControl<CampsiteLiabilityLimit | null>(
                null,
                Validators.required
            ),
            emergencyExpenseEnabled: new FormControl<boolean | null>(null, Validators.required),
            emergencyExpenseLimit: new FormControl<EmergencyExpenseLimit | null>(
                null,
                Validators.required
            ),
            offRoadRecoveryEnabled: new FormControl<boolean | null>(null, Validators.required),
            scheduledMedicalBenefitEnabled: new FormControl<boolean | null>(
                null,
                Validators.required
            ),
            towRoadsideEnabled: new FormControl<boolean | null>(null, Validators.required),
            towRoadsideLimit: new FormControl<TowRoadsideLimit | null>(null, Validators.required),
        });

    constructor(
        private fb: FormBuilder,
        private quoteEnsFormService: QuoteEnsFormService,
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowForm$.subscribe((quoteEnsFlowForm) => {
                this.quoteEnsFlowForm = quoteEnsFlowForm;
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowFormValuePure$.subscribe(
                (quoteEnsFlowFormValuePure) => {
                    this._processQuoteEnsFlowForm(quoteEnsFlowFormValuePure);
                    this.changeDetectorRef.detectChanges();
                }
            )
        );

        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuote$.subscribe((activeEnsQuote) => {
                this.activeEnsQuote = activeEnsQuote;
                this.changeDetectorRef.detectChanges();
            })
        );

        // Special Enabled
        this.subscription.add(
            this.campsiteLiabilityEnabledControl.valueChanges.subscribe(
                (campsiteLiabilityEnabledControlValue) => {
                    if (
                        this.quoteEnsFlowFormValue.specialCoverages.campsiteLiability.selected ===
                        campsiteLiabilityEnabledControlValue
                    ) {
                        return; // INFO: Avoid infinite loop with setQuoteEnsFlowForm below
                    }
                    this.quoteEnsFlowFormValue.specialCoverages.campsiteLiability.selected =
                        campsiteLiabilityEnabledControlValue;
                    if (campsiteLiabilityEnabledControlValue) {
                        this.campsiteLiabilityLimitControl.enable({ emitEvent: false });
                    } else {
                        this.campsiteLiabilityLimitControl.disable({ emitEvent: false });
                    }
                    this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
                }
            )
        );
        this.subscription.add(
            this.emergencyExpenseEnabledControl.valueChanges.subscribe(
                (emergencyExpenseEnabledControlValue) => {
                    if (
                        this.quoteEnsFlowFormValue.specialCoverages.emergencyExpense.selected ===
                        emergencyExpenseEnabledControlValue
                    ) {
                        return; // INFO: Avoid infinite loop with setQuoteEnsFlowForm below
                    }
                    this.quoteEnsFlowFormValue.specialCoverages.emergencyExpense.selected =
                        emergencyExpenseEnabledControlValue;
                    if (emergencyExpenseEnabledControlValue) {
                        this.emergencyExpenseLimitControl.enable({ emitEvent: false });
                    } else {
                        this.emergencyExpenseLimitControl.disable({ emitEvent: false });
                    }
                    this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
                }
            )
        );
        this.subscription.add(
            this.offRoadRecoveryEnabledControl.valueChanges.subscribe(
                (offRoadRecoveryEnabledControlValue) => {
                    if (
                        this.quoteEnsFlowFormValue.specialCoverages.offRoadRecovery.selected ===
                        offRoadRecoveryEnabledControlValue
                    ) {
                        return; // INFO: Avoid infinite loop with setQuoteEnsFlowForm below
                    }
                    this.quoteEnsFlowFormValue.specialCoverages.offRoadRecovery.selected =
                        offRoadRecoveryEnabledControlValue;
                    this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
                }
            )
        );
        this.subscription.add(
            this.scheduledMedicalBenefitEnabledControl.valueChanges.subscribe(
                (scheduledMedicalBenefitEnabledControlValue) => {
                    if (
                        this.quoteEnsFlowFormValue.specialCoverages.scheduledMedicalBenefit
                            .selected === scheduledMedicalBenefitEnabledControlValue
                    ) {
                        return; // INFO: Avoid infinite loop with setQuoteEnsFlowForm below
                    }
                    this.quoteEnsFlowFormValue.specialCoverages.scheduledMedicalBenefit.selected =
                        scheduledMedicalBenefitEnabledControlValue;
                    this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
                }
            )
        );
        this.subscription.add(
            this.towRoadsideEnabledControl.valueChanges.subscribe(
                (towRoadsideEnabledControlValue) => {
                    if (
                        this.quoteEnsFlowFormValue.specialCoverages.towRoadside.selected ===
                        towRoadsideEnabledControlValue
                    ) {
                        return; // INFO: Avoid infinite loop with setQuoteEnsFlowForm below
                    }
                    this.quoteEnsFlowFormValue.specialCoverages.towRoadside.selected =
                        towRoadsideEnabledControlValue;
                    if (towRoadsideEnabledControlValue) {
                        this.towRoadsideLimitControl.enable({ emitEvent: false });
                    } else {
                        this.towRoadsideLimitControl.disable({ emitEvent: false });
                    }

                    this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
                }
            )
        );

        // Special Limit
        this.subscription.add(
            this.campsiteLiabilityLimitControl.valueChanges.subscribe(
                (campsiteLiabilityLimitControlValue) => {
                    this.quoteEnsFlowFormValue.specialCoverages.campsiteLiability.campsiteLiabilityLimit =
                        campsiteLiabilityLimitControlValue;
                    this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
                }
            )
        );
        this.subscription.add(
            this.emergencyExpenseLimitControl.valueChanges.subscribe(
                (emergencyExpenseLimitControlValue) => {
                    this.quoteEnsFlowFormValue.specialCoverages.emergencyExpense.emergencyExpenseLimit =
                        emergencyExpenseLimitControlValue;
                    this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
                }
            )
        );
        this.subscription.add(
            this.towRoadsideLimitControl.valueChanges.subscribe((towRoadsideLimitControlValue) => {
                this.quoteEnsFlowFormValue.specialCoverages.towRoadside.towRoadsideLimit =
                    towRoadsideLimitControlValue;
                this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // eslint-disable-next-line complexity
    private _processQuoteEnsFlowForm(quoteEnsFlowFormValuePure: QuoteEnsFlowFormValue | null) {
        if (quoteEnsFlowFormValuePure === null) {
            return;
        }
        if (compare(this.quoteEnsFlowFormValue, quoteEnsFlowFormValuePure)) {
            return;
        }
        this.quoteEnsFlowFormValue = quoteEnsFlowFormValuePure;

        this.specialCoveragesAdjustmentsForm.setValue(
            {
                campsiteLiabilityEnabled:
                    this.quoteEnsFlowFormValue.specialCoverages.campsiteLiability.selected,
                campsiteLiabilityLimit:
                    this.quoteEnsFlowFormValue.specialCoverages.campsiteLiability
                        .campsiteLiabilityLimit,
                emergencyExpenseEnabled:
                    this.quoteEnsFlowFormValue.specialCoverages.emergencyExpense.selected,
                emergencyExpenseLimit:
                    this.quoteEnsFlowFormValue.specialCoverages.emergencyExpense
                        .emergencyExpenseLimit,
                offRoadRecoveryEnabled:
                    this.quoteEnsFlowFormValue.specialCoverages.offRoadRecovery.selected,
                scheduledMedicalBenefitEnabled:
                    this.quoteEnsFlowFormValue.specialCoverages.scheduledMedicalBenefit.selected,
                towRoadsideEnabled:
                    this.quoteEnsFlowFormValue.specialCoverages.towRoadside.selected,
                towRoadsideLimit:
                    this.quoteEnsFlowFormValue.specialCoverages.towRoadside.towRoadsideLimit,
            },
            { emitEvent: false }
        );

        if (!this.campsiteLiabilityEnabledControl.value) {
            this.campsiteLiabilityLimitControl.disable({ emitEvent: false });
        }

        if (!this.emergencyExpenseEnabledControl.value) {
            this.emergencyExpenseLimitControl.disable({ emitEvent: false });
        }

        if (!this.towRoadsideEnabledControl.value) {
            this.towRoadsideLimitControl.disable({ emitEvent: false });
        }
    }

    /* Accessor Methods */

    // Special Enabled
    get campsiteLiabilityEnabledControl() {
        return this.specialCoveragesAdjustmentsForm.get('campsiteLiabilityEnabled') as FormControl;
    }

    get emergencyExpenseEnabledControl() {
        return this.specialCoveragesAdjustmentsForm.get('emergencyExpenseEnabled') as FormControl;
    }

    get offRoadRecoveryEnabledControl() {
        return this.specialCoveragesAdjustmentsForm.get('offRoadRecoveryEnabled') as FormControl;
    }

    get scheduledMedicalBenefitEnabledControl() {
        return this.specialCoveragesAdjustmentsForm.get(
            'scheduledMedicalBenefitEnabled'
        ) as FormControl;
    }

    get towRoadsideEnabledControl() {
        return this.specialCoveragesAdjustmentsForm.get('towRoadsideEnabled') as FormControl;
    }

    // Special Limit
    get campsiteLiabilityLimitControl() {
        return this.specialCoveragesAdjustmentsForm.get('campsiteLiabilityLimit') as FormControl;
    }

    get emergencyExpenseLimitControl() {
        return this.specialCoveragesAdjustmentsForm.get('emergencyExpenseLimit') as FormControl;
    }

    get towRoadsideLimitControl() {
        return this.specialCoveragesAdjustmentsForm.get('towRoadsideLimit') as FormControl;
    }
}
