import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { StateShort, unlicensedStates } from '@backend-types/states';
import { AnalyticsService, HotKeysService, ToastService } from '@common/services';
import { CalculateQuoteEnsTotalResponse, QuoteEnsFormStep } from '@modules/quote/models';
import {
    PartnerService,
    QuoteEnsCalculateService,
    QuoteEnsFormService,
    QuoteEnsRetrievalService,
    QuoteService,
    RateIssue,
    RateIssueService,
} from '@modules/quote/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-rates',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-rates.component.html',
    styleUrls: ['quote-ens-rates.component.scss'],
})
export class QuoteEnsRatesComponent implements OnInit, OnDestroy {
    @ViewChild('quoteBox') quoteBox!: ElementRef<HTMLDivElement>;
    @ViewChild('underQuoteBox') underQuoteBox!: ElementRef<HTMLDivElement>;

    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();
    @Output() jump = new EventEmitter<QuoteEnsFormStep>();

    // quoteTotal!: string;
    // quoteTotalDollars!: string;
    // quoteTotalCents!: string;
    quoteEnsTotal: CalculateQuoteEnsTotalResponse | null = null;

    activeEnsQuote!: EnsResponseWithEnsOnly | null;

    rateIssues: RateIssue[] = [];

    quotePriceCardFixed = false;

    underQuoteBoxTopMax = 10000; // Something high enough to start

    subscription: Subscription = new Subscription();

    constructor(
        private hotKeysService: HotKeysService,
        private router: Router,
        private quoteEnsCalculateService: QuoteEnsCalculateService,
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private quoteEnsFormService: QuoteEnsFormService,
        private quoteService: QuoteService,
        private ngbModalService: NgbModal,
        private rateIssueService: RateIssueService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private title: Title,
        private analyticsService: AnalyticsService,
        private partnerService: PartnerService
    ) {
        this.title.setTitle('Tredder Quote - Rates');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_rates',
        });
        this.analyticsService.sendConversionQuoteRates();
    }
    ngOnInit() {
        if (
            this.quoteEnsFormService.quoteEnsFlowFormValue.homeBase &&
            this.quoteEnsFormService.quoteEnsFlowFormValue.homeBase.country !== 'US'
        ) {
            this.router.navigate(['/quote/sorry'], {
                queryParams: {
                    reason: 'not-in-area',
                },
            });
        }
        this.partnerService.smPostback();
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.purchase();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.quoteEnsCalculateService.quoteEnsTotal$.subscribe((quoteEnsTotal) => {
                this.quoteEnsTotal = quoteEnsTotal;
                // if (!quoteEnsTotal) {
                //     this.quoteTotal = '--';
                //     this.quoteTotalDollars = '--';
                //     this.quoteTotalCents = '--';
                // } else {
                //     const { quoteTotal, quoteTotalDollars, quoteTotalCents } = quoteEnsTotal;
                //     this.quoteTotal = quoteTotal;
                //     this.quoteTotalDollars = quoteTotalDollars;
                //     this.quoteTotalCents = quoteTotalCents;
                // }
                this.changeDetectorRef.detectChanges();
            })
        );
        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuote$.subscribe((activeEnsQuote) => {
                if (!activeEnsQuote) {
                    return;
                }

                this.activeEnsQuote = activeEnsQuote;

                this.changeDetectorRef.detectChanges();
            })
        );
        this.subscription.add(
            this.rateIssueService.rateIssues$.subscribe((rateIssues) => {
                this.rateIssues = rateIssues;
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener('window:scroll', ['$event'])
    scrollHandler(event: Event) {
        const quoteBoxTop = this.quoteBox.nativeElement.getBoundingClientRect().top;
        const underQuoteBoxTop = this.underQuoteBox.nativeElement.getBoundingClientRect().top;

        if (quoteBoxTop <= 0) {
            if (!this.quotePriceCardFixed) {
                this.quotePriceCardFixed = true;
                this.underQuoteBoxTopMax = underQuoteBoxTop;
                this.changeDetectorRef.detectChanges();
            }
        }

        if (underQuoteBoxTop > this.underQuoteBoxTopMax) {
            if (this.quotePriceCardFixed) {
                this.quotePriceCardFixed = false;
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    purchase() {
        if (unlicensedStates.includes(this.state as StateShort)) {
            this.router.navigate(['/quote/sorry'], {
                queryParams: {
                    reason: 'unlicensed-state',
                },
            });

            return;
        }
        this.subscription.add(
            this.quoteEnsRetrievalService
                .updateQuoteForPurchase$({
                    generateDocs: false,
                    updateAddress: true,
                })
                .subscribe(() => {
                    this.router.navigate(['/quote/ens'], {
                        queryParams: {
                            'quote-ens-form-step': 'purchase',
                        },
                    });
                })
        );
    }

    emailQuote() {
        this.quoteService
            .emailQuote$(this.quoteEnsRetrievalService.activeQuoteEnsId)
            .subscribe(() => {
                this.toastService.showSuccess({
                    body: `Quote emailed to ${this.emailAddress}`,
                    options: {
                        autohide: true,
                        delay: 5000,
                        closeOnClick: true,
                    },
                });
            });
    }

    /*
     * ACCESSORS
     * */
    get emailAddress() {
        return this.quoteEnsFormService.quoteEnsFlowFormValue.ensPersonalInfo!.email;
    }

    get state() {
        return this.quoteEnsFormService.quoteEnsFlowFormValue.homeBase!.state;
    }
}
