import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { State } from '@backend-types/states';
import { ModelFormGroup } from '@common/models';
import {
    AnalyticsService,
    AssertionService,
    EnvService,
    OverlayService,
    SignUpService,
    UtilityService,
} from '@common/services';
import { catchError, of, Subscription } from 'rxjs';

interface EarlyAccessFormValue {
    email: string;
    zip: string;
}

@Component({
    selector: 'sbf-early-access-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './early-access-form.component.html',
    styleUrls: ['early-access-form.component.scss'],
})
export class EarlyAccessFormComponent implements OnInit, OnDestroy {
    invalidZip: string | null = null;

    eState = State;
    subscription: Subscription = new Subscription();

    earlyAccessForm: ModelFormGroup<EarlyAccessFormValue> = this.fb.group({
        email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
        zip: new FormControl<string | null>(null, [Validators.required, Validators.minLength(5)]),
    });

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private envService: EnvService,
        private signUpService: SignUpService,
        private utilityService: UtilityService,
        private overlayService: OverlayService,
        private assertionService: AssertionService,
        private analyticsService: AnalyticsService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        const notInZipZip = this.utilityService.localStorage.getItem('notInZipZip');
        if (notInZipZip) {
            this.zipControl.setValue(notInZipZip);
            this.utilityService.localStorage.removeItem('notInZipZip');
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onSubmit() {
        if (this.earlyAccessForm.status === 'VALID') {
            if (this.utilityService.localStorageClean.getItem('sb-testing')) {
                return this._submitEarlyAccess('TESTING');
            } else {
                this.utilityService.window.grecaptcha.ready(() => {
                    this.utilityService.window.grecaptcha
                        .execute(this.envService.config.recaptchaSiteKey, {
                            action: 'joinCommunity',
                        })
                        .then((token) => {
                            this._submitEarlyAccess(token);
                        });
                });
            }
        }

        this.earlyAccessForm.markAllAsTouched();
    }

    private _submitEarlyAccess(token: string) {
        this.overlayService.show('Joining...');
        this.invalidZip = null;
        const earlyAccessFormValue = this._earlyAccessFormValue();

        this.signUpService
            .earlyAccess$({
                email: earlyAccessFormValue.email,
                zip: earlyAccessFormValue.zip,
                grToken: token,
            })
            .pipe(
                catchError((error) => {
                    this.invalidZip = this.zipControl.value;
                    this.overlayService.hide();
                    this.changeDetectorRef.detectChanges();
                    return of();
                })
            )
            .subscribe(() => {
                this.overlayService.hide();
                this.changeDetectorRef.detectChanges();
                this.analyticsService.sendEventSignUp('overlandExpo');
                this.router.navigate(['/early-access-success']);
            });
    }

    private _earlyAccessFormValue(): EarlyAccessFormValue {
        const { email, zip } = this.earlyAccessForm.value;

        this.assertionService.isDefinedOrThrow(email);
        this.assertionService.isDefinedOrThrow(zip);

        return {
            email,
            zip,
        };
    }

    /* Accessor Methods */

    get emailControl() {
        return this.earlyAccessForm.get('email') as FormControl;
    }

    get emailControlValid() {
        return this.emailControl.touched && !this.emailControlInvalid;
    }

    get emailControlInvalid() {
        return (
            this.emailControl.touched &&
            (this.emailControl.hasError('required') || this.emailControl.hasError('email'))
        );
    }

    get zipControl() {
        return this.earlyAccessForm.get('zip') as FormControl;
    }

    get zipControlValid() {
        return this.zipControl.touched && !this.zipControlInvalid;
    }

    get zipControlInvalid() {
        return (
            this.zipControl.touched &&
            (this.zipControl.hasError('required') || this.zipControl.hasError('minlength'))
        );
    }
}
