import { DynamicRouteData } from '@modules/navigation/models';

export const error400: DynamicRouteData = {
    isPublic: true,
    title: 'Error 400',
};

export const error401: DynamicRouteData = {
    isPublic: true,
    title: 'Error 401',
};

export const error403: DynamicRouteData = {
    isPublic: true,
    title: 'Error 403',
};

export const error404: DynamicRouteData = {
    isPublic: true,
    title: 'Error 404',
};

export const error500: DynamicRouteData = {
    isPublic: true,
    title: 'Error 500',
};

export const error503: DynamicRouteData = {
    isPublic: true,
    title: 'Error 503',
};

export const error504: DynamicRouteData = {
    isPublic: true,
    title: 'Error 504',
};

export const errorConfigs = { error401, error404, error500, error503 };
