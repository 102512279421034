import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbf-markdown-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './markdown-modal.component.html',
    styleUrls: ['markdown-modal.component.scss'],
})
export class MarkdownModalComponent implements OnInit {
    title!: string;
    md!: string;

    constructor(public activeModal: NgbActiveModal) {}
    ngOnInit() {}
    close() {
        this.activeModal.close();
    }
}
