import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'sbf-chevron-expand-contract',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './chevron-expand-contract.component.html',
    styleUrls: ['chevron-expand-contract.component.scss'],
})
export class ChevronExpandContractComponent implements OnInit {
    @Input() initialState!: 'expand' | 'contract';
    @Output() expand = new EventEmitter<void>();
    @Output() contract = new EventEmitter<void>();
    expandContractState!: 'expand' | 'contract';

    constructor() {}
    ngOnInit() {
        if (!this.initialState) {
            throw new Error('MUST_SET_INITIAL_STATE');
        }
        this.expandContractState = this.initialState;
    }

    clicked(event: PointerEvent) {
        event.stopImmediatePropagation();

        if (this.expandContractState === 'expand') {
            this.contract.emit();
            this.expandContractState = 'contract';
        } else {
            this.expand.emit();
            this.expandContractState = 'expand';
        }
    }
}
