import { DevUtilsModalConfig } from '@common/models';

import { homeBaseScenarios, personalInfoScenarios, rc2Scenarios } from './scenarios';

export const devUtilsModalConfig: DevUtilsModalConfig = {
    homeBase: {
        header: 'Home Base Dev Tools',
        scenarios: homeBaseScenarios,
    },
    personalInfo: {
        header: 'Personal Info Base Dev Tools',
        scenarios: personalInfoScenarios,
    },
    rc2: {
        header: 'RC2 Dev Tools',
        scenarios: rc2Scenarios,
    },
};
