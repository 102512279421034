<form [formGroup]="activeQuoteRigSetForm" *ngIf="activeQuoteRigSetForm">
    <div class="row">
        <div class="col-sm-6 col-12">
            <div class="r-text-lg text-primary mb-2 ms-1">Vehicle Use</div>
            <div class="d-inline-block w-100"><sbf-quote-rig-set-form-rig-details-quick [formOnly]="true" (rigDetailsFormValid)="rigDetailsFormValid.next($event)" sbwAutoFocus formControlName="rigDetails"></sbf-quote-rig-set-form-rig-details-quick></div>
        </div>
        <div class="col-sm-6 col-12" *ngIf="estimateForQuote?.estimatePayload?.modsValue">
            <div class="r-text-lg text-primary mb-2 ms-1">Modifications</div>
            <div class="d-inline-block w-100"><sbf-quote-rig-set-form-mods-details [formOnly]="true" (modsDetailsFormValid)="modsDetailsFormValid.next($event)" formControlName="modsDetails"></sbf-quote-rig-set-form-mods-details></div>
        </div>
    </div>
    <div class="row" *ngIf="estimateForQuote?.estimatePayload?.camperValue">
        <div class="r-text-lg text-primary mb-2 ms-1">Camper</div>
        <div class="d-inline-block w-100 mb-3"><sbf-quote-rig-set-form-camper-details [formOnly]="true" (camperDetailsFormValid)="camperDetailsFormValid.next($event)" formControlName="camperDetails"></sbf-quote-rig-set-form-camper-details></div>
    </div>
    <div class="row" *ngIf="estimateForQuote?.estimatePayload?.trailerValue">
        <div class="r-text-lg text-primary mb-2 ms-1">Trailer</div>
        <div class="d-inline-block w-100"><sbf-quote-rig-set-form-trailer-details [formOnly]="true" (trailerDetailsFormValid)="trailerDetailsFormValid.next($event)" formControlName="trailerDetails"></sbf-quote-rig-set-form-trailer-details></div>
    </div>
</form>
