<div class="tredder-card mb-4" *ngIf="activeEnsQuote &amp;&amp; quoteEnsFlowFormValue">
    <h2>Coveragee</h2>
    <div class="row">
        <div class="col-xl-6 col-12">
            <ng-container *ngFor="let rigSet of quoteEnsFlowFormValue.rigSets; let i = index;"
                ><div class="text-primary text-lg mb-2">{{rigSet.rigName}}</div>
                <div class="ms-3" *ngIf="rigSet.coverageSelect.modsCoverage">
                    <div class="d-inline-block text-cyan-blue me-3"><i-bs name="check-lg" width="1.5em" height="1.5em"></i-bs></div>
                    <div class="d-inline-block align-bottom lh-30">{{rigSet.modsDetails.modsValue | currency : 'USD' : 'symbol' : '1.0'}} worth of coverage for mods and upgrades</div>
                </div>
                <div class="ms-3" *ngIf="rigSet.coverageSelect.camperCoverage">
                    <div class="d-inline-block text-cyan-blue me-3"><i-bs name="check-lg" width="1.5em" height="1.5em"></i-bs></div>
                    <div class="d-inline-block align-bottom lh-30">{{rigSet.camperDetails.unitValue | currency : 'USD' : 'symbol' : '1.0'}} worth of coverage for my camper</div>
                </div>
                <div class="ms-3" *ngIf="rigSet.coverageSelect.trailerCoverage">
                    <div class="d-inline-block text-cyan-blue me-3"><i-bs name="check-lg" width="1.5em" height="1.5em"></i-bs></div>
                    <div class="d-inline-block align-bottom lh-30">{{rigSet.trailerDetails.unitValue | currency : 'USD' : 'symbol' : '1.0'}} worth of coverage for my trailer</div>
                </div>
                <div class="mb-3"></div>
                <div class="ms-3 mb-3" *ngIf="quoteEnsFlowFormValue.gearCoverage">
                    <div class="d-inline-block text-cyan-blue me-3"><i-bs name="check-lg" width="1.5em" height="1.5em"></i-bs></div>
                    <div class="d-inline-block align-bottom lh-30">{{quoteEnsFlowFormValue.gearDetails.gearValue | currency : 'USD' : 'symbol' : '1.0'}} worth of coverage for my gear</div>
                </div></ng-container
            >
        </div>
        <div class="col-xl-6 col-12">
            <div class="ms-3" *ngIf="quoteEnsFlowFormValue.specialCoverages.offRoadRecovery.selected">
                <div class="d-inline-block text-cyan-blue me-3"><i-bs name="check-lg" width="1.5em" height="1.5em"></i-bs></div>
                <div class="d-inline-block align-bottom lh-30">{{quoteEnsFlowFormValue.specialCoverages.offRoadRecovery.offRoadRecoveryLimit | currency : 'USD' : 'symbol' : '1.0'}} of off-road recovery coverage</div>
            </div>
            <div class="ms-3" *ngIf="quoteEnsFlowFormValue.specialCoverages.emergencyExpense.selected">
                <div class="d-inline-block text-cyan-blue me-3"><i-bs name="check-lg" width="1.5em" height="1.5em"></i-bs></div>
                <div class="d-inline-block align-bottom lh-30">{{quoteEnsFlowFormValue.specialCoverages.emergencyExpense.emergencyExpenseLimit | currency : 'USD' : 'symbol' : '1.0'}} of emergency expense coverage</div>
            </div>
            <div class="ms-3" *ngIf="quoteEnsFlowFormValue.specialCoverages.towRoadside.selected">
                <div class="d-inline-block text-cyan-blue me-3"><i-bs name="check-lg" width="1.5em" height="1.5em"></i-bs></div>
                <div class="d-inline-block align-bottom lh-30">{{quoteEnsFlowFormValue.specialCoverages.towRoadside.towRoadsideLimit | currency : 'USD' : 'symbol' : '1.0'}} of towing and roadside assistance coverage</div>
            </div>
            <div class="ms-3 mt-3">
                <div class="d-inline-block text-cyan-blue me-3"><i-bs name="check-lg" width="1.5em" height="1.5em"></i-bs></div>
                <div class="d-inline-block align-bottom lh-30">Six month coverage term</div>
            </div>
        </div>
    </div>
</div>
