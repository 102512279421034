<div class="modal-header"><div class="r-text-lg heavy text-primary">Payment Schedule</div></div>
<div class="modal-body p-4">
    <ng-container *ngFor="let installment of installments; let i = index;"
        ><div class="d-flex text-primary-subtle mb-2 align-items-end">
            <div class="flex-shrink-0">{{i === 0 ? 'Today' : installment.dueDate | isoDateFormatUS}}</div>
            <div class="w-100 dotted border-primary-subtle"></div>
            <div class="flex-shrink-0">{{installment.debit | currency : 'USD' : 'symbol' : '1.2'}}</div>
        </div></ng-container
    >
</div>
<div class="d-flex justify-content-end px-3 pb-3">
    <button class="btn rounded-pill btn-primary-gradient text-white fw-500" data-cy="closeTaxesFeesModal" (click)="close()" tabindex="0"><div class="mx-3">Close</div></button>
</div>
