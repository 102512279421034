<sbf-floating-bubble
    ><div class="d-flex justify-content-center mb-2"><div class="heavy">GET EARLY ACCESS</div></div>
    <div class="d-flex justify-content-center mb-2"><div class="text-sm text-red text-left mb-2" *ngIf="invalidZip">Invalid zip code: {{invalidZip}}. Try again.</div></div>
    <form class="text-base" [formGroup]="earlyAccessForm" (ngSubmit)="onSubmit()">
        <div class="input-group mb-3">
            <input class="form-control" id="emailInput" data-cy="emailInput" autocomplete="email" type="email" aria-describedby="emailHelp" sbwAutoFocus formControlName="email" placeholder="Email address" [class.is-valid]="emailControlValid" [class.is-invalid]="emailControlInvalid" />
            <div class="invalid-feedback" *ngIf='emailControl.hasError("required")'>Email required.</div>
            <div class="invalid-feedback" *ngIf='emailControl.hasError("email")'>Email is not valid.</div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-floating">
                    <input class="form-control" id="zipInput" data-cy="zipInput" autocomplete="off" type="text" aria-describedby="zipHelp" formControlName="zip" [class.is-invalid]="zipControlInvalid" placeholder="zip" mask="99999" />
                    <div class="invalid-feedback" *ngIf='zipControl.hasError("required")'>Zip Code required.</div>
                    <div class="invalid-feedback" *ngIf='zipControl.hasError("minlength")'>Zip Code must be 5 digits.</div>
                    <label class="text-gray-600 small" for="zipInput">Zip Code</label>
                </div>
            </div>
            <div class="col-6">
                <div class="btn-shadow-container"><button class="btn btn-lg btn-secondary fw-700 btn-bevel bevel-10 w-100" data-cy="earlyAccessSubmitButton" type="submit" [disabled]="earlyAccessForm.invalid">SUBMIT</button></div>
            </div>
        </div>
    </form></sbf-floating-bubble
>
