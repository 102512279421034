export enum CurrentInsurance {
    aaa = 'aaa',
    americanModern = 'americanmodern', // collector vehicle only
    amica = 'amica',
    assurant = 'assurant',
    bearRiverMutual = 'bearrivermutual',
    branch = 'branch',
    californiaCasualty = 'californiacasualty',
    chubb = 'chubb',
    coloradoFarmBureau = 'coloradofarmbureau',
    connectByAmFam = 'connectbyamfam',
    dairyLand = 'dairyland',
    directAuto = 'directauto',
    elephant = 'elephant',
    encompass = 'encompass',
    esurance = 'esurance',
    farmBureauFinancialServices = 'farmbureaufinancialservices',
    foremost = 'foremost',
    fredLoya = 'fredloya',
    geico = 'geico',
    hagerty = 'hagerty',
    homeSite = 'homesite',
    horaceMann = 'horacemann',
    integrityPc = 'integritypc',
    kemperPersonalInsurance = 'kemperpersonalinsurance',
    lemonade = 'lemonade',
    libertyMutual = 'libertymutual',
    louisianaFarmBureau = 'louisianafarmbureau',
    metlife = 'metlife',
    metroMile = 'metromile',
    midvaleInsuranceAuto = 'midvaleinsuranceauto',
    nationwide = 'nationwide',
    njm = 'njm',
    pemcoMutual = 'pemcomutual',
    qbe = 'qbe',
    root = 'root',
    safeCo = 'safeco',
    tennesseeFarmBureau = 'tennesseefarmbureau',
    theGeneral = 'thegeneral',
    twentyFirstCentury = '21stcentury',
    wawanesa = 'wawanesa',
    georgiaFarmBureau = 'georgiafarmbureau',
    pennNational = 'pennnational',
    donegalGroup = 'donegalgroup',
    indianaFarmBureau = 'indianafarmbureau',
    allstate = 'allstate',
    floridaFarmBureau = 'floridafarmbureau',
    mississippiFarmBureau = 'mississippifarmbureau',
    southCarolinaFarmBureau = 'southcarolinafarmbureau',
    arkansasFarmBureau = 'arkansasfarmbureau',
    progressive = 'progressive',
    travelers = 'travelers',
    berkshireHathawayGuardInsuranceCompanies = 'berkshirehathawayguardinsurancecompanies', // has commercial auto
    autoOwners = 'autoowners',
    stateFarm = 'statefarm',
    cincinnatiFinancialGroup = 'cincinnatifinancialgroup',
    farmers = 'farmers',
    acuity = 'acuity',
    hanover = 'hanover',
    amFam = 'amfam',
    westfield = 'westfield',
    stateAuto = 'stateauto',
    westBend = 'westbend',
    grange = 'grange',
    countryFinancial = 'countryfinancial',
    arbella = 'arbella',
    vermontMutual = 'vermontmutual',
    mainStreetAmerica = 'mainstreetamerica',
    plymouthRock = 'plymouthrock',
    mercury = 'mercury',
    americanNational = 'americannational',
    mapfre = 'mapfre',
    shelter = 'shelter',
    centralInsuranceCompanies = 'centralinsurancecompanies',
    nationalGeneral = 'nationalgeneral',
    texasFarmBureau = 'texasfarmbureau',
    northCarolinaFarmBureau = 'northcarolinafarmbureau',
    pureInsurance = 'pureinsurance',
    kentuckyFarmBureau = 'kentuckyfarmbureau',
    alfaInsurance = 'alfainsurance',
    usaa = 'usaa',
    nycm = 'nycm',
    safetyInsurance = 'safetyinsurance',
    michiganFarmBureau = 'michiganfarmbureau',
    bristolWestInsurance = 'bristolwestinsurance',
    uaic = 'uaic',
    stillwater = 'stillwater',
    erie = 'erie',
    // manual check found no auto is offered
    // americanIntegrityInsurance = 'americanintegrityinsurance',
    // andover = 'andover', // no auto
    // citizensPropertyInsurance = 'citizenspropertyinsurance',
    // fedNatInsuranceFlorida = 'fednatinsuranceflorida',
    // floridaPeninsulaInsurance = 'floridapeninsulainsurance',
    // frontLine = 'frontline',
    // heritageInsurance = 'heritageinsurance',
    // hippo = 'hippo',
    // homeOwnersOfAmerica = 'homeownersofamerica',
    // midvaleInsuranceHome = 'midvaleinsurancehome',
    // sageSure = 'sagesure',
    // securityFirstFlorida = 'securityfirstflorida',
    // selective = 'selective',
    // towerHillInsurance = 'towerhillinsurance', // watercraft only
    // universalProperty = 'universalproperty',
    // upcInsurance = 'upcinsurance',
    // universalNorthAmerica = 'universalnorthamerica',
    // wrightFlood = 'wrightflood',
}

export const currentInsuranceText: EnumMap<CurrentInsurance> = {
    '21stcentury': '21st Century Insurance',
    aaa: 'AAA Insurance',
    acuity: 'Acuity Insurance',
    allstate: 'Allstate',
    alfainsurance: 'Alfa Insurance',
    amfam: 'American Family',
    americanmodern: 'American Modern',
    americannational: 'American National',
    amica: 'Amica Mutual Insurance',
    arbella: 'Arbella Insurance',
    arkansasfarmbureau: 'Arkansas Farm Bureau',
    assurant: 'Assurant',
    autoowners: 'Auto-Owners Insurance',
    bearrivermutual: 'Bear River Mutual',
    berkshirehathawayguardinsurancecompanies: 'Berkshire Hathaway GUARD Insurance Companies',
    branch: 'Branch',
    bristolwestinsurance: 'Bristol West Insurance',
    californiacasualty: 'California Casualty',
    centralinsurancecompanies: 'Central Insurance Companies',
    chubb: 'Chubb',
    cincinnatifinancialgroup: 'Cincinnati Financial Group',
    coloradofarmbureau: 'Colorado Farm Bureau',
    connectbyamfam: 'Connect by American Family',
    countryfinancial: 'COUNTRY Financial',
    dairyland: 'Dairyland',
    directauto: 'Direct Auto Insurance',
    donegalgroup: 'Donegal Insurance Group',
    elephant: 'Elephant Insurance',
    encompass: 'Encompass Insurance',
    erie: 'Erie Insurance',
    esurance: 'Esurance',
    farmbureaufinancialservices: 'Farm Bureau Financial Services',
    farmers: 'Farmers',
    floridafarmbureau: 'Florida Farm Bureau',
    foremost: 'Foremost Insurance Group',
    fredloya: 'Fred Loya Insurance',
    geico: 'GEICO',
    georgiafarmbureau: 'Georgia Farm Bureau',
    grange: 'Grange Insurance',
    hagerty: 'Hagerty',
    hanover: 'The Hanover Insurance Group',
    homesite: 'Homesite Insurance',
    horacemann: 'Horace Mann',
    indianafarmbureau: 'Indiana Farm Bureau',
    integritypc: 'Integrity P&C',
    kemperpersonalinsurance: 'Kemper Personal Insurance',
    kentuckyfarmbureau: 'Kentucky Farm Bureau',
    lemonade: 'Lemonade',
    libertymutual: 'Liberty Mutual',
    louisianafarmbureau: 'Louisiana Farm Bureau',
    mapfre: 'MAPFRE Insurance Company',
    mainstreetamerica: 'MSA Group (Main Street America Insurance)',
    mercury: 'Mercury',
    metlife: 'MetLife',
    metromile: 'Metromile',
    michiganfarmbureau: 'Michigan Farm Bureau',
    midvaleinsuranceauto: 'Midvale Insurance Auto',
    mississippifarmbureau: 'Mississippi Farm Bureau',
    nationalgeneral: 'National General',
    nationwide: 'Nationwide',
    njm: 'NJM (New Jersey Manufacturers)',
    northcarolinafarmbureau: 'North Carolina Farm Bureau',
    nycm: 'NYCM (New York Central Mutual)',
    pemcomutual: 'Pemco Mutual',
    pennnational: 'Penn National Insurance',
    plymouthrock: 'Plymouth Rock Assurance',
    progressive: 'Progressive',
    pureinsurance: 'PURE Insurance',
    qbe: 'QBE North America',
    root: 'Root Insurance',
    safeco: 'Safeco Insurance',
    safetyinsurance: 'Safety Insurance',
    shelter: 'Shelter Insurance',
    southcarolinafarmbureau: 'South Carolina Farm Bureau',
    stateauto: 'State Auto Insurance',
    statefarm: 'State Farm',
    stillwater: 'Stillwater Insurance Group',
    tennesseefarmbureau: 'Tennessee Farm Bureau',
    texasfarmbureau: 'Texas Farm Bureau',
    thegeneral: 'The General',
    travelers: 'Travelers',
    uaic: 'United Automobile Insurance Company',
    usaa: 'USAA',
    vermontmutual: 'Vermont Mutual',
    wawanesa: 'Wawanesa General',
    westbend: 'West Bend Mutual',
    westfield: 'Westfield',
    // fednatinsuranceflorida: 'FedNat Insurance Florida',
    // floridapeninsulainsurance: 'Florida Peninsula Insurance Company',
    // selective: 'Selective',
    // andover: 'The Andover Companies',
    // wrightflood: 'Wright Flood',
    // towerhillinsurance: 'Tower Hill Insurance',
    // universalnorthamerica: 'Universal North America',
    // universalproperty: 'Universal Property & Casualty Insurance Company',
    // upcinsurance: 'UPC Insurance',
    // sagesure: 'SageSure',
    // securityfirstflorida: 'Security First Insurance Company',
    // midvaleinsurancehome: 'Midvale Insurance Home',
    // heritageinsurance: 'Heritage Insurance',
    // hippo: 'Hippo',
    // homeownersofamerica: 'Homeowners of America',
    // frontline: 'Frontline Insurance',
    // citizenspropertyinsurance: 'Citizens Property Insurance',
    // americanintegrityinsurance: 'American Integrity Insurance',
};
