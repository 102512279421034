<div class="svg-container">
    <svg id="svg-spinner" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 37.75 37.75">
        <defs>
            <style>
                .d {
                    fill: #e28b35;
                }
            </style>
        </defs>
        <g id="c">
            <path class="d" d="M18.87,1.53c5.32,0,10.31,2.45,13.57,6.55H7.53v7.08h7.11v17.89h8.51V15.15h12.66c.27,1.22,.4,2.47,.4,3.72,0,9.56-7.78,17.35-17.35,17.35S1.53,28.44,1.53,18.87,9.31,1.53,18.87,1.53m0-1.53C8.45,0,0,8.45,0,18.87s8.45,18.87,18.87,18.87,18.87-8.45,18.87-18.87c0-1.82-.26-3.58-.74-5.25h-15.38V31.51h-5.45V13.63h-7.11v-4.03h26.25C32.07,3.87,25.92,0,18.87,0h0Z" />
        </g>
    </svg>
</div>
