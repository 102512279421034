import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormBuilder,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { InputCheckboxValue, ModelFormGroupArray } from '@common/models';
import { AssertionService } from '@common/services';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-input-checkbox-multi',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './input-checkbox-multi.component.html',
    styleUrls: ['input-checkbox-multi.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: InputCheckboxMultiComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: InputCheckboxMultiComponent,
        },
    ],
})
export class InputCheckboxMultiComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @Input() idAppend!: string;
    @Input() checkColumn!: boolean;

    formClasses = ['form-check', 'form-check-inline'];

    subscription: Subscription = new Subscription();

    inputCheckboxesForm: ModelFormGroupArray<{
        checkboxes: InputCheckboxValue;
    }> = this.fb.group({
        checkboxes: this.fb.array<InputCheckboxValue>([]),
    });

    onTouched: () => unknown = () => {};
    onChange = (checkboxes: InputCheckboxValue[]) => {};

    constructor(
        private fb: FormBuilder,
        private assertionService: AssertionService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        if (!this.idAppend) {
            throw new Error('NEED_ID_APPEND_FOR_INPUT_RADIO');
        }
        if (this.checkColumn) {
            this.formClasses = [
                'd-flex',
                'justify-content-center',
                'align-items-center',
                'flex-column',
            ];
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    clicked(event: InputEvent, index: number) {
        if (!(event.target instanceof HTMLElement)) return;
        // event.stopImmediatePropagation();
        // console.log(event.target?.nodeName);
        if (['INPUT', 'LABEL'].includes(event.target?.nodeName)) {
            return;
        }
        const checkbox = this.inputCheckboxesForm.controls.checkboxes.at(index);
        const currentCheckboxValue = checkbox.value;
        if (currentCheckboxValue) {
            checkbox.patchValue({
                ...currentCheckboxValue,
                checked: !currentCheckboxValue.checked,
            });
        }
    }

    registerOnChange(onChange: (checkboxes: InputCheckboxValue[]) => unknown) {
        this.onChange = onChange;

        this.subscription.add(
            this.inputCheckboxesForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.inputCheckboxesForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const inputCheckboxesFormValue = this._inputCheckboxesFormValuePure();
                        onChange(inputCheckboxesFormValue);
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.inputCheckboxesForm.disable({ emitEvent: false });
        } else {
            this.inputCheckboxesForm.enable({ emitEvent: false });
        }
    }

    writeValue(inputCheckboxesKeyValues: InputCheckboxValue[] | null) {
        if (inputCheckboxesKeyValues === null) {
            this.inputCheckboxesForm.reset();
        }
        if (inputCheckboxesKeyValues) {
            this.inputCheckboxesArray.clear({ emitEvent: false });
            inputCheckboxesKeyValues.forEach((inputCheckboxesKeyValue) => {
                const inputCheckboxesKeyValueForm =
                    this._inputCheckboxesKeyValuesForm(inputCheckboxesKeyValue);
                this.inputCheckboxesArray.push(inputCheckboxesKeyValueForm, { emitEvent: false });
            });
            this.inputCheckboxesArray.updateValueAndValidity();
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.inputCheckboxesForm?.invalid) {
            return { inputCheckboxesFormInvalid: true };
        }

        return null;
    }

    private _inputCheckboxesKeyValuesForm(inputCheckboxKeyValue: InputCheckboxValue) {
        return this.fb.group({
            keyName: [inputCheckboxKeyValue.keyName, Validators.required],
            checked: [inputCheckboxKeyValue.checked, Validators.required],
            wrapperClasses: [inputCheckboxKeyValue.wrapperClasses],
            subText: [inputCheckboxKeyValue.subText],
        });
    }

    private _inputCheckboxesFormValuePure(): InputCheckboxValue[] {
        const { checkboxes } = this.inputCheckboxesForm.value;

        this.assertionService.isDefinedOrThrow(checkboxes);

        return checkboxes.map((checkbox) => {
            this.assertionService.isDefinedOrThrow(checkbox);
            return checkbox;
        });
    }

    /* Accessor Methods */

    get inputCheckboxesArray() {
        return this.inputCheckboxesForm.get('checkboxes') as FormArray;
    }
}
