<div class="tredder-card coverage-card mb-5" *ngIf="quoteEnsFlowFormValue">
    <div class="text-start mb-3">
        <div class="d-flex justify-content-between align-items-center">
            <h2>Personal Info</h2>
            <div class="text-link" data-cy="editPersonalInfoButton" (click)="editPersonalInfo()">edit</div>
        </div>
    </div>
    <hr class="full mb-3" />
    <div class="text-primary r-text">{{quoteEnsFlowFormValue.ensPersonalInfo.firstName}} {{quoteEnsFlowFormValue.ensPersonalInfo.lastName}}</div>
    <div class="text-primary r-text">{{quoteEnsFlowFormValue.ensPersonalInfo.email}}</div>
    <div class="text-primary r-text">{{quoteEnsFlowFormValue.ensPersonalInfo.birthday}}</div>
    <div class="text-primary r-text">{{quoteEnsFlowFormValue.ensPersonalInfo.maritalStatus}}</div>
</div>
