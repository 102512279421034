import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { screenSizeValues } from '@common/models';
import { ResizeService } from '@common/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-rates-card',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-rates-card.component.html',
    styleUrls: ['quote-ens-rates-card.component.scss'],
})
export class QuoteEnsRatesCardComponent implements OnInit, OnDestroy, AfterContentInit {
    @ContentChild('ensRatesCardImage') ensRatesCardImage!: ElementRef<HTMLSpanElement>;
    @ContentChild('ensRatesCardValue') ensRatesCardValue!: ElementRef<HTMLSpanElement>;
    @ContentChild('ensRatesCardTitle') ensRatesCardTitle!: ElementRef<HTMLSpanElement>;
    @ContentChild('ensRatesCardText') ensRatesCardText!: ElementRef<HTMLSpanElement>;
    @ContentChild('ensRatesCardAction') ensRatesCardAction!: ElementRef<HTMLSpanElement>;
    @ContentChild('ensRatesCardSwitch') ensRatesCardSwitch!: ElementRef<HTMLSpanElement>;

    currentSize!: number;
    screenSizeValues = screenSizeValues;

    subscription: Subscription = new Subscription();

    constructor(
        private resizeService: ResizeService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.subscription.add(
            this.resizeService.screenSize$.subscribe((size) => {
                this.currentSize = screenSizeValues[size];
                this.changeDetectorRef.detectChanges();
            })
        );
    }
    ngAfterContentInit() {
        this.changeDetectorRef.detectChanges();
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

// TODO: FINISH REST TO NEW TEMPLATE
// text expand section..... for less text
