import { OptionsRecordsArray } from '@common/models';

export const modsValuesOptionsRecordsArray: OptionsRecordsArray = [
    // { 2500: '$2,500' },
    { 5000: '$5,000' },
    { 7500: '$7,500' },
    { 10000: '$10,000' },
    { 20000: '$20,000' },
    { 30000: '$30,000' },
    { 40000: '$40,000' },
    { 50000: '$50,000' },
    { 60000: '$60,000' },
    { 70000: '$70,000' },
    { 80000: '$80,000' },
    { 90000: '$90,000' },
    { 100000: '$100,000' },
    { 110000: '$110,000' },
    { 120000: '$120,000' },
    { 130000: '$130,000' },
    { 140000: '$140,000' },
    { 150000: '$150,000' },
    { 160000: '$160,000' },
    { 170000: '$170,000' },
    { 180000: '$180,000' },
    { 190000: '$190,000' },
    { 200000: '$200,000' },
    { 210000: '$210,000' },
    { 220000: '$220,000' },
    { 230000: '$230,000' },
    { 240000: '$240,000+' },
];

// export const unitValuesOptionsRecordsArray: OptionsRecordsArray = [
//     { 2500: '$2,500' },
//     { 5000: '$5,000' },
//     { 7500: '$7,500' },
//     { 10000: '$10,000' },
//     { 20000: '$20,000' },
//     { 30000: '$30,000' },
//     { 40000: '$40,000' },
//     { 50000: '$50,000' },
//     { 60000: '$60,000' },
//     { 70000: '$70,000' },
//     { 80000: '$80,000' },
//     { 90000: '$90,000' },
//     { 100000: '$100,000' },
//     { 110000: '$110,000' },
//     { 120000: '$120,000' },
//     { 130000: '$130,000' },
//     { 140000: '$140,000' },
//     { 150000: '$150,000' },
//     { 160000: '$160,000' },
//     { 170000: '$170,000' },
//     { 180000: '$180,000' },
//     { 190000: '$190,000' },
//     { 200000: '$200,000' },
//     { 210000: '$210,000' },
//     { 220000: '$220,000' },
//     { 230000: '$230,000' },
//     { 240000: '$240,000' },
// ];
