<div class="container-xxl">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block">
                <div class="quote-title">Everything look</div>
                <div class="quote-title">good?</div>
            </div>
            <div class="d-md-block d-none"><div class="quote-title">Everything look good?</div></div>
        </div>
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3"><div class="quote-subtitle">Let's make sure everything here is accurate.</div></div>
        <div class="row justify-content-center" *ngIf="!!activeQuoteRigSetForm">
            <div class="col-lg-8 col-12">
                <div class="card">
                    <div class="card-header card-header-topographic text-start">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="r-text-sub-heading text-white" data-cy="rigReviewRigNameText">{{activeQuoteRigSetForm?.value?.rigName}}</div>
                            <div class="text-end">
                                <div class="r-text-sub-heading text-white">{{valueCovered | currency : 'USD' : 'symbol' : '1.0'}}</div>
                                <div class="r-text-micro-heading text-white light">Total covered value</div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body text-start">
                        <div class="mb-3">
                            <div class="text-lg text-primary" data-cy="rigReviewRigDetailsText1">{{activeQuoteRigSetForm?.value?.rigDetails?.year}} {{activeQuoteRigSetForm?.value?.rigDetails?.make}} {{activeQuoteRigSetForm?.value?.rigDetails?.model}}</div>
                            <div class="text-sm" data-cy="rigReviewRigDetailsText2">{{activeQuoteRigSetForm?.value?.rigDetails?.trim}}</div>
                        </div>
                        <div class="row">
                            <sbf-col-key-value class="mb-3" key="Mods &amp; Upgrades" [value]='activeQuoteRigSetForm?.value?.modsDetails.modsValue | currency : "USD" : "symbol" : "1.0"' keyIdAppend="mods" valueIdAppend="mods" *ngIf="activeQuoteRigSetForm?.value?.coverageSelect?.modsCoverage"
                                ><div class="pointer text-secondary ms-2" data-cy="rigReviewEditModsCard" (click)="jump.emit(eQuoteRigSetFormStep.modsDetails)"><i-bs name="pencil-square" width="1.25em" height="1.25em"></i-bs></div></sbf-col-key-value
                            ><sbf-col-key-value class="mb-3" key="Mods &amp; Upgrades" [value]="" keyIdAppend="mods" valueIdAppend="mods" *ngIf="!activeQuoteRigSetForm?.value?.coverageSelect?.modsCoverage"><div class="pointer text-secondary ms-2" data-cy="rigReviewAddModsCard" (click)="addMods()">Add Mods</div></sbf-col-key-value
                            ><sbf-col-key-value class="mb-3" key="Camper" [value]='activeQuoteRigSetForm?.value?.camperDetails.unitValue | currency : "USD" : "symbol" : "1.0"' keyIdAppend="camper" valueIdAppend="camper" *ngIf="activeQuoteRigSetForm?.value?.coverageSelect?.camperCoverage"
                                ><div class="pointer text-secondary ms-2" data-cy="rigReviewEditCamperCard" (click)="jump.emit(eQuoteRigSetFormStep.camperDetails)"><i-bs name="pencil-square" width="1.25em" height="1.25em"></i-bs></div></sbf-col-key-value
                            ><sbf-col-key-value class="mb-3" key="Camper" [value]="" keyIdAppend="camper" valueIdAppend="camper" *ngIf="!activeQuoteRigSetForm?.value?.coverageSelect?.camperCoverage"><div class="pointer text-secondary ms-2" data-cy="rigReviewAddCamperCard" (click)="addCamper()">Add Camper</div></sbf-col-key-value
                            ><sbf-col-key-value class="mb-3" key="Trailer" [value]='activeQuoteRigSetForm?.value?.trailerDetails.unitValue | currency : "USD" : "symbol" : "1.0"' keyIdAppend="trailer" valueIdAppend="trailer" *ngIf="activeQuoteRigSetForm?.value?.coverageSelect?.trailerCoverage"
                                ><div class="pointer text-secondary ms-2" data-cy="rigReviewEditTrailerCard" (click)="jump.emit(eQuoteRigSetFormStep.trailerDetails)"><i-bs name="pencil-square" width="1.25em" height="1.25em"></i-bs></div></sbf-col-key-value
                            ><sbf-col-key-value class="mb-3" key="Trailer" [value]="" keyIdAppend="trailer" valueIdAppend="trailer" *ngIf="!activeQuoteRigSetForm?.value?.coverageSelect?.trailerCoverage"><div class="pointer text-secondary ms-2" data-cy="rigReviewAddTrailerCard" (click)="addTrailer()">Add Trailer</div></sbf-col-key-value>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5 mt-md-5 mt-3" data-cy="quoteFlowRigReviewNextButton" type="button" (click)="next.emit()" tabindex="0">
            <div class="d-flex align-items-center justify-content-center">Next</div>
            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
        </button>
        <div class="d-flex justify-content-start"><sbf-back-button data-cy="quoteFlowTrailerDetailsBackButton" (click)="back.emit()"></sbf-back-button></div>
    </div>
</div>
