<div class="tredder-card mb-4" *ngIf="activeEnsQuote &amp;&amp; twentySixteenPaymentPlanSource">
    <div class="row">
        <div class="col-md-6 col-12 mb-md-0 mb-3">
            <h2>Pay by Installments</h2>
            <div class="d-inline-block me-1 mb-1">
                Total premium of
                <div class="text-primary d-inline-block">{{twentySixteenPaymentPlanSource.debit | currency : 'USD' : 'symbol' : '1.2'}}</div>
                includes
                <div class="text-link text-cyan-blue primary-hover d-inline-block underline" data-cy="futurePaymentTwentySixteensButton" (click)="showFuturePayments()">future payments</div>
                plus applicable
                <div class="text-link text-cyan-blue primary-hover d-inline-block underline" data-cy="taxesAndFeesTwentySixteenButton" (click)="showTaxesAndFees()">taxes & fees</div>
            </div>
            <div class="d-inline-block me-1">Price per 6-month term.</div>
        </div>
        <div class="col-md-6 col-12 text-end">
            <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button" data-cy="payTwentySixteenButton" type="button" (click)="pay()" tabindex="0"><div class="d-flex align-items-center justify-content-center">Pay {{twentySixteenPaymentPlanSource.installments[0].debit | currency : 'USD' : 'symbol' : '1.2'}} Today</div></button>
        </div>
    </div>
</div>
