import { InjectionToken } from '@angular/core';
import { PlatformService } from '@common/services/platform.service';

export const WindowToken = new InjectionToken('WINDOW');
export function windowProvider(platformService: PlatformService) {
    if (platformService.isBrowser) {
        return window;
    }
    if (platformService.isServer) {
        return {
            currentEnv: 'prod',
            navigator: {
                userAgent: '',
            },
        };
    }
}
