import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { PromotionKeys, ToastBodySwitch } from '@common/models';
import { UtilityService } from '@common/services';
import { ToastService } from '@common/services/toast.service';

@Component({
    selector: 'sbpro-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['toast.component.scss'],
})
export class ToastComponent implements OnInit {
    @Input() index!: number;
    @Input() header!: string | TemplateRef<unknown>;
    @Input() body!: string | TemplateRef<unknown>;
    @Input() bodySwitch!: ToastBodySwitch;
    @Input() toastData!: unknown;
    @Input() autohide = false;
    @Input() delay!: number;
    @Input() classes!: string;
    @Input() headerClasses!: string;
    @Input() bodyClasses!: string;
    @Input() bodyIcon!: string;
    @Input() toastID!: string;
    @Input() closeOnClick!: boolean;
    @Input() noCloseButton!: boolean;

    eToastBodySwitch = ToastBodySwitch;

    headerIsTemplate!: boolean;
    bodyIsTemplate!: boolean;
    promoTemplate!: PromotionKeys | undefined;

    timeoutID!: number;

    toastContext = this;

    constructor(
        public toastService: ToastService,
        private utilityService: UtilityService
    ) {}

    ngOnInit() {
        this.headerIsTemplate = this.header instanceof TemplateRef;
        this.bodyIsTemplate = this.body instanceof TemplateRef;

        if (this.autohide) {
            this.timeoutID = window.setTimeout(() => {
                this.toastService.remove(this.toastID);
            }, this.delay || 5000);
        }
        if (!this.headerClasses) {
            this.headerClasses = 'bg-secondary text-white ';
        }
    }

    clicked() {
        if (this.closeOnClick) {
            this.toastService.remove(this.toastID);
        }
    }

    close() {
        this.toastService.remove(this.toastID);
    }

    reloadWebsite() {
        this.utilityService.window.location.reload();
    }
}
