import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

@Component({
    selector: 'sbf-pdf-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './pdf-modal.component.html',
    styleUrls: ['pdf-modal.component.scss'],
})
export class PdfModalComponent implements OnInit {
    @ViewChild('pdfViewer') pdfViewer!: PdfJsViewerComponent;
    pdfBlob!: Blob;
    downloadFileName!: string;

    constructor(public activeModal: NgbActiveModal) {}
    ngOnInit() {}
    close() {
        this.activeModal.close();
    }
}
