import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    PaymentPlanInstallment,
    PaymentPlanName,
    PaymentPlanSource,
} from '@backend-types/payment-plan';
import { PolicyEnsForBillingTyped } from '@backend-types/policy-ens';
import { CalculateRateEnsOnlyResponse } from '@backend-types/roc-lib';
import { PaymentPlanService } from '@modules/quote/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbf-endorsement-summary-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './endorsement-summary-modal.component.html',
    styleUrls: ['endorsement-summary-modal.component.scss'],
})
export class EndorsementSummaryModalComponent implements OnInit {
    newQuoteEnsOnly!: CalculateRateEnsOnlyResponse;
    loadedPolicy!: PolicyEnsForBillingTyped;
    policyQuoteEns!: CalculateRateEnsOnlyResponse;

    foundTotalPaymentPlanPolicyQuoteEns!: PaymentPlanSource;
    foundTwentySixteenPaymentPlanPolicyQuoteEns!: PaymentPlanSource;

    foundTotalPaymentPlanNewQuote!: PaymentPlanSource;
    foundTwentySixteenPaymentPlanNewQuote!: PaymentPlanSource;

    foundTotalPaymentPlanNewQuoteEndorsement!: PaymentPlanSource;
    foundTwentySixteenPaymentPlanNewQuoteEndorsement!: PaymentPlanSource;

    policyQuoteEnsFirstPayment!: PaymentPlanInstallment;
    policyQuoteEnsSecondPayment!: PaymentPlanInstallment;

    newQuoteFirstPayment!: PaymentPlanInstallment;
    newQuoteSecondPayment!: PaymentPlanInstallment;

    newQuoteEndorsmentFirstPayment!: PaymentPlanInstallment;
    newQuoteEndorsmentSecondPayment?: PaymentPlanInstallment;

    maxRigs = 1;
    maxRigsArray!: boolean[];

    continue!: () => void;

    constructor(
        public activeModal: NgbActiveModal,
        private paymentPlanService: PaymentPlanService
    ) {}
    ngOnInit() {
        const endorsementRates = this.newQuoteEnsOnly.endorsementRates;
        if (!endorsementRates) {
            throw new Error('ENDORSEMENT_RATES_NOT_FOUND_ON_QUOTE_ENS');
        }
        const policyQuoteEns = this.loadedPolicy.quoteEns.calculateRateEnsOnlyResponse;

        this.policyQuoteEns = policyQuoteEns;

        if (policyQuoteEns.coverageBreakdown.rigs.length > this.maxRigs) {
            this.maxRigs = policyQuoteEns.coverageBreakdown.rigs.length;
        }

        if (this.newQuoteEnsOnly.coverageBreakdown.rigs.length > this.maxRigs) {
            this.maxRigs = this.newQuoteEnsOnly.coverageBreakdown.rigs.length;
        }

        this.maxRigsArray = new Array(this.maxRigs);

        this.foundTotalPaymentPlanPolicyQuoteEns = this.paymentPlanService.getPaymentPlanSource(
            policyQuoteEns,
            PaymentPlanName.full,
            false
        );

        this.foundTwentySixteenPaymentPlanPolicyQuoteEns =
            this.paymentPlanService.getPaymentPlanSource(
                policyQuoteEns,
                PaymentPlanName.twentySixteen,
                false
            );

        this.policyQuoteEnsFirstPayment =
            this.foundTwentySixteenPaymentPlanPolicyQuoteEns.installments[0];
        this.policyQuoteEnsSecondPayment =
            this.foundTwentySixteenPaymentPlanPolicyQuoteEns.installments[1];

        this.foundTotalPaymentPlanNewQuote = this.paymentPlanService.getPaymentPlanSource(
            this.newQuoteEnsOnly,
            PaymentPlanName.full,
            false
        );

        this.foundTwentySixteenPaymentPlanNewQuote = this.paymentPlanService.getPaymentPlanSource(
            this.newQuoteEnsOnly,
            PaymentPlanName.twentySixteen,
            false
        );

        this.newQuoteFirstPayment = this.foundTwentySixteenPaymentPlanNewQuote.installments[0];
        this.newQuoteSecondPayment = this.foundTwentySixteenPaymentPlanNewQuote.installments[1];

        this.foundTotalPaymentPlanNewQuoteEndorsement =
            this.paymentPlanService.getPaymentPlanSource(
                this.newQuoteEnsOnly,
                PaymentPlanName.full,
                true
            );

        this.foundTwentySixteenPaymentPlanNewQuoteEndorsement =
            this.paymentPlanService.getPaymentPlanSource(
                this.newQuoteEnsOnly,
                PaymentPlanName.twentySixteen,
                true
            );

        this.newQuoteEndorsmentFirstPayment =
            this.foundTwentySixteenPaymentPlanNewQuoteEndorsement.installments[0];
        this.newQuoteEndorsmentSecondPayment =
            this.foundTwentySixteenPaymentPlanNewQuoteEndorsement.installments[1];
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
