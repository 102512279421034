import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GeneratedDocument, QuoteEnsGeneratedDocuments } from '@backend-types/document';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { State, stringStateToState } from '@backend-types/states';
import { AnalyticsService, DocumentsService, HotKeysService } from '@common/services';
import { AgencyAdminAccount } from '@modules/manage-common/models';
import { QuoteEnsFormService, QuoteEnsRetrievalService } from '@modules/quote/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { ElectronicSignatureModalComponent } from '../electronic-signature-modal/electronic-signature-modal.component';

@Component({
    selector: 'sbf-quote-ens-documents',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-documents.component.html',
    styleUrls: ['quote-ens-documents.component.scss'],
})
export class QuoteEnsDocumentsComponent implements OnInit, OnDestroy {
    @Input() endorsement = false;
    @Input() agencyAdminAccount = AgencyAdminAccount.account;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    activeEnsQuote!: EnsResponseWithEnsOnly | null;
    quoteEnsFlowFormValue!: QuoteEnsFlowFormValue;
    documentAcknowledged!: boolean;
    eSignatureAcknowledged!: boolean;
    state!: State;
    quoteEnsDocuments!: QuoteEnsGeneratedDocuments;
    // documentsToShow: DocumentConfig[] = [
    //     legalDocsConfig.policyEnsApplication,
    //     legalDocsConfig.catExclusion,
    //     legalDocsConfig.stateEns,
    // ];

    subscription: Subscription = new Subscription();

    constructor(
        private hotKeysService: HotKeysService,
        private quoteEnsFormService: QuoteEnsFormService,
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private router: Router,
        private documentsService: DocumentsService,
        private ngbModalService: NgbModal,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Documents');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_documents',
            label: this.agencyAdminAccount,
            category: this.endorsement ? 'endorsement' : undefined,
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.continue();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );

        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.quoteEnsFormService.setDocumentsAcknowledged(true);
                this.quoteEnsFormService.setESignatureAcknowledged(true);
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowFormValuePure$.subscribe(
                (quoteEnsFlowFormValuePure) => {
                    if (!quoteEnsFlowFormValuePure) {
                        throw new Error('QUOTE_ENS_FLOW_FORM_VALUE_SHOULD_BE_EXIST');
                    }
                    this.quoteEnsFlowFormValue = quoteEnsFlowFormValuePure;
                    this.state = stringStateToState(this.quoteEnsFlowFormValue.homeBase.state);
                    this.documentAcknowledged = !!quoteEnsFlowFormValuePure.documentsAcknowledged;
                    this.eSignatureAcknowledged =
                        !!quoteEnsFlowFormValuePure.eSignatureConsentGiven;
                    this.changeDetectorRef.detectChanges();
                }
            )
        );

        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuote$.subscribe((activeEnsQuote) => {
                if (!activeEnsQuote) {
                    return;
                }

                if (!activeEnsQuote.quoteEnsDocuments) {
                    throw new Error(
                        `QUOTE_ENS_DOCUMENTS_NOT_ATTACHED_TO_QUOTE: ${activeEnsQuote.quoteEnsId}`
                    );
                }
                this.activeEnsQuote = activeEnsQuote;
                this.quoteEnsDocuments = activeEnsQuote.quoteEnsDocuments;

                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    read(generatedDocument: GeneratedDocument) {
        this.documentsService.read(generatedDocument);
    }

    download(generatedDocument: GeneratedDocument) {
        this.documentsService.download(generatedDocument);
    }

    documentAcknowledgeChange(documentAcknowledged: boolean) {
        this.quoteEnsFormService.setDocumentsAcknowledged(documentAcknowledged);
    }

    eSignatureAcknowledgeChange(eSignatureAcknowledged: boolean) {
        this.quoteEnsFormService.setESignatureAcknowledged(eSignatureAcknowledged);
    }

    continue() {
        this.subscription.add(
            this.quoteEnsRetrievalService
                .updateQuoteForPurchase$({
                    generateDocs: false,
                    updateAddress: false,
                })
                .subscribe(() => {
                    switch (this.agencyAdminAccount) {
                        case AgencyAdminAccount.agency:
                            if (this.endorsement) {
                                this.router.navigate(['/agency/policies/endorsement/payment'], {
                                    queryParamsHandling: 'merge',
                                });
                            } else {
                                this.router.navigate(['/agency/quotes/payment'], {
                                    queryParamsHandling: 'merge',
                                });
                            }
                            break;
                        case AgencyAdminAccount.admin:
                            this.router.navigate(['/admin/policies-ens/endorsement/payment'], {
                                queryParamsHandling: 'merge',
                            });
                            break;
                        case AgencyAdminAccount.account:
                            if (this.endorsement) {
                                this.router.navigate(['/account/insurance/endorsement/payment'], {
                                    queryParamsHandling: 'merge',
                                });
                            } else {
                                this.router.navigate(['/quote/ens'], {
                                    queryParams: {
                                        'quote-ens-form-step': 'payment',
                                    },
                                    queryParamsHandling: 'merge',
                                });
                            }
                            break;
                        default:
                            throw new Error(
                                `AGENCY_ADMIN_ACCOUNT_NOT_RECOGNIZED: ${this.agencyAdminAccount}`
                            );
                    }
                })
        );
    }

    showElectronicSignaturePolicy() {
        this.ngbModalService.open(ElectronicSignatureModalComponent, {
            size: 'lg',
            centered: false,
        });
    }
}
