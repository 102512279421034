import {
    animate,
    animateChild,
    group,
    query,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const inOutAnimation = trigger('inOutAnimation', [
    transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('.2s ease-out', style({ height: '*', opacity: 1 })),
    ]),
    transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('.2s ease-in', style({ height: 0, opacity: 0 })),
    ]),
]);

export const subMenuAnimation = trigger('subMenuAnimation', [
    state(
        'main',
        style({
            left: 0,
        })
    ),
    state(
        'sub',
        style({
            left: '-100%',
        })
    ),
    state(
        'sub2',
        style({
            left: '-200%',
        })
    ),
    transition('main => sub', [animate('.2s ease-in')]),
    transition('sub => main', [animate('.2s ease-out')]),
    transition('sub => sub2', [animate('.2s ease-in')]),
    transition('sub2 => sub', [animate('.2s ease-out')]),
]);

export const subMenuFadeAnimation = trigger('subMenuFadeAnimation', [
    transition(':leave', [style({ opacity: 1 }), animate('.2s ease-in', style({ opacity: 0 }))]),
    transition(':enter', [style({ opacity: 0 }), animate('.2s ease-out', style({ opacity: 1 }))]),
]);

export const fadeInOutAnimation = trigger('fadeInOut', [
    state('void', style({ opacity: 0 })),
    transition(':enter', animate('0.3s ease-in')),
    transition(':leave', animate('0.3s ease-out')),
]);

export const slideInOutAnimation = trigger('slideInOut', [
    state('flyIn', style({ transform: 'translateX(0)' })),
    transition(':enter', [style({ transform: 'translateX(100%)' }), animate('0.2s ease-in')]),
    // transition(':leave', [animate('0.2s ease-out', style({ transform: 'translateX(100%)' }))]),
]);

export const slideInAnimation = trigger('routeAnimations', [
    transition('HomePage => ConfigureGamePage, GameLoungePage => GameTablePage', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
            }),
        ]),
        // query('router-outlet ~ *', [style({}), animate(1, style({}))]),
        query(':enter', [style({ left: '100%' })]),
        query(':leave', animateChild()),
        group([
            query(':leave', [animate('300ms ease-out', style({ left: '-100%' }))]),
            query(':enter', [animate('300ms ease-out', style({ left: '0%' }))]),
        ]),
    ]),
    transition('GameTablePage => GameLoungePage', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                right: 0,
                width: '100%',
            }),
        ]),
        query(':enter', [style({ right: '100%' })]),
        query(':leave', animateChild()),
        group([
            query(':leave', [animate('300ms ease-out', style({ right: '-100%' }))]),
            query(':enter', [animate('300ms ease-out', style({ right: '0%' }))]),
        ]),
    ]),
    // transition('* <=> *', [
    //     style({ position: 'relative' }),
    //     query(
    //         ':enter, :leave',
    //         [
    //             style({
    //                 position: 'absolute',
    //                 top: 0,
    //                 left: 0,
    //                 width: '100%',
    //             }),
    //         ],
    //         { optional: true }
    //     ),
    //     query(':enter', [style({ left: '-100%' })], { optional: true }),
    //     query(':leave', animateChild(), { optional: true }),
    //     group([
    //         query(':leave', [animate('200ms ease-out', style({ left: '100%', opacity: 0 }))], {
    //             optional: true,
    //         }),
    //         query(':enter', [animate('300ms ease-out', style({ left: '0%' }))], { optional: true }),
    //         query('@*', animateChild(), { optional: true }),
    //     ]),
    // ]),
]);
