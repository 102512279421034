import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { ModelFormGroup } from '@common/models';
import { AssertionService } from '@common/services';
import { Subscription, tap } from 'rxjs';

interface InputSwitchFormValue {
    isChecked: boolean;
}

@Component({
    selector: 'sbf-input-switch',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './input-switch.component.html',
    styleUrls: ['input-switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: InputSwitchComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: InputSwitchComponent,
        },
    ],
})
export class InputSwitchComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {
    @Input() label!: string;
    @Input() idAppend!: string;
    @Input() size?: 'sm';

    isSmall = false;

    inputClasses?: string;

    subscription: Subscription = new Subscription();

    inputSwitchForm: ModelFormGroup<InputSwitchFormValue> = this.fb.group({
        isChecked: new FormControl<boolean | null>(null, [Validators.required]),
    });

    onTouched: () => unknown = () => {};
    onChange = (isChecked: boolean) => {};

    constructor(
        private fb: FormBuilder,
        private assertionService: AssertionService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    // @HostListener('click') onClick() {
    //     console.log('User Click using Host Listener');
    // }

    ngOnInit() {
        if (!this.idAppend) {
            throw new Error('NEED_ID_APPEND_FOR_INPUT_SWITCH');
        }
        if (this.size === 'sm') {
            this.isSmall = true;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(onChange: (isChecked: boolean) => unknown) {
        this.onChange = onChange;

        this.subscription.add(
            this.inputSwitchForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.inputSwitchForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const inputSwitchFormValue = this._inputSwitchFormValuePure();
                        onChange(inputSwitchFormValue);
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.inputSwitchForm.disable({ emitEvent: false });
        } else {
            this.inputSwitchForm.enable({ emitEvent: false });
        }
    }

    writeValue(value: boolean | null) {
        if (value === null) {
            this.inputSwitchForm.reset();
        }
        this.isCheckedControl.setValue(value);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.inputSwitchForm?.invalid) {
            return { inputSwitchFormInvalid: true };
        }

        return null;
    }

    clicked(event: InputEvent) {
        event.stopImmediatePropagation();
    }

    private _inputSwitchFormValuePure(): boolean {
        const { isChecked } = this.inputSwitchForm.value;

        this.assertionService.isDefinedOrThrow(isChecked);

        return isChecked;
    }

    /* Accessor Methods */

    get isCheckedControl() {
        return this.inputSwitchForm.get('isChecked') as FormControl;
    }
}
