import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { ResizeService } from '@common/services';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[sbfCheckOverflowed]',
})
export class CheckOverflowedDirective implements OnDestroy {
    subscription: Subscription;
    @Input('width-check') widthCheck!: boolean;

    constructor(
        private element: ElementRef,
        private resizeService: ResizeService
    ) {
        this.subscription = new Subscription();
    }

    ngAfterViewChecked(): void {
        this.checkOverflow();
        this.subscription.add(
            this.resizeService.screenSize$.subscribe(() => {
                this.checkOverflow();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public checkOverflow(): void {
        const elem = this.element.nativeElement;
        const wrapperSize = this.widthCheck ? elem.clientWidth : elem.clientHeight;
        const contentSize = this.widthCheck ? elem.scrollWidth : elem.scrollHeight;

        if (wrapperSize < contentSize) {
            this.element.nativeElement.classList.add('overflowed');
        } else {
            this.element.nativeElement.classList.remove('overflowed');
        }
    }
}
