import { ElementRef, Injectable } from '@angular/core';
import { NavigationService } from '@modules/navigation/services';

import { UtilityService } from '.';

@Injectable()
export class ScrollService {
    constructor(
        private utilityService: UtilityService,
        private navigationService: NavigationService
    ) {}

    scrollTo(elementRef: ElementRef<HTMLElement>, offset = 0): void {
        const top =
            elementRef.nativeElement.getBoundingClientRect().top +
            this.utilityService.window.scrollY -
            this.navigationService.navHeight -
            offset;

        this.utilityService.window.scrollTo({
            top,
            behavior: 'smooth',
        });
    }
}
