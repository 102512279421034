import { InjectionToken } from '@angular/core';
import { PlatformService } from '@common/services/platform.service';

export const IntlToken = new InjectionToken('INTL');
export function intlProvider(platformService: PlatformService) {
    if (platformService.isBrowser) {
        return Intl;
    }
    if (platformService.isServer) {
        return {};
    }
}
