<div class="container-xxl">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-block"><div class="quote-title">The Tredder Pledge</div></div>
        </div>
        <div class="col-lg-8 col-md-10 col-12 mx-auto">
            <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
                <div class="quote-subtitle mb-2">
                    <div class="d-inline-block me-1">As a valued member of the Tredder</div>
                    <div class="d-inline-block me-1">community, I pledge to accurately</div>
                    <div class="d-inline-block">represent my rig and its setup.</div>
                </div>
                <div class="quote-subtitle">
                    <div class="d-inline-block me-1">In doing so, helping to keep prices</div>
                    <div class="d-inline-block">low for all.</div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center mb-md-5 mb-3">
            <div class="card bg-gray-100 text-primary-alt pointer" (click)="pledgeOuterBoxClicked($event)">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-start flex-column">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <img class="max-width-image mb-md-5 mb-3" style="height: 180px" src="/assets/img/svg/trevor.svg" alt="Trevor" />
                            <div class="d-flex justify-content-between align-items-center flex-column">
                                <div class="text-primary mb-3">We are in this together!</div>
                                <sbf-input-checkbox class="d-inline me-2" data-cy="pledgeInput" idAppend="pledgeInput" [double]="true" [bgAlt]="true" [ngModel]="hasPledged" (ngModelChange)="pledgeInputChange($event)"></sbf-input-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5" data-cy="quoteFlowEnsPledgeNextButton" type="button" (click)="next.emit()" [attr.disabled]="!hasPledged ? true : null" tabindex="0">
            <div class="d-flex align-items-center justify-content-center">Next</div>
            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
        </button>
    </div>
</div>
