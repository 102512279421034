<div class="container-xxl" *ngIf="!formOnly">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block">
                <div class="quote-title">Just around the</div>
                <div class="quote-title">bend...</div>
            </div>
            <div class="d-md-block d-none"><div class="quote-title">Just around the bend...</div></div>
        </div>
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3"><div class="quote-subtitle">Last pieces of information to get you an accurate quote!</div></div>
        <div class="row justify-content-center">
            <div class="col-lg-8 col-12">
                <div class="card">
                    <div class="card-body">
                        <ng-template [ngTemplateOutlet]="ensPersonalInfoFormTemplate"></ng-template
                        ><button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5 mt-md-5 mt-3" data-cy="quoteFlowPersonalInfoNextButton" type="button" (click)="seeMyQuote()" tabindex="0" [attr.disabled]="!allValid ? true : null">
                            <div class="d-flex align-items-center justify-content-center">See my quote</div>
                            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
                        </button>
                        <div class="d-flex justify-content-start"><sbf-back-button data-cy="quoteFlowPersonalInfoBackButton" (click)="back.emit()"></sbf-back-button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="formOnly"><ng-template [ngTemplateOutlet]="ensPersonalInfoFormTemplate"></ng-template></ng-container
><ng-template #ensPersonalInfoFormTemplate
    ><form data-cy="ensPersonalInfoForm" [formGroup]="ensPersonalInfoForm">
        <div class="row">
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="firstNameInput" data-cy="firstNameInput" autocomplete="off" type="text" aria-describedby="firstNameHelp" sbwAutoFocus [formOnly]="formOnly" formControlName="firstName" [class.is-invalid]="firstNameControlInvalid" placeholder="firstName" />
                    <div class="invalid-feedback" *ngIf='firstNameControl.hasError("required")'>First Name required.</div>
                    <label class="text-gray-600 small" for="firstNameInput">First Name</label>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="lastNameInput" data-cy="lastNameInput" autocomplete="off" type="text" aria-describedby="lastNameHelp" formControlName="lastName" [class.is-invalid]="lastNameControlInvalid" placeholder="lastName" />
                    <div class="invalid-feedback" *ngIf='lastNameControl.hasError("required")'>Last Name required.</div>
                    <label class="text-gray-600 small" for="lastNameInput">Last Name</label>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="birthdayInput" data-cy="birthdayInput" autocomplete="off" type="date" [min]="minBirthday" [max]="maxBirthday" aria-describedby="birthdayHelp" formControlName="birthday" [class.is-invalid]="birthdayControlInvalid" placeholder="birthday" />
                    <div class="invalid-feedback" *ngIf='birthdayControl.hasError("required")'>Date of Birth required.</div>
                    <div class="invalid-feedback" *ngIf='birthdayControl.hasError("badBirthday")'>Date of Birth must put your age between 16 and 100</div>
                    <label class="text-gray-600 small" for="birthdayInput">Date of Birth</label>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="emailInput" data-cy="emailInput" autocomplete="off" type="text" aria-describedby="emailHelp" formControlName="email" [class.is-invalid]="emailControlInvalid" placeholder="email" />
                    <div class="invalid-feedback" *ngIf='emailControl.hasError("required")'>Email required.</div>
                    <div class="invalid-feedback" *ngIf='emailControl.hasError("email")'>Email is not valid.</div>
                    <label class="text-gray-600 small" for="emailInput">Email</label>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="phoneInput" data-cy="phoneInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="phoneHelp" formControlName="phone" [class.is-invalid]="phoneControlInvalid" placeholder="phone" mask="(000) 000-0000" />
                    <div class="invalid-feedback" *ngIf='phoneControl.hasError("required")'>Phone required.</div>
                    <div class="invalid-feedback" *ngIf='phoneControl.hasError("mask")'>Phone is not valid.</div>
                    <label class="text-gray-600 small" for="phoneInput">Phone</label>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <sbf-input-select data-cy="maritalStatusSelect" idAppend="maritalStatus" formControlName="maritalStatus" defaultText="Marital Status" [optionsRecordsArray]="[eDriverMaritalStatusText]" [class.is-invalid]="maritalStatusControlInvalid"></sbf-input-select>
                    <div class="invalid-feedback" *ngIf='maritalStatusControl.hasError("required")'>Marital Status required.</div>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-start align-items-center">
                    <sbf-input-checkbox class="me-3" data-cy="tcpaAgreeCheckbox" #tcpaAgree idAppend="tcpaAgree" [double]="true" [bgAlt]="true" formControlName="tcpaAgree"></sbf-input-checkbox>
                    <div class="text-start text-sm">I agree to receive autodialed and/or prerecorded calls and texts about my policy from or on behalf of Tredder.</div>
                </div>
            </div>
        </div>
    </form></ng-template
>
