import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbf-existing-policy-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './existing-policy-modal.component.html',
    styleUrls: ['existing-policy-modal.component.scss'],
})
export class ExistingPolicyModalComponent implements OnInit {
    constructor(
        private activeModal: NgbActiveModal,
        private router: Router
    ) {}
    ngOnInit() {}

    logIn() {
        this.activeModal.close();
        this.router.navigate(['/account/insurance/dashboard']);
    }
}
