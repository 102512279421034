import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
} from '@angular/core';
import { ScreenSize } from '@common/models';
import { PlatformService, ResizeService, UtilityService } from '@common/services';

@Component({
    selector: 'sbf-size-detector',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './size-detector.component.html',
    styleUrls: ['size-detector.component.scss'],
})
export class SizeDetectorComponent implements AfterViewInit {
    prefix = 'is-';
    sizes = [
        {
            id: ScreenSize.xs,
            name: 'xs',
            css: `d-block d-sm-none`,
        },
        {
            id: ScreenSize.sm,
            name: 'sm',
            css: `d-none d-sm-block d-md-none`,
        },
        {
            id: ScreenSize.md,
            name: 'md',
            css: `d-none d-md-block d-lg-none`,
        },
        {
            id: ScreenSize.lg,
            name: 'lg',
            css: `d-none d-lg-block d-xl-none`,
        },
        {
            id: ScreenSize.xl,
            name: 'xl',
            css: `d-none d-xl-block`,
        },
    ];

    @HostListener('window:resize', [])
    private onResize() {
        this.detectScreenSize();
    }

    constructor(
        private elementRef: ElementRef,
        private resizeService: ResizeService,
        private platformService: PlatformService,
        private utilityService: UtilityService
    ) {}

    ngAfterViewInit() {
        if (this.platformService.isServer) {
            this.resizeService.onResize(ScreenSize.md);
        } else {
            this.detectScreenSize();
        }
    }

    private detectScreenSize() {
        const currentSize = this.sizes.find((x) => {
            const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);
            const isVisible = this.utilityService.window.getComputedStyle(el).display != 'none';
            return isVisible;
        });

        if (!currentSize) {
            throw new Error('UNABLE_TO_FIND_CURRENTSIZE');
        }
        this.resizeService.onResize(currentSize.id);
    }
}
