import { DynamicRouteData } from '@modules/navigation/models';

import { sharedMetaTags } from './_shared-meta-tags';

export const expoWest_2023: DynamicRouteData = {
    isPublic: true,
    title: 'Expo West 2023',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const rally: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Rally Game',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const instagram: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const youtube: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const facebook: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const x: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const threads: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const tiktok: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const landingPageConfigs = {
    expoWest_2023,
    rally,
    instagram,
    youtube,
    facebook,
    x,
    threads,
    tiktok,
};
