import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sbpro-page-card',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './page-card.component.html',
    styleUrls: ['page-card.component.scss'],
})
export class PageCardComponent implements OnInit {
    @Input() size!: 'small' | 'medium' | 'large';

    constructor() {}
    ngOnInit() {}
}
