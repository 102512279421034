import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '@modules/navigation/services';

@Component({
    selector: 'sbf-sticky-action-footer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './sticky-action-footer.component.html',
    styleUrls: ['sticky-action-footer.component.scss'],
})
export class StickyActionFooterComponent implements OnInit, OnDestroy {
    constructor(private layoutService: LayoutService) {}
    ngOnInit() {
        this.layoutService.stickyActionFooterVisible = true;
    }
    ngOnDestroy() {
        this.layoutService.stickyActionFooterVisible = false;
    }
}
