<div class="toast show mb-1" [attr.data-cy]='"toastContainer_"+ index' [ngClass]="classes" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000">
    <div class="toast-header" [attr.data-cy]='"toastHeader_"+ index' *ngIf="!!header" [ngClass]="headerClasses">
        <ng-container *ngIf="headerIsTemplate"><ng-template *ngTemplateOutlet="header; context:toastContext"></ng-template></ng-container
        ><ng-container *ngIf="!headerIsTemplate &amp;&amp; header"
            ><div class="d-flex justify-content-between w-100" [ngClass]='{"justify-content-between": !noCloseButton, "justify-content-center": noCloseButton}'><span> {{header}}</span><button class="btn btn-white-100 btn-xs" (click)="clicked()" *ngIf="!noCloseButton">close</button></div></ng-container
        >
    </div>
    <ng-container *ngIf="!!body"
        ><div class="toast-body" [attr.data-cy]='"toastBody_"+ index' [ngClass]="bodyClasses">
            <ng-container *ngIf="bodyIsTemplate"><ng-template *ngTemplateOutlet="body; context:toastContext"></ng-template></ng-container
            ><ng-container *ngIf="!bodyIsTemplate &amp;&amp; body"
                ><ng-container *ngIf="bodyIcon; else NoIcon"
                    ><div class="d-flex w-100 align-items-center"><sbf-tredder-icons name="{{bodyIcon}}" height="14"></sbf-tredder-icons><span>{{body}}</span></div></ng-container
                ><ng-template #NoIcon>{{body}}</ng-template></ng-container
            >
        </div></ng-container
    ><ng-container *ngIf="bodySwitch === eToastBodySwitch.reloadSite"
        ><div class="toast-body d-flex justify-content-between align-items-center" [attr.data-cy]='"toastBody_"+ index' [ngClass]="bodyClasses">
            <div class="me-2">Reload for the new version</div>
            <button class="btn btn-xs rounded-pill btn-secondary-gradient text-white fw-500" data-cy="reloadWebsiteButton" (click)="reloadWebsite()" tabindex="0"><div class="mx-1">Reload</div></button>
        </div></ng-container
    >
</div>
