import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AnalyticsService, HotKeysService } from '@common/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-pledge',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-pledge.component.html',
    styleUrls: ['quote-ens-pledge.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteEnsPledgeComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteEnsPledgeComponent,
        },
    ],
})
export class QuoteEnsPledgeComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    subscription: Subscription = new Subscription();

    hasPledged = false;

    onTouched: () => unknown = () => {};
    onChange = (hasPledged: boolean | null) => {};

    constructor(
        private hotKeysService: HotKeysService,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Pledge');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_pledge',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.pledgeInputChange(true);
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(onChange: (hasPledged: boolean | null) => unknown) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {}

    writeValue(value: boolean | null) {
        if (value === null) {
            return;
        }
        if (value) {
            this.hasPledged = value;
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.hasPledged) {
            return { hasNotPledged: true };
        }

        return null;
    }

    pledgeInputChange(value: boolean) {
        this.hasPledged = value;
        this.onChange(value);
    }

    pledgeOuterBoxClicked(event: PointerEvent) {
        event.preventDefault();
        this.pledgeInputChange(!this.hasPledged);
    }
}
