import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { LogService, PlatformService, UtilityService } from '.';

interface KnownStyles {
    [index: string]: {
        loaded: boolean;
        loadInTesting: boolean;
        url: string;
        integrity?: string;
        crossOrigin?: string;
    };
}
type StyleName = 'easyMarkdown';

@Injectable()
export class StyleService {
    knownStyles!: KnownStyles;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private platformService: PlatformService,
        private utilityService: UtilityService,
        private logService: LogService
    ) {
        this.knownStyles = {
            easyMarkdown: {
                loaded: false,
                loadInTesting: true,
                url: 'https://unpkg.com/easymde/dist/easymde.min.css',
            },
        };
    }

    loadStyle(styleName: StyleName): Promise<boolean> {
        if (this.platformService.isServer) {
            this.logService.info(`Not loading style: ${styleName} in server environment`);
            return Promise.resolve(false);
        }
        const style = this.knownStyles[styleName];

        if (!style.loadInTesting && this.utilityService.localStorageClean.getItem('sb-testing')) {
            this.logService.info(`Not loading style: ${styleName} in testing environment`);
            return Promise.resolve(false);
        }

        if (style.loaded) {
            this.logService.info(`${styleName} is already loaded`);
            return Promise.resolve(true);
        }
        return new Promise((resolve, reject) => {
            const head = this.document.getElementsByTagName('head')[0];
            const existingStyle = this.document.getElementById(
                `known-style-${styleName}`
            ) as HTMLLinkElement;

            if (existingStyle) {
                this.logService.info(`${styleName} is already loaded`);
                resolve(true);
            } else {
                const newLink = this.document.createElement('link');
                newLink.addEventListener('load', () => {
                    this.logService.info(`${styleName} loaded dynamically`);
                    resolve(true);
                });
                newLink.id = `known-style-${styleName}`;
                newLink.rel = 'stylesheet';
                newLink.href = style.url;
                if (style.integrity) {
                    newLink.integrity = style.integrity;
                }
                if (style.crossOrigin) {
                    newLink.crossOrigin = style.crossOrigin;
                }
                head.appendChild(newLink);
                style.loaded = true;
            }
        });
    }
}
