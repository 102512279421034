export enum RocVariableType {
    string = 'string',
    number = 'number',
    boolean = 'boolean',
    Big = 'Big',
    NumberRange = 'NumberRange',
    Gender = 'Gender',
    MaritalStatus = 'MaritalStatus',
    YesNo = 'YesNo',
    PolicyTerm = 'PolicyTerm',
    FinancialResponsibilityTier = 'FinancialResponsibilityTier',
    PriorInsuranceClassification = 'PriorInsuranceClassification',
    RiskGroupCode = 'RiskGroupCode',
    PriorCarrierType = 'PriorCarrierType',
    PriorBILevel = 'PriorBILevel',
    PriorBILimit = 'PriorBILimit',
    ViolationCode = 'ViolationCode',
    ViolationClass = 'ViolationClass',
    NumberOfDistinctAddressChanges = 'NumberOfDistinctAddressChanges',
    VehicleTypeCode = 'VehicleTypeCode',
    VehicleHorsepowerCode = 'VehicleHorsepowerCode',
    VehicleRiskGroupCode = 'VehicleRiskGroupCode',
    LengthOfVehicleOwnership = 'LengthOfVehicleOwnership',
    VehicleUseCode = 'VehicleUseCode',
    PECStatus = 'PECStatus',
    RewriteReason = 'RewriteReason',
    TowOption = 'TowOption',
    UnitCompDeductible = 'UnitCompDeductible',
    UnitCollDeductible = 'UnitCollDeductible',
    AcpeDeductible = 'AcpeDeductible',
    CampsiteLiabilityLimit = 'CampsiteLiabilityLimit',
    ScheduledMedicalBenefitLimit = 'ScheduledMedicalBenefitLimit',
    EmergencyExpenseLimit = 'EmergencyExpenseLimit',
    TowRoadsideLimit = 'TowRoadsideLimit',
    OffRoadRecoveryLimit = 'OffRoadRecoveryLimit',
    UnitClass = 'UnitClass',
    BaseRateDeductible = 'BaseRateDeductible',
    TermMonths = 'TermMonths',
    Territory = 'Territory',
    VehicleUse = 'VehicleUse',
}

export type RocVariableTypes = keyof typeof RocVariableType;
export type RocVariableSelectionTypes = Exclude<
    RocVariableType,
    'string' | 'number' | 'boolean' | 'Big' | 'NumberRange'
>;

export type Deductibles =
    | UnitCompDeductible
    | UnitCollDeductible
    | AcpeDeductible
    | GearDeductible
    | OffRoadRecoveryDeductible;

export type Limits =
    | CampsiteLiabilityLimit
    | ScheduledMedicalBenefitLimit
    | EmergencyExpenseLimit
    | TowRoadsideLimit
    | OffRoadRecoveryLimit;

/* INFO: The values for the RocVariableType Selection Enums
   needs to correspond to cell values in the ROC table
   The corresponding roc{EnumName}Test needs to have the
   enum values as keys and then display text as values
*/

export enum RocGender {
    male = 'M',
    female = 'F',
}

export const rocGenderText = {
    M: 'Male',
    F: 'Female',
};

export enum RocMaritalStatus {
    married = 'M',
    single = 'S',
    notUsed = 'N',
}

export const rocMaritalStatusText = {
    M: 'Married',
    S: 'Single',
    N: 'Not Used',
};

export enum RocYesNo {
    yes = 'Y',
    no = 'N',
}

export const rocYesNoText: EnumMap<RocYesNo> = {
    Y: 'Yes',
    N: 'No',
};

export enum RocPolicyTerm {
    sixMonths = '6',
    twelveMonths = '12',
}

export const rocPolicyTermText: EnumMap<RocPolicyTerm> = {
    6: 'Six Months',
    12: 'Twelve Months',
};

export enum RocViolationCode {
    AAF = 'AAF',
    AFM = 'AFM',
    ANC = 'ANC',
    ANO = 'ANO',
    ASW = 'ASW',
    BOT = 'BOT',
    CMP = 'CMP',
    CMU = 'CMU',
    CRD = 'CRD',
    DEQ = 'DEQ',
    DEV = 'DEV',
    DR = 'DR',
    DWI = 'DWI',
    FAR = 'FAR',
    FDL = 'FDL',
    FEL = 'FEL',
    FLE = 'FLE',
    FRA = 'FRA',
    FTC = 'FTC',
    FTY = 'FTY',
    HOM = 'HOM',
    IBK = 'IBK',
    IP = 'IP',
    IT = 'IT',
    LDL = 'LDL',
    LIC = 'LIC',
    LTS = 'LTS',
    MAJ = 'MAJ',
    MMV = 'MMV',
    NAF = 'NAF',
    NFX = 'NFX',
    PAF = 'PAF',
    PUA = 'PUA',
    PUN = 'PUN',
    REF = 'REF',
    RKD = 'RKD',
    SAF = 'SAF',
    SCH = 'SCH',
    SLV = 'SLV',
    SPD = 'SPD',
    SUS = 'SUS',
    TMP = 'TMP',
    UDR = 'UDR',
    WSR = 'WSR',
}

export enum RocViolationClass {
    AAF = 'AAF',
    IND = 'IND',
    DWI = 'DWI',
    MAJ = 'MAJ',
    MIN = 'MIN',
    NAF = 'NAF',
    SPD = 'SPD',
}

export enum RocFinancialResponsibilityTier {
    A1 = 'A1',
    B1 = 'B1',
    C1 = 'C1',
    D1 = 'D1',
    E1 = 'E1',
    F1 = 'F1',
    G1 = 'G1',
    H1 = 'H1',
    I1 = 'I1',
    J1 = 'J1',
    K1 = 'K1',
    L1 = 'L1',
    M1 = 'M1',
    N1 = 'N1',
    O1 = 'O1',
    P1 = 'P1',
    Q1 = 'Q1',
    R1 = 'R1',
    T1 = 'T1',
    T3 = 'T3',
    T4 = 'T4',
    T5 = 'T5',
    U1 = 'U1',
    V1 = 'V1',
    X1 = 'X1',
    X3 = 'X3',
    X4 = 'X4',
    X5 = 'X5',
    Y1 = 'Y1',
    Z1 = 'Z1',
}

export enum RocPriorInsuranceClassification {
    A = 'A',
    B = 'B',
    C = 'C',
}

export enum RocRiskGroupCode {
    A1 = 'A1',
    A2 = 'A2',
    B1 = 'B1',
    B2 = 'B2',
    C1 = 'C1',
    C2 = 'C2',
}

export enum RocPriorCarrierType {
    N = 'N',
    S = 'S',
    X = 'X',
}

export enum RocPriorBILevel {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
}

export enum RocPriorBILimit {
    one = '1',
    two = '2',
    three = '3',
    four = '4',
    five = '5',
    N = 'N',
    X = 'X',
}

export const rocPriorBILimit: EnumMap<RocPriorBILimit> = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    N: 'N',
    X: 'X',
};

export enum RocNumberOfDistinctAddressChanges {
    zero = '0',
    one = '1',
    two = '2',
    three = '3',
    E = 'E',
    N = 'N',
    P = 'P',
    W = 'W',
    X = 'X',
}

export const rocNumberOfDistinctAddressChanges: EnumMap<RocNumberOfDistinctAddressChanges> = {
    0: 'zero',
    1: 'one',
    2: 'two',
    3: 'three',
    E: 'E',
    N: 'N',
    P: 'P',
    W: 'W',
    X: 'X',
};

export enum RocVehicleTypeCode {
    C = 'C',
    O = 'O',
    P = 'P',
    R = 'R',
    S = 'S',
    X = 'X',
}

export enum RocVehicleHorsepowerCode {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    X = 'X',
}

export enum RocVehicleRiskGroupCode {
    A1 = 'A1',
    B1 = 'B1',
    C1 = 'C1',
    D1 = 'D1',
    E1 = 'E1',
}

export enum RocLengthOfVehicleOwnership {
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    X = 'X',
}

export enum VehicleUseCode {
    one = '1',
    two = '2',
    three = '3',
    four = '4',
}

export const vehicleUseTextCode: EnumMap<VehicleUseCode> = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
};

export enum RocPECStatus {
    C = 'C', // ACP
    E = 'E', // EFT
    P = 'P', // Paid in Full
}

export enum RocRewriteReason {
    C = 'C',
    N = 'N',
    O = 'O',
    R = 'R',
}

export enum RocTowOption {
    'NONE' = 'NONE',
    'ROADSIDE ASSISTANCE' = 'ROADSIDE ASSISTANCE',
    'ROADSIDE ASSISTANCE W/TRIP INTERRUPTION' = 'ROADSIDE ASSISTANCE W/TRIP INTERRUPTION',
}

export enum RocUnitClass {
    'ANIMAL TRAILER NO LIVING QTRS' = 'ANIMAL TRAILER NO LIVING QTRS',
    'ANIMAL TRAILER WITH LIVING QTRS' = 'ANIMAL TRAILER WITH LIVING QTRS',
    'AUTO HAULER' = 'AUTO HAULER',
    'FIFTH WHEEL' = 'FIFTH WHEEL',
    'SPORT UTILITY NO LIVING QTRS' = 'SPORT UTILITY NO LIVING QTRS',
    'SPORT UTILITY TRAILER WITH LIVING QTRS' = 'SPORT UTILITY TRAILER WITH LIVING QTRS',
    'TENT/CAMPING TRAILER' = 'TENT/CAMPING TRAILER',
    'TINY HOUSE' = 'TINY HOUSE',
    'TRAVEL TRAILER' = 'TRAVEL TRAILER',
    'TRUCK MOUNTED CAMPER' = 'TRUCK MOUNTED CAMPER',
    'UTILITY TRAILER' = 'UTILITY TRAILER',
}

export const rocUnitClassCamperText: Partial<EnumMap<RocUnitClass>> = {
    'TRUCK MOUNTED CAMPER': 'Truck Mounted Camper',
};

export const rocUnitClassTrailerText: Partial<EnumMap<RocUnitClass>> = {
    'ANIMAL TRAILER NO LIVING QTRS': 'Animal Trailer No Living Qtrs',
    'ANIMAL TRAILER WITH LIVING QTRS': 'Animal Trailer With Living Qtrs',
    'AUTO HAULER': 'Auto Hauler',
    'FIFTH WHEEL': 'Fifth Wheel',
    'SPORT UTILITY NO LIVING QTRS': 'Sport Utility No Living Qtrs',
    'SPORT UTILITY TRAILER WITH LIVING QTRS': 'Sport Utility Trailer With Living Qtrs',
    'TENT/CAMPING TRAILER': 'Tent/Camping Trailer',
    'TINY HOUSE': 'Tiny House',
    'TRAVEL TRAILER': 'Travel Trailer',
    'UTILITY TRAILER': 'Utility Trailer',
};

// export enum IorR {
//     I = 'I',
//     R = 'R',
// }

// export enum BILimit {
//     none = 'NONE',
//     bi15_30 = '15/30',
//     bi25_50 = '25/50',
//     bi50_100 = '50/100',
//     bi100_300 = '100/300',
//     bi250_500 = '250/500',
//     bi100csl = '100 CSL',
//     bi300csl = '300 CSL',
//     bi500csl = '500 CSL',
// }

// export enum PDLimit {
//     none = 'NONE',
//     pd10 = '10',
//     pd15 = '15',
//     pd20 = '20',
//     pd25 = '25',
//     pd50 = '50',
//     pd100 = '100',
//     pd100csl = '100 CSL',
//     pd300csl = '300 CSL',
//     pd500csl = '500 CSL',
// }

// export enum MEDLimit {
//     none = 'NONE',
//     med500 = '500 PER PERSON',
//     med1000 = '1,000 PER PERSON',
//     med2000 = '2,000 PER PERSON',
//     med2500 = '2,500 PER PERSON',
//     med5000 = '5,000 PER PERSON',
//     med10000 = '10,000 PER PERSON',
//     med25000 = '25,000 PER PERSON',
// }

// export enum UMLimit {
//     none = 'NONE',
//     um15_30 = '15/30',
//     um25_50 = '25/50',
//     um50_100 = '50/100',
//     um100_300 = '100/300',
//     um250_500 = '250/500',
//     um100csl = '100 CSL',
//     um300csl = '300 CSL',
//     um500csl = '500 CSL',
// }

// export enum UIMLimit {
//     none = 'NONE',
//     uim15_30 = '15/30',
//     uim25_50 = '25/50',
//     uim50_100 = '50/100',
//     uim100_300 = '100/300',
//     uim250_500 = '250/500',
//     uim100csl = '100 CSL',
//     uim300csl = '300 CSL',
//     uim500csl = '500 CSL',
// }

// export enum COMPLimit {
//     none = 'NONE',
//     comp0ded = '0 DED',
//     comp50ded = '50 DED',
//     comp100ded = '100 DED',
//     comp200ded = '200 DED',
//     comp250ded = '250 DED',
//     comp500ded = '500 DED',
//     comp1000ded = '1,000 DED',
//     comp2000ded = '2,000 DED',
//     comp2500ded = '2,500 DED',
//     comp50ded_0glass = '50 DED W/ $0 GLASS',
//     comp100ded_0glass = '100 DED W/ $0 GLASS',
//     comp200ded_0glass = '200 DED W/ $0 GLASS',
//     comp250ded_0glass = '250 DED W/ $0 GLASS',
//     comp500ded_0glass = '500 DED W/ $0 GLASS',
//     comp1000ded_0glass = '1,000 DED W/ $0 GLASS',
//     comp2000ded_0glass = '2,000 DED W/ $0 GLASS',
//     comp2500ded_0glass = '2,500 DED W/ $0 GLASS',
//     comp500ded_250lp = '500 DED, 250 TO LP',
// }

// export enum COLLLimit {
//     none = 'NONE',
//     coll100ded = '100 DED',
//     coll200ded = '200 DED',
//     coll250ded = '250 DED',
//     coll500ded = '500 DED',
//     coll1000ded = '1,000 DED',
//     coll2000ded = '2,000 DED',
//     coll500ded_250lp = '500 DED, 250 TO LP',
//     coll2500ded_250lp = '2,500 DED, 250 TO LP',
// }

// export enum LOANLimit {
//     none = 'NONE',
//     coll0ded = '0 DED',
// }

// export enum RENTLimit {
//     none = 'NONE',
//     rent40day_1200max = '40 PER DAY 1200 MAX',
//     rent50day_1500max = '50 PER DAY 1500 MAX',
//     rent60day_1800max = '60 PER DAY 1800 MAX',
// }

// export enum ACPELimit {
//     none = 'NONE',
//     acpe1_100 = '1-100 ACPE,1000 CPE INCL',
//     acpe101_200 = '101-200 ACPE,1000 CPE INCL',
//     acpe201_500 = '201-500 ACPE,1000 CPE INCL',
//     acpe501_1000 = '501-1000 ACPE,1000 CPE INCL',
//     acpe1001_1500 = '1001-1500 ACPE,1000 CPE INCL',
//     acpe1501_2000 = '1501-2000 ACPE,1000 CPE INCL',
//     acpe2001_2500 = '2001-2500 ACPE,1000 CPE INCL',
//     acpe2501_3000 = '2501-3000 ACPE,1000 CPE INCL',
//     acpe3001_3500 = '3001-3500 ACPE,1000 CPE INCL',
//     acpe3501_4000 = '3501-4000 ACPE,1000 CPE INCL}',
// }

export enum BaseRateDeductible {
    fiveHundred = '500',
}

export const baseRateDeductibleText: EnumMap<BaseRateDeductible> = {
    500: '$500',
};

export enum UnitCompDeductible {
    // oneHundred = '100',
    // twoHundredFifty = '250',
    fiveHundred = '500',
    sevenHundredFifty = '750',
    oneThousand = '1000',
    twoThousand = '2000',
    fiveThousand = '5000',
}

export const unitCompDeductibleText: EnumMap<UnitCompDeductible> = {
    // 100: '$100',
    // 250: '$250',
    500: '$500',
    750: '$750',
    1000: '$1,000',
    2000: '$2,000',
    5000: '$5,000',
};

export enum UnitCollDeductible {
    // oneHundred = '100',
    // twoHundredFifty = '250',
    fiveHundred = '500',
    sevenHundredFifty = '750',
    oneThousand = '1000',
    twoThousand = '2000',
    fiveThousand = '5000',
}

export const unitCollDeductibleText: EnumMap<UnitCollDeductible> = {
    // 100: '$100',
    // 250: '$250',
    500: '$500',
    750: '$750',
    1000: '$1,000',
    2000: '$2,000',
    5000: '$5,000',
};

export enum AcpeDeductible {
    fiveHundred = '500',
    oneThousand = '1000',
    oneThousandFiveHundred = '1500',
    twoThousand = '2000',
    fiveThousand = '5000',
}

export const acpeDeductibleText: EnumMap<AcpeDeductible> = {
    500: '$500',
    1000: '$1,000',
    1500: '$1,500',
    2000: '$2,000',
    5000: '$5,000',
};

export enum GearDeductible {
    fiveHundred = '500',
}

export const gearDeductibleText: EnumMap<GearDeductible> = {
    500: '$500',
};

export enum OffRoadRecoveryDeductible {
    fiveHundred = '500',
}

export const offRoadRecoveryDeductibleText: EnumMap<OffRoadRecoveryDeductible> = {
    500: '$500',
};

export enum CampsiteLiabilityLimit {
    tenThousand = '10000',
    twentyFiveThousand = '25000',
    fiftyThousand = '50000',
    oneHundredThousand = '100000',
    threeHundredThousand = '300000',
    fiveHundredThousand = '500000',
}

export const campsiteLiabilityLimitText: EnumMap<CampsiteLiabilityLimit> = {
    10000: '$10,000',
    25000: '$25,000',
    50000: '$50,000',
    100000: '$100,000',
    300000: '$300,000',
    500000: '$500,000',
};

export enum ScheduledMedicalBenefitLimit {
    sixtyThousand = '60000',
}

export const scheduledMedicalBenefitLimitText: EnumMap<ScheduledMedicalBenefitLimit> = {
    60000: '$60,000',
};

export enum EmergencyExpenseLimit {
    fiveHundred = '500',
    sevenHundredFifty = '750',
    oneThousand = '1000',
}

export const emergencyExpenseLimitText: EnumMap<EmergencyExpenseLimit> = {
    500: '$500',
    750: '$750',
    1000: '$1,000',
};

export enum TowRoadsideLimit {
    oneHundred = '100',
    twoHundredFifty = '250',
    fiveHundred = '500',
}

export const towRoadsideLimitText: EnumMap<TowRoadsideLimit> = {
    100: '$100',
    250: '$250',
    500: '$500',
};

export enum OffRoadRecoveryLimit {
    fiveThousand = '5000',
}

export const offRoadRecoveryLimitText: EnumMap<OffRoadRecoveryLimit> = {
    5000: '$5000',
};

export enum TermMonths {
    six = '6',
    twelve = '12',
}

export const termMonthsText: EnumMap<TermMonths> = {
    6: '6 Months',
    12: '1 Year',
};

export enum Territory {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
    I = 'I',
}

export enum RocRigOwnedTenure {
    lessThanOneMonth = 'lessThanOneMonth',
    oneMonthToOneYear = 'oneMonthToOneYear',
    oneYearToThreeYears = 'oneYearToThreeYears',
    threeYearsToFiveYears = 'threeYearsToFiveYears',
    fiveYearsOrMore = 'fiveYearsOrMore',
}

export const rocRigOwnedTenureText: EnumMap<RocRigOwnedTenure> = {
    lessThanOneMonth: 'Less than 1 month',
    oneMonthToOneYear: '1 month - 1 year',
    oneYearToThreeYears: '1 year - 3 years',
    threeYearsToFiveYears: '3 years - 5 years',
    fiveYearsOrMore: '5 Years or more',
};

export enum RocVehicleUse {
    pleasure = 'P',
    dailyDriver = 'D',
    commute = 'C',
}

export const rocVehicleUseText: EnumMap<RocVehicleUse> = {
    P: 'Pleasure',
    D: 'Daily Driver',
    C: 'Commute',
};
