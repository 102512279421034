<ng-container *ngIf="currentSize &gt;= screenSizeValues.md"
    ><div class="d-md-flex d-none justify-content-start align-items-start">
        <div class="p-3 coverage-image"><ng-container *ngTemplateOutlet="ensRatesCardImageTemplate"></ng-container></div>
        <div class="p-3 text-start flex-grow-1">
            <div class="mb-2"><ng-container *ngTemplateOutlet="ensRatesCardValueTemplate"></ng-container></div>
            <div class="text-primary"><ng-container *ngTemplateOutlet="ensRatesCardTitleTemplate"></ng-container></div>
            <div class="soft mb-3"><ng-container *ngTemplateOutlet="ensRatesCardTextTemplate"></ng-container></div>
            <ng-container *ngTemplateOutlet="ensRatesCardActionTemplate"></ng-container>
        </div>
        <div class="p-3 coverage-switch" *ngIf="ensRatesCardSwitch"><ng-container *ngTemplateOutlet="ensRatesCardSwitchTemplate"></ng-container></div></div></ng-container
><ng-container *ngIf="currentSize &lt; screenSizeValues.md &amp;&amp; !ensRatesCardSwitch"
    ><div class="d-md-none d-flex justify-content-start align-items-start">
        <div class="py-3 coverage-image"><ng-container *ngTemplateOutlet="ensRatesCardImageTemplate"></ng-container></div>
        <div class="p-3 text-start flex-grow-1">
            <div class="text-primary"><ng-container *ngTemplateOutlet="ensRatesCardTitleTemplate"></ng-container></div>
        </div>
    </div>
    <div class="mb-2"><ng-container *ngTemplateOutlet="ensRatesCardValueTemplate"></ng-container></div>
    <div class="text-start soft mb-3 px-2"><ng-container *ngTemplateOutlet="ensRatesCardTextTemplate"></ng-container></div>
    <div class="mb-5"><ng-container *ngTemplateOutlet="ensRatesCardActionTemplate"></ng-container></div></ng-container
><ng-container *ngIf="currentSize &lt; screenSizeValues.md &amp;&amp; ensRatesCardSwitch"
    ><div class="d-md-none d-flex justify-content-between align-items-start">
        <div class="py-3 coverage-image"><ng-container *ngTemplateOutlet="ensRatesCardImageTemplate"></ng-container></div>
        <div class="p-3 coverage-switch"><ng-container *ngTemplateOutlet="ensRatesCardSwitchTemplate"></ng-container></div>
    </div>
    <div class="pb-3 ps-2 text-start flex-grow-1">
        <div class="text-primary"><ng-container *ngTemplateOutlet="ensRatesCardTitleTemplate"></ng-container></div>
    </div>
    <div class="mb-2"><ng-container *ngTemplateOutlet="ensRatesCardValueTemplate"></ng-container></div>
    <div class="text-start soft mb-3 px-2"><ng-container *ngTemplateOutlet="ensRatesCardTextTemplate"></ng-container></div>
    <div class="mb-5"><ng-container *ngTemplateOutlet="ensRatesCardActionTemplate"></ng-container></div></ng-container
><!-- TEMPLATES--><ng-template #ensRatesCardImageTemplate><ng-content select="ensRatesCardImage"></ng-content></ng-template><ng-template #ensRatesCardValueTemplate><ng-content select="ensRatesCardValue"></ng-content></ng-template><ng-template #ensRatesCardTitleTemplate><ng-content select="ensRatesCardTitle"></ng-content></ng-template><ng-template #ensRatesCardTextTemplate><ng-content select="ensRatesCardText"></ng-content></ng-template><ng-template #ensRatesCardActionTemplate><ng-content select="ensRatesCardAction"></ng-content></ng-template><ng-template #ensRatesCardSwitchTemplate><ng-content select="ensRatesCardSwitch"></ng-content></ng-template>
