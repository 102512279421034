import { Injectable } from '@angular/core';
import { ActivatedRoute, ChildActivationEnd, Router } from '@angular/router';
import { UtilityService } from '@common/services';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

@Injectable()
export class NavigationService {
    _sideNavVisible$ = new BehaviorSubject(true);
    _currentURL$ = new BehaviorSubject('');
    _currentURLPathOnly$ = new BehaviorSubject('');
    _navHeight!: number;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        private utilityService: UtilityService
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof ChildActivationEnd))
            .subscribe((event) => {
                let snapshot = (event as ChildActivationEnd).snapshot;
                while (snapshot.firstChild !== null) {
                    snapshot = snapshot.firstChild;
                }
                const marketingSource = snapshot.queryParams.ms;
                if (marketingSource) {
                    this.utilityService.localStorage.setItem('marketing_source', marketingSource);
                }

                this._currentURL$.next(router.url);
                this._currentURLPathOnly$.next(router.url.replace(/#.*$/, '').replace(/\?.*$/, ''));
            });
    }

    get navHeight() {
        return this._navHeight || 0;
    }

    set navHeight(height: number) {
        this._navHeight = height;
    }

    sideNavVisible$(): Observable<boolean> {
        return this._sideNavVisible$;
    }

    toggleSideNav(visibility?: boolean) {
        if (typeof visibility !== 'undefined') {
            this._sideNavVisible$.next(visibility);
        } else {
            this._sideNavVisible$.next(!this._sideNavVisible$.value);
        }
    }

    currentURL$(): Observable<string> {
        return this._currentURL$.asObservable().pipe(distinctUntilChanged());
    }
    currentURLPathOnly$(): Observable<string> {
        return this._currentURLPathOnly$.asObservable().pipe(distinctUntilChanged());
    }

    goTo(url: string, clearCache = false) {
        if (clearCache) {
            this.utilityService.clearAllTredderFromLocalStorage();
        }
        this.router.navigate([url]).then(() => {
            this.utilityService.window.location.reload();
        });
    }

    shouldLoadDeferredService$(): Observable<boolean> {
        return this.currentURLPathOnly$().pipe(
            switchMap((path) => {
                const noLoadPathList = ['/estimate/widget'];

                if (noLoadPathList.includes(path)) {
                    console.info(`### INFO: NOT_LOADING_DELAYED_SCRIPTS_FOR: ${path}`);
                    return of(false);
                } else {
                    console.info(`### INFO: LOADING_DELAYED_SCRIPTS`);
                    return of(true);
                }
            }),
            filter((shouldLoad) => shouldLoad)
        );
    }
}
