export enum LogLevel {
    trace = 'trace',
    debug = 'debug',
    info = 'info',
    warn = 'warn',
    error = 'error',
    fatal = 'fatal',
}

export type LogLevelValues = { [index in LogLevel]: number };

export const logLevelValues: LogLevelValues = {
    trace: 0,
    debug: 10,
    info: 20,
    warn: 30,
    error: 40,
    fatal: 50,
};
