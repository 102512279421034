<div class="modal-header"><div class="r-text-lg heavy text-primary">Update Ready</div></div>
<div class="modal-body">
    <div class="r-text-base text-primary mb-3">
        A new version of Tredder has been pushed out to this
        <div class="heavy d-inline">non-production environment.</div>
    </div>
    <div class="r-text-base text-primary mb-3">This means the database has been reset, so anything you may have created is now gone.</div>
    <div class="r-text-base text-primary mb-3">In order to continue testing, you will need to clear your cache and refresh this and any other open windows.</div>
    <div class="r-text-base text-primary mb-3">You can use the button below to clear your cache and reload this page.</div>
</div>
<div class="d-flex justify-content-end">
    <button class="btn rounded-pill btn-primary-gradient text-white fw-500 m-3" data-cy="closeAddModModal" (click)="clearCacheAndRefresh()" tabindex="0"><div class="mx-3">Clear Cache and Reload Page</div></button>
</div>
