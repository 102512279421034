export enum ScreenSize {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
}

export type ScreenSizeValues = { [index in ScreenSize]: number };

export const screenSizeValues: ScreenSizeValues = {
    xs: 0,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
};
