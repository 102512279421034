import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, ReplaySubject } from 'rxjs';

import { EnvService, LogService, PlatformService, UtilityService } from '.';

type KnownScripts = Record<
    ScriptName,
    {
        appended: boolean;
        onLoad: ReplaySubject<void>;
        loadInTesting: boolean;
        url: string;
        dynamicUrl?: boolean;
        integrity?: string;
        crossOrigin?: string;
    }
>;

type ScriptName =
    | 'canopyConnect'
    | 'grecaptcha'
    | 'stripe'
    | 'easyMarkdown'
    | 'marked'
    | 'imageCompression'
    | 'fuse'
    | 'sentry'
    | 'gtag'
    | 'places'
    | 'intercom'
    | 'viralSweep';

@Injectable()
export class ScriptService {
    knownScripts!: KnownScripts;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private platformService: PlatformService,
        private utilityService: UtilityService,
        private envService: EnvService,
        private logService: LogService
    ) {
        this.knownScripts = {
            canopyConnect: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: true,
                url: 'https://cdn.usecanopy.com/v2/canopy-connect.js',
            },
            grecaptcha: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: false,
                url: `https://www.google.com/recaptcha/api.js?render=${this.envService.config.recaptchaSiteKey}`,
            },
            stripe: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: true,
                url: 'https://js.stripe.com/v3/',
            },
            easyMarkdown: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: true,
                url: 'https://unpkg.com/easymde/dist/easymde.min.js',
            },
            marked: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: true,
                url: 'https://cdn.jsdelivr.net/npm/marked/marked.min.js',
            },
            imageCompression: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: true,
                url: '/dynamic-external/browser-image-compression.js',
            },
            fuse: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: true,
                // url: 'https://cdn.jsdelivr.net/npm/fuse.js@7.0.0',
                url: '/dynamic-external/fuse.js',
            },
            sentry: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: false,
                url: 'https://js.sentry-cdn.com/c6fa7a0554524c2690ed06f0f0a69a51.min.js',
            },
            gtag: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: false,
                // url: '/app-scripts/partytown/google-tag-manager.js?id=G-70QZ0VK35H',
                url: 'https://www.googletagmanager.com/gtag/js?id=G-70QZ0VK35H',
            },
            places: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: true,
                // https://developers.google.com/maps/documentation/javascript/place-autocomplete
                url: `https://maps.googleapis.com/maps/api/js?key=${this.envService.config.googlePlacesAPIKey}&libraries=places`,
            },
            intercom: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: false,
                url: 'https://widget.intercom.io/widget/tpqkc4pf',
            },
            viralSweep: {
                appended: false,
                onLoad: new ReplaySubject<void>(1),
                loadInTesting: false,
                url: 'https://app.viralsweep.com/promo/ca/153542-16236_1709919996?h=',
                dynamicUrl: true,
            },
        };
    }

    async loadScript(scriptName: ScriptName, dynamicUrl?: string): Promise<boolean> {
        if (this.platformService.isServer) {
            this.logService.info(`Not loading script: ${scriptName} in server environment`);
            return Promise.resolve(false);
        }
        const script = this.knownScripts[scriptName];

        if (!script.loadInTesting && this.utilityService.localStorageClean.getItem('sb-testing')) {
            this.logService.info(`Not loading script: ${scriptName} in testing environment`);
            return Promise.resolve(false);
        }

        if (script.appended) {
            this.logService.info(`${scriptName} is already appended`);
            await firstValueFrom(script.onLoad);
            return Promise.resolve(true);
        }
        return new Promise(async (resolve, reject) => {
            const head = this.document.getElementsByTagName('head')[0];
            const existingScript = this.document.getElementById(
                `known-script-${scriptName}`
            ) as HTMLScriptElement;

            if (existingScript) {
                this.logService.info(`${scriptName} exists already`);
                await firstValueFrom(script.onLoad);
                resolve(true);
            } else {
                const newScript = this.document.createElement('script');
                newScript.addEventListener('load', () => {
                    this.logService.info(`${scriptName} loaded dynamically`);
                    script.onLoad.next();
                    resolve(true);
                });
                newScript.id = `known-script-${scriptName}`;
                newScript.src = script.dynamicUrl ? `${script.url}${dynamicUrl}` : script.url;
                if (script.integrity) {
                    newScript.integrity = script.integrity;
                }
                if (script.crossOrigin) {
                    newScript.crossOrigin = script.crossOrigin;
                }
                head.appendChild(newScript);
                script.appended = true;
                // return true;
            }
        });
    }

    async loadScripts(scriptNames: ScriptName[]): Promise<boolean> {
        if (this.platformService.isServer) {
            this.logService.warn(`Not loading scripts: ${scriptNames} in server environment`);
            return Promise.resolve(false);
        }
        const asyncArray: Promise<boolean>[] = [];
        scriptNames.forEach((scriptName) => asyncArray.push(this.loadScript(scriptName)));
        try {
            await Promise.all(asyncArray);
            return true;
        } catch (error) {
            return false;
        }
    }
}
