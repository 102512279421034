import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Params, Router } from '@angular/router';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { AgencyAdminAccount } from '@modules/manage-common/models';
import { QuoteEnsFormStep } from '@modules/quote/models';
import { QuoteEnsFormService } from '@modules/quote/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-coverage-adjustments-form-personal-info',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './coverage-adjustments-form-personal-info.component.html',
    styleUrls: ['coverage-adjustments-form-personal-info.component.scss'],
})
export class CoverageAdjustmentsFormPersonalInfoComponent implements OnInit, OnDestroy {
    // @Input() forAgency = false;
    @Input() agencyAdminAccount = AgencyAdminAccount.account;
    @Input() endorsement?: UUID;

    subscription: Subscription = new Subscription();
    quoteEnsFlowFormValue!: QuoteEnsFlowFormValue;

    constructor(
        private quoteEnsFormService: QuoteEnsFormService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowFormValuePure$.subscribe(
                (quoteEnsFlowFormValuePure) => {
                    this._processQuoteEnsFlowForm(quoteEnsFlowFormValuePure);
                    this.changeDetectorRef.detectChanges();
                }
            )
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    editPersonalInfo() {
        switch (this.agencyAdminAccount) {
            case AgencyAdminAccount.agency:
                if (this.endorsement) {
                    this.router.navigate([
                        `/agency/policies/endorsement/quote/${this.endorsement}`,
                    ]);
                } else {
                    this.router.navigate(['/agency/quotes/edit']);
                }
                break;
            case AgencyAdminAccount.admin:
                this.router.navigate([`/admin/policies-ens/endorsement/quote/${this.endorsement}`]);
                break;
            case AgencyAdminAccount.account:
                const queryParams: Params = {
                    'quote-ens-form-step': QuoteEnsFormStep.ensPersonalInfo,
                };
                if (this.endorsement) {
                    queryParams['jump-back-endorsement'] = this.endorsement;
                } else {
                    queryParams['jump-back-ens'] = QuoteEnsFormStep.rates;
                }

                this.router.navigate(['/quote/ens'], {
                    queryParams,
                });
                break;

            default:
                throw new Error(`AGENCY_ADMIN_ACCOUNT_NOT_RECOGNIZED: ${this.agencyAdminAccount}`);
        }
    }

    private _processQuoteEnsFlowForm(quoteEnsFlowFormValuePure: QuoteEnsFlowFormValue | null) {
        if (quoteEnsFlowFormValuePure === null) {
            return;
        }
        this.quoteEnsFlowFormValue = quoteEnsFlowFormValuePure;
    }
}
