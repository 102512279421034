import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum RateIssueKind {
    modsValue = 'modsValue',
    camperValue = 'camperValue',
    trailerValue = 'trailerValue',
    gearValue = 'gearValue',
    gearOnly = 'gearOnly',
}

export type RateIssueMessages = {
    [index in RateIssueKind]: RateIssue<index>;
};

const rateIssueMessages: RateIssueMessages = {
    modsValue: { kind: RateIssueKind.modsValue, message: 'Mods Value Invalid' },
    camperValue: { kind: RateIssueKind.camperValue, message: 'Camper Value Invalid' },
    trailerValue: { kind: RateIssueKind.trailerValue, message: 'Trailer Value Invalid' },
    gearValue: { kind: RateIssueKind.gearValue, message: 'Gear Value Invalid' },
    gearOnly: { kind: RateIssueKind.gearOnly, message: 'Can Not Have Gear Only' },
};

export interface RateIssue<T extends RateIssueKind = RateIssueKind> {
    kind: T;
    message: string;
}

@Injectable()
export class RateIssueService {
    private _rateIssues$ = new BehaviorSubject<RateIssue[]>([]);

    constructor() {}

    get rateIssues$(): Observable<RateIssue[]> {
        return this._rateIssues$.asObservable();
    }

    setRateIssue(rateIssueKind: RateIssueKind) {
        const currentIssues = this._rateIssues$.value;
        const foundIssue = currentIssues.find((issue) => issue.kind === rateIssueKind);
        if (foundIssue) {
            return;
        }
        currentIssues.push(rateIssueMessages[rateIssueKind]);
        this._rateIssues$.next(currentIssues);
    }

    unsetRateIssue(rateIssueKind: RateIssueKind) {
        const currentIssues = this._rateIssues$.value;
        const foundIndex = currentIssues.findIndex((issue) => issue.kind === rateIssueKind);
        if (foundIndex >= 0) {
            currentIssues.splice(foundIndex, 1);
            this._rateIssues$.next(currentIssues);
            return;
        }
    }
}
