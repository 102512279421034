<div class="early-access-background">
    <div class="d-flex justify-content-between px-4 pt-3">
        <div class="mt-1 ms-3"><sbf-tredder-icons name="tredder-logo" height="20px" color="#8fc3e9"></sbf-tredder-icons></div>
        <div class="close text-white text-end pointer" (click)="activeModal.close()">Close x</div>
    </div>
    <div class="row mt-lg-10 mt-3">
        <div class="col-lg-6 justify-content-center d-lg-flex d-none">
            <div class="r-text-48 text-white flex-shrink-0 heavy">
                <div class="d-block text-nowrap text-shadow">Tredder is a new</div>
                <div class="d-block text-nowrap text-shadow">kind of insurance</div>
                <div class="d-block text-nowrap text-shadow">made for</div>
                <div class="d-block text-nowrap text-shadow">overlanders</div>
            </div>
        </div>
        <div class="col-12 justify-content-center d-lg-none d-flex mb-3">
            <div class="r-text-48 text-white flex-shrink-0 heavy">
                <div class="d-block text-nowrap text-shadow">Tredder is a new kind of</div>
                <div class="d-block text-nowrap text-shadow">insurance made</div>
                <div class="d-block text-nowrap text-shadow">for overlanders</div>
            </div>
        </div>
        <div class="col-lg-6 col-12 d-flex justify-content-center" *ngIf="!success">
            <div class="sign-up-card p-5">
                <div class="mb-5">
                    <div class="text-start">
                        <div class="text-baby-blue mb-2">Tredder is launching very soon</div>
                        <div class="text-primary text-lg heavy">Stay up to date for</div>
                        <div class="text-primary text-lg heavy">when Tredder launches</div>
                        <div class="text-primary text-lg heavy">in your area.</div>
                    </div>
                    <div class="d-flex justify-content-center"><div class="text-sm text-red text-left" *ngIf="invalidZip">Invalid zip code: {{invalidZip}}. Try again.</div></div>
                </div>
                <form class="text-base" [formGroup]="earlyAccessForm" (ngSubmit)="onSubmit()">
                    <div class="input-group mb-5">
                        <input class="form-control" id="emailInput" data-cy="emailInput" autocomplete="email" type="email" aria-describedby="emailHelp" sbwAutoFocus formControlName="email" placeholder="Email address" [class.is-valid]="emailControlValid" [class.is-invalid]="emailControlInvalid" />
                        <div class="invalid-feedback" *ngIf='emailControl.hasError("required")'>Email required.</div>
                        <div class="invalid-feedback" *ngIf='emailControl.hasError("email")'>Email is not valid.</div>
                    </div>
                    <div class="input-group mb-5">
                        <div class="form-floating">
                            <input class="form-control" id="zipInput" data-cy="zipInput" autocomplete="off" type="text" aria-describedby="zipHelp" formControlName="zip" [class.is-invalid]="zipControlInvalid" placeholder="zip" mask="99999" />
                            <div class="invalid-feedback" *ngIf='zipControl.hasError("required")'>Zip Code required.</div>
                            <div class="invalid-feedback" *ngIf='zipControl.hasError("minlength")'>Zip Code must be 5 digits.</div>
                            <label class="text-gray-600 small" for="zipInput">Zip Code</label>
                        </div>
                    </div>
                    <div class="flex">
                        <button class="btn btn-xl rounded-pill btn-primary-gradient text-white fw-500 w-100" data-cy="earlyAccessSubmitButton" type="submit" tabindex="0"><div class="d-flex align-items-center">Sign Up</div></button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-lg-6 col-12 d-flex justify-content-center" *ngIf="success">
            <div class="sign-up-card p-5">
                <div class="mb-2">
                    <div class="d-flex justify-content-center align-items-center mb-3">
                        <sbf-tredder-icons name="success" height="35px" width="35px"></sbf-tredder-icons>
                        <div class="success text-primary text-lg2 ms-2 heavy">Success!</div>
                    </div>
                    <div class="text-primary text-base light text-center mb-3">
                        <div class="d-block">Thanks for your interest. Why not</div>
                        <div class="d-block">share Tredder with your friends?</div>
                    </div>
                    <div class="container">
                        <div class="row g-2">
                            <div class="col-6">
                                <a class="social-icon-large d-flex flex-column justify-content-end align-items-center pointer" data-cy="shareTwitterButton" href="https://twitter.com/intent/tweet?url=https%3A%2F%2Ftredder.com&amp;text=Tredder%20will%20insure%20your%20overlanding%20vehicle!" target="_blank"
                                    ><sbf-tredder-icons name="twitter-color" height="37px"></sbf-tredder-icons>
                                    <div class="text-gray-500 text-sm mt-1 pb-1">TWITTER</div></a
                                >
                            </div>
                            <div class="col-6">
                                <a class="social-icon-large d-flex flex-column justify-content-end align-items-center pointer" data-cy="shareFacebookButton" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftredder.com" target="_blank"
                                    ><sbf-tredder-icons name="facebook-color" height="45px"></sbf-tredder-icons>
                                    <div class="text-gray-500 text-sm mt-1 pb-1">FACEBOOK</div></a
                                >
                            </div>
                            <div class="col-6">
                                <div class="social-icon-large d-flex flex-column justify-content-end align-items-center pointer" (click)="doCopyLink()" tabindex="0">
                                    <sbf-tredder-icons class="d-block mb-1" name="copy-link" height="19px"></sbf-tredder-icons>
                                    <div class="text-gray-500 text-sm mt-2 pb-1">COPY LINK</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center"><div class="text-primary mt-2" *ngIf="linkCopied">Link Copied!</div></div>
                </div>
            </div>
        </div>
    </div>
    <div class="fine-print text-sm lh-sm text-white px-4 mb-4">*Prices are based on rating plans that may vary by state. Coverage options are selected by the customer, and availability and eligibility may vary. Discount varies and is not available in all states or situations and potential savings could be different.</div>
</div>
