import { AnimationEvent } from '@angular/animations';
import { DecimalPipe } from '@angular/common';
import { Injectable, PipeTransform } from '@angular/core';
import { SortDirection } from '@common/directives';
import { compareForSort, orEMPTY } from '@common/helpers';
import justCompare from 'just-compare';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export interface TableDataObject {
    // The exception that proves the rule
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataObject: Record<string, any>;
    sortKey: string;
    searchKeys: string[];
}

export interface PassThroughObject {
    // The exception that proves the rule
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    passThrough: any;
}

export type TableDataValues =
    | string
    | number
    | boolean
    | null
    | unknown[]
    | TableDataObject
    | PassThroughObject;

export interface TableData {
    [key: string]: TableDataValues;
}

type Selectable = TableData | { selected: boolean };

interface SearchResult {
    data: TableData[];
    total: number;
}

export interface FilterConfig {
    filterKey: string;
    filterValues: string[];
    filterRange: number[][];
    tableDataObject?: string;
}

export interface TableState {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: string;
    sortDirection: SortDirection;
    searchKeys: string[];
}

function sort(data: TableData[], column: string, direction: string): TableData[] {
    if (direction === '') {
        return data;
    } else {
        // eslint-disable-next-line complexity
        return data.sort((a, b) => {
            const aColumn = a[column];
            const bColumn = b[column];
            if (Array.isArray(aColumn) || Array.isArray(bColumn)) {
                throw new Error('CAN_NOT_SORT_ARRAY');
            }
            if (_isDataTableObject(aColumn) && _isDataTableObject(bColumn)) {
                const res = compareForSort(
                    aColumn.dataObject[aColumn.sortKey] as string | number,
                    bColumn.dataObject[bColumn.sortKey] as string | number
                );
                return direction === 'asc' ? res : -res;
            }
            const res = compareForSort(aColumn as string | number, bColumn as string | number);
            return direction === 'asc' ? res : -res;
        });
    }
}

function _isDataTableObject(namedColum: TableDataValues): namedColum is TableDataObject {
    if (typeof namedColum !== 'object') {
        return false;
    }
    if ((namedColum as unknown[])?.length) {
        return false;
    }
    return !!(namedColum as TableDataObject)?.dataObject;
}

/* eslint-disable complexity */
function matches(
    data: TableData,
    searchKeys: string[],
    term: string,
    decimalPipe: PipeTransform
): boolean {
    let isMatch = false;
    for (const searchKey of searchKeys) {
        const dataKey = data[searchKey];
        if (!dataKey) {
            return false;
        }
        if (
            typeof dataKey === 'string' &&
            (dataKey as string).toLowerCase().includes(term.toLowerCase())
        ) {
            isMatch = true;
            break;
        }
        if (
            typeof dataKey === 'number' &&
            decimalPipe.transform(dataKey).toLowerCase().includes(term.toLowerCase())
        ) {
            isMatch = true;
            break;
        }
        if (_isDataTableObject(dataKey)) {
            dataKey.searchKeys.forEach((searchKey) => {
                if (
                    orEMPTY(dataKey.dataObject[searchKey])
                        .toLowerCase()
                        .includes(term.toLowerCase())
                ) {
                    isMatch = true;
                }
            });
            break;
        }
        if (
            Array.isArray(dataKey) &&
            (dataKey as unknown as { name: string }[]).find(
                (someObject) =>
                    someObject.name && someObject.name.toLowerCase().includes(term.toLowerCase())
            )
        ) {
            isMatch = true;
            break;
        }
    }
    return isMatch;
}

function filters(datum: TableData, filters: FilterConfig[]): boolean {
    if (filters.length === 0) {
        return true;
    }

    let isMatch = true;
    filters.forEach((_filter) => {
        if (_filter.filterValues.length > 0) {
            isMatch = isMatch && _filterAlphanumeric(datum, _filter);
        }
        if (_filter.filterRange.length > 0) {
            isMatch = isMatch && _filterNumeric(datum, _filter);
        }
    });

    return isMatch;
}

function _filterAlphanumeric(datum: TableData, _filter: FilterConfig): boolean {
    let isMatch = true;
    let stringToMatchAgainst: string | undefined;
    if (_filter.tableDataObject) {
        stringToMatchAgainst = (datum[_filter.tableDataObject as string] as TableDataObject)
            .dataObject[_filter.filterKey];
    }
    if (datum.hasOwnProperty(_filter.filterKey)) {
        stringToMatchAgainst = datum[_filter.filterKey] as string;
    }
    if (stringToMatchAgainst) {
        const foundValue = _filter.filterValues.find(
            (filterValue) => filterValue === stringToMatchAgainst
        );
        isMatch = isMatch && !!foundValue;
    }

    return isMatch;
}

function _filterNumeric(datum: TableData, _filter: FilterConfig): boolean {
    let isMatch = true;
    let numberToMatchAgainst: number | undefined;
    if (_filter.tableDataObject) {
        numberToMatchAgainst = (datum[_filter.tableDataObject as string] as TableDataObject)
            .dataObject[_filter.filterKey];
    }
    if (datum.hasOwnProperty(_filter.filterKey)) {
        numberToMatchAgainst = datum[_filter.filterKey] as number;
    }
    if (typeof numberToMatchAgainst === 'number') {
        const isBetweenSomeFilterRange = _filter.filterRange.some((rangeArray) => {
            return (
                rangeArray[0] <= (numberToMatchAgainst as number) &&
                (numberToMatchAgainst as number) <= rangeArray[1]
            );
        });

        isMatch = isMatch && isBetweenSomeFilterRange;
    }

    return isMatch;
}

@Injectable()
export class TableService {
    private _originalData!: TableData[];
    private _originalSelected!: { [key: string]: boolean };
    private _currentlySelected!: { [key: string]: boolean };
    private _filteredData!: TableData[];
    // private _searchKeys!: string[];
    private _filters: FilterConfig[] = [];
    private _data$ = new BehaviorSubject<TableData[]>([]);
    private _selectionPurity$ = new BehaviorSubject<boolean>(true);
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _total$ = new BehaviorSubject<number>(0);
    private _selected$ = new BehaviorSubject<TableData[]>([]);
    private _hasSelected$ = new BehaviorSubject<boolean>(false);
    private _toggleAllState = false;
    private _animationEnd$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private _paginate = true;
    private _singleSelect = false;
    private _active = false;

    public pageSize$ = new ReplaySubject<number>(1);

    constructor(private decimalPipe: DecimalPipe) {
        this._search$
            .pipe(
                tap(() => this._loading$.next(true)),
                // debounceTime(100),
                switchMap(() => this._search()),
                // delay(100),
                tap(() => this._loading$.next(false))
            )
            .subscribe((result) => {
                this._data$.next(result.data);
                this._total$.next(result.total);
                this._selected$.next(result.data.filter((row) => row.selected));
            });
    }

    private _state: TableState = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: '',
        searchKeys: [],
    };

    setData(
        data: TableData[],
        searchKeys: string[],
        initialState?: Partial<TableState>,
        paginate = true,
        singleSelect = false
    ) {
        this._setPageSize(data.length);
        this._paginate = paginate;
        this._singleSelect = singleSelect;

        if (initialState) {
            Object.assign(this._state, initialState);
        }

        this._originalData = data;
        this._originalSelected = {};
        this._currentlySelected = {};

        this._hasSelected$.next(false);

        data.forEach((datum) => {
            this._originalSelected[datum.id as string | number] = (datum as Selectable)
                .selected as boolean;
            this._currentlySelected[datum.id as string | number] = (datum as Selectable)
                .selected as boolean;
        });
        this._filteredData = [];
        this._state.searchKeys = searchKeys;
        this._search$.next();
        this._toggleAllState = false;
        this._active = true;
    }

    addRow(row: TableData) {
        this._originalData.unshift(row);
        if (row.selected) {
            this._currentlySelected[row.id as string] = true;
        }
        this._search$.next();
    }

    setInactive() {
        this._active = false;
    }

    toggleAll() {
        this._toggleAllState = !this._toggleAllState;
        this._filteredData.forEach(
            (data) => ((data as Selectable).selected = this._toggleAllState)
        );
        this._filteredData.forEach((datum) => {
            this._currentlySelected[datum.id as string | number] = (datum as Selectable)
                .selected as boolean;
        });
        this._updateSelectionPurity();
        this._search$.next();
        this._hasSelected$.next(!this.noneSelected);
    }

    toggleRowSelected(id: UUID) {
        const toToggle = this._originalData.find((data) => data.id === id) as Selectable;
        if (toToggle) {
            const toToggleSelected = !toToggle.selected;
            if (this._singleSelect) {
                this._clearSelections();
            }
            toToggle.selected = toToggleSelected;
            this._currentlySelected[id] = toToggle.selected;
            this._updateSelectionPurity();
            this._search$.next();
            this._hasSelected$.next(!this.noneSelected);
        }
    }

    private _clearSelections() {
        for (const key in this._currentlySelected) {
            if (Object.prototype.hasOwnProperty.call(this._currentlySelected, key)) {
                // assuming below that id is index
                this._originalData[key as unknown as number].selected = false;
                this._currentlySelected[key] = false;
            }
        }
    }
    private _updateSelectionPurity() {
        this._selectionPurity$.next(justCompare(this._originalSelected, this._currentlySelected));
    }

    private _setPageSize(length: number) {
        if (length >= 5000) {
            this._state.pageSize = 500;
            return;
        }
        if (length >= 1000) {
            this._state.pageSize = 100;
            return;
        }
        if (length >= 500) {
            this._state.pageSize = 50;
            return;
        }
        if (length >= 100) {
            this._state.pageSize = 25;
            return;
        }
        this._state.pageSize = 10;
    }

    animationEnd($event: AnimationEvent) {
        if ($event.fromState !== 'void') {
            this._animationEnd$.next(true);
        }
    }

    get data$() {
        return this._data$.asObservable();
    }
    get selectionPurity$() {
        return this._selectionPurity$.asObservable();
    }
    get loading$() {
        return this._loading$.asObservable();
    }
    get animationEnd$() {
        return this._animationEnd$.asObservable();
    }
    get total$() {
        return this._total$.asObservable();
    }
    get selected$() {
        return this._selected$.asObservable();
    }
    get searchTerm() {
        return this._state.searchTerm;
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
    }
    get searchKeys() {
        return this._state.searchKeys;
    }
    set searchKeys(searchKeys: string[]) {
        this._set({ searchKeys });
    }
    get page() {
        return this._state.page;
    }
    set page(page: number) {
        this._set({ page });
    }
    get pageSize() {
        return this._state.pageSize;
    }
    set pageSize(pageSize: number) {
        this.pageSize$.next(pageSize);
        this._set({ pageSize });
    }
    get toggleAllState() {
        return this._toggleAllState;
    }
    get selected() {
        return this._originalData.filter(
            (datum) => this._currentlySelected[datum.id as string | number]
        );
    }
    get noneSelected(): boolean {
        let noneSelectedValue = true;
        for (const key in this._currentlySelected) {
            if (Object.prototype.hasOwnProperty.call(this._currentlySelected, key)) {
                const element = this._currentlySelected[key];
                if (element === true) {
                    noneSelectedValue = false;
                    break;
                }
            }
        }
        return noneSelectedValue;
    }
    get hasSelected$() {
        return this._hasSelected$.asObservable();
    }
    set sortColumn(sortColumn: string) {
        this._set({ sortColumn });
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }

    set filters(filters: FilterConfig[]) {
        if (this._active) {
            this._filters = filters;
            this._search$.next();
        }
    }

    private _set(patch: Partial<TableState>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchTerm, searchKeys } = this._state;

        // 1. sort
        let data = sort(this._originalData, sortColumn, sortDirection);

        // 2. filter
        data = data.filter((datum) => filters(datum, this._filters));

        // 2. search
        data = data.filter((datum) => matches(datum, searchKeys, searchTerm, this.decimalPipe));
        const total = data.length;

        this._filteredData = data;

        // // 3. paginate
        if (this._paginate) {
            data = data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        }

        return of({ data, total });
    }
}
