import type Big from 'big.js';

export enum InsuranceCoverageKey {
    bodilyInjury = 'bodilyInjury',
    propertyDamage = 'propertyDamage',
    comprehensive = 'comprehensive',
    collision = 'collision',
    gap = 'gap',
    medical = 'medical',
    personalInjuryProtection = 'personalInjuryProtection',
    unUnderinsuredMotoristBodilyInjury = 'unUnderinsuredMotoristBodilyInjury',
    unUnderinsuredMotoristPropertyDamage = 'unUnderinsuredMotoristPropertyDamage',
    rental = 'rental',
    // tow = 'tow',
    // trailerContents = 'trailerContents',
    trailerComprehensive = 'trailerComprehensive',
    trailerCollision = 'trailerCollision',
    acpeComprehensive = 'acpeComprehensive',
    acpeCollision = 'acpeCollision',
    overlandingGearACV = 'overlandingGearACV',
    overlandingGearReplacementCost = 'overlandingGearReplacementCost',
    campsiteLiability = 'campsiteLiability',
    scheduledMedicalBenefit = 'scheduledMedicalBenefit',
    emergencyExpense = 'emergencyExpense',
    towRoadside = 'towRoadside',
    offRoadRecovery = 'offRoadRecovery',
}

export enum InsuranceCoverageKeyShort {
    'BI' = 'BI',
    'PD' = 'PD',
    'COMP' = 'COMP',
    'COLL' = 'COLL',
    'LOAN' = 'LOAN',
    'MED' = 'MED',
    'PIP' = 'PIP',
    'UM/UIMBI' = 'UM/UIMBI',
    'UM/UIMPD' = 'UM/UIMPD',
    'RENT' = 'RENT',
    // 'CONTENTS' = 'CONTENTS',
    'COMP-TRLR' = 'COMP-TRLR',
    'COLL-TRLR' = 'COLL-TRLR',
    'COMP-ACPE' = 'COMP-ACPE',
    'COLL-ACPE' = 'COLL-ACPE',
    'OGACV' = 'OGACV',
    'OGRC' = 'OGRC',
    'CAMPLI' = 'CAMPLI',
    'SMB' = 'SMB',
    'EMEXP' = 'EMEXP',
    'TOWR' = 'TOWR',
    'OFFRD' = 'OFFRD',
}

export type InsuranceCoverages = keyof typeof InsuranceCoverageKey;

export enum InsuranceCoveragesSet {
    base = 'base',
    trailer = 'trailer',
    unit = 'unit',
    acpe = 'acpe',
    gear = 'gear',
    special = 'special',
}

export type InsuranceCoveragesSets =
    | InsuranceCoveragesBase
    | InsuranceCoveragesTrailer
    | InsuranceCoveragesUnit
    | InsuranceCoveragesAcpe
    | InsuranceCoveragesGear
    | InsuranceCoveragesSpecial;

export type InsuranceCoveragesBase = Extract<
    InsuranceCoverages,
    | 'bodilyInjury'
    | 'propertyDamage'
    | 'comprehensive'
    | 'collision'
    | 'gap'
    | 'medical'
    | 'personalInjuryProtection'
    | 'unUnderinsuredMotoristBodilyInjury'
    | 'unUnderinsuredMotoristPropertyDamage'
    | 'rental'
    | 'tow'
    | 'acpeComprehensive'
    | 'acpeCollision'
>;

export type InsuranceCoveragesTrailer = Extract<
    InsuranceCoverages,
    'trailerContents' | 'trailerComprehensive' | 'trailerCollision'
>;

export type InsuranceCoveragesUnit = Extract<
    InsuranceCoverages,
    'trailerComprehensive' | 'trailerCollision'
>;

export type InsuranceCoveragesAcpe = Extract<
    InsuranceCoverages,
    'acpeComprehensive' | 'acpeCollision'
>;

export type InsuranceCoveragesGear = Extract<
    InsuranceCoverages,
    'overlandingGearACV' | 'overlandingGearReplacementCost'
>;

export type InsuranceCoveragesSpecial = Extract<
    InsuranceCoverages,
    | 'campsiteLiability'
    | 'scheduledMedicalBenefit'
    | 'emergencyExpense'
    | 'towRoadside'
    | 'offRoadRecovery'
>;

function isDefined<T>(value: T): boolean {
    if (value === undefined || value === null) {
        return false;
    }
    return true;
}

export function isInsuranceCoveragesBase(
    value: InsuranceCoverageFactors<string, string>
): asserts value is InsuranceCoverageFactors<InsuranceCoveragesBase, string> {
    // TODO: THIS IS SHALLOW, SHOULD CHECK ALL VALUES
    if (!isDefined(value.bodilyInjury)) {
        throw new Error(`BODILY_INJURY_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_BASE`);
    }
}

export function isInsuranceCoveragesTrailer(
    value: InsuranceCoverageFactors<string, string>
): asserts value is InsuranceCoverageFactors<InsuranceCoveragesTrailer, string> {
    // TODO: THIS IS SHALLOW, SHOULD CHECK ALL VALUES
    if (!isDefined(value.trailerContents)) {
        throw new Error(`TRAILER_CONTENTS_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_TRAILER`);
    }
}

export function isInsuranceCoveragesUnit(
    value: InsuranceCoverageFactors<string, string>
): asserts value is InsuranceCoverageFactors<InsuranceCoveragesUnit, string> {
    if (!isDefined(value.trailerComprehensive)) {
        throw new Error(`TRAILER_COMPREHENSIVE_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_UNIT`);
    }
    if (!isDefined(value.trailerCollision)) {
        throw new Error(`TRAILER_COLLISION_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_UNIT`);
    }
}

export function isInsuranceCoveragesAcpe(
    value: InsuranceCoverageFactors<string, string>
): asserts value is InsuranceCoverageFactors<InsuranceCoveragesAcpe, string> {
    if (!isDefined(value.acpeComprehensive)) {
        throw new Error(`ACPE_COMPREHENSIVE_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_ACPE`);
    }
    if (!isDefined(value.acpeCollision)) {
        throw new Error(`ACPE_COLLISION_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_ACPE`);
    }
}

export function isInsuranceCoveragesGear(
    value: InsuranceCoverageFactors<string, string>
): asserts value is InsuranceCoverageFactors<InsuranceCoveragesGear, string> {
    if (!isDefined(value.overlandingGearACV)) {
        throw new Error(`OVERLANDING_GEAR_ACV_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_GEAR`);
    }
    if (!isDefined(value.overlandingGearReplacementCost)) {
        throw new Error(`OVERLANDING_GEAR_RC_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_GEAR`);
    }
}

export function isInsuranceCoveragesSpecial(
    value: InsuranceCoverageFactors<string, string>
): asserts value is InsuranceCoverageFactors<InsuranceCoveragesSpecial, string> {
    if (!isDefined(value.campsiteLiability)) {
        throw new Error(`CAMPSITE_LIABILITY_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_SPECIAL`);
    }
    if (!isDefined(value.scheduledMedicalBenefit)) {
        throw new Error(`SCHEDULED_MEDICAL_BENEFIT_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_SPECIAL`);
    }
    if (!isDefined(value.emergencyExpense)) {
        throw new Error(`EMERGENCY_EXPENSE_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_SPECIAL`);
    }
    if (!isDefined(value.towRoadside)) {
        throw new Error(`TOW_ROADSIDE_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_SPECIAL`);
    }
    if (!isDefined(value.offRoadRecovery)) {
        throw new Error(`OFF_ROAD_RECOVERY_DOES_NOT_EXIST_FOR_INSURANCE_COVERAGE_SPECIAL`);
    }
}

export type InsuranceCoverageFactors<S extends string, T = Big> = {
    [key in S]: T;
};

// INFO: Used in Front End
export const insuranceCoverageMap: EnumMap<InsuranceCoverageKey> = {
    bodilyInjury: 'BI',
    propertyDamage: 'PD',
    comprehensive: 'COMP',
    collision: 'COLL',
    gap: 'LOAN',
    medical: 'MED',
    personalInjuryProtection: 'PIP',
    unUnderinsuredMotoristBodilyInjury: 'UM/UIMBI',
    unUnderinsuredMotoristPropertyDamage: 'UM/UIMPD',
    rental: 'RENT',
    trailerComprehensive: 'COMP-TRLR',
    trailerCollision: 'COLL-TRLR',
    acpeComprehensive: 'COMP-ACPE',
    acpeCollision: 'COLL-ACPE',
    overlandingGearACV: 'OGACV',
    overlandingGearReplacementCost: 'OGRC',
    campsiteLiability: 'CAMPLI',
    scheduledMedicalBenefit: 'SMB',
    emergencyExpense: 'EMEXP',
    towRoadside: 'TOWR',
    offRoadRecovery: 'OFFRD',
};

export const insuranceCoverageKeysBase: InsuranceCoverageKey[] = [
    InsuranceCoverageKey.bodilyInjury,
    InsuranceCoverageKey.propertyDamage,
    InsuranceCoverageKey.comprehensive,
    InsuranceCoverageKey.collision,
    InsuranceCoverageKey.gap,
    InsuranceCoverageKey.medical,
    InsuranceCoverageKey.personalInjuryProtection,
    InsuranceCoverageKey.unUnderinsuredMotoristBodilyInjury,
    InsuranceCoverageKey.unUnderinsuredMotoristPropertyDamage,
    InsuranceCoverageKey.rental,
    // InsuranceCoverageKey.tow,
    InsuranceCoverageKey.acpeComprehensive,
    InsuranceCoverageKey.acpeCollision,
];

export const insuranceCoverageKeysTrailer: InsuranceCoverageKey[] = [
    // InsuranceCoverageKey.trailerContents,
    InsuranceCoverageKey.trailerComprehensive,
    InsuranceCoverageKey.trailerCollision,
];

// INFO: Used in Front End
export const insuranceCoverageKeyBaseOrder: InsuranceCoverageKey[] = [
    InsuranceCoverageKey.bodilyInjury,
    InsuranceCoverageKey.propertyDamage,
    InsuranceCoverageKey.comprehensive,
    InsuranceCoverageKey.collision,
    InsuranceCoverageKey.gap,
    InsuranceCoverageKey.medical,
    InsuranceCoverageKey.personalInjuryProtection,
    InsuranceCoverageKey.unUnderinsuredMotoristBodilyInjury,
    InsuranceCoverageKey.unUnderinsuredMotoristPropertyDamage,
    InsuranceCoverageKey.rental,
    InsuranceCoverageKey.acpeComprehensive,
    InsuranceCoverageKey.acpeCollision,
];

// INFO: Used in Front End
export const insuranceCoverageKeyTrailerOrder: InsuranceCoverageKey[] = [
    InsuranceCoverageKey.trailerComprehensive,
    InsuranceCoverageKey.trailerCollision,
];

// INFO: Used in Front End
export const insuranceCoverageKeyUnitOrder: InsuranceCoverageKey[] = [
    InsuranceCoverageKey.trailerComprehensive,
    InsuranceCoverageKey.trailerCollision,
];

export const insuranceCoverageKeyAcpeOrder: InsuranceCoverageKey[] = [
    InsuranceCoverageKey.acpeComprehensive,
    InsuranceCoverageKey.acpeCollision,
];

export const insuranceCoverageKeyGearOrder: InsuranceCoverageKey[] = [
    InsuranceCoverageKey.overlandingGearACV,
    InsuranceCoverageKey.overlandingGearReplacementCost,
];

export const insuranceCoverageKeySpecialOrder: InsuranceCoverageKey[] = [
    InsuranceCoverageKey.campsiteLiability,
    InsuranceCoverageKey.scheduledMedicalBenefit,
    InsuranceCoverageKey.emergencyExpense,
    InsuranceCoverageKey.towRoadside,
    InsuranceCoverageKey.offRoadRecovery,
];

// INFO: Used in Front End
export const insuranceCoverageKeyAllShortOrder: InsuranceCoverageKeyShort[] = [
    InsuranceCoverageKeyShort.BI,
    InsuranceCoverageKeyShort.PD,
    InsuranceCoverageKeyShort.COMP,
    InsuranceCoverageKeyShort.COLL,
    InsuranceCoverageKeyShort.LOAN,
    InsuranceCoverageKeyShort.MED,
    InsuranceCoverageKeyShort.PIP,
    InsuranceCoverageKeyShort['UM/UIMBI'],
    InsuranceCoverageKeyShort['UM/UIMPD'],
    InsuranceCoverageKeyShort.RENT,
    // InsuranceCoverageKeyShort.TOW,
    // InsuranceCoverageKeyShort.CONTENTS,
    InsuranceCoverageKeyShort['COMP-TRLR'],
    InsuranceCoverageKeyShort['COLL-TRLR'],
    InsuranceCoverageKeyShort['COMP-ACPE'],
    InsuranceCoverageKeyShort['COLL-ACPE'],
    // InsuranceCoverageKeyShort.TLREPC,
    InsuranceCoverageKeyShort.OGACV,
    InsuranceCoverageKeyShort.OGRC,
    InsuranceCoverageKeyShort.CAMPLI,
    InsuranceCoverageKeyShort.SMB,
    InsuranceCoverageKeyShort.EMEXP,
    InsuranceCoverageKeyShort.OFFRD,
];

// INFO: Used in Front End
export const insuranceCoverageKeyBaseShortOrder: InsuranceCoverageKeyShort[] = [
    InsuranceCoverageKeyShort.BI,
    InsuranceCoverageKeyShort.PD,
    InsuranceCoverageKeyShort.COMP,
    InsuranceCoverageKeyShort.COLL,
    InsuranceCoverageKeyShort.LOAN,
    InsuranceCoverageKeyShort.MED,
    InsuranceCoverageKeyShort.PIP,
    InsuranceCoverageKeyShort['UM/UIMBI'],
    InsuranceCoverageKeyShort['UM/UIMPD'],
    InsuranceCoverageKeyShort.RENT,
    // InsuranceCoverageKeyShort.TOWR,
    InsuranceCoverageKeyShort['COMP-ACPE'],
    InsuranceCoverageKeyShort['COLL-ACPE'],
];

// INFO: Used in Front End
export const insuranceCoverageKeyTrailerShortOrder: InsuranceCoverageKeyShort[] = [
    // InsuranceCoverageKeyShort.CONTENTS,
    InsuranceCoverageKeyShort['COMP-TRLR'],
    InsuranceCoverageKeyShort['COLL-TRLR'],
    InsuranceCoverageKeyShort['COMP-ACPE'],
    InsuranceCoverageKeyShort['COLL-ACPE'],
];

export const insuranceCoverageKeyUnitShortOrder: InsuranceCoverageKeyShort[] = [
    InsuranceCoverageKeyShort['COMP-TRLR'],
    InsuranceCoverageKeyShort['COLL-TRLR'],
];

export const insuranceCoverageKeyAcpeShortOrder: InsuranceCoverageKeyShort[] = [
    InsuranceCoverageKeyShort['COMP-ACPE'],
    InsuranceCoverageKeyShort['COLL-ACPE'],
];

export const insuranceCoverageKeyGearShortOrder: InsuranceCoverageKeyShort[] = [
    InsuranceCoverageKeyShort.OGACV,
    InsuranceCoverageKeyShort.OGRC,
];

export const insuranceCoverageKeySpecialShortOrder: InsuranceCoverageKeyShort[] = [
    InsuranceCoverageKeyShort.CAMPLI,
    InsuranceCoverageKeyShort.SMB,
    InsuranceCoverageKeyShort.EMEXP,
    InsuranceCoverageKeyShort.OFFRD,
];
