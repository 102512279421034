import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { NumberRange } from '@backend-types/utility';
import { InputRangeFormValue, ModelFormGroup } from '@common/models';
import { AssertionService } from '@common/services';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-input-range',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './input-range.component.html',
    styleUrls: ['input-range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: InputRangeComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: InputRangeComponent,
        },
    ],
})
export class InputRangeComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {
    @Input() idAppend!: string;

    subscription: Subscription = new Subscription();

    inputRangeForm: ModelFormGroup<InputRangeFormValue> = this.fb.group({
        min: new FormControl<number | null>(null, Validators.required),
        max: new FormControl<number | null>(null, Validators.required),
    });

    onTouched: () => unknown = () => {};
    onChange = (range: NumberRange) => {};

    constructor(
        private fb: FormBuilder,
        private assertionService: AssertionService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        if (!this.idAppend) {
            throw new Error('NEED_ID_APPEND_FOR_INPUT_RADIO');
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(onChange: (range: NumberRange) => unknown) {
        this.onChange = onChange;

        this.subscription.add(
            this.inputRangeForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.inputRangeForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const inputRangeFormValue = this._inputRangeFormValue();
                        onChange({
                            min: inputRangeFormValue.min,
                            max: inputRangeFormValue.max,
                        });
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.inputRangeForm.disable({ emitEvent: false });
        } else {
            this.inputRangeForm.enable({ emitEvent: false });
        }
    }

    writeValue(range: NumberRange | null) {
        if (range === null) {
            this.inputRangeForm.reset();
            return;
        }
        this.minControl.setValue(range.min, { emitEvent: false });
        this.maxControl.setValue(range.max, { emitEvent: false });
        this.changeDetectorRef.detectChanges();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.inputRangeForm?.invalid) {
            return { inputRangeFormInvalid: true };
        }

        return null;
    }

    private _inputRangeFormValue(): InputRangeFormValue {
        const { min, max } = this.inputRangeForm.value;

        this.assertionService.isDefinedOrThrow(min);
        this.assertionService.isDefinedOrThrow(max);

        return {
            min,
            max,
        };
    }

    /* Accessor Methods */

    get minControl() {
        return this.inputRangeForm.get('min') as FormControl;
    }

    get minControlValid() {
        return this.minControl.value && !this.minControl.hasError('required');
    }

    get minControlInvalid() {
        return this.minControl.touched && this.minControl.hasError('required');
    }

    get maxControl() {
        return this.inputRangeForm.get('max') as FormControl;
    }

    get maxControlValid() {
        return this.maxControl.value && !this.maxControl.hasError('required');
    }

    get maxControlInvalid() {
        return this.maxControl.touched && this.maxControl.hasError('required');
    }
}
