import { InjectionToken } from '@angular/core';

export const ParseToken = new InjectionToken('JSON["parse"]');
export function parseProvider() {
    return JSON.parse;
}

export const StringifyToken = new InjectionToken('JSON["stringify"]');
export function stringifyProvider() {
    return JSON.stringify;
}
