<div class="container-xxl mt-md-10 mt-5">
    <div class="d-flex justify-content-center align-items-center">
        <div class="col-md-6 col-12" *ngIf="email">
            <div class="text-primary r-text-heading mb-3">
                Finish creating
                <div class="d-xl-inline d-inline-block">your account</div>
            </div>
            <div class="text-start quote-subtitle mb-5">
                Use this account to manage your policies and
                <div class="d-sm-inline-block d-inline">social profiles with Tredder.</div>
            </div>
            <sbf-create-password-form [userID]="userID" [email]="email" [passwordResetToken]="passwordResetToken" redirectUrl="/account/insurance/dashboard"></sbf-create-password-form>
        </div>
    </div>
</div>
