import { Injectable } from '@angular/core';
import { PaymentPlanName, PaymentPlanSource } from '@backend-types/payment-plan';
import { CalculateRateEnsOnlyResponse } from '@backend-types/roc-lib';

@Injectable()
export class PaymentPlanService {
    constructor() {}

    getPaymentPlanSource(
        ensOnly: CalculateRateEnsOnlyResponse,
        paymentPlanName: PaymentPlanName,
        endorsement: boolean
    ): PaymentPlanSource {
        if (endorsement) {
            const foundPlan = ensOnly.endorsementRates?.paymentPlanSources.find(
                (paymentPlanSource) => paymentPlanSource.name === paymentPlanName
            );
            if (!foundPlan) {
                throw new Error(`UNABLE_TO_FIND_ENDORSEMENT_PLAN: ${paymentPlanName}`);
            }
            return foundPlan;
        } else {
            const foundPlan = ensOnly.paymentPlanSources.find(
                (paymentPlanSource) => paymentPlanSource.name === paymentPlanName
            );
            if (!foundPlan) {
                throw new Error(`UNABLE_TO_FIND_PLAN: ${paymentPlanName}`);
            }

            return foundPlan;
        }
    }
}
