<ng-container *ngIf='size === "small"'
    ><div class="col-xl-5 col-lg-6 col-md-8 col-sm-11">
        <div class="card round-rect my-5"><ng-container *ngTemplateOutlet="content"></ng-container></div></div></ng-container
><ng-container *ngIf='size === "medium"'
    ><div class="col-xl-8 col-lg-9">
        <div class="card round-rect my-5"><ng-container *ngTemplateOutlet="content"></ng-container></div></div></ng-container
><ng-container *ngIf='size === "large"'
    ><div class="col-xl-10 col-lg-12">
        <div class="card round-rect my-5"><ng-container *ngTemplateOutlet="content"></ng-container></div></div></ng-container
><ng-template #content><ng-content></ng-content></ng-template>
