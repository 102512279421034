import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { PaymentPlanName, PaymentPlanSource } from '@backend-types/payment-plan';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { AnalyticsService } from '@common/services';
import { AgencyAdminAccount } from '@modules/manage-common/models';
import {
    PaymentPlanService,
    QuoteEnsFormService,
    QuoteEnsRetrievalService,
} from '@modules/quote/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { TaxesFeesModalComponent } from '../taxes-fees-modal/taxes-fees-modal.component';

@Component({
    selector: 'sbf-quote-ens-purchase-payment-full',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-purchase-payment-full.component.html',
    styleUrls: ['quote-ens-purchase-payment-full.component.scss'],
})
export class QuoteEnsPurchasePaymentFullComponent implements OnInit, OnDestroy {
    @Input() endorsement = false;
    @Input() agencyAdminAccount = AgencyAdminAccount.account;

    activeEnsQuote!: EnsResponseWithEnsOnly | null;
    quoteEnsFlowFormValue!: QuoteEnsFlowFormValue;
    fullPaymentPlanSource!: PaymentPlanSource;

    subscription: Subscription = new Subscription();

    constructor(
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private quoteEnsFormService: QuoteEnsFormService,
        private paymentPlanService: PaymentPlanService,
        private router: Router,
        private ngbModalService: NgbModal,
        private changeDetectorRef: ChangeDetectorRef,
        private analyticsService: AnalyticsService
    ) {
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_purchase_full',
            label: this.agencyAdminAccount,
            category: this.endorsement ? 'endorsement' : undefined,
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuote$.subscribe((activeEnsQuote) => {
                if (!activeEnsQuote) {
                    return;
                }

                this.activeEnsQuote = activeEnsQuote;

                this.fullPaymentPlanSource = this.paymentPlanService.getPaymentPlanSource(
                    activeEnsQuote.ensOnly,
                    PaymentPlanName.full,
                    this.endorsement
                );
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    showTaxesAndFees() {
        const {
            componentInstance,
        }: {
            componentInstance: TaxesFeesModalComponent;
            result: Promise<unknown>;
        } = this.ngbModalService.open(TaxesFeesModalComponent, {
            size: 'md',
            centered: false,
        });

        componentInstance.paymentPlanSource = this.fullPaymentPlanSource;
    }

    pay() {
        this.quoteEnsFormService.setSelectedPaymentPlan(PaymentPlanName.full);
        this.subscription.add(
            this.quoteEnsRetrievalService
                .updateQuoteForPurchase$({
                    generateDocs: this.isAdminOrAgency,
                    updateAddress: false,
                })
                .subscribe(() => {
                    switch (this.agencyAdminAccount) {
                        case AgencyAdminAccount.agency:
                            if (this.endorsement) {
                                this.router.navigate(['/agency/policies/endorsement/documents'], {
                                    queryParams: {
                                        plan: PaymentPlanName.full,
                                    },
                                });
                            } else {
                                this.router.navigate(['/agency/quotes/documents'], {
                                    queryParams: {
                                        plan: PaymentPlanName.full,
                                    },
                                });
                            }
                            break;
                        case AgencyAdminAccount.admin:
                            this.router.navigate(['/admin/policies-ens/endorsement/documents'], {
                                queryParams: {
                                    plan: PaymentPlanName.full,
                                },
                            });
                            break;
                        case AgencyAdminAccount.account:
                            if (this.endorsement) {
                                this.router.navigate(['/account/insurance/endorsement/documents'], {
                                    queryParams: {
                                        plan: PaymentPlanName.full,
                                    },
                                });
                            } else {
                                this.router.navigate(['/quote/ens'], {
                                    queryParams: {
                                        'quote-ens-form-step': 'rc2',
                                        plan: PaymentPlanName.full,
                                    },
                                });
                            }
                            break;
                        default:
                            throw new Error(
                                `AGENCY_ADMIN_ACCOUNT_NOT_RECOGNIZED: ${this.agencyAdminAccount}`
                            );
                    }
                })
        );
    }
    get isAdminOrAgency(): boolean {
        return (
            this.agencyAdminAccount === AgencyAdminAccount.admin ||
            this.agencyAdminAccount === AgencyAdminAccount.agency
        );
    }
}
