import { Pipe, PipeTransform } from '@angular/core';
import { createSortByKey, SortDirectionCompare } from '@common/helpers';

@Pipe({
    name: 'callback',
    pure: false,
})
export class CallbackPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(items: any[], callback: (item: any) => boolean): unknown {
        if (!items || !callback) {
            return items;
        }
        return items.filter((item) => callback(item));
    }
}

@Pipe({
    name: 'sortByKey',
})
export class SortByKey implements PipeTransform {
    transform(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: Record<string, any>[],
        key: string,
        direction: SortDirectionCompare = SortDirectionCompare.asc
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Record<string, any>[] {
        return value.sort(createSortByKey(key, direction));
    }
}

@Pipe({
    name: 'ssn',
})
export class SSN implements PipeTransform {
    constructor() {}
    transform(ssnString: string) {
        const last4 = ssnString.slice(5, 9);
        return `###-##-${last4}`;
    }
}
