import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { quoteEnsExclusionConfigs } from '@backend-types/exclusions';
import { EnsResponse, EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { PremiumBreakdown } from '@backend-types/roc-lib';
import { inputIsEnsResponseWithEnsOnly } from '@common/helpers';
import {
    AnalyticsService,
    AssertionService,
    DocumentsService,
    HashService,
    OverlayService,
    UtilityService,
} from '@common/services';
import Big from 'big.js';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    Observable,
    of,
    ReplaySubject,
    switchMap,
    take,
    tap,
    throwError,
} from 'rxjs';

import { QuoteService } from './quote.service';
import { QuoteCacheService } from './quote-cache.service';
import { QuoteEnsFormService } from './quote-ens-form.service';

export interface UXPremiumBreakdown {
    mods?: UXPremiumBreakdownDetails;
    gear?: UXPremiumBreakdownDetails;
    camper?: UXPremiumBreakdownDetails;
    trailer?: UXPremiumBreakdownDetails;
    offRoad?: UXPremiumBreakdownDetails;
    emergency?: UXPremiumBreakdownDetails;
    towing?: UXPremiumBreakdownDetails;
    rigs?: UXPremiumBreakdownRig[];
}

export interface UXPremiumBreakdownRig {
    mods?: UXPremiumBreakdownDetails;
    camper?: UXPremiumBreakdownDetails;
    trailer?: UXPremiumBreakdownDetails;
}

export interface UXPremiumBreakdownDetails {
    amount: string;
    percent: string;
}

@Injectable()
export class QuoteEnsRetrievalService {
    private _activeEnsQuote$ = new ReplaySubject<EnsResponseWithEnsOnly | null>(1);
    private _dynamicallyUpdateActiveEnsQuote$ = new BehaviorSubject<boolean>(false);
    private _premiumBreakdown$ = new ReplaySubject<UXPremiumBreakdown | null>(1);

    constructor(
        private hashService: HashService,
        private utilityService: UtilityService,
        private overlayService: OverlayService,
        private quoteEnsFormService: QuoteEnsFormService,
        private quoteService: QuoteService,
        private documentsService: DocumentsService,
        private router: Router,
        private analyticsService: AnalyticsService,
        private quoteCacheService: QuoteCacheService,
        private assertionService: AssertionService
    ) {
        combineLatest([
            this.quoteEnsFormService.quoteEnsFlowFormValuePure$,
            this._dynamicallyUpdateActiveEnsQuote$.pipe(distinctUntilChanged()),
        ]).subscribe(([quoteEnsFlowFormValue, dynamicallyUpdateActiveEnsQuote]) => {
            if (quoteEnsFlowFormValue === null) {
                this._activeEnsQuote$.next(null);
            }

            if (!dynamicallyUpdateActiveEnsQuote || quoteEnsFlowFormValue === null) {
                return;
            }
            if (!this.quoteEnsFormService.quoteEnsFlowFormIsValid) {
                // INFO: Need this because of debounce
                return;
            }
            const quoteEnsFlowFormValueHash =
                this.hashService.hashFromQuoteEnsFlowFormValue(quoteEnsFlowFormValue);
            const activeEnsQuoteCache = this.utilityService.getStoredObject<EnsResponseWithEnsOnly>(
                `EnsQuoteCache-${quoteEnsFlowFormValueHash}`
            );
            if (activeEnsQuoteCache) {
                this._activeEnsQuote$.next(activeEnsQuoteCache);
            } else {
                this._sendQuoteRequest(quoteEnsFlowFormValue, quoteEnsFlowFormValueHash);
            }
        });
        this._activeEnsQuote$.next(null);
    }

    get activeEnsQuote$(): Observable<EnsResponseWithEnsOnly | null> {
        this._dynamicallyUpdateActiveEnsQuote$.next(true);
        return this._activeEnsQuote$.pipe(
            finalize(() => {
                this._dynamicallyUpdateActiveEnsQuote$.next(false);
            })
        );
    }

    get activeEnsQuotePremiumBreakdown$(): Observable<UXPremiumBreakdown | null> {
        return this._activeEnsQuote$.pipe(
            map((activeEnsQuote) => {
                if (!activeEnsQuote) {
                    return null;
                }
                return this._createBreakdown(activeEnsQuote.ensOnly.premiumBreakdown);
            })
        );
    }

    get activeQuoteEnsId(): UUID {
        const activeQuoteEnsId = this.utilityService.localStorage.getItem(`ActiveQuoteEnsId`);

        if (!activeQuoteEnsId) {
            throw new Error('ACTIVE_QUOTE_ENS_ID_NOT_IN_LOCAL_STORAGE');
        }

        return activeQuoteEnsId;
    }

    get activeQuoteEnsIdNoThrow(): UUID | null {
        const activeQuoteEnsId = this.utilityService.localStorage.getItem(`ActiveQuoteEnsId`);
        return activeQuoteEnsId;
    }

    set activeQuoteEnsId(activeQuoteEnsId: UUID) {
        this.utilityService.localStorage.setItem(`ActiveQuoteEnsId`, activeQuoteEnsId);
    }

    clearActiveQuote() {
        this.utilityService.localStorage.removeItem('ActiveQuoteEnsId');
        this._activeEnsQuote$.next(null);
    }

    refreshActiveEnsQuote() {
        this._activeEnsQuote$.next(null);
        this.quoteCacheService.clearQuoteEnsLocalStorageCacheForRefresh();
    }

    updateQuoteForPurchase$(params: {
        generateDocs: boolean;
        updateAddress: boolean;
    }): Observable<EnsResponseWithEnsOnly> {
        if (params.generateDocs) {
            this.documentsService.resetDocumentBlobs();
        }
        const activeQuoteEnsId = this.utilityService.localStorage.getItem(`ActiveQuoteEnsId`);
        if (!activeQuoteEnsId) {
            return throwError(() => new Error('ACTIVE_QUOTE_ENS_ID_UNDEFINED'));
        }
        this.overlayService.show('Calculating');
        return this.quoteEnsFormService.quoteEnsFlowFormValuePure$.pipe(
            take(1),
            switchMap((quoteEnsFlowFormValue) => {
                if (quoteEnsFlowFormValue === null) {
                    return throwError(() => new Error('QUOTE_ENS_FLOW_FORM_VALUE_NULL'));
                }
                if (quoteEnsFlowFormValue.effectiveDate === null) {
                    quoteEnsFlowFormValue.effectiveDate = new Date().toISOString();
                }
                return this.quoteService
                    .ensQuoteUpdate$({
                        rocID: 'latest',
                        quoteEnsFlowFormValue: quoteEnsFlowFormValue,
                        quoteID: activeQuoteEnsId,
                        generateDocs: params.generateDocs,
                        updateAddress: params.updateAddress,
                    })
                    .pipe(
                        tap((ensResponse) =>
                            this._processResponse(
                                ensResponse,
                                this.hashService.hashFromQuoteEnsFlowFormValue(
                                    quoteEnsFlowFormValue
                                )
                            )
                        ),
                        catchError((error: Error) => {
                            this._activeEnsQuote$.next(null);
                            this.overlayService.hide();
                            return throwError(() => error);
                        }),
                        finalize(() => {
                            this.overlayService.hide();
                        }),
                        filter(inputIsEnsResponseWithEnsOnly)
                    );
            })
        );
    }

    private _sendQuoteRequest(
        quoteEnsFlowFormValue: QuoteEnsFlowFormValue,
        quoteEnsFlowFormValueHash: string
    ): void {
        const activeQuoteEnsId = this.utilityService.localStorage.getItem(`ActiveQuoteEnsId`);

        if (quoteEnsFlowFormValue.effectiveDate === null) {
            quoteEnsFlowFormValue.effectiveDate = new Date().toISOString();
        }

        this.overlayService.show('Calculating');
        if (activeQuoteEnsId) {
            this.quoteService
                .ensQuoteUpdate$({
                    rocID: 'latest',
                    quoteEnsFlowFormValue: quoteEnsFlowFormValue,
                    quoteID: activeQuoteEnsId,
                })
                .pipe(
                    tap((ensResponse) => {
                        this.utilityService.localStorage.setItem(
                            'ActiveQuoteEnsUserId',
                            ensResponse.userId
                        );
                    }),
                    catchError((error: Error) => {
                        this._activeEnsQuote$.next(null);
                        this.overlayService.hide();
                        return throwError(() => error);
                    })
                )
                .subscribe((result) => {
                    this._processResponse(result, quoteEnsFlowFormValueHash);
                    this.overlayService.hide();
                });
        } else {
            this.quoteService
                .ensQuote$({
                    rocID: 'latest',
                    quoteEnsFlowFormValue: quoteEnsFlowFormValue,
                })
                .pipe(
                    tap((ensResponse) => {
                        this.utilityService.localStorage.setItem(
                            'ActiveQuoteEnsUserId',
                            ensResponse.userId
                        );
                    }),
                    catchError((error: Error) => {
                        this._activeEnsQuote$.next(null);
                        this.overlayService.hide();
                        return throwError(() => error);
                    })
                )
                .subscribe((result) => {
                    this._processResponse(result, quoteEnsFlowFormValueHash);
                    this.overlayService.hide();
                });
        }
    }

    private _processResponse(result: EnsResponse, quoteEnsFlowFormValueHash: string) {
        if (result.exclusion) {
            this._activeEnsQuote$.next(null);
            this.analyticsService.sendEventCustom({
                action: 'quote_ens_retrieval_exclusion',
                label: result.exclusion,
            });
            return this.router.navigate([`/quote/sorry`], {
                queryParams: { reason: quoteEnsExclusionConfigs[result.exclusion].queryParam },
                replaceUrl: true,
            });
        }

        if (result.ensOnly) {
            this.analyticsService.sendEventCustom({
                action: 'quote_ens_retrieval_success',
                value: Big(result.ensOnly.totals.total).toNumber(),
            });
            this._activeEnsQuote$.next(result as EnsResponseWithEnsOnly);
        }

        this.utilityService.storeObject(`EnsQuoteCache-${quoteEnsFlowFormValueHash}`, result);
        this.activeQuoteEnsId = result.quoteEnsId;
    }

    getQuoteById$(id: UUID) {
        return this.quoteService.ensQuoteById$(id).pipe(
            switchMap((result) => {
                this.activeQuoteEnsId = id;
                this.quoteEnsFormService.setQuoteEnsFlowForm(result.quoteEnsFlowFormValue);
                return of(result);
            })
        );
    }

    // eslint-disable-next-line complexity
    private _createBreakdown(premiumBreakdown: PremiumBreakdown): UXPremiumBreakdown {
        let mods = new Big(0);
        let camper = new Big(0);
        let trailer = new Big(0);
        const gear = new Big(premiumBreakdown.gearEnsActualCashValue?.total || '0');
        const offRoad = new Big(premiumBreakdown.offRoadRecovery?.total || '0');
        const emergency = new Big(premiumBreakdown.emergencyExpense?.total || '0');
        const towing = new Big(premiumBreakdown.towRoadside?.total || '0');

        const rigs: UXPremiumBreakdownRig[] = [];
        // eslint-disable-next-line complexity
        premiumBreakdown.rigs.forEach((rig, rigIndex) => {
            const currentMods = new Big(rig.modsComprehensive?.total || 0).plus(
                rig.modsCollision?.total || 0
            );
            const currentCamper = new Big(rig.camperComprehensive?.total || 0).plus(
                rig.camperCollision?.total || 0
            );
            const currentTrailer = new Big(rig.trailerComprehensive?.total || 0).plus(
                rig.trailerCollision?.total || 0
            );

            mods = mods.plus(currentMods);
            camper = camper.plus(currentCamper);
            trailer = trailer.plus(currentTrailer);

            const totalRigPremium = currentMods.plus(currentCamper).plus(currentTrailer);
            rigs.push({
                mods: currentMods.eq(0)
                    ? undefined
                    : {
                          amount: currentMods.toFixed(0, Big.roundHalfEven),
                          percent: currentMods.div(totalRigPremium).times(100).toFixed(2) + '%',
                      },
                camper: currentCamper.eq(0)
                    ? undefined
                    : {
                          amount: currentCamper.toFixed(0, Big.roundHalfEven),
                          percent: currentCamper.div(totalRigPremium).times(100).toFixed(2) + '%',
                      },
                trailer: currentTrailer.eq(0)
                    ? undefined
                    : {
                          amount: currentTrailer.toFixed(0, Big.roundHalfEven),
                          percent: currentTrailer.div(totalRigPremium).times(100).toFixed(2) + '%',
                      },
            });
        });

        const totalPremium = mods
            .plus(gear)
            .plus(camper)
            .plus(trailer)
            .plus(offRoad)
            .plus(emergency)
            .plus(towing);

        return {
            mods: mods.eq(0)
                ? undefined
                : {
                      amount: mods.toFixed(0, Big.roundHalfEven),
                      percent: mods.div(totalPremium).times(100).toFixed(2) + '%',
                  },
            gear: gear.eq(0)
                ? undefined
                : {
                      amount: gear.toFixed(0, Big.roundHalfEven),
                      percent: gear.div(totalPremium).times(100).toFixed(2) + '%',
                  },
            camper: camper.eq(0)
                ? undefined
                : {
                      amount: camper.toFixed(0, Big.roundHalfEven),
                      percent: camper.div(totalPremium).times(100).toFixed(2) + '%',
                  },
            trailer: trailer.eq(0)
                ? undefined
                : {
                      amount: trailer.toFixed(0, Big.roundHalfEven),
                      percent: trailer.div(totalPremium).times(100).toFixed(2) + '%',
                  },
            offRoad: offRoad.eq(0)
                ? undefined
                : {
                      amount: offRoad.toFixed(0, Big.roundHalfEven),
                      percent: offRoad.div(totalPremium).times(100).toFixed(2) + '%',
                  },
            emergency: emergency.eq(0)
                ? undefined
                : {
                      amount: emergency.toFixed(0, Big.roundHalfEven),
                      percent: emergency.div(totalPremium).times(100).toFixed(2) + '%',
                  },
            towing: towing.eq(0)
                ? undefined
                : {
                      amount: towing.toFixed(0, Big.roundHalfEven),
                      percent: towing.div(totalPremium).toFixed(2) + '%',
                  },
            rigs,
        };
    }
}
