export interface TabNavName {
    slug: string;
    display: string;
}

export interface AccountAreaTabNavs {
    [index: string]: AccountAreaTabNavName[];
}

export interface AccountAreaTabNavName {
    slug: string;
    display: string;
    activeMatch: string;
    routerLink: string;
}

export const accountAreaTabNavs: AccountAreaTabNavs = {
    insurance: [
        {
            slug: 'dashboard',
            display: 'Policies',
            activeMatch: '/account/insurance/dashboard',
            routerLink: '/account/insurance/dashboard',
        },
        {
            slug: 'billing',
            display: 'Billing',
            activeMatch: '/account/insurance/billing',
            routerLink: '/account/insurance/billing',
        },
        {
            slug: 'documents',
            display: 'Documents',
            activeMatch: '/account/insurance/documents',
            routerLink: '/account/insurance/documents',
        },
        {
            slug: 'claims',
            display: 'Claims',
            activeMatch: '/account/insurance/claims',
            routerLink: '/account/insurance/claims',
        },
    ],
};
