// Keeping only for TableService.
// Use src/modules/app-common/services/assertion.service.ts instead

// export const orNA = <T>(value?: T): T | string => {
//     if (!value) {
//         return 'N/A';
//     }
//     return value;
// };

// export const orNULL = <T>(value?: T): T | null => {
//     if (!value) {
//         return null;
//     }
//     return value;
// };

// export const orUndefined = <T>(value?: T): T | undefined => {
//     if (!value) {
//         return undefined;
//     }
//     return value;
// };

export const orEMPTY = (value?: string | null): string => {
    if (!value) {
        return '';
    }
    return value;
};
