import { IncidentService } from './incident.service';
import { LegalDocsService } from './legal-docs.service';
import { PartnerService } from './partner.service';
import { PaymentPlanService } from './payment-plan.service';
import { QuoteService } from './quote.service';
import { QuoteCacheService } from './quote-cache.service';
import { QuoteEnsCalculateService } from './quote-ens-calculate.service';
import { QuoteEnsFormService } from './quote-ens-form.service';
import { QuoteEnsRetrievalService } from './quote-ens-retrieval.service';
import { QuoteRigSetFormService } from './quote-rig-set-form.service';
import { QuoteSharedService } from './quote-shared.service';
import { RateIssueService } from './rate-issue.service';
import { RoundingService } from './rounding.service';
import { VehicleInfoService } from './vehicle-info.service';

export const services = [
    QuoteService,
    QuoteCacheService,
    IncidentService,
    QuoteSharedService,
    QuoteEnsFormService,
    QuoteRigSetFormService,
    QuoteEnsCalculateService,
    QuoteEnsRetrievalService,
    LegalDocsService,
    PaymentPlanService,
    RoundingService,
    VehicleInfoService,
    RateIssueService,
    PartnerService,
];

export * from './incident.service';
export * from './legal-docs.service';
export * from './partner.service';
export * from './payment-plan.service';
export * from './quote.service';
export * from './quote-cache.service';
export * from './quote-ens-calculate.service';
export * from './quote-ens-form.service';
export * from './quote-ens-retrieval.service';
export * from './quote-rig-set-form.service';
export * from './quote-shared.service';
export * from './rate-issue.service';
export * from './rounding.service';
export * from './vehicle-info.service';
