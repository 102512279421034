import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import {
    QuoteRigSetFormValue,
    QuoteRigSetRigCoverageSelectFormValue,
} from '@backend-types/quote-flow-ens';
import { slideInOutAnimation } from '@common/animations';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { EstimateForQuote } from '@modules/estimate/models';
import { QuoteEnsFormStep, QuoteRigSetFormStep } from '@modules/quote/models';
import { QuoteRigSetFormService } from '@modules/quote/services';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-rig-set-form-quick',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-rig-set-form-quick.component.html',
    styleUrls: ['quote-rig-set-form-quick.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteRigSetFormQuickComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteRigSetFormQuickComponent,
        },
    ],
    animations: [slideInOutAnimation],
})
export class QuoteRigSetFormQuickComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @Input() estimateForQuote!: EstimateForQuote;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();
    @Output() rigDetailsFormValid = new EventEmitter<boolean>();
    @Output() modsDetailsFormValid = new EventEmitter<boolean>();
    @Output() camperDetailsFormValid = new EventEmitter<boolean>();
    @Output() trailerDetailsFormValid = new EventEmitter<boolean>();

    activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;
    rigIndex!: number;
    jumpBackEns?: QuoteEnsFormStep;
    jumpBackRig?: QuoteRigSetFormStep;
    jumpBackEndorsement?: UUID;

    eQuoteRigSetFormStep = QuoteRigSetFormStep;
    subscription: Subscription = new Subscription();
    invalidZip: string | null = null;
    currentQuoteRigSetFormStep: QuoteRigSetFormStep = QuoteRigSetFormStep.rigName;

    onTouched: () => unknown = () => {};
    onChange = (quoteRigSetFormValue: ModelFormValue<QuoteRigSetFormValue>[]) => {};

    constructor(
        private quoteRigSetFormService: QuoteRigSetFormService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.subscription.add(
            this.quoteRigSetFormService
                .getActiveQuoteRigSetForm$()
                .subscribe((activeQuoteRigSetForm) => {
                    this.activeQuoteRigSetForm = activeQuoteRigSetForm;
                    this.changeDetectorRef.markForCheck();
                })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (quoteRigSetFormValue: ModelFormValue<QuoteRigSetFormValue>[]) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.activeQuoteRigSetForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.activeQuoteRigSetForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe((valueChanges) => {
                    // console.log(this.activeQuoteRigSetForm);
                    try {
                        const quoteRigSetFormValuesArray =
                            this.quoteRigSetFormService.getQuoteRigSetFormValuesArray();
                        onChange(quoteRigSetFormValuesArray);
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.activeQuoteRigSetForm.disable({
                emitEvent: false,
            });
        } else {
            this.activeQuoteRigSetForm.enable({
                emitEvent: false,
            });
        }
    }

    writeValue(value: QuoteRigSetFormValue[] | null) {
        // if (value === null) {
        //     this.activeQuoteRigSetForm.reset();
        // }
        if (value) {
            this.quoteRigSetFormService.setQuoteRigSetFormValuesArray(value);
            this.changeDetectorRef.detectChanges();
            // this.activeQuoteRigSetForm.setValue(value, { emitEvent: false });
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // return this.activeQuoteRigSetForm.errors;
        // if (this.quoteRigSetFormService.dirtyInvalid()) {
        //     console.log('has dirtyInvalid');
        //     return { quoteRigSetFormHasError: true };
        // }
        return null;
    }

    /* Accessor Methods */

    get coverageSelectControl() {
        return this.activeQuoteRigSetForm.get(
            'coverageSelect'
        ) as FormControl<QuoteRigSetRigCoverageSelectFormValue>;
    }
}
