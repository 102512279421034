import { Injectable } from '@angular/core';
import { OverlayServiceOptions, OverlayTemplate } from '@common/models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OverlayService {
    isVisible$ = new BehaviorSubject<boolean>(false);
    template!: OverlayTemplate;
    showSpinner!: boolean;
    message = '';

    constructor() {}
    show(message: string = '', options?: OverlayServiceOptions) {
        this.message = message;
        this.template = options?.template || OverlayTemplate.text;
        this.showSpinner = options?.showSpinner || true;
        this.isVisible$.next(true);
    }
    hide() {
        this.isVisible$.next(false);
        this.message = '';
    }
}
