import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'sbf-floating-bubble',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './floating-bubble.component.html',
    styleUrls: ['floating-bubble.component.scss'],
})
export class FloatingBubbleComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
