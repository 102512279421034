import { Injectable } from '@angular/core';
import { PaymentPlanName } from '@backend-types/payment-plan';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { CalculateQuoteEnsTotalResponse } from '@modules/quote/models';
import Big from 'big.js';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { PaymentPlanService } from './payment-plan.service';
import { QuoteEnsFormService } from './quote-ens-form.service';
import { QuoteEnsRetrievalService } from './quote-ens-retrieval.service';

@Injectable()
export class QuoteEnsCalculateService {
    private _quoteEnsTotal$ = new BehaviorSubject<CalculateQuoteEnsTotalResponse | null>(null);

    constructor(
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private quoteEnsFormService: QuoteEnsFormService,
        private paymentPlanService: PaymentPlanService
    ) {
        combineLatest([
            this.quoteEnsRetrievalService.activeEnsQuote$,
            this.quoteEnsFormService.quoteEnsFlowFormValuePure$,
        ]).subscribe(([activeEnsQuote, quoteEnsFlowFormValuePure]) => {
            if (activeEnsQuote && quoteEnsFlowFormValuePure) {
                this._quoteEnsTotal$.next(
                    this.calculateQuoteEnsTotal(activeEnsQuote, quoteEnsFlowFormValuePure)
                );
            } else {
                this._quoteEnsTotal$.next(null);
            }
        });
    }

    get quoteEnsTotal$(): Observable<CalculateQuoteEnsTotalResponse | null> {
        return this._quoteEnsTotal$.asObservable();
    }

    // INFO: This is not used any more, but could be brought back if we start trying to cache in front end again
    // **************************************************************************************************
    // IMPORTANT: THIS FUNCTION NEEDS TO STAY IN SYNC WITH THE BACK END FUNCTION: calculateSelectedTotal
    //            in src/lib/roc/calculate/calculate-selected-total.ts
    // **************************************************************************************************

    calculateQuoteEnsTotal(
        ensResponse: EnsResponseWithEnsOnly,
        quoteEnsFlowFormValue: QuoteEnsFlowFormValue
    ): CalculateQuoteEnsTotalResponse {
        let quoteTotalBig = Big(ensResponse.ensOnly.totals.total);
        let quoteTotal: string;
        let quoteTotalDollars: string;
        let quoteTotalCents: string;

        let quotePaymentsInitialBig: Big;
        let quotePaymentsInitial: string;
        let quotePaymentsInitialDollars: string;
        let quotePaymentsInitialCents: string;
        let quotePaymentsInstallmentBig: Big;
        let quotePaymentsInstallment: string;
        let quotePaymentsInstallmentDollars: string;
        let quotePaymentsInstallmentCents: string;

        quoteTotal = quoteTotalBig.toFixed(2).toString();
        const splitQuoteToal = quoteTotal.split('.');
        quoteTotalDollars = splitQuoteToal[0];
        quoteTotalCents = splitQuoteToal[1];

        const twentySixteenPaymentPlanSource = this.paymentPlanService.getPaymentPlanSource(
            ensResponse.ensOnly,
            PaymentPlanName.twentySixteen,
            false
        );
        const initialPayment = twentySixteenPaymentPlanSource.installments[0].debit;
        if (!initialPayment) {
            throw new Error('INITIAL_PAYMENT_NOT_FOUND');
        }
        quotePaymentsInitialBig = Big(initialPayment);
        quotePaymentsInitial = quotePaymentsInitialBig.toFixed(2).toString();
        const splitQuotePaymentsInitial = quotePaymentsInitial.split('.');
        quotePaymentsInitialDollars = splitQuotePaymentsInitial[0];
        quotePaymentsInitialCents = splitQuotePaymentsInitial[1];

        const installmentPayment = twentySixteenPaymentPlanSource.installments[1].debit;
        if (!installmentPayment) {
            throw new Error('INSTALLMENT_PAYMENT_NOT_FOUND');
        }
        quotePaymentsInstallmentBig = Big(installmentPayment);
        quotePaymentsInstallment = quotePaymentsInstallmentBig.toFixed(2).toString();
        const splitQuotePaymentsInstallment = quotePaymentsInstallment.split('.');
        quotePaymentsInstallmentDollars = splitQuotePaymentsInstallment[0];
        quotePaymentsInstallmentCents = splitQuotePaymentsInstallment[1];

        return {
            quoteTotalBig,
            quoteTotal,
            quoteTotalDollars,
            quoteTotalCents,
            quotePaymentsInitialBig,
            quotePaymentsInitial,
            quotePaymentsInitialDollars,
            quotePaymentsInitialCents,
            quotePaymentsInstallment,
            quotePaymentsInstallmentBig,
            quotePaymentsInstallmentDollars,
            quotePaymentsInstallmentCents,
        };
    }
}
