import { formatCurrency } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import Big from 'big.js';

@Pipe({
    name: 'dbNumberToCurrency',
})
export class DBNumberToCurrency implements PipeTransform {
    constructor() {}
    transform(someNumber: number, toFixed = 2) {
        return Big(someNumber).div(100).toFixed(toFixed).toString();
    }
}

@Pipe({
    name: 'coverageBreakdown',
})
export class CoverageBreakdownPipe implements PipeTransform {
    constructor() {}
    transform(value?: string | null | undefined): string {
        if (typeof value === 'undefined') {
            return '---';
        }
        if (value === null) {
            return '---';
        }
        return formatCurrency(parseInt(value, 10), 'en-US', '$', 'USD', '1.0');
        // return Big(value).toFixed(0);
    }
}
