import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { AnalyticsService, AssertionService, HotKeysService } from '@common/services';
import { distinct, Subscription, tap } from 'rxjs';

export interface RigNameFormValue {
    rigName: string;
}

@Component({
    selector: 'sbf-quote-rig-set-form-rig-name',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-rig-set-form-rig-name.component.html',
    styleUrls: ['quote-rig-set-form-rig-name.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteRigSetFormRigNameComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteRigSetFormRigNameComponent,
        },
    ],
})
export class QuoteRigSetFormRigNameComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @Input() formOnly = false;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    subscription: Subscription = new Subscription();

    rigNameForm: ModelFormGroup<RigNameFormValue> = this.fb.group({
        rigName: new FormControl<string | null>(null, [Validators.required]),
    });

    onTouched: () => unknown = () => {};
    onChange = (rigName: string | null) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Rig Name');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_rig_name',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.rigNameForm.setValue({
                    rigName: 'Rigatoni',
                });
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(onChange: (rigName: string | null) => unknown) {
        this.onChange = onChange;

        this.subscription.add(
            this.rigNameForm.valueChanges
                .pipe(
                    distinct((rigNameFormValue) => rigNameFormValue.rigName),
                    tap(() => {
                        if (this.rigNameForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const { rigName } = this._rigNameFormValue();
                        onChange(rigName);
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.rigNameForm.disable();
        } else {
            this.rigNameForm.enable();
        }
    }

    writeValue(value: string | null) {
        if (value === null) {
            this.rigNameForm.reset();
        }
        if (value) {
            this.rigNameForm.setValue(
                {
                    rigName: value,
                },
                { emitEvent: false }
            );
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.rigNameForm?.invalid) {
            return { rigNameFormInvalid: true };
        }

        return null;
    }

    private _rigNameFormValue(): ModelFormValue<RigNameFormValue> {
        const { rigName } = this.rigNameForm.value;

        this.assertionService.isDefinedOrThrow(rigName);

        return {
            rigName,
        };
    }

    /* Accessor Methods */

    get rigNameControl() {
        return this.rigNameForm.get('rigName') as FormControl;
    }

    get rigNameControlValid() {
        return this.rigNameControl.value && !this.rigNameControl.hasError('required');
    }

    get rigNameControlInvalid() {
        return this.rigNameControl.touched && this.rigNameControl.hasError('required');
    }
}
