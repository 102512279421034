import { KeyValue } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { InputSelectFormValue, ModelFormGroup, OptionsRecordsArray } from '@common/models';
import { AssertionService } from '@common/services';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-input-select',
    // changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './input-select.component.html',
    styleUrls: ['input-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: InputSelectComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: InputSelectComponent,
        },
    ],
})
export class InputSelectComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator, AfterViewInit
{
    @ViewChild('inputSelect') inputSelect!: ElementRef<HTMLSelectElement>;
    @Input() idAppend!: string;
    @Input() defaultText!: string;
    @Input() optionsRecordsArray!: OptionsRecordsArray;
    @Input() displayTextAppend!: string;
    @Input() size: 'small' | 'large' | 'tredder' = 'tredder';
    @Input() styleClass: 'tredder' | 'tredder-shaded' = 'tredder';

    subscription: Subscription = new Subscription();
    unlistenFocus!: () => void;
    unlistenBlur!: () => void;

    inputSelectForm: ModelFormGroup<InputSelectFormValue> = this.fb.group({
        selectedValue: new FormControl<string | null>(null, Validators.required),
    });

    onTouched: () => unknown = () => {};
    onChange = (selectedInput: string) => {};
    constructor(
        private fb: FormBuilder,
        private assertionService: AssertionService,
        private renderer2: Renderer2,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        if (!this.idAppend) {
            throw new Error('NEED_ID_APPEND_FOR_INPUT_SELECT');
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        // this.unlistenFocus();
        // this.unlistenBlur();
    }

    ngAfterViewInit() {
        // this.unlistenFocus = this.renderer2.listen(this.inputSelect.nativeElement, 'click', () => {
        //     console.log('click');
        //     this.showParens();
        // });
        // this.unlistenBlur = this.renderer2.listen(this.inputSelect.nativeElement, 'blur', () => {
        //     console.log('blur');
        //     this.hideParens();
        // });
    }

    registerOnChange(onChange: (selectedInput: string) => unknown) {
        this.onChange = onChange;

        this.subscription.add(
            this.inputSelectForm.valueChanges
                .pipe(
                    tap(() => {
                        // console.log('change');
                        // this.hideParens();

                        if (this.inputSelectForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const inputSelectFormValue = this._inputSelectFormValue();
                        onChange(inputSelectFormValue.selectedValue);
                    } catch (error) {}
                })
        );
    }

    onBlur() {
        this.onTouched();
        // this.hideParens();
    }

    // hideParens() {
    //     for (const optionNode of Array.from(
    //         this.inputSelect.nativeElement.children as HTMLCollection
    //     )) {
    //         console.log(optionNode.innerHTML);

    //         const parensText = optionNode.innerHTML.match(/\(.*\)/);
    //         console.log(parensText);

    //         if (parensText) {
    //             (optionNode as HTMLOptionElement).dataset.parensText = parensText[0];
    //             optionNode.innerHTML = optionNode.innerHTML.replace(/\(.*\)/, '');
    //         }
    //     }
    // }

    // showParens() {
    //     for (const optionNode of Array.from(
    //         this.inputSelect.nativeElement.children as HTMLCollection
    //     )) {
    //         console.log((optionNode as HTMLOptionElement).dataset.parensText);

    //         if ((optionNode as HTMLOptionElement).dataset.parensText) {
    //             optionNode.innerHTML =
    //                 optionNode.innerHTML + (optionNode as HTMLOptionElement).dataset.parensText;
    //         }
    //     }
    // }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.inputSelectForm.disable({ emitEvent: false });
        } else {
            this.inputSelectForm.enable({ emitEvent: false });
        }
    }

    writeValue(selectedValue: string | null) {
        if (selectedValue === null) {
            this.inputSelectForm.reset();
        }
        this.selectedValueControl.setValue(selectedValue, { emitEvent: false });
        this.changeDetectorRef.detectChanges();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.inputSelectForm?.invalid) {
            return { inputSelectFormInvalid: true };
        }

        return null;
    }

    private _inputSelectFormValue(): InputSelectFormValue {
        const { selectedValue } = this.inputSelectForm.value;

        this.assertionService.isDefinedOrThrow(selectedValue);

        return {
            selectedValue,
        };
    }

    sortNull(a: KeyValue<string, unknown>, b: KeyValue<string, unknown>) {
        return 0;
    }

    clicked($event: unknown) {
        console.log($event);
    }
    /* Accessor Methods */

    get selectedValueControl() {
        return this.inputSelectForm.get('selectedValue') as FormControl;
    }

    get selectedValueControlValid() {
        return this.selectedValueControl.value && !this.selectedValueControl.hasError('required');
    }

    get selectedValueControlInvalid() {
        return this.selectedValueControl.touched && this.selectedValueControl.hasError('required');
    }
}
