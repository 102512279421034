import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { rigDetailOtherOption } from '@modules/quote/models';
import { VehicleInfoService } from '@modules/quote/services';
import { map, Observable, of, take } from 'rxjs';

export function CheckMakeAvailabilityValidator(
    vehicleInfoService: VehicleInfoService
): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        const { value } = control;

        if (value === rigDetailOtherOption) {
            const modelControl = control.parent?.get('model');
            const trimControl = control.parent?.get('trim');
            modelControl?.setValue(rigDetailOtherOption);
            trimControl?.setValue(rigDetailOtherOption);
            return of(null);
        }

        return !value
            ? of(null)
            : vehicleInfoService.possibleMakes$.pipe(
                  take(1),
                  map((possibleMakes) => {
                      if (
                          !possibleMakes ||
                          possibleMakes.length === 0 ||
                          possibleMakes.find((make) => make.name === value)
                      ) {
                          return null;
                      }

                      return { possibleMakes: true };
                  })
              );
    };
}

export function CheckModelAvailabilityValidator(
    vehicleInfoService: VehicleInfoService
): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        const { value } = control;
        const trimControl = control.parent?.get('trim');

        if (value === rigDetailOtherOption) {
            trimControl?.setValue(rigDetailOtherOption);
            return of(null);
        }

        return !value
            ? of(null)
            : vehicleInfoService.possibleModels$.pipe(
                  take(1),
                  map((possibleModels) => {
                      if (
                          !possibleModels ||
                          possibleModels.length === 0 ||
                          possibleModels.find((model) => model.name === value)
                      ) {
                          return null;
                      }

                      return { possibleModels: true };
                  })
              );
    };
}

export function CheckTrimsAvailabilityValidator(
    vehicleInfoService: VehicleInfoService
): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        const { value } = control;

        return !value || value === rigDetailOtherOption
            ? of(null)
            : vehicleInfoService.possibleTrims$.pipe(
                  take(1),
                  map((possibleTrims) => {
                      if (
                          !possibleTrims ||
                          possibleTrims.length === 0 ||
                          possibleTrims.find((trim) => trim === value)
                      ) {
                          return null;
                      }

                      return { possibleTrims: true };
                  })
              );
    };
}
