import {
    HttpClient,
    HttpEvent,
    HttpEventType,
    HttpProgressEvent,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DownloadProgress, OverlayTemplate } from '@common/models';
import { saveAs } from 'file-saver-es';
import { filter, map, Observable, ReplaySubject, scan } from 'rxjs';

import { OverlayService } from './overlay.service';

export interface DownloadBlobParameters<T> {
    url: string;
    downloadFilename: string;
    postBody?: T;
    save?: boolean;
    returnBlob?: boolean;
}

const defaultParameters: Partial<DownloadBlobParameters<unknown>> = {
    save: true,
    returnBlob: false,
};

@Injectable()
export class DownloadService {
    _downloadProgress$ = new ReplaySubject<DownloadProgress>(1);

    constructor(
        private http: HttpClient,
        private overlayService: OverlayService
    ) {}

    downloadBlob$<T>(passedParameters: DownloadBlobParameters<T>): Observable<boolean | Blob> {
        const parameters = {
            ...defaultParameters,
            ...passedParameters,
        };
        this.overlayService.show('', { template: OverlayTemplate.progress });
        return this.http
            .request(parameters.postBody ? 'POST' : 'GET', parameters.url, {
                observe: 'events',
                reportProgress: true,
                responseType: 'blob',
                body: parameters.postBody,
            })
            .pipe(
                scan(
                    (previous: DownloadProgress, event: HttpEvent<Blob>): DownloadProgress => {
                        if (this.isHttpProgressEvent(event)) {
                            return {
                                progress: event.total
                                    ? Math.round((100 * event.loaded) / event.total)
                                    : previous.progress,
                                state: 'IN_PROGRESS',
                                content: null,
                            };
                        }
                        if (this.isHttpResponse(event)) {
                            return {
                                progress: 100,
                                state: 'DONE',
                                content: event.body,
                            };
                        }
                        return previous;
                    },
                    { state: 'PENDING', progress: 0, content: null }
                ),
                filter((downloadProgress) => {
                    this._downloadProgress$.next(downloadProgress);
                    if (downloadProgress.state === 'DONE') {
                        this._downloadProgress$.next({
                            state: 'PENDING',
                            progress: 0,
                            content: null,
                        });
                        return true;
                    }
                    return false;
                }),
                map((downloadProgress) => {
                    // INFO: This shold only be called once when downloadProgress.state === 'DONE'
                    if (!downloadProgress.content) {
                        return false;
                    }
                    if (parameters.save) {
                        saveAs(downloadProgress.content, parameters.downloadFilename);
                    }
                    if (parameters.returnBlob) {
                        return downloadProgress.content;
                    }
                    return true;
                })
            );
    }

    get downloadProgress$(): Observable<DownloadProgress> {
        return this._downloadProgress$.asObservable();
    }

    isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
        return event.type === HttpEventType.Response;
    }

    isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
        return event.type === HttpEventType.DownloadProgress;
    }
}
