<div class="container-xxl" *ngIf="!formOnly"></div>
<ng-container *ngIf="formOnly"><ng-template [ngTemplateOutlet]="existingCoverageManualFormTemplate"></ng-template></ng-container
><ng-template #existingCoverageManualFormTemplate
    ><form data-cy="existingCoverageManualForm" [formGroup]="existingCoverageManualForm">
        <div class="row">
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <sbf-input-select data-cy="carrierSelect" idAppend="carrier" formControlName="carrier" defaultText="Carrier" [optionsRecordsArray]="[currentInsuranceText]" [class.is-invalid]="carrierControlInvalid"></sbf-input-select>
                    <div class="invalid-feedback" *ngIf='carrierControl.hasError("required")'>Carrier required.</div>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="carrierPolicyIdInput" data-cy="carrierPolicyIdInput" autocomplete="off" type="text" aria-describedby="carrierPolicyIdHelp" formControlName="carrierPolicyId" [class.is-invalid]="carrierPolicyIdControlInvalid" placeholder="carrierPolicyId" />
                    <div class="invalid-feedback" *ngIf='carrierPolicyIdControl.hasError("required")'>Carrier Policy ID required.</div>
                    <label class="text-gray-600 small" for="carrierPolicyIdInput">Carrier Policy ID</label>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control valid-override" id="comprehensiveLimitInput" data-cy="comprehensiveLimitInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="comprehensiveLimitHelp" formControlName="comprehensiveLimit" [class.is-invalid]="comprehensiveLimitControlInvalid" [class.is-valid]="comprehensiveLimitControlValid" placeholder="comprehensiveLimit" prefix=" $" mask="separator.0" thousandSeparator="," />
                    <div class="invalid-feedback" *ngIf='comprehensiveLimitControl.hasError("required")'>Comp Limit required.</div>
                    <label class="text-gray-600 small" for="comprehensiveLimitInput">Comp Limit</label>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control valid-override" id="comprehensiveDeductibleInput" data-cy="comprehensiveDeductibleInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="comprehensiveDeductibleHelp" formControlName="comprehensiveDeductible" [class.is-invalid]="comprehensiveDeductibleControlInvalid" [class.is-valid]="comprehensiveDeductibleControlValid" placeholder="comprehensiveDeductible" prefix=" $" mask="separator.0" thousandSeparator="," />
                    <div class="invalid-feedback" *ngIf='comprehensiveDeductibleControl.hasError("required")'>Comp Deductible required.</div>
                    <div class="invalid-feedback" data-cy="invalidFeedbackValueMin" *ngIf='comprehensiveDeductibleControl.hasError("min")'>Value must be $500 or greater.</div>
                    <label class="text-gray-600 small" for="comprehensiveDeductibleInput">Comp Deductible</label>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control valid-override" id="collisionLimitInput" data-cy="collisionLimitInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="collisionLimitHelp" formControlName="collisionLimit" [class.is-invalid]="collisionLimitControlInvalid" [class.is-valid]="collisionLimitControlValid" placeholder="collisionLimit" prefix=" $" mask="separator.0" thousandSeparator="," />
                    <div class="invalid-feedback" *ngIf='collisionLimitControl.hasError("required")'>Coll Limit required.</div>
                    <label class="text-gray-600 small" for="collisionLimitInput">Coll Limit</label>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control valid-override" id="collisionDeductibleInput" data-cy="collisionDeductibleInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="collisionDeductibleHelp" formControlName="collisionDeductible" [class.is-invalid]="collisionDeductibleControlInvalid" [class.is-valid]="collisionDeductibleControlValid" placeholder="collisionDeductible" prefix=" $" mask="separator.0" thousandSeparator="," />
                    <div class="invalid-feedback" *ngIf='collisionDeductibleControl.hasError("required")'>Coll Deductible required.</div>
                    <div class="invalid-feedback" data-cy="invalidFeedbackValueMin" *ngIf='collisionDeductibleControl.hasError("min")'>Value must be $500 or greater.</div>
                    <label class="text-gray-600 small" for="collisionDeductibleInput">Coll Deductible</label>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control valid-override" id="bodilyInjuryLimitInput" data-cy="bodilyInjuryLimitInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="bodilyInjuryLimitHelp" formControlName="bodilyInjuryLimit" [class.is-invalid]="bodilyInjuryLimitControlInvalid" [class.is-valid]="bodilyInjuryLimitControlValid" placeholder="bodilyInjuryLimit" prefix=" $" mask="separator.0" thousandSeparator="," />
                    <div class="invalid-feedback" *ngIf='bodilyInjuryLimitControl.hasError("required")'>BI Limit required.</div>
                    <label class="text-gray-600 small" for="bodilyInjuryLimitInput">BI Limit</label>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control valid-override" id="bodilyInjuryDeductibleInput" data-cy="bodilyInjuryDeductibleInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="bodilyInjuryDeductibleHelp" formControlName="bodilyInjuryDeductible" [class.is-invalid]="bodilyInjuryDeductibleControlInvalid" [class.is-valid]="bodilyInjuryDeductibleControlValid" placeholder="bodilyInjuryDeductible" prefix=" $" mask="separator.0" thousandSeparator="," />
                    <div class="invalid-feedback" *ngIf='bodilyInjuryDeductibleControl.hasError("required")'>BI Deductible required.</div>
                    <div class="invalid-feedback" data-cy="invalidFeedbackValueMin" *ngIf='bodilyInjuryDeductibleControl.hasError("min")'>Value must be $500 or greater.</div>
                    <label class="text-gray-600 small" for="bodilyInjuryDeductibleInput">BI Deductible</label>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="startDateInput" data-cy="startDateInput" autocomplete="off" type="date" [min]="minStartDateString" [max]="todayString" aria-describedby="startDateHelp" formControlName="startDate" [class.is-invalid]="startDateControlInvalid" placeholder="startDate" />
                    <div class="invalid-feedback" *ngIf='startDateControl.hasError("required")'>Start Date required.</div>
                    <div class="invalid-feedback" *ngIf='startDateControl.hasError("notWithinInterval")'>Start Date must be in the last 2 years</div>
                    <label class="text-gray-600 small" for="startDateInput">Start Date</label>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="endDateInput" data-cy="endDateInput" autocomplete="off" type="date" [min]="minStartDateString" [max]="todayString" aria-describedby="endDateHelp" formControlName="endDate" [class.is-invalid]="endDateControlInvalid" placeholder="endDate" />
                    <div class="invalid-feedback" *ngIf='endDateControl.hasError("required")'>End Date required.</div>
                    <div class="invalid-feedback" *ngIf='endDateControl.hasError("notWithinInterval")'>End Date must be in the next 2 years</div>
                    <label class="text-gray-600 small" for="endDateInput">End Date</label>
                </div>
            </div>
        </div>
    </form></ng-template
>
