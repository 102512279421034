/* eslint-disable simple-import-sort/imports */
import { NgModule, ModuleWithProviders } from '@angular/core';

/* Services */
import * as navigationServices from './services';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
})
export class NavigationServicesModule {
    static forRoot(): ModuleWithProviders<NavigationServicesModule> {
        return {
            ngModule: NavigationServicesModule,
            providers: [...navigationServices.services],
        };
    }
}
