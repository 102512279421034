import { DynamicRouteData } from '@modules/navigation/models';

export const estimate: DynamicRouteData = {
    isPublic: true,
    title: 'Estimate',
};

export const estimateConfigs = {
    estimate,
};
