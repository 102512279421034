import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Handle401Actions } from '@common/models';
import { AuthUtilsService, OverlayService } from '@common/services';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authUtilsService: AuthUtilsService,
        private overlayService: OverlayService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            // eslint-disable-next-line complexity
            catchError((error: HttpErrorResponse) => {
                this.overlayService.hide();
                if (error.status === 401) {
                    switch (_handle401(error)) {
                        case Handle401Actions.throwError:
                            return throwError(() => error.error);
                        case Handle401Actions.removeTokens:
                            this.authUtilsService.removeTokens();
                    }
                    this.router.navigate(['/error/401']);
                }
                if (_isWhiteListedError(request.method, request.url)) {
                    return throwError(() => error);
                }
                if (error.status === 400) {
                    this.router.navigate(['/error/400']);
                }
                if (error.status === 403) {
                    this.router.navigate(['/error/403']);
                }
                if (error.status === 404) {
                    this.router.navigate(['/error/404']);
                }
                if (error.status === 405) {
                    this.router.navigate(['/error/405']);
                }
                if (error.status === 500) {
                    this.router.navigate(['/error/500']);
                }
                if (error.status === 503) {
                    this.router.navigate(['/error/503']);
                }
                if (error.status === 504) {
                    this.router.navigate(['/error/504']);
                }
                console.error(error);
                return throwError(() => error);
            })
        );
    }
}

function _handle401(error: HttpErrorResponse): Handle401Actions {
    if (
        ['EMAIL_NOT_FOUND', 'INVALID_PASSWORD', 'PASSWORD_CHANGE_REQUIRED', 'ACCOUNT_LOCKED'].find(
            (code) => code === error.error.message
        )
    ) {
        return Handle401Actions.throwError;
    }
    if (error.error.message === 'NO_USER') {
        return Handle401Actions.removeTokens;
    }
    return Handle401Actions.noAction;
}

function _isWhiteListedError(method: string, url: string): boolean {
    const whiteList: { [index: string]: string[] } = {
        GET: ['assets/*', 'api/latest/auth/confirm-email/*', 'api/latest/account/quote'],
        POST: [
            'api/latest/payment/*',
            'api/latest/auth/*',
            'api/latest/account/post',
            'api/latest/zip/lookup',
            'api/latest/site/early-access',
            '/api/latest/admin/invoice/find',
            '/api/latest/admin/receipt/find',
            '/api/latest/admin/policy-ens/find',
            '/api/latest/admin/quote-ens/find',
            '/api/latest/agency/invoice/find',
            '/api/latest/agency/receipt/find',
            '/api/latest/agency/policy-ens/find',
            '/api/latest/agency/quote-ens/find',
        ],
        PUT: ['api/latest/account/post/*'],
    };
    const paths: string[] = whiteList[method];
    if (paths) {
        for (const path of paths) {
            if (url.match(path)) {
                return true;
            }
        }
    }

    return false;
}
