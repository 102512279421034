import { Injectable } from '@angular/core';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';

import { AssertionService } from './assertion.service';

@Injectable()
export class HashService {
    constructor(private assertionService: AssertionService) {}

    hash(stringToHash: string): string {
        var hash = 0,
            i,
            chr;
        if (stringToHash.length === 0) return hash.toString();
        for (i = 0; i < stringToHash.length; i++) {
            chr = stringToHash.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash.toString(16);
    }

    hashFromQuoteEnsFlowFormValue(quoteEnsFlowFormValue: QuoteEnsFlowFormValue): string {
        // Creating string of values that need to go to the backend to update quote
        let stringToHash =
            `birthday:${this.assertionService.orEMPTY(
                quoteEnsFlowFormValue.ensPersonalInfo?.birthday
            )},` +
            `maritalStatus:${this.assertionService.orEMPTY(
                quoteEnsFlowFormValue.ensPersonalInfo?.maritalStatus
            )},` +
            `campsiteLiabilityLimit:${this.assertionService.orEMPTY(
                quoteEnsFlowFormValue.specialCoverages?.campsiteLiability.campsiteLiabilityLimit
            )},` +
            // `offRoadRecoveryLimit:${this.assertionService.orEMPTY(
            //     quoteEnsFlowFormValue.specialCoverages?.offRoadRecovery.offRoadRecoveryLimit
            // )},` +
            `emergencyExpenseLimit:${this.assertionService.orEMPTY(
                quoteEnsFlowFormValue.specialCoverages?.emergencyExpense.emergencyExpenseLimit
            )},` +
            // `scheduledMedicalBenefitLimit:${this.assertionService.orEMPTY(quoteEnsFlowFormValue.specialCoverages?.scheduledMedicalBenefit.scheduledMedicalBenefitLimit)},` +
            `towRoadsideLimit:${this.assertionService.orEMPTY(
                quoteEnsFlowFormValue.specialCoverages?.towRoadside.towRoadsideLimit
            )},` +
            `campsiteLiabilityEnabled:${this.assertionService.orEMPTY(
                quoteEnsFlowFormValue.specialCoverages?.campsiteLiability.selected.toString()
            )},` +
            `offRoadRecoveryEnabled:${this.assertionService.orEMPTY(
                quoteEnsFlowFormValue.specialCoverages?.offRoadRecovery.selected.toString()
            )},` +
            `emergencyExpenseEnabled:${this.assertionService.orEMPTY(
                quoteEnsFlowFormValue.specialCoverages?.emergencyExpense.selected.toString()
            )},` +
            `scheduledMedicalBenefitEnabled:${this.assertionService.orEMPTY(
                quoteEnsFlowFormValue.specialCoverages?.scheduledMedicalBenefit.selected.toString()
            )},` +
            `towRoadsideEnabled:${this.assertionService.orEMPTY(
                quoteEnsFlowFormValue.specialCoverages?.towRoadside.selected.toString()
            )},` +
            `zip_${quoteEnsFlowFormValue.homeBase.zip},`;

        quoteEnsFlowFormValue.rigSets.forEach((rigSet, index) => {
            if (rigSet.coverageSelect.camperCoverage) {
                stringToHash =
                    stringToHash +
                    `camperUnitAge_${index}:${rigSet.camperDetails?.unitAge},` +
                    `camperUnitClass_${index}:${rigSet.camperDetails?.unitClass},` +
                    `camperUnitCollDeductible_${index}:${rigSet.camperDetails?.unitCollDeductible},` +
                    `camperUnitCompDeductible_${index}:${rigSet.camperDetails?.unitCompDeductible},` +
                    `camperUnitLength_${index}:${rigSet.camperDetails?.unitLength},` +
                    `camperUnitOwnedTenure_${index}:${rigSet.camperDetails?.unitOwnedTenure},` +
                    `camperUnitValue_${index}:${rigSet.camperDetails?.unitValue},`;
            }
            if (rigSet.coverageSelect.trailerCoverage) {
                stringToHash =
                    stringToHash +
                    `trailerUnitAge_${index}:${rigSet.trailerDetails?.unitAge},` +
                    `trailerUnitClass_${index}:${rigSet.trailerDetails?.unitClass},` +
                    `trailerUnitCollDeductible_${index}:${rigSet.trailerDetails?.unitCollDeductible},` +
                    `trailerUnitCompDeductible_${index}:${rigSet.trailerDetails?.unitCompDeductible},` +
                    `trailerUnitLength_${index}:${rigSet.trailerDetails?.unitLength},` +
                    `trailerUnitOwnedTenure_${index}:${rigSet.trailerDetails?.unitOwnedTenure},` +
                    `trailerUnitValue_${index}:${rigSet.trailerDetails?.unitValue},`;
            }
            if (rigSet.coverageSelect.modsCoverage) {
                stringToHash =
                    stringToHash +
                    `modsDeductible_${index}:${rigSet.modsDetails?.modsDeductible},` +
                    `modsValue_${index}:${rigSet.modsDetails?.modsValue},`;
            }
            stringToHash =
                stringToHash + `storageMethod_${index}:${rigSet.rigDetails.storageMethod},`;
        });

        if (quoteEnsFlowFormValue.gearCoverage) {
            stringToHash =
                stringToHash +
                `gearValue:${quoteEnsFlowFormValue.gearDetails?.gearValue.toString()},` +
                `gearCoverageType:${quoteEnsFlowFormValue.gearDetails?.gearCoverageType},`;
        }

        if (quoteEnsFlowFormValue.rc2) {
            stringToHash =
                stringToHash +
                `licenseStatus:${quoteEnsFlowFormValue.rc2.licenseStatus},` +
                `yearsLicensed:${quoteEnsFlowFormValue.rc2.yearsLicensed},` +
                `currentInsurance:${quoteEnsFlowFormValue.rc2.currentInsurance},` +
                `overTwoViolations:${quoteEnsFlowFormValue.rc2.overTwoViolations},` +
                `overTwelveLiftKit:${quoteEnsFlowFormValue.rc2.overTwelveLiftKit},` +
                `numberOfResidents:${quoteEnsFlowFormValue.rc2.numberOfResidents.toString()},`;
        }

        const hashedString = this.hash(stringToHash);
        // console.log(hashedString);

        return hashedString;
    }
}
