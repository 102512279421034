import type { RocTableConfigColumns } from './roc-lib-config';
import type { InsuranceCoverageKey } from './roc-lib-coverage';
import { RocVariableType } from './roc-lib-variable-types';

export const weightColumn: EnumMap<InsuranceCoverageKey, RocTableConfigColumns> = {
    bodilyInjury: {
        name: 'BI',
        keyNames: ['BI'],
        type: RocVariableType.Big,
    },
    propertyDamage: {
        name: 'PD',
        keyNames: ['PD'],
        type: RocVariableType.Big,
    },
    comprehensive: {
        name: 'COMP',
        keyNames: ['COMP'],
        type: RocVariableType.Big,
    },
    collision: {
        name: 'COLL',
        keyNames: ['COLL'],
        type: RocVariableType.Big,
    },
    gap: {
        name: 'LOAN',
        keyNames: ['LOAN'],
        type: RocVariableType.Big,
    },
    medical: {
        name: 'MED',
        keyNames: ['MED'],
        type: RocVariableType.Big,
    },
    personalInjuryProtection: {
        name: 'PIP',
        keyNames: ['PIP'],
        type: RocVariableType.Big,
    },
    unUnderinsuredMotoristBodilyInjury: {
        name: 'UM/UIMBI',
        keyNames: ['UM/UIMBI'],
        type: RocVariableType.Big,
    },
    unUnderinsuredMotoristPropertyDamage: {
        name: 'UM/UIMPD',
        keyNames: ['UM/UIMPD'],
        type: RocVariableType.Big,
    },
    rental: {
        name: 'RENT',
        keyNames: ['RENT'],
        type: RocVariableType.Big,
    },
    trailerComprehensive: {
        name: 'COMP-TRLR',
        keyNames: ['COMP-TRLR'],
        type: RocVariableType.Big,
    },
    trailerCollision: {
        name: 'COLL-TRLR',
        keyNames: ['COLL-TRLR'],
        type: RocVariableType.Big,
    },
    acpeComprehensive: {
        name: 'COMP-ACPE',
        keyNames: ['COMP-ACPE'],
        type: RocVariableType.Big,
    },
    acpeCollision: {
        name: 'COLL-ACPE',
        keyNames: ['COLL-ACPE'],
        type: RocVariableType.Big,
    },
    overlandingGearACV: {
        name: 'OGACV',
        keyNames: ['OGACV'],
        type: RocVariableType.Big,
    },
    overlandingGearReplacementCost: {
        name: 'OGRC',
        keyNames: ['OGRC'],
        type: RocVariableType.Big,
    },
    campsiteLiability: {
        name: 'CAMPLI',
        keyNames: ['CAMPLI'],
        type: RocVariableType.Big,
    },
    scheduledMedicalBenefit: {
        name: 'SMB',
        keyNames: ['SMB'],
        type: RocVariableType.Big,
    },
    emergencyExpense: {
        name: 'EMEXP',
        keyNames: ['EMEXP'],
        type: RocVariableType.Big,
    },
    towRoadside: {
        name: 'TOWR',
        keyNames: ['TOWR'],
        type: RocVariableType.Big,
    },
    offRoadRecovery: {
        name: 'OFFRD',
        keyNames: ['OFFRD'],
        type: RocVariableType.Big,
    },
};

export const commonWeightColumns = [
    weightColumn.bodilyInjury,
    weightColumn.propertyDamage,
    weightColumn.comprehensive,
    weightColumn.collision,
    weightColumn.gap,
    weightColumn.medical,
    weightColumn.personalInjuryProtection,
    weightColumn.unUnderinsuredMotoristBodilyInjury,
    weightColumn.unUnderinsuredMotoristPropertyDamage,
];

export const commonMinusLoanWeightColumns = [
    weightColumn.bodilyInjury,
    weightColumn.propertyDamage,
    weightColumn.comprehensive,
    weightColumn.collision,
    weightColumn.medical,
    weightColumn.personalInjuryProtection,
    weightColumn.unUnderinsuredMotoristBodilyInjury,
    weightColumn.unUnderinsuredMotoristPropertyDamage,
];

export const allWeightColumns = [
    weightColumn.bodilyInjury,
    weightColumn.propertyDamage,
    weightColumn.comprehensive,
    weightColumn.collision,
    weightColumn.gap,
    weightColumn.medical,
    weightColumn.personalInjuryProtection,
    weightColumn.unUnderinsuredMotoristBodilyInjury,
    weightColumn.unUnderinsuredMotoristPropertyDamage,
    weightColumn.rental,
    // weightColumn.tow,
    // InsuranceCoverageKey.acpeComprehensive,
    // InsuranceCoverageKey.acpeCollision,
    // weightColumn.trailerContents,
    weightColumn.trailerComprehensive,
    weightColumn.trailerCollision,
];

export const allMinusACPEWeightColumns = [
    weightColumn.bodilyInjury,
    weightColumn.propertyDamage,
    weightColumn.comprehensive,
    weightColumn.collision,
    weightColumn.gap,
    weightColumn.medical,
    weightColumn.personalInjuryProtection,
    weightColumn.unUnderinsuredMotoristBodilyInjury,
    weightColumn.unUnderinsuredMotoristPropertyDamage,
    weightColumn.rental,
    // weightColumn.tow,
    // weightColumn.trailerContents,
    weightColumn.trailerComprehensive,
    weightColumn.trailerCollision,
];

export const allMinusContentsACPEWeightColumns = [
    weightColumn.bodilyInjury,
    weightColumn.propertyDamage,
    weightColumn.comprehensive,
    weightColumn.collision,
    weightColumn.gap,
    weightColumn.medical,
    weightColumn.personalInjuryProtection,
    weightColumn.unUnderinsuredMotoristBodilyInjury,
    weightColumn.unUnderinsuredMotoristPropertyDamage,
    weightColumn.rental,
    // weightColumn.tow,
    weightColumn.trailerComprehensive,
    weightColumn.trailerCollision,
];

export const baseRatesVehicleColumns = [
    weightColumn.bodilyInjury,
    weightColumn.propertyDamage,
    weightColumn.comprehensive,
    weightColumn.collision,
    weightColumn.gap,
    weightColumn.medical,
    weightColumn.personalInjuryProtection,
    weightColumn.unUnderinsuredMotoristBodilyInjury,
    weightColumn.unUnderinsuredMotoristPropertyDamage,
    weightColumn.rental,
    // weightColumn.tow,
    weightColumn.acpeComprehensive,
    weightColumn.acpeCollision,
];

export const baseMinusLoanRatesVehicleColumns = [
    weightColumn.bodilyInjury,
    weightColumn.propertyDamage,
    weightColumn.comprehensive,
    weightColumn.collision,
    weightColumn.medical,
    weightColumn.personalInjuryProtection,
    weightColumn.unUnderinsuredMotoristBodilyInjury,
    weightColumn.unUnderinsuredMotoristPropertyDamage,
    weightColumn.rental,
    // weightColumn.tow,
    weightColumn.acpeComprehensive,
    weightColumn.acpeCollision,
];

export const baseMinusLoanACPERatesVehicleColumns = [
    weightColumn.bodilyInjury,
    weightColumn.propertyDamage,
    weightColumn.comprehensive,
    weightColumn.collision,
    weightColumn.medical,
    weightColumn.personalInjuryProtection,
    weightColumn.unUnderinsuredMotoristBodilyInjury,
    weightColumn.unUnderinsuredMotoristPropertyDamage,
    weightColumn.rental,
    // weightColumn.tow,
];

export const baseRatesTrailerColumns = [
    weightColumn.trailerComprehensive,
    weightColumn.trailerCollision,
];

export const allENSColumns = [
    weightColumn.trailerComprehensive,
    weightColumn.trailerCollision,
    weightColumn.acpeComprehensive,
    weightColumn.acpeCollision,
    weightColumn.overlandingGearACV,
    weightColumn.overlandingGearReplacementCost,
    weightColumn.campsiteLiability,
    weightColumn.scheduledMedicalBenefit,
    weightColumn.emergencyExpense,
    weightColumn.towRoadside,
    weightColumn.offRoadRecovery,
];

export const unitENSColumns = [weightColumn.trailerComprehensive, weightColumn.trailerCollision];

export const acpeENSColumns = [weightColumn.acpeComprehensive, weightColumn.acpeCollision];

export const gearENSColumns = [
    weightColumn.overlandingGearACV,
    weightColumn.overlandingGearReplacementCost,
];

export const specialENSColumns = [
    weightColumn.campsiteLiability,
    weightColumn.scheduledMedicalBenefit,
    weightColumn.emergencyExpense,
    weightColumn.towRoadside,
    weightColumn.offRoadRecovery,
];
