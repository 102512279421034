<div class="container-xxl" *ngIf="!formOnly">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block"><div class="quote-title">Where is home base?</div></div>
            <div class="d-md-block d-none"><div class="quote-title">Where is home base?</div></div>
        </div>
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3"><div class="quote-subtitle" data-cy="homeBaseSubtitleText">Where do you keep your rig and gear in between adventures.</div></div>
        <div class="row justify-content-center">
            <div class="col-lg-8 col-12">
                <div class="card">
                    <div class="card-body">
                        <ng-template [ngTemplateOutlet]="homeBaseFormTemplate"></ng-template
                        ><button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5 mt-md-5 mt-3" data-cy="quoteFlowHomeBaseNextButton" type="button" (click)="next.emit()" tabindex="0" [attr.disabled]="!allValid ? true : null">
                            <div class="d-flex align-items-center justify-content-center">Next</div>
                            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
                        </button>
                        <div class="d-flex justify-content-start"><sbf-back-button data-cy="quoteFlowHomeBaseBackButton" (click)="back.emit()"></sbf-back-button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="formOnly"><ng-template [ngTemplateOutlet]="homeBaseFormTemplate"></ng-template></ng-container
><ng-template #homeBaseFormTemplate
    ><div class="d-flex justify-content-between align-items-center">
        <span><div class="r-text-lg text-primary mb-2 ms-1" data-cy="homebaseAddressText" *ngIf="formOnly">Address</div></span>
        <div class="text-secondary pointer mb-3" *ngIf="allValid" (click)="changeAddress()">Change Address</div>
    </div>
    <div class="form-floating" [ngClass]='{"d-none": allValid, "d-block": !allValid}'><input class="form-control mb-3" id="autoCompleteInput" #autoCompleteInput data-cy="autoCompleteInput" autocomplete="off" type="text" aria-describedby="autoCompleteInput" sbwAutoFocus [formOnly]="formOnly" /><label class="text-gray-600 small" for="zipInput">Enter a physical address here</label></div>
    <form *ngIf="allValid" data-cy="homeBaseForm" [formGroup]="homeBaseForm">
        <div class="row">
            <div class="col-12">
                <div class="form-floating mb-3">
                    <input class="form-control pointer-events-none border-0" id="streetInput" data-cy="streetInput" autocomplete="off" type="text" aria-describedby="streetHelp" formControlName="street" [class.is-invalid]="streetControlInvalid" placeholder="street" tabindex="-1" readonly />
                    <div class="invalid-feedback" *ngIf='streetControl.hasError("required")'>Street required.</div>
                    <label class="text-gray-600 small" for="streetInput">Street</label>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control pointer-events-none border-0" id="cityInput" data-cy="cityInput" autocomplete="off" type="text" aria-describedby="cityHelp" formControlName="city" [class.is-invalid]="cityControlInvalid" placeholder="city" tabindex="-1" readonly />
                    <div class="invalid-feedback" *ngIf='cityControl.hasError("required")'>City required.</div>
                    <label class="text-gray-600 small" for="cityInput">City</label>
                </div>
            </div>
            <div class="col-sm-2 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control pointer-events-none border-0" id="stateInput" data-cy="stateInput" autocomplete="off" type="text" aria-describedby="stateHelp" formControlName="state" [class.is-invalid]="stateControlInvalid" placeholder="state" tabindex="-1" readonly />
                    <div class="invalid-feedback" *ngIf='stateControl.hasError("required")'>State required.</div>
                    <label class="text-gray-600 small" for="stateInput">State</label>
                </div>
            </div>
            <div class="col-sm-4 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control pointer-events-none border-0" id="zipInput" data-cy="zipInput" autocomplete="off" type="text" aria-describedby="zipHelp" formControlName="zip" [class.is-invalid]="zipControlInvalid" placeholder="zip" tabindex="-1" readonly />
                    <div class="invalid-feedback" *ngIf='zipControl.hasError("required")'>Zip required.</div>
                    <label class="text-gray-600 small" for="zipInput">Zip</label>
                </div>
            </div>
        </div>
    </form></ng-template
>
