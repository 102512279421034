import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
    QuoteEnsFlowFormValue,
    QuoteRigSetCombinedCoverageSelectFormValue,
} from '@backend-types/quote-flow-ens';
import { isWithinInterval, subYears } from 'date-fns';

export class ValidateCheckPasswords {
    static validate(control: AbstractControl): ValidationErrors | null {
        const password = control.get('password')?.value || control.get('newPassword')?.value;
        const confirmPassword = control.get('confirmPassword')?.value;
        return password === confirmPassword ? null : { passwordMismatch: true };
    }
}

// export class ValidateEmailRFC5322 {
//     static validate(control: AbstractControl): ValidationErrors | null {
//         let regex = new RegExp(
//             "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
//         );
//         const valid = regex.test(control.value);
//         return valid ? null : { email: true };
//     }
// }

export function validateVIN(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        if (control.value.length < 11 || control.value.length > 17) {
            return { badLength: true };
        }
        return null;
    };
}

export function validateEmailsCommaSeparated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return { noEmails: true };
        }
        let allValid = true;
        const badEmails: string[] = [];
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const emails = (control.value as string).split(',');

        emails.forEach((email) => {
            const trimmedEmail = email.trim();
            const isGoodEmail = emailRegex.test(trimmedEmail);
            if (!isGoodEmail) {
                badEmails.push(trimmedEmail);
                allValid = false;
            }
        });

        if (!allValid) {
            return { hasBadEmail: badEmails.toString() };
        }
        return null;
    };
}

export function validateBirthday(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        const isGood = isWithinInterval(new Date(control.value), {
            start: subYears(new Date(), 100),
            end: subYears(new Date(), 16),
        });
        if (isGood) {
            return null;
        }

        return { badBirthday: true };
    };
}

export function validateIsWithinInterval(start: Date, end: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        const isGood = isWithinInterval(new Date(control.value), {
            start,
            end,
        });
        if (isGood) {
            return null;
        }

        return { notWithinInterval: true };
    };
}

// INFO: use mask error
// export function validatePhone(): ValidatorFn {
//     return (control: AbstractControl): ValidationErrors | null => {
//         if (!control.value) {
//             return null;
//         }
//         const isGood = control.value.length === 7;
//         if (isGood) {
//             return null;
//         }

//         return { badPhone: true };
//     };
// }

export function validateQuoteEnsFlowForm(): ValidatorFn {
    // eslint-disable-next-line complexity
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        const quoteEnsFlowFormValue: QuoteEnsFlowFormValue = control.value;

        if (!quoteEnsFlowFormValue.rigSets) {
            return null;
        }

        let hasError = false;
        const errorObject: Record<string, boolean> = {};

        // eslint-disable-next-line complexity
        quoteEnsFlowFormValue.rigSets.forEach((rigSet, index) => {
            if (rigSet.coverageSelect.modsCoverage) {
                if (
                    (rigSet.modsDetails?.modsValue || 0) <= 0 ||
                    (rigSet.modsDetails?.modsValue || 250000) > 250000
                ) {
                    hasError = true;
                    errorObject[`modsValue_${index}`] = true;
                }
            }

            if (rigSet.coverageSelect.camperCoverage) {
                if (
                    (rigSet.camperDetails?.unitValue || 0) <= 0 ||
                    (rigSet.camperDetails?.unitValue || 1000000) > 1000000
                ) {
                    hasError = true;
                    errorObject[`camperValue_${index}`] = true;
                }
            }
            if (rigSet.coverageSelect.trailerCoverage) {
                if (
                    (rigSet.trailerDetails?.unitValue || 0) <= 0 ||
                    (rigSet.trailerDetails?.unitValue || 1000000) > 1000000
                ) {
                    hasError = true;
                    errorObject[`trailerValue_${index}`] = true;
                }
            }
        });

        if (quoteEnsFlowFormValue.gearCoverage) {
            if (
                (quoteEnsFlowFormValue.gearDetails?.gearValue || 0) <= 0 ||
                (quoteEnsFlowFormValue.gearDetails?.gearValue || 250000) > 250000
            ) {
                hasError = true;
                errorObject[`gearValue`] = true;
            }
        }

        if (!hasError) {
            return null;
        }

        return errorObject;
    };
}

// export function dirtyInvalid(): ValidatorFn {
//     return (control: AbstractControl): ValidationErrors | null => {
//         let hasDirtyInvalid = false;
//         const valueObject = control.value;

//         for (const key in valueObject) {
//             if (Object.prototype.hasOwnProperty.call(valueObject, key)) {
//                 const control = valueObject[key];
//                 if (control.invalid && control.dirty) {
//                     hasDirtyInvalid = true;
//                 }
//             }
//         }
//         if (hasDirtyInvalid) {
//             return {
//                 dirtyInvalid: hasDirtyInvalid,
//             };
//         } else {
//             return null;
//         }
//     };
// }

export function validateCancelDate(minCancelDate: string, maxCancelDate: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        const isGood = isWithinInterval(new Date(control.value), {
            start: new Date(minCancelDate),
            end: new Date(maxCancelDate),
        });
        if (isGood) {
            return null;
        }

        return { badCancelDate: true };
    };
}

export function validateCoverageSelect(): ValidatorFn {
    // eslint-disable-next-line complexity
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        const coverageSelect: QuoteRigSetCombinedCoverageSelectFormValue = control.value;

        if (
            coverageSelect.gearCoverage &&
            !coverageSelect.modsCoverage &&
            !coverageSelect.camperCoverage &&
            !coverageSelect.trailerCoverage
        ) {
            return { gearCoverageRequiresModsOrUnit: true };
        }

        return null;
    };
}
