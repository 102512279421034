<form class="d-flex align-items-center justify-content-evenly h-100" [formGroup]="inputRangeForm">
    <div class="form-floating">
        <input class="form-control" [attr.id]='"rangeMinInput" + idAppend' [attr.data-cy]='"rangeMinInput" + idAppend' autocomplete="off" type="number" aria-describedby="minRangeInput" formControlName="min" [class.is-invalid]="minControlInvalid" placeholder="Min" />
        <div class="invalid-feedback" *ngIf='minControl.hasError("required")'>Min required.</div>
        <label class="text-gray-600 small" [attr.for]='"rangeMinInput" + idAppend'>Min</label>
    </div>
    <div class="form-floating">
        <input class="form-control" [attr.id]='"rangeMaxInput" + idAppend' [attr.data-cy]='"rangeMaxInput" + idAppend' autocomplete="off" type="number" aria-describedby="maxRangeInput" formControlName="max" [class.is-invalid]="maxControlInvalid" placeholder="Max" />
        <div class="invalid-feedback" *ngIf='maxControl.hasError("required")'>Max required.</div>
        <label class="text-gray-600 small" [attr.for]='"rangeMaxInput" + idAppend'>Max</label>
    </div>
</form>
