import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CreatePostPayload,
    DeleteMultiplePostsPayload,
    PostForList,
    PostForResults,
    UpdatePostPayload,
} from '@backend-types/post';
import { BehaviorSubject, catchError, Observable, of, ReplaySubject, tap } from 'rxjs';

import { ActiveAuthUser, EnvService, LogService } from '.';

const allPostsSubject$ = new ReplaySubject<PostForList[]>(1);
const allPostsLoaded$ = new BehaviorSubject(false);
const userPostsSubject$ = new ReplaySubject<PostForList[]>(1);
const userPostsLoaded$ = new BehaviorSubject(false);

@Injectable()
export class PostService {
    constructor(
        private http: HttpClient,
        private envService: EnvService,
        private logService: LogService
    ) {}

    get allPosts$(): Observable<PostForList[]> {
        return allPostsSubject$.asObservable();
    }

    loadAllPosts() {
        if (allPostsLoaded$.value) {
            return;
        }

        this._loadAllPosts();

        allPostsLoaded$.next(true);
    }

    private _loadAllPosts(): void {
        this.http
            .get<PostForList[]>(`${this.envService.config.backendURL}/api/latest/admin/post`)
            .pipe(
                catchError((error: Error) => {
                    return of();
                }),
                tap((posts) => this.logService.debug(posts, '### DEBUG - POSTS: '))
            )
            .subscribe((posts) => allPostsSubject$.next(posts));
    }

    get userPosts$(): Observable<PostForList[]> {
        return userPostsSubject$.asObservable();
    }

    loadUserPosts(user: ActiveAuthUser) {
        if (userPostsLoaded$.value) {
            return;
        }

        this._loadUserPosts(user);

        userPostsLoaded$.next(true);
    }

    private _loadUserPosts(user: ActiveAuthUser): void {
        this.http
            .get<PostForList[]>(`${this.envService.config.backendURL}/api/latest/account/post`)
            .pipe(
                catchError((error: Error) => {
                    return of();
                }),
                tap((posts) => this.logService.debug(posts, '### DEBUG - POSTS: '))
            )
            .subscribe((posts) => userPostsSubject$.next(posts));
    }

    createPost$(
        createPostPayload: CreatePostPayload,
        user?: ActiveAuthUser
    ): Observable<PostForList> {
        return this.http
            .post<PostForList>(
                `${this.envService.config.backendURL}/api/latest/${
                    user ? 'account' : 'admin'
                }/post`,
                createPostPayload
            )
            .pipe(
                tap(() => {
                    if (!user) {
                        this._loadAllPosts();
                    } else {
                        this._loadUserPosts(user);
                    }
                })
            );
    }

    updatePost$(
        postID: UUID,
        updatePostPayload: UpdatePostPayload,
        user?: ActiveAuthUser
    ): Observable<PostForResults> {
        return this.http
            .put<PostForResults>(
                `${this.envService.config.backendURL}/api/latest/${
                    user ? 'account' : 'admin'
                }/post/${postID}`,
                updatePostPayload
            )
            .pipe(
                tap(() => {
                    if (!user) {
                        this._loadAllPosts();
                    } else {
                        this._loadUserPosts(user);
                    }
                })
            );
    }

    deletePosts$(
        deleteMultiplePostsPayload: DeleteMultiplePostsPayload,
        user?: ActiveAuthUser
    ): Observable<void> {
        return this.http
            .post<undefined>(
                `${this.envService.config.backendURL}/api/latest/${
                    user ? 'account' : 'admin'
                }/post/delete-multiple`,
                deleteMultiplePostsPayload
            )
            .pipe(
                tap(() => {
                    if (!user) {
                        this._loadAllPosts();
                    } else {
                        this._loadUserPosts(user);
                    }
                })
            );
    }

    getPost$(postID: UUID, user?: ActiveAuthUser): Observable<PostForResults> {
        return this.http.get<PostForResults>(
            `${this.envService.config.backendURL}/api/latest/${
                user ? 'account' : 'admin'
            }/post/${postID}`
        );
    }
}
