import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbpro-confirmation-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['confirmation-modal.component.scss'],
    standalone: true,
    imports: [CommonModule, NgbModalModule],
})
export class ConfirmationModalComponent implements OnInit {
    title = 'Title';
    text?: string;
    text2?: string;
    textArray?: string[];
    cancelLabel = 'CANCEL';
    confirmationLabel = 'CONFIRM';
    cancelClasses = ['btn-danger', 'btn-sm'];
    confirmClasses = ['btn-primary-alt', 'btn-sm'];
    noCancel = false;

    constructor(private activeModal: NgbActiveModal) {}
    ngOnInit() {}
    cancel() {
        this.activeModal.dismiss();
    }
    confirm() {
        this.activeModal.close();
    }
}
