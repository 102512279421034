import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sbf-col-key-value',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './col-key-value.component.html',
    styleUrls: ['col-key-value.component.scss'],
})
export class ColKeyValueComponent implements OnInit {
    @Input() key!: string;
    @Input() value!: string;
    @Input() keyClasses?: string[];
    @Input() valueClasses?: string[];
    @Input() keyIdAppend?: string;
    @Input() valueIdAppend?: string;

    constructor() {}
    ngOnInit() {}
}
