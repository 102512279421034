import { Injectable } from '@angular/core';
import { ToastMessage, ToastMessageOptions, ToastMessageShowParameters } from '@common/models';
import { v4 as uuid } from 'uuid';

import { HotKeysService } from './hot-keys.service';

@Injectable()
export class ToastService {
    defaultOptions: ToastMessageOptions = {
        autohide: true,
        delay: 30000,
        closeOnClick: true,
    };

    constructor(private hotKeysService: HotKeysService) {
        // THIS WAS CAUSING x NOT TO WORK ON REGISTER PAGE
        // this.hotKeysService.addShortcut$({ keys: 'x' }).subscribe(() => {
        //     this.toasts.shift();
        // });
        // this.hotKeysService.addShortcut$({ keys: 'shift.x' }).subscribe(() => {
        //     this.toasts = [];
        // });
    }

    toasts: ToastMessage[] = [];

    showSuccess<T>(toastMessageShowParameters: ToastMessageShowParameters<T>) {
        this.show<T>({
            ...toastMessageShowParameters,
            options: {
                ...toastMessageShowParameters.options,
                classes: `success ${toastMessageShowParameters.options?.classes}`,
                bodyIcon: 'check',
            },
        });
    }

    showError<T>(toastMessageShowParameters: ToastMessageShowParameters<T>) {
        this.show<T>({
            ...toastMessageShowParameters,
            options: {
                ...toastMessageShowParameters.options,
                classes: `error ${toastMessageShowParameters.options?.classes}`,
                bodyIcon: 'alert',
            },
        });
    }

    show<T>(toastMessageShowParameters: ToastMessageShowParameters<T>) {
        if (toastMessageShowParameters.flatten) {
            this.toasts = [
                {
                    uuid: uuid(),
                    ...toastMessageShowParameters,
                    options: {
                        ...this.defaultOptions,
                        ...toastMessageShowParameters.options,
                    },
                },
            ];
        } else {
            this.toasts.push({
                uuid: uuid(),
                ...toastMessageShowParameters,
                options: {
                    ...this.defaultOptions,
                    ...toastMessageShowParameters.options,
                },
            });
        }
    }

    remove(toastID: string) {
        this.toasts = this.toasts.filter((toast) => toast.uuid !== toastID);
    }
}
