import { Injectable } from '@angular/core';
import { DevUtilsModalKind, FunctionReferences } from '@common/models/dev-utils.model';
import { EnvService } from '@common/services';
import { DevUtilsModalComponent } from '@modules/dev/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DevUtilsModalService {
    constructor(
        private ngbModalService: NgbModal,
        private envService: EnvService
    ) {}

    open<T extends DevUtilsModalKind>(
        devUtilsModalKind: T,
        functionRefereces: FunctionReferences[T]
    ): void {
        if (this.envService.isProd) {
            return;
        }
        const instance = this.ngbModalService.open(DevUtilsModalComponent, {
            size: 'lg',
        });
        instance.componentInstance.devUtilsModalKind = devUtilsModalKind;
        instance.componentInstance.functionRefereces = functionRefereces;
    }
}
