<div class="modal-header"><div class="r-text-lg heavy text-primary">Taxes & Fees Breakdown</div></div>
<div class="modal-body px-4 pt-0 pb-3">
    <section id="state" *ngIf="stateFeesAndTaxes?.length &gt; 0">
        <ng-container *ngFor="let fee of stateFeesAndTaxes"><ng-container *ngTemplateOutlet="Fee, context: {name: fee.invoiceLineItemKind, amount: fee.debit, showState: true}"></ng-container></ng-container>
    </section>
    <section id="tredder">
        <ng-container *ngFor="let fee of tredderFees"><ng-container *ngTemplateOutlet="Fee, context: {name: fee.invoiceLineItemKind, amount: fee.debit}"></ng-container></ng-container>
    </section>
</div>
<div class="d-flex justify-content-end px-3 pb-3">
    <button class="btn rounded-pill btn-primary-gradient text-white fw-500" data-cy="closeTaxesFeesModal" (click)="close()" tabindex="-1"><div class="mx-3">Close</div></button>
</div>
<ng-template #Fee let-name="name" let-amount="amount" let-showState="showState"
    ><div class="d-flex text-primary-subtle mb-2 align-items-end mt-3">
        <div class="flex-shrink-0"><span *ngIf="showState">{{stateName | capitalCase}}&nbsp;</span>{{name | capitalCase}}</div>
        <div class="w-100 dotted border-primary-subtle"></div>
        <div class="flex-shrink-0">{{amount | currency : 'USD' : 'symbol' : '1.2'}}</div>
    </div></ng-template
>
