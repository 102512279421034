import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { ModelFormGroup } from '@common/models';
import { AnalyticsService, HotKeysService } from '@common/services';
import { quoteEnsFlowFormDefaultTestValues } from '@data/manual-test/ens-flow-form.data';
import { QuoteEnsFormService } from '@modules/quote/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-intro',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-intro.component.html',
    styleUrls: ['quote-ens-intro.component.scss'],
})
export class QuoteEnsIntroComponent implements OnInit, OnDestroy {
    @Output() next = new EventEmitter<void>();

    lastClick: 'title' | 'image' | null = null;
    manualTestClickCount = 0;

    quoteEnsFlowForm!: ModelFormGroup<QuoteEnsFlowFormValue>;

    subscription: Subscription = new Subscription();

    constructor(
        private quoteEnsFormService: QuoteEnsFormService,
        private hotKeysService: HotKeysService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Intro');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_intro',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowForm$.subscribe((quoteEnsFlowForm) => {
                this.quoteEnsFlowForm = quoteEnsFlowForm;
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );

        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this._setFormValueAndGoToReview();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    titleClicked() {
        if (this.lastClick !== 'title') {
            this.lastClick = 'title';
            this.manualTestClickCount++;
            return;
        }
        this.manualTestClickCount = 0;
    }

    imageClicked() {
        if (this.lastClick !== 'image') {
            this.lastClick = 'image';
            this.manualTestClickCount++;
            if (this.manualTestClickCount > 10) {
                this._setFormValueAndGoToReview();
            }
            return;
        }
        this.manualTestClickCount = 0;
    }

    private _setFormValueAndGoToReview() {
        this.quoteEnsFlowForm.setValue(quoteEnsFlowFormDefaultTestValues);
        this.changeDetectorRef.detectChanges();
        this.router.navigate(['/quote/ens'], {
            queryParams: { 'quote-ens-form-step': 'rates' },
        });
    }
}
