import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    QuoteRigSetFormValue,
    QuoteRigSetRigCoverageSelectFormValue,
} from '@backend-types/quote-flow-ens';
import { slideInOutAnimation } from '@common/animations';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { QuoteEnsFormStep, QuoteRigSetFormStep } from '@modules/quote/models';
import { QuoteRigSetFormService, QuoteSharedService } from '@modules/quote/services';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-rig-set-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-rig-set-form.component.html',
    styleUrls: ['quote-rig-set-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteRigSetFormComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteRigSetFormComponent,
        },
    ],
    animations: [slideInOutAnimation],
})
export class QuoteRigSetFormComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;
    rigIndex!: number;
    jumpBackEns?: QuoteEnsFormStep;
    jumpBackRig?: QuoteRigSetFormStep;
    jumpBackEndorsement?: UUID;

    eQuoteRigSetFormStep = QuoteRigSetFormStep;
    subscription: Subscription = new Subscription();
    invalidZip: string | null = null;
    currentQuoteRigSetFormStep: QuoteRigSetFormStep = QuoteRigSetFormStep.rigName;

    onTouched: () => unknown = () => {};
    onChange = (quoteRigSetFormValue: ModelFormValue<QuoteRigSetFormValue>[]) => {};

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private quoteSharedService: QuoteSharedService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.subscription.add(
            this.quoteRigSetFormService
                .getActiveQuoteRigSetForm$()
                .subscribe((activeQuoteRigSetForm) => {
                    this.activeQuoteRigSetForm = activeQuoteRigSetForm;
                    this.changeDetectorRef.detectChanges();
                })
        );

        this.subscription.add(
            this.route.queryParamMap.subscribe((queryParamMap) => {
                const quoteRigSetFormStep = this.quoteSharedService.queryParamToQuoteRigSetFormStep(
                    queryParamMap.get('quote-rig-set-step')
                );
                if (quoteRigSetFormStep) {
                    this.currentQuoteRigSetFormStep = quoteRigSetFormStep;
                }
                const rigIndex = parseInt(queryParamMap.get('rig-index') || '0', 10);
                this.quoteRigSetFormService.setActiveQuoteRigSetFormIndex(rigIndex);
                this.rigIndex = rigIndex;

                this.jumpBackEns = queryParamMap.get('jump-back-ens') as QuoteEnsFormStep;
                this.jumpBackRig = queryParamMap.get('jump-back-rig') as QuoteRigSetFormStep;
                this.jumpBackEndorsement = queryParamMap.get('jump-back-endorsement') as UUID;

                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (quoteRigSetFormValue: ModelFormValue<QuoteRigSetFormValue>[]) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.activeQuoteRigSetForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.activeQuoteRigSetForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe((valueChanges) => {
                    try {
                        const quoteRigSetFormValuesArray =
                            this.quoteRigSetFormService.getQuoteRigSetFormValuesArray();
                        onChange(quoteRigSetFormValuesArray);
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.activeQuoteRigSetForm.disable({
                emitEvent: false,
            });
        } else {
            this.activeQuoteRigSetForm.enable({
                emitEvent: false,
            });
        }
    }

    writeValue(value: QuoteRigSetFormValue[] | null) {
        // if (value === null) {
        //     this.activeQuoteRigSetForm.reset();
        // }
        if (value) {
            this.quoteRigSetFormService.setQuoteRigSetFormValuesArray(value);
            // this.activeQuoteRigSetForm.setValue(value, { emitEvent: false });
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // if (this.quoteRigSetFormService.dirtyInvalid()) {
        //     return { quoteRigSetFormHasError: true };
        // }

        return null;
    }

    // eslint-disable-next-line complexity
    nextQuoteRigSetFormStep() {
        if (this.jumpBackEndorsement) {
            this.router.navigateByUrl(
                `/account/insurance/coverage/edit/${this.jumpBackEndorsement}`
            );
            return;
        }
        if (this.jumpBackRig) {
            this._gotoStep(this.jumpBackRig, {
                'jump-back-rig': undefined,
            });
            return;
        }
        if (this.jumpBackEns) {
            this.next.emit();
            return;
        }
        switch (this.currentQuoteRigSetFormStep) {
            case QuoteRigSetFormStep.rigName:
                this._gotoStep(QuoteRigSetFormStep.rigDetails);
                break;
            case QuoteRigSetFormStep.rigDetails:
                this._gotoStep(QuoteRigSetFormStep.coverageSelect);
                break;
            case QuoteRigSetFormStep.coverageSelect:
                if (this.coverageSelectControl.value.modsCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.modsDetails);
                    break;
                }
                if (this.coverageSelectControl.value.camperCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.camperDetails);
                    break;
                }
                if (this.coverageSelectControl.value.trailerCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.trailerDetails);
                    break;
                }
                // Info: No coverage on Rig, so jump out to gear
                this.next.emit();
                break;
            case QuoteRigSetFormStep.modsDetails:
                if (this.coverageSelectControl.value.camperCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.camperDetails);
                    break;
                }
                if (this.coverageSelectControl.value.trailerCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.trailerDetails);
                    break;
                }
                this._gotoStep(QuoteRigSetFormStep.rigReview);
                break;
            case QuoteRigSetFormStep.camperDetails:
                if (this.coverageSelectControl.value.trailerCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.trailerDetails);
                    break;
                }
                this._gotoStep(QuoteRigSetFormStep.rigReview);
                break;
            case QuoteRigSetFormStep.trailerDetails:
                this._gotoStep(QuoteRigSetFormStep.rigReview);
                break;
            case QuoteRigSetFormStep.rigReview:
                this.next.emit();
                break;
            default:
                break;
        }
    }

    // eslint-disable-next-line complexity
    backQuoteRigSetFormStep() {
        switch (this.currentQuoteRigSetFormStep) {
            case QuoteRigSetFormStep.rigName:
                this.back.emit();
                break;
            case QuoteRigSetFormStep.rigDetails:
                this._gotoStep(QuoteRigSetFormStep.rigName);
                break;
            case QuoteRigSetFormStep.coverageSelect:
                this._gotoStep(QuoteRigSetFormStep.rigDetails);
                break;
            case QuoteRigSetFormStep.modsDetails:
                this._gotoStep(QuoteRigSetFormStep.coverageSelect);
                break;
            case QuoteRigSetFormStep.camperDetails:
                if (this.coverageSelectControl.value.modsCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.modsDetails);
                    break;
                }
                this._gotoStep(QuoteRigSetFormStep.coverageSelect);
                break;
            case QuoteRigSetFormStep.trailerDetails:
                if (this.coverageSelectControl.value.camperCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.camperDetails);
                    break;
                }
                if (this.coverageSelectControl.value.modsCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.modsDetails);
                    break;
                }
                this._gotoStep(QuoteRigSetFormStep.coverageSelect);
                break;
            case QuoteRigSetFormStep.rigReview:
                if (this.coverageSelectControl.value.trailerCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.trailerDetails);
                    break;
                }
                if (this.coverageSelectControl.value.camperCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.camperDetails);
                    break;
                }
                if (this.coverageSelectControl.value.modsCoverage) {
                    this._gotoStep(QuoteRigSetFormStep.modsDetails);
                    break;
                }
                this._gotoStep(QuoteRigSetFormStep.coverageSelect);
                break;
            default:
                break;
        }
    }

    jumpToStep(step: QuoteRigSetFormStep) {
        this._gotoStep(step, {
            'jump-back-rig': QuoteRigSetFormStep.rigReview,
        });
    }

    private _gotoStep(
        step: QuoteRigSetFormStep,
        queryParams: Record<string, string | undefined> = {}
    ) {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            queryParams: {
                ...queryParams,
                'quote-rig-set-step': step,
            },
        });
    }

    /* Accessor Methods */

    get coverageSelectControl() {
        return this.activeQuoteRigSetForm.get(
            'coverageSelect'
        ) as FormControl<QuoteRigSetRigCoverageSelectFormValue>;
    }
}
