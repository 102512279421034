<div class="pt-lg-5 pt-2"></div>
<div class="container-xxl">
    <div class="p-sm-3 mb-10">
        <div class="container px-0">
            <div class="d-flex justify-content-center align-items-center"><div class="text-grey-43 semi-bold r-text-jumbo mt-5">Sorry...</div></div>
            <div class="row justify-content-center mb-3">
                <div class="col-xl-8 col-12"><div class="quote-subtitle mt-5 mx-2">We were unable to process your payment.</div></div>
            </div>
            <div class="row justify-content-center mb-3">
                <div class="col-xl-8 col-12">
                    <div class="quote-subtitle mt-3 mx-2 mb-3">Feel free to try another card.</div>
                    <div class="d-flex justify-content-center align-items-center">
                        <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button w-50" data-cy="tryAnotherCardButton" type="button" (click)="tryAnotherCard()" tabindex="0"><div class="d-flex align-items-center justify-content-center">Try another card</div></button>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-8 col-12"><div class="quote-subtitle mt-3 mx-2 mb-3">Or give us a call</div></div>
            </div>
            <div class="row justify-content-center" *ngIf="activeEnsQuote">
                <div class="col-xl-8 col-12">
                    <div class="quote-subtitle mt-3 mx-2 mb-3">
                        Quote:
                        <div class="d-inline-block text-primary">{{activeEnsQuote.quoteEnsQuoteNumber | quoteEnsNumber}}</div>
                    </div>
                </div>
            </div>
            <div class="d-inline-flex justify-content-center w-100">
                <div class="card mt-4">
                    <div class="carb-body py-3 px-4">
                        <div class="d-flex justify-content-between align-items-center"><i-bs name="telephone" width="1.5em" height="1.5em"></i-bs><a class="text-cyan-blue text-xl semi-bold ms-3" href="tel:8448733337">844-TREDDER</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
