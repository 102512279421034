<div class="modal-header"><div class="r-text-lg heavy text-primary">Enter VIN</div></div>
<div class="modal-body p-4">
    <form data-cy="vinEntryForm" [formGroup]="form">
        <div class="fw-bold w-100 d-flex text-danger align-items-center justify-content-center mb-3 r-text-base" *ngIf="showVINDecodeError">Unable to decode VIN</div>
        <div class="row">
            <div class="col-12">
                <div class="form-floating">
                    <input class="form-control" id="vinInput" data-cy="vinInput" autocomplete="off" type="text" sbwAutoFocus formControlName="vin" [class.is-invalid]="vinControlInvalid" (keydown)="checkKeyDown($event)" placeholder="vin" mask="AAAAAAAAAAAAAAAAA" />
                    <div class="invalid-feedback" *ngIf="vinControlInvalid"><ng-container *ngIf="vinControl.hasError('required')">VIN required.</ng-container><ng-container *ngIf="vinControl.hasError('minlength') || vinControl.hasError('maxlength')">VIN must be 17 characters long</ng-container></div>
                    <label class="text-gray-60 small" for="vinInput">VIN</label>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="d-flex justify-content-end px-3 pb-3">
    <button class="btn rounded-pill fw-500 me-3" data-cy="closeButtonEnterVinModal" (click)="close()" tabindex="0"><div class="mx-3">Cancel</div></button><button class="btn rounded-pill btn-primary-gradient text-white fw-500" data-cy="submitButtonEnterVinModal" (click)="submit()" tabindex="1" [disabled]="form.invalid"><div class="mx-3">Submit</div></button>
</div>
