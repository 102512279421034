import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ExistingCoveragePullStatus } from '@backend-types/coverage-verification';
import { quoteEnsExclusionConfigs } from '@backend-types/exclusions';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { inputIsNotNullOrUndefined } from '@common/helpers';
import {
    CanopyConnectHandler,
    MetaDataForCanopyConnect,
} from '@common/models/canopy-connect.model';
import {
    AnalyticsService,
    ConfirmationService,
    CoverageVerificationService,
    DynamicExternalService,
    ExistingCoverageService,
    HotKeysService,
    OverlayService,
} from '@common/services';
import { AgencyAdminAccount } from '@modules/manage-common/models';
import { QuoteEnsFormService, QuoteEnsRetrievalService } from '@modules/quote/services';
import { filter, finalize, Subscription, take } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-dec-pull',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-dec-pull.component.html',
    styleUrls: ['quote-ens-dec-pull.component.scss'],
})
export class QuoteEnsDecPullComponent implements OnInit, OnDestroy {
    @Input() endorsement = false;
    @Input() agencyAdminAccount = AgencyAdminAccount.account;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    activeEnsQuote!: EnsResponseWithEnsOnly | null;

    canopyConnectHandler!: CanopyConnectHandler;
    existingCoverageSubmitted = false;
    existingCoveragesExists = false;

    subscription: Subscription = new Subscription();

    constructor(
        private analyticsService: AnalyticsService,
        private changeDetectorRef: ChangeDetectorRef,
        private confirmationService: ConfirmationService,
        private coverageVerificationService: CoverageVerificationService,
        private dynamicExternalService: DynamicExternalService,
        private hotKeysService: HotKeysService,
        private overlayService: OverlayService,
        private quoteEnsFormService: QuoteEnsFormService,
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private router: Router,
        private title: Title,
        private existingCoverageService: ExistingCoverageService
    ) {
        this.title.setTitle('Tredder Quote - Existing Coverage');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_dec_pull',
            label: this.agencyAdminAccount,
            category: this.endorsement ? 'endorsement' : undefined,
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.continue();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );

        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuote$
                .pipe(filter(inputIsNotNullOrUndefined), take(1))
                .subscribe((activeEnsQuote) => {
                    this.activeEnsQuote = activeEnsQuote;

                    if (
                        this.existingCoverageService.getCurrentValidExistingCoverage(
                            activeEnsQuote.existingCoverages || []
                        )
                    ) {
                        this.existingCoveragesExists = true;
                    } else {
                        if (!this.canopyConnectHandler) {
                            this.subscription.add(
                                this.dynamicExternalService
                                    .canopyConnectHandler$(activeEnsQuote.quoteEnsId, undefined, {
                                        onExit: (error, payload) => {
                                            if (error || !payload || !payload.pull) {
                                                return;
                                            }
                                            this.updateQuoteEnsPolicyCanopyConnectPullStatus(
                                                payload.pull.pull_id,
                                                payload.pull.metadata
                                            );
                                        },
                                    })
                                    .pipe(take(1))
                                    .subscribe((canopyConnectHandler) => {
                                        this.canopyConnectHandler = canopyConnectHandler;
                                        this.changeDetectorRef.detectChanges();
                                    })
                            );
                        }
                    }

                    this.changeDetectorRef.detectChanges();
                })
        );
    }

    private updateQuoteEnsPolicyCanopyConnectPullStatus(
        pullId: string,
        metaData: MetaDataForCanopyConnect
    ) {
        if (pullId && metaData) {
            this.existingCoverageSubmitted = true;
            this.checkExistingCoverage();
        }
    }

    startInsuranceImport() {
        this.canopyConnectHandler.open();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        if (this.canopyConnectHandler) {
            this.canopyConnectHandler.destroy();
        }
    }

    continue() {
        this.next.emit();
    }

    checkExistingCoverage() {
        if (!this.activeEnsQuote?.quoteEnsId) {
            return;
        }

        this.overlayService.show('Verifying Insurance Information...');

        this.coverageVerificationService
            .checkExistingCoveragePullStatus$({
                quoteEnsId: this.activeEnsQuote.quoteEnsId,
            })
            .pipe(
                take(1),
                finalize(() => this.overlayService.hide())
            )
            .subscribe((response) => {
                switch (response.result) {
                    case 'timeout': {
                        this.confirmationService
                            .confirm$({
                                title: 'Existing Coverage Verification',
                                textArray: [
                                    'We had trouble verifying your existing auto insurance coverage.',
                                    'This may be a timing issue, please try again.',
                                    'If this persists, please contact:',
                                    '(844)-TREDDER',
                                    '(844)-873-3337',
                                ],
                                confirmClasses: ['btn-primary text-white'],
                                confirmationLabel: 'Try Again',
                                noCancel: true,
                            })
                            .pipe(take(1))
                            .subscribe((response) => {
                                if (response) {
                                    this.overlayService.show('Verifying Insurance Information...');
                                    this.checkExistingCoverage();
                                }
                            });
                        break;
                    }
                    case ExistingCoveragePullStatus.error:
                    case ExistingCoveragePullStatus.pullInvalid: {
                        this.router.navigate([`/quote/sorry`], {
                            queryParams: {
                                reason: quoteEnsExclusionConfigs.noInsurance.queryParam,
                            },
                            replaceUrl: true,
                        });

                        break;
                    }
                    case ExistingCoveragePullStatus.pulled: {
                        if (response.quoteEnsFlowFormValue) {
                            this.quoteEnsFormService.setQuoteEnsFlowForm(
                                response.quoteEnsFlowFormValue
                            );
                        }
                        this.quoteEnsRetrievalService.refreshActiveEnsQuote();
                        this.continue();
                        break;
                    }
                    default: {
                        throw new Error(`UNRECOGNIZED_RESPONSE: ${response.result}`);
                    }
                }
            });
    }

    skipExistingCoverage() {
        return this.confirmationService
            .confirm$({
                title: 'Primary Insurance Required',
                textArray: [
                    'I understand that one of the conditions to maintain a Tredder policy is to have a primary auto insurance policy with Comprehensive and Collision Coverages.',
                    'If you do not want to import your insurance now, you will be able to import it in your account after you create your Tredder policy.',
                    'Click I UNDERSTAND to proceed without importing your primary insurance right now.',
                ],
                // confirmClasses: ['btn-primary text-white'],
                confirmationLabel: 'I UNDERSTAND',
                cancelLabel: 'Import now',
            })
            .pipe(take(1))
            .subscribe((response) => {
                if (response) {
                    this.quoteEnsFormService.setExistingCoveragePullStatus(
                        ExistingCoveragePullStatus.skippedForQuote
                    );
                    this.subscription.add(
                        this.quoteEnsRetrievalService
                            .updateQuoteForPurchase$({
                                generateDocs: true,
                                updateAddress: false,
                            })
                            .subscribe(() => {
                                this.continue();
                            })
                    );
                }
            });
    }
}
