<div class="container-xxl" *ngIf="activeEnsQuote">
    <div class="text-center">
        <div class="r-text-xxl text-primary mb-3">Document Acknowledgement</div>
        <div class="r-text-base mb-3">Please read all documents thoroughly and carefully</div>
    </div>
    <div class="container-md flex-grow-1">
        <div class="card br-75 w-100 h-100" *ngIf="quoteEnsDocuments">
            <div class="card-body px-5 py-4">
                <div class="d-flex justify-content-between align-items-center flex-column h-100">
                    <div class="d-flex justify-content-evenly align-items-center flex-wrap w-100 mt-md-5 mt-3">
                        <ng-container *ngFor="let quoteEnsDocument of quoteEnsDocuments | keyvalue; let i = index;"
                            ><div class="text-center mx-2 mb-5">
                                <i-bs class="me-2 d-inline-block mb-1" name="file-text" width="3em" height="3em"></i-bs>
                                <div class="r-text-base text-primary">{{quoteEnsDocument.value.displayName}}</div>
                                <div class="text-center"><a class="text-cyan-blue" [attr.data-cy]='"readDocumentLink-" + i' href="javascript:void(0);" (click)="read(quoteEnsDocument.value)">read</a>&nbsp; | &nbsp;<a class="text-cyan-blue" [attr.data-cy]='"downloadDocumentLink-" + i' href="javascript:void(0);" (click)="download(quoteEnsDocument.value)">download</a></div>
                            </div></ng-container
                        >
                    </div>
                    <div class="d-inline-block">
                        <div class="d-flex justify-content-start align-items-center mb-3">
                            <sbf-input-checkbox class="me-3" data-cy="documentAcknowledgeCheckbox" #documentAcknowledge idAppend="documentAcknowledge" [double]="true" [bgAlt]="true" [ngModel]="documentAcknowledged" (ngModelChange)="documentAcknowledgeChange($event)"></sbf-input-checkbox>
                            <div class="text-sm">I acknowledge that I have read and understand all the documents presented here.</div>
                        </div>
                        <div class="d-flex mb-3">
                            <div class="d-flex justify-content-start align-items-center">
                                <sbf-input-checkbox class="me-3" data-cy="eSignatureAcknowledgeCheckbox" #eSignatureAcknowledge idAppend="eSignatureAcknowledge" [double]="true" [bgAlt]="true" [ngModel]="eSignatureAcknowledged" (ngModelChange)="eSignatureAcknowledgeChange($event)"></sbf-input-checkbox>
                                <div class="text-sm">I am consenting to electronic signatures and signing the Tredder Policy Application.</div>
                            </div>
                        </div>
                    </div>
                    <a class="r-text-base text-baby-blue pointer" (click)="showElectronicSignaturePolicy()">View Electronic Signature Policy</a
                    ><button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-md-5 mb-3 mt-md-5 mt-3" data-cy="documentAcknowledgementButton" type="button" (click)="continue()" tabindex="0" [disabled]="!(documentAcknowledged &amp;&amp; eSignatureAcknowledged)">
                        <div class="d-flex align-items-center justify-content-center">Payment</div>
                        <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
                    </button>
                </div>
                <div class="d-flex justify-content-start"><sbf-back-button data-cy="documentsBackButton" (click)="back.emit()"></sbf-back-button></div>
            </div>
        </div>
    </div>
</div>
