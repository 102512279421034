import Big from 'big.js';

export enum QuoteEnsFormStep {
    none = 'none',
    intro = 'intro',
    pledge = 'pledge',
    rigSet = 'rig-set',
    gearConditional = 'gear-conditional',
    gearDetails = 'gear-details',
    homeBase = 'home-base',
    ensPersonalInfo = 'ens-personal-info',
    rates = 'rates',
    purchase = 'purchase',
    rc2 = 'rc2',
    decPull = 'dec-pull',
    documents = 'documents',
    payment = 'payment',
    paymentConfirmed = 'payment-confirmed',
    paymentError = 'payment-error',
    password = 'password',
}

export enum QuoteRigSetFormStep {
    rigName = 'rig-name',
    rigDetails = 'rig-details',
    coverageSelect = 'coverage-select',
    modsDetails = 'mods-details',
    camperDetails = 'camper-details',
    trailerDetails = 'trailer-details',
    rigReview = 'rig-review',
}

export interface CalculateQuoteEnsTotalResponse {
    quoteTotalBig: Big;
    quoteTotal: string;
    quoteTotalDollars: string;
    quoteTotalCents: string;
    quotePaymentsInitialBig: Big;
    quotePaymentsInitial: string;
    quotePaymentsInitialDollars: string;
    quotePaymentsInitialCents: string;
    quotePaymentsInstallmentBig: Big;
    quotePaymentsInstallment: string;
    quotePaymentsInstallmentDollars: string;
    quotePaymentsInstallmentCents: string;
    // valueCovered: Big;
}

export interface QuoteFlowFormMake {
    name: string;
    kind: VehicleKind;
}

export interface QuoteFlowFormModel {
    name: string;
    kind: VehicleKind;
}

// VehicleKind is for year, make, model filter
export enum VehicleKind {
    car = 'car',
    truck = 'truck',
    motorcycle = 'motorcycle',
    unknown = 'unknown',
}

export const rigDetailOtherOption = 'Other / Not Listed';
