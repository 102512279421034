import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { QuoteEnsRetrievalService } from '@modules/quote/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-purchase-coverage',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-purchase-coverage.component.html',
    styleUrls: ['quote-ens-purchase-coverage.component.scss'],
})
export class QuoteEnsPurchaseCoverageComponent implements OnInit, OnDestroy {
    activeEnsQuote!: EnsResponseWithEnsOnly | null;
    quoteEnsFlowFormValue!: QuoteEnsFlowFormValue;

    subscription: Subscription = new Subscription();

    constructor(
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuote$.subscribe((activeEnsQuote) => {
                if (!activeEnsQuote) {
                    return;
                }
                this.activeEnsQuote = activeEnsQuote;
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
