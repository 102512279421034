import { Pipe, PipeTransform } from '@angular/core';
import { ScreenSize } from '@common/models';
import { ResizeService } from '@common/services';
import { format, formatDistanceToNow, parseISO } from 'date-fns';

@Pipe({
    name: 'formatDistanceToNow',
})
export class FormatDistanceToNow implements PipeTransform {
    constructor() {}
    transform(value: string) {
        return formatDistanceToNow(new Date(value));
    }
}

@Pipe({
    name: 'publishedPostDateFormat',
})
export class PublishedPostDateFormat implements PipeTransform {
    constructor() {}
    transform(value: string | null) {
        if (!value) return;
        return format(new Date(value), `d LLL yyyy`);
    }
}

@Pipe({
    name: 'isoDateFormatVariable',
})
export class ISODateFormatVariable implements PipeTransform {
    constructor(private resizeService: ResizeService) {}
    transform(value: string | null, dropYearUnderSize?: ScreenSize) {
        if (!value) return;
        if (dropYearUnderSize && dropYearUnderSize === ScreenSize.xs) {
            return format(new Date(value), `MM/dd`);
        }
        return format(new Date(value), `MM/dd/yyyy`);
    }
}

@Pipe({
    name: 'isoDateFormat',
})
export class ISODateFormat implements PipeTransform {
    constructor() {}
    transform(value: string | null) {
        if (!value) return;
        return format(parseISO(value), `MM/dd/yyyy`);
    }
}

@Pipe({
    name: 'activePolicyRangeDateFormat',
})
export class ActivePolicyRangeDateFormat implements PipeTransform {
    constructor() {}
    transform(firstDate: string | null, secondDate: string | null) {
        if (!firstDate || !secondDate) return;
        return `${format(new Date(firstDate), 'MMM dd, yyyy')} - ${format(
            new Date(secondDate),
            'MMM dd, yyyy'
        )}`;
    }
}

@Pipe({
    name: 'shortMonthDayYearFormat',
})
export class ShortMonthDayYearFormat implements PipeTransform {
    constructor() {}
    transform(firstDate: string | null) {
        if (!firstDate) return;
        return `${format(new Date(firstDate), 'MMM dd, yyyy')}`;
    }
}

@Pipe({
    name: 'isoDateFormatUS',
})
export class ISODateFormatUS implements PipeTransform {
    constructor() {}
    transform(value: string | null) {
        if (!value) return;
        return format(parseISO(value), `MM/dd/yyyy`);
    }
}

@Pipe({
    name: 'isoDateFormatWithTime',
})
export class ISODateFormatWithTime implements PipeTransform {
    constructor() {}
    transform(value: string | null) {
        if (!value) return;
        return format(parseISO(value), `MM/dd/yyyy HH:mm bbbb`);
    }
}

@Pipe({
    name: 'isoDateFormatWithTimeZone',
})
export class ISODateFormatWithTimeZone implements PipeTransform {
    constructor() {}
    transform(value: string | null) {
        if (!value) return 'na';
        return format(parseISO(value), `MM/dd/yyyy HH:mm X`);
    }
}

@Pipe({
    name: 'isoDateSpelledWithTimeZone',
})
export class ISODateSpelledWithTimeZone implements PipeTransform {
    constructor() {}
    transform(value: string | null) {
        if (!value) return 'na';
        return format(parseISO(value), `MMM dd, yyyy HH:mm X`);
    }
}
