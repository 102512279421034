import { Injectable, Injector, StaticProvider } from '@angular/core';
import { EarlyAccessModalComponent } from '@common/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { provideNgxMask } from 'ngx-mask';

import { UtilityService } from './utility.service';

@Injectable()
export class EarlyAccessService {
    constructor(
        private injector: Injector,
        private ngbModalService: NgbModal,
        private utilityService: UtilityService
    ) {}

    showEarlyAccess(onLoad: boolean = false) {
        const alreadySignedUp = this.utilityService.localStorage.getItem('tredder-early-access');
        if (onLoad && alreadySignedUp) {
            return;
        }
        this.ngbModalService.open(EarlyAccessModalComponent, {
            size: 'xl',
            injector: Injector.create({
                providers: [provideNgxMask() as unknown as StaticProvider],
                parent: this.injector,
            }),
        });
    }
}
