<div class="modal-header modal-header-topographic">
    <h5 class="modal-title text-white" data-cy="coverageChangeSummaryTitle">Coverage Change Summary</h5>
    <button class="btn-close text-white" data-cy="coverageChangeSummaryCloseIcon" type="button" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-4 text-center"><div class="r-text-lg text-primary"></div></div>
        <div class="col-4 text-center"><div class="r-text-lg text-primary heavy">Before</div></div>
        <div class="col-4 text-center"><div class="r-text-lg text-primary heavy">After</div></div>
    </div>
    <hr class="my-2" />
    <div class="row">
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column"><div class="text-xs">- &nbsp; PAYMENTS &nbsp; -</div></div>
        </div>
    </div>
    <hr class="my-2" />
    <div class="row">
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <div class="r-text-base text-primary">Paid in Full</div>
                <div class="text-xs text-cyan-blue">6 months</div>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center"><div class="r-text-base text-primary">{{foundTotalPaymentPlanPolicyQuoteEns.debit | currency : 'USD' : 'symbol' : '1.2'}}</div></div>
        <div class="col-4 d-flex justify-content-center align-items-center"><div class="r-text-base text-primary">{{foundTotalPaymentPlanNewQuote.debit | currency : 'USD' : 'symbol' : '1.2'}}</div></div>
    </div>
    <hr class="my-1" />
    <div class="row">
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <div class="r-text-base text-primary">Paid in Full</div>
                <div class="text-xs text-cyan-blue">prorated</div>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center"><div class="r-text-base text-primary">----</div></div>
        <div class="col-4 d-flex justify-content-center align-items-center" *ngIf="foundTotalPaymentPlanNewQuoteEndorsement.debit"><div class="r-text-base text-cyan-blue">{{foundTotalPaymentPlanNewQuoteEndorsement.debit | currency : 'USD' : 'symbol' : '1.2'}}</div></div>
        <div class="col-4 d-flex justify-content-center align-items-center" *ngIf="foundTotalPaymentPlanNewQuoteEndorsement.credit">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <div class="r-text-base text-success" data-cy="endorsementSummaryModalRefundAmount">{{foundTotalPaymentPlanNewQuoteEndorsement.credit | currency : 'USD' : 'symbol' : '1.2'}}</div>
                <div class="text-xs text-success">REFUND</div>
            </div>
        </div>
    </div>
    <hr class="my-1" />
    <div class="row">
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <div class="r-text-base text-primary">Paid monthly</div>
                <div class="text-xs text-cyan-blue">6 months</div>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <div class="r-text-base text-primary">{{policyQuoteEnsSecondPayment.debit | currency : 'USD' : 'symbol' : '1.2'}}</div>
                <div class="text-xs">
                    <div class="d-inline text-primary me-1">Initial</div>
                    <div class="d-inline text-primary">{{policyQuoteEnsFirstPayment.debit | currency : 'USD' : 'symbol' : '1.2'}}</div>
                </div>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <div class="r-text-base text-primary">{{newQuoteSecondPayment.debit | currency : 'USD' : 'symbol' : '1.2'}}</div>
                <div class="text-xs">
                    <div class="d-inline text-primary me-1">Initial</div>
                    <div class="d-inline text-primary">{{newQuoteFirstPayment.debit | currency : 'USD' : 'symbol' : '1.2'}}</div>
                </div>
            </div>
        </div>
    </div>
    <hr class="my-1" />
    <div class="row">
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <div class="r-text-base text-primary">Paid monthly</div>
                <div class="text-xs text-cyan-blue">prorated</div>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center"><div class="r-text-base text-primary">----</div></div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="!foundTwentySixteenPaymentPlanNewQuoteEndorsement.doNotUse">
                <div class="r-text-base text-cyan-blue">{{(newQuoteEndorsmentSecondPayment?.debit || 0) | currency : 'USD' : 'symbol' : '1.2'}}</div>
                <div class="text-xs">
                    <div class="d-inline text-cyan-blue me-1">Initial</div>
                    <div class="d-inline text-cyan-blue">{{newQuoteEndorsmentFirstPayment.debit | currency : 'USD' : 'symbol' : '1.2'}}</div>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="foundTwentySixteenPaymentPlanNewQuoteEndorsement.doNotUse"><div class="r-text-base text-primary">----</div></div>
        </div>
    </div>
    <hr class="my-1" />
    <div class="row my-2">
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column"><div class="text-xs">- &nbsp; COVERAGE &nbsp; -</div></div>
        </div>
    </div>
    <hr class="my-2" />
    <ng-container *ngFor="let rig of maxRigsArray; let i = index;"
        ><div class="row">
            <div class="col-4 d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-between align-items-center flex-column">
                    <span
                        ><div class="d-inline r-text-base text-primary me-1">Mods</div>
                        <div class="d-inline text-xs text-cyan-blue">Rig {{i + 1}}</div></span
                    >
                    <div class="text-xs text-primary">deductible</div>
                </div>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="policyQuoteEns.coverageBreakdown.rigs[i]?.modsCollision else coverageDeclined">
                    <div class="r-text-base text-primary">{{policyQuoteEns.coverageBreakdown.rigs[i]?.modsCollision?.limit | coverageBreakdown}}</div>
                    <div class="text-xs"><div class="d-inline text-primary">{{policyQuoteEns.coverageBreakdown.rigs[i]?.modsCollision?.deductible | coverageBreakdown}}</div></div>
                </div>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="newQuoteEnsOnly.coverageBreakdown.rigs[i]?.modsCollision else coverageDeclined">
                    <div class="r-text-base text-primary">{{newQuoteEnsOnly.coverageBreakdown.rigs[i]?.modsCollision?.limit | coverageBreakdown}}</div>
                    <div class="text-xs"><div class="d-inline text-primary">{{newQuoteEnsOnly.coverageBreakdown.rigs[i]?.modsCollision?.deductible | coverageBreakdown}}</div></div>
                </div>
            </div>
        </div>
        <hr class="my-1" />
        <div class="row">
            <div class="col-4 d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-between align-items-center flex-column">
                    <span
                        ><div class="d-inline r-text-base text-primary me-1">Camper</div>
                        <div class="d-inline text-xs text-cyan-blue">Rig {{i + 1}}</div></span
                    >
                    <div class="text-xs text-primary">deductible comp</div>
                    <div class="text-xs text-primary">deductible coll</div>
                </div>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="policyQuoteEns.coverageBreakdown.rigs[i]?.camperComprehensive else coverageDeclined">
                    <div class="r-text-base text-primary">{{policyQuoteEns.coverageBreakdown.rigs[i]?.camperComprehensive?.limit | coverageBreakdown}}</div>
                    <div class="text-xs"><div class="d-inline text-primary">{{policyQuoteEns.coverageBreakdown.rigs[i]?.camperComprehensive?.deductible | coverageBreakdown}}</div></div>
                    <div class="text-xs"><div class="d-inline text-primary">{{policyQuoteEns.coverageBreakdown.rigs[i]?.camperCollision?.deductible | coverageBreakdown}}</div></div>
                </div>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="newQuoteEnsOnly.coverageBreakdown.rigs[i]?.camperComprehensive else coverageDeclined">
                    <div class="r-text-base text-primary">{{newQuoteEnsOnly.coverageBreakdown.rigs[i]?.camperComprehensive?.limit | coverageBreakdown}}</div>
                    <div class="text-xs"><div class="d-inline text-primary">{{newQuoteEnsOnly.coverageBreakdown.rigs[i]?.camperComprehensive?.deductible | coverageBreakdown}}</div></div>
                    <div class="text-xs"><div class="d-inline text-primary">{{newQuoteEnsOnly.coverageBreakdown.rigs[i]?.camperCollision?.deductible | coverageBreakdown}}</div></div>
                </div>
            </div>
        </div>
        <hr class="my-1" />
        <div class="row">
            <div class="col-4 d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-between align-items-center flex-column">
                    <span
                        ><div class="d-inline r-text-base text-primary me-1">Trailer</div>
                        <div class="d-inline text-xs text-cyan-blue">Rig {{i + 1}}</div></span
                    >
                    <div class="text-xs text-primary">deductible comp</div>
                    <div class="text-xs text-primary">deductible coll</div>
                </div>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="policyQuoteEns.coverageBreakdown.rigs[i]?.trailerComprehensive else coverageDeclined">
                    <div class="r-text-base text-primary">{{policyQuoteEns.coverageBreakdown.rigs[i]?.trailerComprehensive?.limit | coverageBreakdown}}</div>
                    <div class="text-xs"><div class="d-inline text-primary">{{policyQuoteEns.coverageBreakdown.rigs[i]?.trailerComprehensive?.deductible | coverageBreakdown}}</div></div>
                    <div class="text-xs"><div class="d-inline text-primary">{{policyQuoteEns.coverageBreakdown.rigs[i]?.trailerCollision?.deductible | coverageBreakdown}}</div></div>
                </div>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="newQuoteEnsOnly.coverageBreakdown.rigs[i]?.trailerComprehensive else coverageDeclined">
                    <div class="r-text-base text-primary">{{newQuoteEnsOnly.coverageBreakdown.rigs[i]?.trailerComprehensive?.limit | coverageBreakdown}}</div>
                    <div class="text-xs"><div class="d-inline text-primary">{{newQuoteEnsOnly.coverageBreakdown.rigs[i]?.trailerComprehensive?.deductible | coverageBreakdown}}</div></div>
                    <div class="text-xs"><div class="d-inline text-primary">{{newQuoteEnsOnly.coverageBreakdown.rigs[i]?.trailerCollision?.deductible | coverageBreakdown}}</div></div>
                </div>
            </div>
            <hr class="my-1" /></div
    ></ng-container>
    <div class="row">
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <span><div class="d-inline r-text-base text-primary me-1">Gear</div></span>
                <div class="text-xs text-primary">deductible</div>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="policyQuoteEns.coverageBreakdown.gearEnsActualCashValue else coverageDeclined">
                <div class="r-text-base text-primary">{{policyQuoteEns.coverageBreakdown.gearEnsActualCashValue?.limit | coverageBreakdown}}</div>
                <div class="text-xs"><div class="d-inline text-primary">{{policyQuoteEns.coverageBreakdown.gearEnsActualCashValue?.deductible | coverageBreakdown}}</div></div>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="newQuoteEnsOnly.coverageBreakdown.gearEnsActualCashValue else coverageDeclined">
                <div class="r-text-base text-primary">{{newQuoteEnsOnly.coverageBreakdown.gearEnsActualCashValue?.limit | coverageBreakdown}}</div>
                <div class="text-xs"><div class="d-inline text-primary">{{newQuoteEnsOnly.coverageBreakdown.gearEnsActualCashValue?.deductible | coverageBreakdown}}</div></div>
            </div>
        </div>
    </div>
    <hr class="my-1" />
    <div class="row">
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <span><div class="d-inline r-text-base text-primary me-1">Off Road</div></span>
                <div class="text-xs text-primary">deductible</div>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="policyQuoteEns.coverageBreakdown.offRoadRecovery else coverageDeclined">
                <div class="r-text-base text-primary">{{policyQuoteEns.coverageBreakdown.offRoadRecovery?.limit | coverageBreakdown}}</div>
                <div class="text-xs"><div class="d-inline text-primary">{{policyQuoteEns.coverageBreakdown.offRoadRecovery?.deductible | coverageBreakdown}}</div></div>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="newQuoteEnsOnly.coverageBreakdown.offRoadRecovery else coverageDeclined">
                <div class="r-text-base text-primary">{{newQuoteEnsOnly.coverageBreakdown.offRoadRecovery?.limit | coverageBreakdown}}</div>
                <div class="text-xs"><div class="d-inline text-primary">{{newQuoteEnsOnly.coverageBreakdown.offRoadRecovery?.deductible | coverageBreakdown}}</div></div>
            </div>
        </div>
    </div>
    <hr class="my-1" />
    <div class="row">
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <span><div class="d-inline r-text-base text-primary me-1">Emergency</div></span>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="policyQuoteEns.coverageBreakdown.emergencyExpense else coverageDeclined"><div class="r-text-base text-primary">{{policyQuoteEns.coverageBreakdown.emergencyExpense?.limit | coverageBreakdown}}</div></div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="newQuoteEnsOnly.coverageBreakdown.emergencyExpense else coverageDeclined"><div class="r-text-base text-primary">{{newQuoteEnsOnly.coverageBreakdown.emergencyExpense?.limit | coverageBreakdown}}</div></div>
        </div>
    </div>
    <hr class="my-1" />
    <div class="row">
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column">
                <span><div class="d-inline r-text-base text-primary me-1">Tow/Roadside</div></span>
            </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="policyQuoteEns.coverageBreakdown.towRoadside else coverageDeclined"><div class="r-text-base text-primary">{{policyQuoteEns.coverageBreakdown.towRoadside?.limit | coverageBreakdown}}</div></div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center flex-column" *ngIf="newQuoteEnsOnly.coverageBreakdown.towRoadside else coverageDeclined"><div class="r-text-base text-primary">{{newQuoteEnsOnly.coverageBreakdown.towRoadside?.limit | coverageBreakdown}}</div></div>
        </div>
    </div>
</div>
<div class="modal-footer"><button class="btn rounded-pill btn-secondary-gradient text-white fw-500 me-2" data-cy="coverageChangeSummaryCancelButton" type="button" (click)="cancel()">Cancel</button><button class="btn rounded-pill btn-primary-gradient text-white fw-500" data-cy="coverageChangeSummaryContinueButton" type="button" (click)="continue()">Continue</button></div>
<ng-template #coverageDeclined
    ><div class="d-flex justify-content-between align-items-center flex-column"><div class="r-text-base text-secondary">DECLINED</div></div></ng-template
>
