import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UserForTokenTyped } from '@backend-types/authorization';
import { UserForAdminAuth } from '@backend-types/user';

@Component({
    selector: 'sbf-profile-pic',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './profile-pic.component.html',
    styleUrls: ['profile-pic.component.scss'],
})
export class ProfilePicComponent implements OnInit {
    @Input() user!: UserForAdminAuth | UserForTokenTyped;
    @Input() imgClasses = '';
    constructor() {}
    ngOnInit() {}
}
