import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { update } from 'jdenticon';

@Directive({ selector: '[sbfJdenticonHash]' })
export class JdenticonHashDirective implements OnChanges {
    @Input() sbfJdenticonHash!: string;

    constructor(private el: ElementRef) {}

    ngOnChanges() {
        update(this.el.nativeElement, this.sbfJdenticonHash);
    }
}
