import { Injectable } from '@angular/core';
import { inputIsNotNullOrUndefined } from '@common/helpers';
import { ScreenSize } from '@common/models';
import { BehaviorSubject, distinctUntilChanged, filter, Observable } from 'rxjs';

@Injectable()
export class ResizeService {
    private _screenSize$ = new BehaviorSubject<ScreenSize | null>(null);

    constructor() {}

    onResize(size: ScreenSize) {
        this._screenSize$.next(size);
    }

    get screenSize$(): Observable<ScreenSize> {
        return this._screenSize$
            .asObservable()
            .pipe(filter(inputIsNotNullOrUndefined), distinctUntilChanged());
    }

    get screenSize(): ScreenSize | null {
        return this._screenSize$.value;
    }
}
