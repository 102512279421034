/* eslint-disable simple-import-sort/imports */
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Modules */
import {
    NgbAlertModule,
    NgbCarouselModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '@modules/icons/icons.module';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; // <-- Import PdfJsViewerModule module
import { AutosizeModule } from 'ngx-autosize';

/* Containers */
import * as appCommonContainers from './containers';

/* Components */
import { components } from './components';

/* Directives */
import * as appCommonDirectives from './directives';

/* Pipes */
import * as appCommonPipes from './pipes';

export const ngbModules = [
    NgbAlertModule,
    NgbCarouselModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbPopoverModule,
];

const ngxMaskModules = [NgxMaskDirective, NgxMaskPipe];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgOptimizedImage,
        FormsModule,
        ReactiveFormsModule,
        IconsModule,
        PdfJsViewerModule,
        ...ngbModules,
        ...ngxMaskModules,
        AutosizeModule,
    ],
    declarations: [
        ...appCommonContainers.containers,
        ...components,
        ...appCommonDirectives.directives,
        ...appCommonPipes.pipes,
    ],
    exports: [
        ...appCommonContainers.containers,
        ...components,
        ...appCommonDirectives.directives,
        ...appCommonPipes.pipes,
        FormsModule,
        ReactiveFormsModule,
        IconsModule,
        ...ngbModules,
        ...ngxMaskModules,
    ],
    providers: [provideNgxMask(), ...appCommonPipes.pipes],
})
export class AppCommonModule {}
