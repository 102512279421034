import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { PaymentPlanName } from '@backend-types/payment-plan';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { AnalyticsService, HotKeysService } from '@common/services';
import { AgencyAdminAccount } from '@modules/manage-common/models';
import { QuoteEnsFormService, QuoteEnsRetrievalService } from '@modules/quote/services';
import { filter, Subscription, take } from 'rxjs';

enum DocumentDeliveryKind {
    electronic = 'electronic',
    physical = 'physical',
}

@Component({
    selector: 'sbf-quote-ens-purchase',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-purchase.component.html',
    styleUrls: ['quote-ens-purchase.component.scss'],
})
export class QuoteEnsPurchaseComponent implements OnInit, OnDestroy {
    @Input() endorsement = false;
    @Input() agencyAdminAccount = AgencyAdminAccount.account;

    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    activeEnsQuote!: EnsResponseWithEnsOnly | null;
    effectiveDate!: string;

    quoteEnsFlowFormValue!: QuoteEnsFlowFormValue;

    ePaymentPlanName = PaymentPlanName;
    autoBillingOptIn = new FormControl<boolean | null>(true);

    subscription: Subscription = new Subscription();

    documentDeliveryKindText: EnumMap<DocumentDeliveryKind> = {
        electronic: 'Sent by Email (paperless)',
        physical: 'Sent by US Mail',
    };

    documentDeliveryForm: ModelFormGroup<{ documentDelivery: DocumentDeliveryKind }> =
        this.fb.group({
            documentDelivery: new FormControl<DocumentDeliveryKind | null>(null, [
                Validators.required,
            ]),
        });

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private quoteEnsFormService: QuoteEnsFormService,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Purchase');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_purchase',
            label: this.agencyAdminAccount,
            category: this.endorsement ? 'endorsement' : undefined,
        });
    }

    ngOnInit() {
        // this.subscription.add(
        //     this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
        //         this.next.emit();
        //     })
        // );
        // this.subscription.add(
        //     this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
        //         this.back.emit();
        //     })
        // );
        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowFormValue$.subscribe((quoteEnsFlowFormValue) => {
                this._setAutoBillOptInControl(quoteEnsFlowFormValue);
                this._setDocumentDeliveryControl(quoteEnsFlowFormValue);
            })
        );

        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuote$
                .pipe(take(1)) // INFO: take(1) so that we don't hit timeout issues when updating quote from purchase-x components
                .subscribe((activeEnsQuote) => {
                    if (!activeEnsQuote) {
                        return;
                    }
                    this.effectiveDate = activeEnsQuote.ensOnly.paymentPlanSources[0].effectiveDate;
                    this.activeEnsQuote = activeEnsQuote;
                    this.changeDetectorRef.detectChanges();
                })
        );

        this.subscription.add(
            this.documentDeliveryControl.valueChanges.subscribe((documentDelivery) => {
                this.quoteEnsFormService.setElectronicDocuments(
                    documentDelivery === DocumentDeliveryKind.electronic
                );
            })
        );
        this.subscription.add(
            this.autoBillingOptIn.valueChanges
                .pipe(filter((value) => value !== null))
                .subscribe((value) => {
                    this.quoteEnsFormService.setAutoBillOptIn(value as boolean);
                })
        );
    }

    private _setAutoBillOptInControl(
        quoteEnsFlowFormValue: Partial<ModelFormValue<QuoteEnsFlowFormValue>>
    ) {
        if (quoteEnsFlowFormValue.autoBillingOptIn === undefined) {
            throw new Error('QUOTE_ENS_FLOW_FORM_VALUE_SHOULD_EXIST');
        }

        if (quoteEnsFlowFormValue.autoBillingOptIn === this.autoBillingOptIn.value) {
            return;
        }

        this.autoBillingOptIn.setValue(quoteEnsFlowFormValue.autoBillingOptIn, {
            onlySelf: true,
            emitEvent: false,
        });
        this.changeDetectorRef.detectChanges();
    }
    private _setDocumentDeliveryControl(
        quoteEnsFlowFormValue: Partial<ModelFormValue<QuoteEnsFlowFormValue>>
    ) {
        if (
            quoteEnsFlowFormValue.electronicDocuments &&
            this.documentDeliveryControl.value === DocumentDeliveryKind.electronic
        ) {
            return;
        }
        if (
            !quoteEnsFlowFormValue.electronicDocuments &&
            this.documentDeliveryControl.value === DocumentDeliveryKind.physical
        ) {
            return;
        }
        this.documentDeliveryControl.setValue(
            quoteEnsFlowFormValue.electronicDocuments
                ? DocumentDeliveryKind.electronic
                : DocumentDeliveryKind.physical
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /* Accessor Methods */

    get documentDeliveryControl() {
        return this.documentDeliveryForm.get('documentDelivery') as FormControl;
    }

    get documentDeliveryControlValid() {
        return (
            this.documentDeliveryControl.value && !this.documentDeliveryControl.hasError('required')
        );
    }
}
