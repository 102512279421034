import { EnvConfig, logLevelValues } from '@common/models';

// NOTE: These are all public key values. DO NOT put private key values here.
export const localEnvConfig: EnvConfig = {
    facebookClientID: '673817013642861',
    githubClientID: 'd617d931ae5216aa095a',
    googleClientID: '702982947710-3g3rkq2rsltinejhljide464emj7eevm.apps.googleusercontent.com',
    recaptchaSiteKey: '6Lf1HvshAAAAAMND4IhGy1msGq3xkOuU8eNIdjar',
    stripePublishableKey:
        'pk_test_51NiMDEHu8ViMSJQxBceqLRMB8F3SIn38s9NfnKkzR3svwW6wQgvXtaCnWTeM1ImcwDNMLIZyj220sUNysLTx2FHD00a5q5S9hD',
    googlePlacesAPIKey: 'AIzaSyBcOPlFoM5T3uz9yuWwseJbQfAqOYaLuJw',
    // backendURL: 'https://192.168.86.31:8765',
    // backendWSURL: 'ws://192.168.86.31:8765',
    // frontendURL: 'https://192.168.86.31:4567',
    backendURL: 'http://localhost:8765',
    backendWSURL: 'ws://localhost:8765',
    frontendURL: 'https://localhost:4567',
    logLevel: logLevelValues['debug'],
    canopyConnectAlias: 'tredder10',
};
