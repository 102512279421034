import { ChangeDetectionStrategy, Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'sbf-optimized-image',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './optimized-image.component.html',
    styleUrls: ['optimized-image.component.scss'],
})
export class OptimizedImageComponent implements OnInit {
    @Input() webp?: string;
    @Input() jpeg?: string;
    @Input() png?: string;
    @Input() priority?: boolean;
    @Input() imgClasses?: string;

    webpSanitized!: SafeResourceUrl;
    jpegSanitized!: SafeResourceUrl;
    pngSanitized!: SafeResourceUrl;

    @Input() alt!: string;
    @Input() width!: string;
    @Input() height!: string;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        if (this.webp) {
            this.sanitizer.bypassSecurityTrustResourceUrl(
                this.sanitizer.sanitize(SecurityContext.NONE, this.webp)!
            );
        }
        if (this.jpeg) {
            this.sanitizer.bypassSecurityTrustResourceUrl(
                this.sanitizer.sanitize(SecurityContext.NONE, this.jpeg)!
            );
        }
        if (this.png) {
            this.sanitizer.bypassSecurityTrustResourceUrl(
                this.sanitizer.sanitize(SecurityContext.NONE, this.png)!
            );
        }
    }
}
