import { Pipe, PipeTransform } from '@angular/core';
import { camelCase, capitalCase, kebabCase, sentenceCase } from 'change-case';

@Pipe({
    name: 'humanReadable',
})
export class HumanReadable implements PipeTransform {
    constructor() {}
    transform(someString: string) {
        return capitalCase(kebabCase(someString));
    }
}

@Pipe({
    name: 'camelCase',
})
export class CamelCase implements PipeTransform {
    constructor() {}
    transform(someString: string) {
        return camelCase(someString);
    }
}

@Pipe({
    name: 'sentenceCase',
})
export class SentenceCase implements PipeTransform {
    constructor() {}
    transform(someString: string) {
        return sentenceCase(someString);
    }
}

@Pipe({
    name: 'capitalCase',
})
export class CapitalCase implements PipeTransform {
    constructor() {}
    transform(someString: string) {
        return capitalCase(someString);
    }
}
