import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbf-electronic-signature-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './electronic-signature-modal.component.html',
    styleUrls: ['electronic-signature-modal.component.scss'],
})
export class ElectronicSignatureModalComponent implements OnInit {
    constructor(private activeModal: NgbActiveModal) {}
    ngOnInit() {}
    close() {
        this.activeModal.close();
    }
}
