import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnalyticsService, HotKeysService } from '@common/services';
import { QuoteEnsFormStep } from '@modules/quote/models';
import { QuoteEnsFormService } from '@modules/quote/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-quote-gear-conditional',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-gear-conditional.component.html',
    styleUrls: ['quote-gear-conditional.component.scss'],
})
export class QuoteGearConditionalComponent implements OnInit, OnDestroy {
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();
    @Output() goto = new EventEmitter<QuoteEnsFormStep>();

    rigName!: string;

    subscription: Subscription = new Subscription();

    constructor(
        private hotKeysService: HotKeysService,
        private quoteEnsFormService: QuoteEnsFormService,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Gear Conditional');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_gear_conditional',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowFormValue$.subscribe((quoteEnsFlowFormValue) => {
                if (!quoteEnsFlowFormValue.rigSets) {
                    throw new Error('AT_LEAST_1_RIG_SET_SHOULD_EXIST');
                }
                this.rigName = quoteEnsFlowFormValue.rigSets[0].rigName;
            })
        );
    }

    noGear() {
        this.quoteEnsFormService.noGear();
        this.goto.emit(QuoteEnsFormStep.homeBase);
    }

    addGear() {
        this.quoteEnsFormService.addGear();
        this.next.emit();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
