<div class="d-flex justify-content-between align-items-stretch flex-column flex-grow-1" *ngIf="conversation">
    <div class="message-area br-75 mb-3 p-1 flex-grow-1">
        <div class="message-area-scrollable p-2" #messageAreaScrollable>
            <div class="message d-flex align-items-center" *ngFor="let message of conversation.messages; let i = index;" [ngClass]='{"auth-user": message.authorId === activeAuthUser.id}'>
                <div class="message-date-wrapper d-flex flex-column" [ngClass]='{"auth-user": message.authorId === activeAuthUser.id}'>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="text-xs me-2 heavy" *ngIf="message.authorId !== activeAuthUser.id">{{message.author.firstName}} {{message.author.lastName}}</div>
                        <div class="text-xs me-2">{{message.createdAt | date : 'M/d/yy, h:mm a' }}</div>
                    </div>
                    <div class="message-contents px-2 py-1 br-75 mb-2" [ngClass]='{"auth-user": message.authorId === activeAuthUser.id}'>{{message.body}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-between align-items-center"><textarea class="lh-base w-100 flex-grow-1 me-2 br-75 px-3 py-2 me-2" autocomplete="off" autosize placeholder="Type message here..." rows="1" [(ngModel)]="inputText"></textarea><button class="btn rounded-pill btn-primary-gradient text-white fw-500" data-cy="sendMessageButton" type="submit" (click)="sendMessage()" [disabled]="!inputText">Send</button></div>
    <div class="text-danger text-xs ms-2 mt-1" *ngIf="inputText.length &gt; maxMessageLength">Message too long by {{inputText.length - maxMessageLength}}</div>
</div>
