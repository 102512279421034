import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenResponse, UserForTokenTyped } from '@backend-types/authorization';
import { FacebookAuthPayload } from '@backend-types/social';
import { AnalyticsService, AuthUtilsService, EnvService, UtilityService } from '@common/services';
import { FacebookOAuthReturnedParamters } from '@modules/auth/models';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Injectable({
    providedIn: 'root',
})
export class SocialFacebookService {
    constructor(
        private http: HttpClient,
        private utilityService: UtilityService,
        private router: Router,
        private authUtilsService: AuthUtilsService,
        private analyticsService: AnalyticsService,
        private envService: EnvService
    ) {}

    facebookOAuthInit(fromAccount = false): void {
        if (fromAccount) {
            this.utilityService.localStorage.setItem(`authOrigination`, 'account');
        }
        const state = uuid();
        const origin = this.utilityService.window.document.location.origin;
        const redirectURI = `${origin}/auth/social?social=facebook`;

        this.utilityService.localStorage.setItem(`facebook-oauth-state`, state);
        this.utilityService.window.location.href =
            `https://www.facebook.com/v7.0/dialog/oauth?` +
            `scope=email&` +
            `response_type=code&` +
            `state=${state}&` +
            `redirect_uri=${encodeURIComponent(redirectURI)}&` +
            `client_id=${this.envService.config.facebookClientID}`;
    }

    facebookOAuthReturn(params: FacebookOAuthReturnedParamters) {
        const storedState = this.utilityService.localStorage.getItem(`facebook-oauth-state`);
        this.utilityService.localStorage.removeItem(`facebook-oauth-state`);
        const authOrigination = this.utilityService.localStorage.getItem(`authOrigination`);
        this.utilityService.localStorage.removeItem(`authOrigination`);

        if (params.state !== storedState) {
            return this.router.navigate(['/error/500']);
        }

        const postBody: FacebookAuthPayload = {
            code: params.code,
            state: params.state,
        };

        this.http
            .post<TokenResponse>(
                `${this.envService.config.backendURL}/api/latest/social/facebook-auth`,
                postBody
            )
            .pipe(
                switchMap(
                    (loginResults): Observable<UserForTokenTyped> =>
                        this.authUtilsService.processToken$(loginResults.token)
                ),
                switchMap((user) => {
                    this.analyticsService.sendEventLogin('facebook');
                    if (authOrigination === 'account') {
                        return this.authUtilsService.navigateToAccountAuth();
                    }
                    return this.authUtilsService.navigateAfterAuth(user, '/auth/social');
                }),
                catchError((error: Error) => throwError(() => error))
            )
            .subscribe();
    }
}
