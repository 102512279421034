import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VehiclesForYear } from '@common/models/vehicle-data';
import { EnvService } from '@common/services';
import { validYears } from '@data/vehicle-years.data';
import {
    QuoteFlowFormMake,
    QuoteFlowFormModel,
    rigDetailOtherOption,
    VehicleKind,
} from '@modules/quote/models';
import { DecodedVIN, DecodeVINResponse } from '@modules/site/models';
import { map, merge, Observable, of, OperatorFunction, ReplaySubject, Subject, take } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class VehicleInfoService {
    private _vehiclesForYear$ = new ReplaySubject<VehiclesForYear>(1);
    private _possibleMakes$ = new ReplaySubject<QuoteFlowFormMake[]>(1);
    private _possibleModels$ = new ReplaySubject<QuoteFlowFormModel[]>(1);
    private _possibleTrims$ = new ReplaySubject<string[]>(1);

    validYears = validYears;

    constructor(
        private http: HttpClient,
        private envService: EnvService
    ) {
        this._possibleMakes$.next([]);
        this._possibleModels$.next([]);
        this._possibleTrims$.next([]);
    }

    get possibleMakes$(): Observable<QuoteFlowFormMake[]> {
        return this._possibleMakes$.asObservable();
    }

    get possibleModels$(): Observable<QuoteFlowFormModel[]> {
        return this._possibleModels$.asObservable();
    }

    get possibleTrims$(): Observable<string[]> {
        return this._possibleTrims$.asObservable();
    }
    get vehiclesForYear$(): Observable<VehiclesForYear> {
        return this._vehiclesForYear$;
    }

    loadVehicleData$(year: number) {
        const result = new Subject<VehiclesForYear>();
        this.http
            .get<VehiclesForYear>(`/assets/data/vehicles/car-api/${year}.json`)
            .pipe(
                catchError((err) => {
                    return of({
                        makes: [],
                        models: {},
                    });
                })
            )
            .subscribe((response: VehiclesForYear) => {
                this._vehiclesForYear$.next(response);
                this._possibleMakes$.next(
                    response.makes.map((make) => ({
                        name: make,
                        kind: VehicleKind.unknown,
                    }))
                );
                result.next(response);
            });

        return result.asObservable();
    }

    loadPossibles(make?: string, model?: string) {
        if (make && make !== rigDetailOtherOption) {
            this.vehiclesForYear$.pipe(take(1)).subscribe((data) => {
                const foundMake = data.models[make];

                if (foundMake) {
                    this._possibleModels$.next([
                        ...foundMake?.allModels.map((m) => ({
                            name: m,
                            kind: VehicleKind.unknown,
                        })),
                        { name: rigDetailOtherOption, kind: VehicleKind.unknown },
                    ]);

                    if (model && model !== rigDetailOtherOption) {
                        const foundModelTrims = foundMake.trims[model];
                        if (foundModelTrims) {
                            this._possibleTrims$.next([...foundModelTrims, rigDetailOtherOption]);
                        } else {
                            this._possibleTrims$.next([]);
                        }
                    }
                } else {
                    this._possibleModels$.next([
                        { name: rigDetailOtherOption, kind: VehicleKind.unknown },
                    ]);
                    this._possibleTrims$.next([rigDetailOtherOption]);
                }
            });
        } else {
            this._possibleModels$.next([{ name: rigDetailOtherOption, kind: VehicleKind.unknown }]);
            this._possibleTrims$.next([rigDetailOtherOption]);
        }
    }

    // Sample VIN: 5YJ3E1EA0JF177852
    // 5YJ3E1EA0JF
    // ZDM1TBBS05B003437
    decodeVIN$(vin: string): Observable<DecodedVIN> {
        return this.http
            .get<DecodeVINResponse>(
                `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`
            )
            .pipe(
                map((response) => ({
                    year: parseInt(response.Results[0].ModelYear, 10),
                    make: response.Results[0].Make,
                    model: response.Results[0].Model,
                    trim: response.Results[0].Trim,
                    errorCodes:
                        response.Results[0].ErrorCode === '0'
                            ? []
                            : response.Results[0].ErrorCode.split(','),
                }))
            );
    }

    searchYears(): OperatorFunction<string, readonly string[]> {
        return (value$: Observable<string>) => {
            return value$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                map((term) =>
                    term.length < 2
                        ? []
                        : validYears
                              .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
                              .slice(0, 10)
                )
            );
        };
    }

    searchMakes(makeFocus: Subject<string>): OperatorFunction<string, readonly string[]> {
        return (value$: Observable<string>) => {
            const debouncedText = value$.pipe(debounceTime(300), distinctUntilChanged());

            return merge(makeFocus, debouncedText).pipe(
                withLatestFrom(this.possibleMakes$),
                map(([value, makes]) => {
                    return [
                        ...makes.reduce((makeSet, current) => {
                            if (!value) {
                                makeSet.add(current.name);
                            } else {
                                if (current.name === rigDetailOtherOption) {
                                    makeSet.add(current.name);
                                }
                                const matchValue = value.toLowerCase();
                                const currentMake = current.name.toLowerCase();

                                if (currentMake.indexOf(matchValue) !== 0) {
                                    return makeSet;
                                }

                                makeSet.add(current.name);
                            }

                            return makeSet;
                        }, new Set() as Set<string>),
                        rigDetailOtherOption,
                    ];
                })
            );
        };
    }

    searchModels(modelFocus: Subject<string>): OperatorFunction<string, readonly string[]> {
        return (value$: Observable<string>) => {
            const debouncedText = value$.pipe(debounceTime(300), distinctUntilChanged());
            return merge(debouncedText, modelFocus).pipe(
                withLatestFrom(this.possibleModels$),
                map(([value, models]) => {
                    return [
                        ...models.reduce((modelSet, current) => {
                            if (!value || current.name === rigDetailOtherOption) {
                                modelSet.add(current.name);
                                return modelSet;
                            }

                            if (current.name.toLowerCase().includes(value.toLowerCase())) {
                                modelSet.add(current.name);
                            }

                            return modelSet;
                        }, new Set() as Set<string>),
                    ];
                })
            );
        };
    }

    searchTrims(trimFocus: Subject<string>): OperatorFunction<string, readonly string[]> {
        return (value$: Observable<string>) => {
            const debouncedText = value$.pipe(debounceTime(300), distinctUntilChanged());
            return merge(debouncedText, trimFocus).pipe(
                withLatestFrom(this.possibleTrims$),
                map(([value, trims]) => {
                    if (!value) {
                        return trims;
                    }

                    const valueLowerCase = value.toLowerCase();
                    return [...trims.filter((trim) => trim.toLowerCase().includes(valueLowerCase))];
                })
            );
        };
    }
}
