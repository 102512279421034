import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUtilsService } from '@common/services';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authUtilsService: AuthUtilsService
    ) {}

    // eslint-disable-next-line complexity
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const bearerToken = this.authUtilsService.bearerToken();

        // INFO: Not needed anymore...
        // if (
        //     request.url.match(/https:\/\/stage-test/) ||
        //     request.url.match(/https:\/\/develop-test/)
        // ) {
        //     request = request.clone({
        //         setParams: {
        //             startbootstrap: 'test',
        //         },
        //     });
        // }

        if (request.headers.get('ignoreIterecptor')) {
            return next.handle(request);
        }

        if (bearerToken && !_isWhiteListedAccess(request.method, request.url)) {
            const setHeaders: Dictionary<string> = {
                Authorization: bearerToken,
            };
            // If sending multipart/form-data do not add application/json
            if (!request.headers.has('SB-STACK-MULTI')) {
                setHeaders['Content-Type'] = 'application/json';
            }

            request = request.clone({
                setHeaders,
            });
        }

        return next.handle(request);
    }
}

function _isWhiteListedAccess(method: string, url: string): boolean {
    const whiteList: { [index: string]: string[] } = {
        GET: ['assets/*', 'api/latest/posts/*', 'api/latest/payment/*'],
        POST: ['api/latest/auth/login'],
    };
    const paths: string[] = whiteList[method];
    if (paths) {
        for (const path of paths) {
            if (url.match(path)) {
                return true;
            }
        }
    }

    return false;
}
