import { DynamicRouteData } from '@modules/navigation/models';

export const admin: DynamicRouteData = {
    isPublic: false,
    title: 'Admin',
};

export const adminOrganization: DynamicRouteData = {
    isPublic: false,
    title: 'Admin: Organization',
    activeSideNav: 'organization',
};

export const adminUsers: DynamicRouteData = {
    isPublic: false,
    title: 'Admin: Users',
    activeSideNav: 'users',
    animation: 'AdminUsers',
};

export const adminGroups: DynamicRouteData = {
    isPublic: false,
    title: 'Admin: Groups',
    activeSideNav: 'groups',
    animation: 'AdminGroups',
};

export const adminPostsAll: DynamicRouteData = {
    isPublic: false,
    title: 'Admin: Posts',
    activeSideNav: 'allPosts',
};

export const adminConfigs = { admin, adminOrganization, adminGroups, adminUsers, adminPostsAll };
