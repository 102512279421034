/* eslint-disable simple-import-sort/imports */

import Prism from 'prismjs';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-pug';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-typescript';

import { Injectable } from '@angular/core';
// import 'prismjs/plugins/unescaped-markup';

@Injectable()
export class PrismService {
    Prism: typeof Prism;

    constructor() {
        this.Prism = Prism;
    }

    highlightAll() {
        Prism.highlightAll();
    }
}
