import { ParamMap } from '@angular/router';
import { throwError } from 'rxjs';

export const getParam = <T>(paramMap: ParamMap, param: string): T => {
    const paramValue = paramMap.get(param);
    if (!paramValue) {
        throwError(() => new Error(`PARAM_NOT_FOUND: ${param}`));
    }
    return paramValue as T;
};
