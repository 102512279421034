import type { CanopyConnectDriver } from './canopy-connect';
import type { RunEnvironment, RunEnvironmentRole } from './internal';
import type { QuoteEnsFlowFormValue } from './quote-flow-ens';

export type WebhookCanopyConnectBody =
    | CanopyConnectAuthStatusEventBody
    | CanopyConnectCompleteEventBody
    | CanopyConnectErrorEventBody
    | CanopyConnectPolicyAvailableBody
    | CanopyConnectPoliciesAvailableBody
    | CanopyConnectMonitoringReconnectBody
    | CanopyConnectDataUpdatedBody
    | CanopyConnectServicingWaitingBody;

export interface WebhookCanopyConnectResponse {
    result: string;
    runEnvironmentMismatch?: boolean;
    runEnvironment?: RunEnvironment;
    runEnvironmentRole?: RunEnvironmentRole;
}

export interface CanopyConnectTredderMetadata {
    env: string;
    quoteEnsId?: UUID;
    policyEnsId?: UUID;
    runEnvironment: string;
    runEnvironmentRole: string;
}

interface CanopyConnectBodyBase {
    widget_id: string;
    team_id: string;
    pull_id: string;
    status: CanopyConnectStatus;
    meta_data: CanopyConnectTredderMetadata;
    event_type: CanopyConnectWebhookEvent;
    account_identifier: string;
    data: unknown;
}

export interface CanopyConnectAuthStatusEventBody extends CanopyConnectBodyBase {
    event_type: CanopyConnectWebhookEvent.authStatus;
    data: AuthStatusData;
}

export interface CanopyConnectCompleteEventBody extends CanopyConnectBodyBase {
    event_type: CanopyConnectWebhookEvent.complete;
    is_monitored: boolean;
    monitoring?: CanopyConnectMonitoring;
    data: Record<string, never>;
}

export interface CanopyConnectErrorEventBody extends CanopyConnectBodyBase {
    event_type: CanopyConnectWebhookEvent.error;
    is_monitored: boolean;
    monitoring?: CanopyConnectMonitoring;
    data: {
        type: CanopyConnectErrorType;
    };
}

export interface CanopyConnectPolicyAvailableBody extends CanopyConnectBodyBase {
    event_type: CanopyConnectWebhookEvent.policyAvailable;
}

export interface CanopyConnectPoliciesAvailableBody extends CanopyConnectBodyBase {
    event_type: CanopyConnectWebhookEvent.policiesAvailable;
}

export interface CanopyConnectMonitoringReconnectBody extends CanopyConnectBodyBase {
    event_type: CanopyConnectWebhookEvent.monitoringReconnect;
}

export enum CanopyConnectDataUpdatedType {
    driverAdded = 'DRIVER_ADDED',
    driverUpdated = 'DRIVER_UPDATED',
}

export interface CanopyConnectDataUpdatedBody extends CanopyConnectBodyBase {
    event_type: CanopyConnectWebhookEvent.dataUpdated;
    is_monitored: boolean;
    monitoring?: CanopyConnectMonitoring;
    data: {
        updates: {
            type: CanopyConnectDataUpdatedType;
            driver: CanopyConnectDriver;
        }[];
    };
}

export interface CanopyConnectServicingWaitingBody extends CanopyConnectBodyBase {
    event_type: CanopyConnectWebhookEvent.servicingWaitingForConsumerConfirmation;
}

export interface CanopyConnectMonitoring {
    initial_pull_id: string;
    monitoring_id: string;
}

export type AuthStatusData =
    | AuthStatusSuccessData
    | AuthStatusIdentityVerificationData
    | AuthStatusIdentityVerificationOptionsData
    | AuthStatusNotAuthenticatedData;

export interface AuthStatusSuccessData {
    auth_status: CanopyConnectUserAuthStatus.success;
}

export interface AuthStatusIdentityVerificationOptionsData {
    auth_status: CanopyConnectUserAuthStatus.identityVerificationOptions;
    mfa_options: {
        email: string;
        sms0: string;
        sms1: string;
        voice: string;
        securityQuestion: string;
        pin: string;
        token: string;
    };
}

export interface AuthStatusIdentityVerificationData {
    auth_status: CanopyConnectUserAuthStatus.identityVerification;
    mfa_input_type: CanopyConnectMfaInputType;
    mfa_input_display: string;
}

export interface AuthStatusNotAuthenticatedData {
    auth_status: CanopyConnectUserAuthStatus.notAuthenticated;
    login_error_message: string;
}

export enum CanopyConnectWebhookEvent {
    authStatus = 'AUTH_STATUS',
    complete = 'COMPLETE',
    dataUpdated = 'DATA_UPDATED',
    error = 'ERROR',
    monitoringReconnect = 'MONITORING_RECONNECT',
    policyAvailable = 'POLICY_AVAILABLE',
    policiesAvailable = 'POLICIES_AVAILABLE',
    servicingWaitingForConsumerConfirmation = 'SERVICING_WAITING_FOR_CONSUMER_CONFIRMATION',
}

export enum CanopyConnectUserAuthStatus {
    notAuthenticated = 'NOT_AUTHENTICATED',
    identityVerificationOptions = 'IDENTITY_VERIFICATION_OPTIONS',
    identityVerification = 'IDENTITY_VERIFICATION',
    success = 'SUCCESS',
}

export enum CanopyConnectStatus {
    notAuthenticated = 'NOT_AUTHENTICATED',
    identityVerificationOptions = 'IDENTITY_VERIFICATION_OPTIONS',
    identityVerification = 'IDENTITY_VERIFICATION',
    gettingConsumers = 'GETTING_CONSUMERS',
    success = 'SUCCESS',
}

export enum CanopyConnectMfaInputType {
    carrierPin = 'CARRIER_PIN',
    emailOtp = 'EMAIL_OTP',
    smsOtp = 'SMS_OTP',
    securityQuestion = 'SECURITY_QUESTION',
    otherOtp = 'OTHER_OTP',
    voiceOtp = 'VOICE_OTP',
}

export enum CanopyConnectErrorType {
    internalError = 'INTERNAL_ERROR',
    providerError = 'PROVIDER_ERROR',
}

export enum ExistingCoveragePullStatus {
    notPulled = 'notPulled',
    pulling = 'pulling',
    pulled = 'pulled',
    expired = 'expired',
    notRequired = 'notRequired',
    error = 'error',
    pullInvalid = 'pullInvalid',
    usedManual = 'usedManual',
    skippedForQuote = 'skippedForQuote',
    manualUpload = 'manualUpload',
}

export interface CheckCoverageVerificationPayload {
    quoteEnsId: string;
}

export interface CheckCoverageVerificationResponse {
    result: ExistingCoveragePullStatus;
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
}
