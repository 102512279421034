import type { ExistingCoverage, Prisma } from './_prisma';
import type { CanopyConnectPull } from './canopy-connect';
import type { CurrentInsurance } from './insurance-carriers';
import type { RequireAtLeastOne } from './utility';

export type ExistingCoverageForResults = Prisma.ExistingCoverageGetPayload<{
    // select: {
    //     // id: true;
    // };
}>;

interface BaseCreateExistingCoveragePayload<
    T extends CoverageReportProvider = CoverageReportProvider,
> {
    bodilyInjuryDeductible?: number;
    bodilyInjuryLimit?: number;
    carrier: CurrentInsurance;
    carrierPolicyId: string;
    collisionDeductible?: number;
    collisionLimit?: number;
    comprehensiveDeductible?: number;
    comprehensiveLimit?: number;
    decPageContents: DecPageContents[T];
    endDate: string;
    filePath: string;
    isAcceptable: boolean;
    policyEnsId?: string;
    quoteEnsId?: string;
    reportId: string;
    reportPolicyId: string;
    reportProvider: T;
    startDate: string;
}

export type CreateExistingCoveragePayload = RequireAtLeastOne<
    BaseCreateExistingCoveragePayload,
    'policyEnsId' | 'quoteEnsId'
>;

export interface ReadMultipleExistingCoveragesPayload {
    existingCoverageIDs: string[];
}

export interface DeleteMultipleExistingCoveragesPayload {
    existingCoverageIDs: string[];
}

export interface UpdateExistingCoveragePayload<
    T extends CoverageReportProvider = CoverageReportProvider,
> {
    carrier?: CurrentInsurance;
    collisionDeductible?: number;
    collisionLimit?: number;
    comprehensiveDeductible?: number;
    comprehensiveLimit?: number;
    decPageContents?: DecPageContents[T];
    endDate?: string;
    filePath?: string;
    policyEnsId?: string;
    reportProvider?: T;
    startDate?: string;
}

export type AllExistingCoveragesErrorCodes =
    | 'ERROR_FINDING_EXISTING_COVERAGES'
    | 'EXISTING_COVERAGES_NOT_FOUND';
export type CreateExistingCoveragesErrorCodes =
    | 'EXISTING_COVERAGE_NAME_IN_USE'
    | 'ERROR_CREATING_EXISTING_COVERAGE';
export type DeleteMultipleExistingCoveragesErrorCodes =
    | 'EXISTING_COVERAGES_NOT_FOUND'
    | 'ERROR_FINDING_EXISTING_COVERAGES'
    | 'ERROR_DELETING_EXISTING_COVERAGES';
export type DeleteExistingCoverageErrorCodes =
    | 'EXISTING_COVERAGE_NOT_FOUND'
    | 'ERROR_FINDING_EXISTING_COVERAGE'
    | 'ERROR_DELETING_EXISTING_COVERAGE';
export type FindExistingCoverageErrorCodes =
    | 'ERROR_FINDING_EXISTING_COVERAGE'
    | 'EXISTING_COVERAGE_NOT_FOUND';
export type FindExistingCoveragesErrorCodes =
    | 'ERROR_FINDING_EXISTING_COVERAGES'
    | 'NO_EXISTING_COVERAGES_FOUND';
export type SaveExistingCoverageErrorCodes = 'ERROR_SAVING_EXISTING_COVERAGE';
export type UpdateExistingCoverageErrorCodes =
    | 'ERROR_UPDATING_EXISTING_COVERAGE'
    | 'EXISTING_COVERAGE_NOT_FOUND';

export interface ExistingCoverageTyped<T extends CoverageReportProvider = CoverageReportProvider>
    extends Omit<ExistingCoverage, 'decPageContents'> {
    carrier: CurrentInsurance;
    reportProvider: T;
    decPageContents: DecPageContents[T];
}

export enum CoverageReportProvider {
    canopyConnect = 'canopyConnect',
    fize = 'fize',
    verisk = 'verisk',
    manual = 'manual',
}

export type DecPageContents = {
    fize: DecPageContentFize;
    canopyConnect: DecPageContentCanopyConnect;
    verisk: DecPageContentVerisk;
    manual: null;
};

export interface VerifyDecPageContents<T extends CoverageReportProvider> {
    decPageContents: DecPageContents[T];
}

export interface DecPageContentCanopyConnect {
    pull: CanopyConnectPull;
}

export interface DecPageContentFize {}

export interface DecPageContentVerisk {}
