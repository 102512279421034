export enum Handle401Actions {
    noAction = 'NO_ACTION',
    throwError = 'THROW_ERROR',
    removeTokens = 'REMOVE_TOKENS',
}

export interface SBBackendErrorParameters {
    error?: string;
    message?: string;
    statusCode?: number;
}

export class SBBackendError {
    private error = 'ERROR';
    private message = 'MESSAGE';
    private statusCode = 500;

    constructor(sbBackendErrorParameters: SBBackendErrorParameters) {
        Object.assign(this, sbBackendErrorParameters);
    }
}
