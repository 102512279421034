import { Injectable } from '@angular/core';
import { MembershipForToken, UserForTokenTyped } from '@backend-types/authorization';
import { RoleName } from '@backend-types/role';
import { inputIsNotNullOrUndefined } from '@common/helpers';
import { UtilityService } from '@common/services/utility.service';
import compare from 'just-compare';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { LogService } from '.';

const authUserSubject: ReplaySubject<UserForTokenTyped | null> = new ReplaySubject(1);
const activeAuthUserSubject: ReplaySubject<ActiveAuthUser | null> = new ReplaySubject(1);
const authUserActiveDataSubject: ReplaySubject<MembershipForToken | null> = new ReplaySubject(1);
let _authUserActiveData: MembershipForToken | null;
let _authUser: UserForTokenTyped | null;
let _activeMembershipID: UUID | null;

export interface ActiveAuthUser extends UserForTokenTyped {
    activeData: MembershipForToken;
}

@Injectable()
export class UserService {
    constructor(
        private utilityService: UtilityService,
        private logService: LogService
    ) {
        this.init();
    }

    init() {
        combineLatest([
            authUserSubject.pipe(distinctUntilChanged(compare)),
            authUserActiveDataSubject.pipe(
                // filter(inputIsNotNullOrUndefined),
                distinctUntilChanged(compare)
            ),
        ])
            .pipe(
                map(([user, activeData]) => {
                    if (user === null || activeData === null) {
                        return null;
                    } else {
                        return {
                            ...user,
                            activeData,
                        };
                    }
                }),
                tap((activeAuthUser) =>
                    activeAuthUser
                        ? this.logService.debug(activeAuthUser, '### DEBUG - ACTIVE USER: ')
                        : undefined
                )
            )
            .subscribe((activeAuthUser) => {
                activeAuthUserSubject.next(activeAuthUser);
            });
    }

    setAuthUser(userForToken: UserForTokenTyped): Observable<ActiveAuthUser> {
        authUserSubject.next(this._initAuthUser(userForToken));
        return this.activeAuthUser$;
    }

    get authUser$(): Observable<UserForTokenTyped> {
        return authUserSubject.pipe(filter(inputIsNotNullOrUndefined));
    }

    set activeMembership(activeMembershipID: UUID) {
        if (!_authUser) {
            throw new Error('_AUTH_USER_NOT_SET');
        }
        _activeMembershipID = activeMembershipID;
        this.utilityService.localStorage.setItem(
            `active-membership-id-${_authUser.id}`,
            _activeMembershipID
        );
        _authUser.activeMembershipID = activeMembershipID;
        authUserSubject.next(_authUser);
        this._extractActiveRolesAndGroups(_authUser);
    }

    get authUserActiveData$(): Observable<MembershipForToken> {
        return authUserActiveDataSubject.pipe(filter(inputIsNotNullOrUndefined));
    }

    get activeAuthUser$(): Observable<ActiveAuthUser> {
        return activeAuthUserSubject.pipe(filter(inputIsNotNullOrUndefined));
    }

    get isAdmin(): boolean {
        if (!_authUserActiveData) {
            throw new Error('_AUTH_USER_ACTIVE_DATA_NOT_SET');
        }
        return _authUserActiveData.role?.name === RoleName.admin;
    }

    get isEditor(): boolean {
        if (!_authUserActiveData) {
            throw new Error('_AUTH_USER_ACTIVE_DATA_NOT_SET');
        }
        return _authUserActiveData.role?.name === RoleName.editor;
    }

    get isAffiliate(): boolean {
        if (!_authUserActiveData) {
            throw new Error('_AUTH_USER_ACTIVE_DATA_NOT_SET');
        }
        return (
            _authUserActiveData.role?.name === RoleName.registered &&
            !!_authUserActiveData.groups.find((group) => group.name === 'affiliate')
        );
    }

    get isPartner(): boolean {
        if (!_authUser) {
            throw new Error('_AUTH_USER_NOT_SET');
        }
        return _authUser.isPartner;
    }

    get isAgent(): boolean {
        if (!_authUserActiveData) {
            throw new Error('_AUTH_USER_ACTIVE_DATA_NOT_SET');
        }
        return (
            _authUserActiveData.role?.name === RoleName.admin ||
            _authUserActiveData.role?.name === RoleName.editor
        );
    }

    isConfirmed(): boolean {
        if (!_authUser) {
            throw new Error('_AUTH_USER_NOT_SET');
        }
        return _authUser.emailConfirmed || _authUser.hasSocialAuth;
    }

    logOutUser(): void {
        authUserSubject.next(null);
        activeAuthUserSubject.next(null);
        authUserActiveDataSubject.next(null);
        _authUserActiveData = null;
        _authUser = null;
        _activeMembershipID = null;
    }

    private _initAuthUser(userForToken: UserForTokenTyped): UserForTokenTyped {
        this._setActiveMembershipID(userForToken);
        this._extractActiveRolesAndGroups(userForToken);

        _authUser = userForToken;
        return userForToken;
    }

    private _extractActiveRolesAndGroups(user: UserForTokenTyped): void {
        const activeMembership = user.memberships.find(
            (membership) => membership.id === _activeMembershipID
        );
        if (activeMembership) {
            if (!activeMembership.organizationId) {
                throw new Error(
                    `ORGANIZATION_ID_NOT_ON_ACTIVE_MEMBERSHIP_FOR_MEMBERSHIP: ${activeMembership.id}`
                );
            }
            if (!activeMembership.organization) {
                throw new Error(
                    `ORGANIZATION_NOT_ON_ACTIVE_MEMBERSHIP_FOR_MEMBERSHIP: ${activeMembership.id}`
                );
            }
            _authUserActiveData = {
                id: activeMembership.id,
                organizationId: activeMembership.organizationId,
                organization: activeMembership.organization,
                role: activeMembership.role,
                groups: activeMembership.groups,
            };
            authUserActiveDataSubject.next(_authUserActiveData);
        } else {
            this.logService.info('Active Membership not set');
            // authUserActiveDataSubject.next(undefined);
        }
    }

    private _setActiveMembershipID(user: UserForTokenTyped): void {
        if (_activeMembershipID) {
            user.activeMembershipID = _activeMembershipID;
            return;
        }

        const activeMembershipIDFromStorage = this.utilityService.localStorage.getItem(
            `active-membership-id-${user.id}`
        );

        if (activeMembershipIDFromStorage) {
            _activeMembershipID = activeMembershipIDFromStorage;
            user.activeMembershipID = _activeMembershipID;
            return;
        }

        if (user.memberships.length) {
            _activeMembershipID = user.memberships[0].id;
            this.utilityService.localStorage.setItem(
                `active-membership-id-${user.id}`,
                _activeMembershipID
            );
            user.activeMembershipID = _activeMembershipID;
            return;
        }
    }
}
