import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { HomeBaseFormValue, QuoteRigSetFormValue } from '@backend-types/quote-flow-ens';
import { StateShort } from '@backend-types/states';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { DevUtilsModalKind } from '@common/models/dev-utils.model';
import {
    AnalyticsService,
    AssertionService,
    HotKeysService,
    ScriptService,
    UtilityService,
} from '@common/services';
import { DevUtilsModalService } from '@modules/dev/services';
import { QuoteRigSetFormService } from '@modules/quote/services';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-home-base-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-home-base-form.component.html',
    styleUrls: ['quote-ens-home-base-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteEnsHomeBaseFormComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteEnsHomeBaseFormComponent,
        },
    ],
})
export class QuoteEnsHomeBaseFormComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @ViewChild('autoCompleteInput') autoCompleteInput!: ElementRef<HTMLInputElement>;

    @Input() formOnly = false;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    subscription: Subscription = new Subscription();

    autocomplete!: google.maps.places.Autocomplete;
    activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;

    homeBaseForm: ModelFormGroup<HomeBaseFormValue> = this.fb.group({
        street: new FormControl<string | null>({ value: null, disabled: true }, [
            Validators.required,
        ]),
        zip: new FormControl<string | null>({ value: null, disabled: true }, [Validators.required]),
        city: new FormControl<string | null>({ value: null, disabled: true }, [
            Validators.required,
        ]),
        state: new FormControl<string | null>({ value: null, disabled: true }, [
            Validators.required,
        ]),
        country: new FormControl<string | null>({ value: null, disabled: true }, [
            Validators.required,
        ]),
    });

    onTouched: () => unknown = () => {};
    onChange = (homeBase: ModelFormValue<HomeBaseFormValue> | null) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private scriptService: ScriptService,
        private utilityService: UtilityService,
        private changeDetectorRef: ChangeDetectorRef,
        private devUtilsModalService: DevUtilsModalService,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Home Base');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_home_base',
        });
    }

    async ngOnInit() {
        await this.scriptService.loadScript('places');

        this.autoCompleteInput.nativeElement.onload = () => {
            this.autoCompleteInDom();
        };

        this.autocomplete = new this.utilityService.window.google.maps.places.Autocomplete(
            this.autoCompleteInput.nativeElement,
            {
                componentRestrictions: {
                    country: [
                        'us',
                        StateShort.puertoRico,
                        StateShort.virginIslands,
                        StateShort.guam,
                        StateShort.americanSamoa,
                        StateShort.northernMarianaIslands,
                    ],
                },
                fields: ['address_components'],
                strictBounds: false,
                types: ['address'],
            }
        );

        this.autocomplete.addListener('place_changed', () => {
            const place = this.autocomplete.getPlace();
            const country = place.address_components?.find((addressComponent) =>
                addressComponent.types.includes('country')
            )?.short_name;

            if (country === 'US') {
                this.googlePlaceUsToHomeBase(place);
            } else if (country) {
                this.googlePlaceTerritoryToHomeBase(place, country);
            } else {
                console.warn('country not found');
            }
        });

        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.homeBaseForm.setValue({
                    street: 'Yosemite National Park Road',
                    zip: '95389',
                    city: 'Yosemite Valley',
                    state: 'CA',
                    country: 'US',
                });
                this.changeDetectorRef.detectChanges();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowDown' }).subscribe(() => {
                this.devUtilsModalService.open(DevUtilsModalKind.homeBase, {
                    writeValue: (value) => this.writeValue(value),
                });
            })
        );
        this.subscription.add(
            this.quoteRigSetFormService
                .getActiveQuoteRigSetForm$()
                .subscribe((activeQuoteRigSetForm) => {
                    this.activeQuoteRigSetForm = activeQuoteRigSetForm;
                    this.changeDetectorRef.detectChanges();
                })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    changeAddress() {
        this.homeBaseForm.reset();
        this.onChange(null);
        this.changeDetectorRef.detectChanges();
    }

    autoCompleteInDom() {
        // console.log('yo');
    }
    registerOnChange(onChange: (homeBase: ModelFormValue<HomeBaseFormValue> | null) => unknown) {
        this.onChange = onChange;

        this.subscription.add(
            this.homeBaseForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.homeBaseForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const homeBase = this._homeBaseFormValue();
                        onChange(homeBase);
                    } catch (error) {
                        // console.error(error);
                    }
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.homeBaseForm.disable();
        } else {
            this.homeBaseForm.enable();
        }
    }

    writeValue(value: HomeBaseFormValue | null) {
        if (value === null) {
            this.homeBaseForm.reset();
        }
        if (value) {
            this.homeBaseForm.setValue(value);
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.homeBaseForm?.invalid) {
            return { homeBaseFormInvalid: true };
        }

        return null;
    }

    googlePlaceUsToHomeBase(place: google.maps.places.PlaceResult) {
        const streetNumber = place.address_components?.find((addressComponent) =>
            addressComponent.types.includes('street_number')
        )?.long_name;

        const route = place.address_components?.find((addressComponent) =>
            addressComponent.types.includes('route')
        )?.long_name;

        const street = `${streetNumber} ${route}`;

        const zip = place.address_components?.find((addressComponent) =>
            addressComponent.types.includes('postal_code')
        )?.long_name;

        const city = place.address_components?.find((addressComponent) =>
            addressComponent.types.includes('locality')
        )?.long_name;

        const state = place.address_components?.find((addressComponent) =>
            addressComponent.types.includes('administrative_area_level_1')
        )?.short_name;

        this.homeBaseForm.setValue({
            street,
            zip: zip || null,
            city: city || null,
            state: state || null,
            country: 'US',
        });

        this.changeDetectorRef.detectChanges();
    }
    googlePlaceTerritoryToHomeBase(place: google.maps.places.PlaceResult, country: string) {
        const cityLookup: Record<string, string> = {
            [StateShort.virginIslands]: 'administrative_area_level_1',
            [StateShort.guam]: 'locality',
            [StateShort.puertoRico]: 'locality',
            [StateShort.americanSamoa]: 'locality',
            [StateShort.northernMarianaIslands]: 'locality',
        };

        const plusCode = place.address_components?.find((addressComponent) =>
            addressComponent.types.includes('plus_code')
        )?.long_name;

        const streetNumber = place.address_components?.find((addressComponent) =>
            addressComponent.types.includes('street_number')
        )?.long_name;

        const route = place.address_components?.find((addressComponent) =>
            addressComponent.types.includes('route')
        )?.long_name;

        let street = streetNumber && route ? `${streetNumber} ${route}` : `${plusCode}`;

        let zip = place.address_components?.find((addressComponent) =>
            addressComponent.types.includes('postal_code')
        )?.long_name;

        if (country === StateShort.americanSamoa) {
            zip = '96799';
        }

        const city = place.address_components?.find((addressComponent) =>
            addressComponent.types.find((type) => type === cityLookup[country])
        )?.long_name;

        this.homeBaseForm.setValue({
            street,
            zip: zip || null,
            city: city || null,
            state: country,
            country: country,
        });

        this.changeDetectorRef.detectChanges();
    }

    private _homeBaseFormValue(): ModelFormValue<HomeBaseFormValue> {
        const { street, zip, city, state, country } = this.homeBaseForm.value;

        this.assertionService.isDefinedOrThrow(street);
        this.assertionService.isDefinedOrThrow(zip);
        this.assertionService.isDefinedOrThrow(city);
        this.assertionService.isDefinedOrThrow(state);
        this.assertionService.isDefinedOrThrow(country);

        return {
            street,
            zip,
            city,
            state,
            country,
        };
    }

    /* Accessor Methods */

    get streetControl() {
        return this.homeBaseForm.get('street') as FormControl;
    }

    get streetControlValid() {
        return this.streetControl.value && !this.streetControl.hasError('required');
    }

    get streetControlInvalid() {
        return this.streetControl.touched && this.streetControl.hasError('required');
    }

    get zipControl() {
        return this.homeBaseForm.get('zip') as FormControl;
    }

    get zipControlValid() {
        return this.zipControl.value && !this.zipControl.hasError('required');
    }

    get zipControlInvalid() {
        return this.zipControl.touched && this.zipControl.hasError('required');
    }

    get cityControl() {
        return this.homeBaseForm.get('city') as FormControl;
    }

    get cityControlValid() {
        return this.cityControl.value && !this.cityControl.hasError('required');
    }

    get cityControlInvalid() {
        return this.cityControl.touched && this.cityControl.hasError('required');
    }

    get stateControl() {
        return this.homeBaseForm.get('state') as FormControl;
    }

    get stateControlValid() {
        return this.stateControl.value && !this.stateControl.hasError('required');
    }

    get stateControlInvalid() {
        return this.stateControl.touched && this.stateControl.hasError('required');
    }

    get allValid() {
        return (
            this.streetControlValid &&
            this.zipControlValid &&
            this.cityControlValid &&
            this.stateControlValid
        );
    }
}
