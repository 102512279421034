export interface ObjectWithName {
    name: string;
}

export enum SortDirectionCompare {
    asc = 'ASC',
    desc = 'DESC',
}

export const compareForSort = (v1: string | number, v2: string | number): number => {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

export const createSortByName = (
    direction: SortDirectionCompare = SortDirectionCompare.asc
): ((a: ObjectWithName, b: ObjectWithName) => number) => {
    return (a: ObjectWithName, b: ObjectWithName): number => {
        const res = compareForSort(a.name, b.name);
        return direction === SortDirectionCompare.asc ? res : -res;
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createSortByDate = <T extends Record<string, any> = Record<string, any>>(
    dateKey: string,
    direction: SortDirectionCompare = SortDirectionCompare.asc
): ((a: T, b: T) => number) => {
    return (a: T, b: T): number => {
        const res = compareForSort(a[dateKey], b[dateKey]);
        return direction === SortDirectionCompare.asc ? res : -res;
    };
};

export const createSortByKey = (
    key: string,
    direction: SortDirectionCompare = SortDirectionCompare.asc
): ((a: Record<string, unknown>, b: Record<string, unknown>) => number) => {
    return (a: Record<string, unknown>, b: Record<string, unknown>): number => {
        if (
            typeof a[key] !== 'string' &&
            typeof a[key] !== 'number' &&
            typeof b[key] !== 'string' &&
            typeof b[key] !== 'number'
        ) {
            throw new Error(`CAN_NOT_SORT_ON: ${key}. type is ${typeof a[key]}`);
        }
        const res = compareForSort(a[key] as string | number, b[key] as string | number);
        return direction === SortDirectionCompare.asc ? res : -res;
    };
};
