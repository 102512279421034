import { Injectable } from '@angular/core';
import { ExistingCoverageTyped } from '@backend-types/existing-coverage';
import { isWithinInterval } from 'date-fns';

@Injectable()
export class ExistingCoverageService {
    constructor() {}

    getCurrentValidExistingCoverage = (
        existingCoverages: ExistingCoverageTyped[],
        asOfDate = new Date()
    ): ExistingCoverageTyped | false => {
        const currentValidExistingCoverage = existingCoverages.find((existingCoverage) => {
            if (!existingCoverage.isAcceptable) {
                return false;
            }

            if (
                !isWithinInterval(asOfDate, {
                    start: existingCoverage.startDate,
                    end: existingCoverage.endDate,
                })
            ) {
                return false;
            }

            return true;
        });

        if (currentValidExistingCoverage) {
            return currentValidExistingCoverage;
        }
        return false;
    };
}
