import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sbf-table-sort-icon',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './table-sort-icon.component.html',
    styleUrls: ['table-sort-icon.component.scss'],
})
export class TableSortIconComponent implements OnInit {
    @Input() direction!: string;

    constructor() {}
    ngOnInit() {}
}
