<div class="tredder-card coverage-card mb-5" *ngIf="quoteEnsFlowFormValue">
    <div class="text-start mb-3">
        <div class="d-flex justify-content-between align-items-center">
            <h2>Home Base</h2>
            <div class="text-link" data-cy="editHomeBaseButton" (click)="editHomeBase()">edit</div>
        </div>
    </div>
    <hr class="full mb-3" />
    <div class="text-primary r-text">{{quoteEnsFlowFormValue.homeBase.street}}</div>
    <div class="text-primary r-text">{{quoteEnsFlowFormValue.homeBase.city}}, {{quoteEnsFlowFormValue.homeBase.state}} {{quoteEnsFlowFormValue.homeBase.zip}}</div>
    <div class="text-primary r-text">&nbsp;</div>
    <div class="text-primary r-text">&nbsp;</div>
</div>
