import type { Prisma } from './_prisma';
import type { CreateModificationPayload, ModificationTyped } from './modification';
import type { PolicyPic, UserGeneratedPic } from './picture';
import type { PolicyEnsWithQuoteTyped } from './policy-ens';
import type { QuoteEnsTyped } from './quote-ens';
import { userForBaseInclude, type UserForBaseSelect } from './user';

// TODO.. DO we need both of these?
// Should transform be a subset of relations?

export type RigWithModifications = Prisma.RigGetPayload<{
    include: {
        modifications: true;
    };
}>;

export type RigWithPoliciesQuotesModsCamperTrailer = Prisma.RigGetPayload<{
    include: {
        policiesEns: {
            include: {
                policyWrapper: true;
                quoteEns: true;
                rigs: true;
                sourceQuoteEns: true;
                organization: {
                    select: {
                        id: true;
                        name: true;
                    };
                };
                user: {
                    select: UserForBaseSelect;
                };
                agent: {
                    select: UserForBaseSelect;
                };
            };
        };
        quotesEns: true;
        modifications: true;
        camper: true;
        trailer: true;
    };
}>;

export const rigWithPoliciesQuotesModsInclude = {
    policiesEns: {
        include: {
            policyWrapper: true,
            quoteEns: true,
            rigs: true,
            sourceQuoteEns: true,
            organization: {
                select: {
                    id: true,
                    name: true,
                },
            },
            user: userForBaseInclude,
            agent: userForBaseInclude,
        },
    },
    quotesEns: true,
    modifications: true,
    camper: true,
    trailer: true,
};

export type RigForResults = Prisma.RigGetPayload<{
    include: {
        modifications: true;
    };
}>;

export const rigIncludeRelations = {
    modifications: true,
};

export type RigForList = Prisma.RigGetPayload<object>;

export enum VehicleType {
    none = 'NONE',
    motorcycleUtv = 'MOTORCYCLE_UTV',
    carWagon = 'CAR_WAGON',
    jeepSuv = 'JEEP_SUV',
    minivan = 'MINIVAN',
    truck = 'TRUCK',
    cargoVanRV = 'CARGO_VAN_RV',
    trailerCamper = 'TRAILER_CAMPER',
    unimogMilitaryBus = 'UNIMOG_MILITARY_BUS',
}

export interface CreateRigPayload {
    rigName: string;
    description?: string;
    profilePic?: UserGeneratedPic;
    heroPic?: UserGeneratedPic;
    rigPics?: UserGeneratedPic[];
    vin?: string;
    vehicleType?: VehicleType;
    year: number;
    make: string;
    model: string;
    trim: string;
    rigIsPublic: boolean;
    showValueToPublic: boolean;
    vehicleValue?: number;
    modifications: CreateModificationPayload[];
}

export interface ReadMultipleRigsPayload {
    rigIDs: string[];
}

export interface DeleteMultipleRigsPayload {
    rigIDs: string[];
}

export interface UpdateRigPayload {
    id: UUID;
    rigName?: string;
    description?: string;
    profilePic?: UserGeneratedPic;
    heroPic?: UserGeneratedPic;
    rigPics?: UserGeneratedPic[];
    vin?: string;
    vehicleType?: VehicleType;
    year?: number;
    make?: string;
    model?: string;
    trim?: string;
    rigIsPublic?: boolean;
    showValueToPublic?: boolean;
    vehicleValue?: number;
    // modifications?: CreateModificationPayload[];
    // deleteModificationIDs?: UUID[];
}

export type AllRigsErrorCodes = 'ERROR_FINDING_RIGS' | 'RIGS_NOT_FOUND';
export type CreateRigsErrorCodes =
    | 'RIG_NAME_IN_USE'
    | 'ERROR_CREATING_RIG'
    | 'CREATE_RIG_FAILED_AFTER_MAX_ATTEMPTS';
export type DeleteMultipleRigsErrorCodes =
    | 'RIGS_NOT_FOUND'
    | 'ERROR_FINDING_RIGS'
    | 'ERROR_DELETING_RIGS';
export type DeleteRigErrorCodes = 'RIG_NOT_FOUND' | 'ERROR_FINDING_RIG' | 'ERROR_DELETING_RIG';
export type FindRigErrorCodes = 'ERROR_FINDING_RIG' | 'RIG_NOT_FOUND';
export type FindRigsErrorCodes = 'ERROR_FINDING_RIGS' | 'NO_RIGS_FOUND';
export type SaveRigErrorCodes = 'ERROR_SAVING_RIG';
export type UpdateRigErrorCodes = 'ERROR_UPDATING_RIG' | 'RIG_NOT_FOUND' | 'RIG_IDS_DO_NOT_MATCH';

export interface RigPicUpsertParam {
    rigID: UUID;
}

export interface RigPicUpsertResponse {
    uploadedImageURL: string;
}

export interface RigByShortIdPayload {
    shortID: string;
}

export interface ShortIdResponse {
    shortID: string;
}

export type RigPicKind = 'profilePic' | 'heroPic' | 'rigPics' | 'rigPicsForPolicy';

export interface RigWithModificationsTyped
    extends Omit<RigWithModifications, 'modifications' | RigPicKind> {
    profilePic: UserGeneratedPic | null;
    heroPic: UserGeneratedPic | null;
    rigPics: UserGeneratedPic[] | null;
    rigPicsForPolicy: RigPicsForPolicy | null;
    modifications: ModificationTyped[];
}

export interface RigWithPoliciesQuotesModsCamperTrailerTyped
    extends Omit<
        RigWithPoliciesQuotesModsCamperTrailer,
        'policiesEns' | 'quotesEns' | 'modifications' | RigPicKind
    > {
    policiesEns: PolicyEnsWithQuoteTyped[];
    quotesEns: QuoteEnsTyped[];
    profilePic: UserGeneratedPic | null;
    heroPic: UserGeneratedPic | null;
    rigPics: UserGeneratedPic[] | null;
    rigPicsForPolicy: RigPicsForPolicy | null;
    modifications: ModificationTyped[];
}

export enum RigPicsForPolicyView {
    front = 'front',
    back = 'back',
    driver = 'driver',
    passenger = 'passenger',
    interior1 = 'interior1',
    interior2 = 'interior2',
}

export type RigPicsForPolicy = {
    [index in RigPicsForPolicyView]?: PolicyPic;
};

export interface RigBuilderStartFormValue {
    rigName: string;
    year: number;
    make: string;
    model: string;
    trim: string;
    vin: string | null;
    firstName: string;
    lastName: string;
    email: string;
}

export interface StartRigPayload extends RigBuilderStartFormValue {
    grToken: string;
}

export interface StartRigResponse {
    foundExistingUser: boolean;
    newRigId: UUID;
    userID?: UUID;
    passwordResetToken?: string;
}
