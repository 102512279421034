import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResetTokenValidPayload, ResetTokenValidResponse } from '@backend-types/auth';
import {
    DecodedToken,
    Token,
    TokenResponse,
    UserForTokenTyped,
} from '@backend-types/authorization';
import { inputIsNotNullOrUndefined } from '@common/helpers';
import { UtilityService } from '@common/services/utility.service';
import * as Sentry from '@sentry/angular-ivy';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { ActiveAuthUser, EnvService, OverlayService, UserService } from '.';

const _isLoggedIn$ = new BehaviorSubject(false);

export interface RedirectURL {
    url: string;
    queryParams?: Dictionary<string>;
}

@Injectable()
export class AuthUtilsService {
    constructor(
        private util: UtilityService,
        private userService: UserService,
        private router: Router,
        private http: HttpClient,
        private overlayService: OverlayService,
        private envService: EnvService
    ) {
        const token = this._jwtIsValid();
        if (token) {
            const userForToken = this._decodeToken(token);
            Sentry.setUser({
                email: userForToken.email ? userForToken.email : undefined,
                name: `${userForToken.firstName} ${userForToken.lastName}`,
                segment: `${userForToken.memberships[0]?.role.name}`,
            });

            this.userService.setAuthUser(userForToken);
        }
    }

    get isLoggedIn(): boolean {
        return _isLoggedIn$.value;
    }

    isLoggedIn$(): Observable<boolean> {
        return _isLoggedIn$;
    }

    bearerToken(): string | false {
        const token = this._jwtIsValid();
        if (token) {
            return `bearer ${token}`;
        }
        return false;
    }

    userForAuthGuard$(): Observable<ActiveAuthUser> {
        if (_isLoggedIn$.value) {
            return this.userService.activeAuthUser$.pipe(take(1));
        } else {
            return this._processStoredToken();
        }
    }

    private _processStoredToken(): Observable<ActiveAuthUser> {
        const token = this._jwtIsValid();
        if (token) {
            const userForToken = this._decodeToken(token);
            this.userService.setAuthUser(userForToken);
            return this.userService.activeAuthUser$.pipe(take(1));
        }
        return throwError(() => new Error('INVALID_JWT'));
    }

    refreshToken$(): Observable<UserForTokenTyped> {
        return this.http
            .post<TokenResponse>(
                `${this.envService.config.backendURL}/api/latest/auth/refresh-token`,
                {}
            )
            .pipe(
                switchMap(
                    (tokenResponse): Observable<UserForTokenTyped> =>
                        this.processToken$(tokenResponse.token)
                ),
                catchError((error: Error) => throwError(() => error))
            );
    }

    resetTokenValid$(
        resetTokenValidPayload: ResetTokenValidPayload
    ): Observable<ResetTokenValidResponse> {
        this.overlayService.show('Verifying Token');
        return this.http
            .post<ResetTokenValidResponse>(
                `${this.envService.config.backendURL}/api/latest/auth/reset-token-valid`,
                resetTokenValidPayload
            )
            .pipe(finalize(() => this.overlayService.hide()));
    }

    processToken$(token: Token): Observable<ActiveAuthUser> {
        if (!token) {
            return throwError(() => new Error('NO_TOKEN'));
        }

        this._storeToken(token);
        const userForToken = this._decodeToken(token);
        // INFO:
        return this.userService.setAuthUser(userForToken).pipe(filter(inputIsNotNullOrUndefined));
        // return of(userForToken);
    }

    navigateAfterAuth(user: UserForTokenTyped, currentURL: string, forAuth = false) {
        const redirect = this.determineRedirect(user, currentURL, forAuth);

        if (redirect) {
            this.router.navigate([redirect.url], { queryParams: redirect.queryParams });
            return of(false);
        }

        let toNavigate = '/account/insurance';
        if (this.userService.isAffiliate) {
            toNavigate = '/affiliate/dashboard';
        }
        if (this.userService.isPartner) {
            toNavigate = '/partner/dashboard';
        }
        if (this.userService.isAgent) {
            toNavigate = '/agency/dashboard';
        }
        if (this.userService.isAdmin) {
            toNavigate = '/admin/dashboard';
        }

        this.router.navigate([toNavigate]);
        return of(true);
    }

    navigateToAccountAuth() {
        this.router.navigate(['/account/settings/authorization']);

        return of(true);
    }

    private _decodeToken(token: Token): UserForTokenTyped {
        const decodedToken: DecodedToken = jwtDecode(token);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { exp, iat, ...userForToken } = decodedToken;
        return userForToken;
    }

    private _storeToken(token: Token) {
        return this.util.localStorage.setItem('token', token);
    }

    logOutUser() {
        this.removeTokens();
        this.userService.logOutUser();
    }

    removeTokens() {
        return this.util.localStorage.removeItem('token');
    }

    determineRedirect(
        user: UserForTokenTyped,
        currentURL: string,
        forAuth = false
    ): RedirectURL | false {
        const redirectURL = this.redirectURL;
        if (redirectURL) {
            this.unsetRedirectURL();
            return redirectURL;
        }
        if (forAuth) {
            return false;
        }
        if (currentURL === '/') {
            return false;
        }
        return { url: '/' };
    }

    private _jwtIsValid(): string | false {
        const token = this.util.localStorage.getItem('token');

        if (!token) {
            _isLoggedIn$.next(false);
            return false;
        }

        const decodedToken: DecodedToken = jwtDecode(token);

        const expired = decodedToken.exp * 1000 < Date.now();

        if (expired) {
            this.util.localStorage.removeItem('token');
            _isLoggedIn$.next(false);
            return false;
        }

        _isLoggedIn$.next(true);
        return token;
    }

    get redirectURL() {
        return this.util.getStoredObject('redirect-url');
    }

    set redirectURL(redirectURL: RedirectURL | undefined) {
        if (!redirectURL) {
            this.unsetRedirectURL();
        } else {
            this.util.storeObject('redirect-url', redirectURL);
        }
    }

    unsetRedirectURL() {
        this.util.removeObject('redirect-url');
    }
}
