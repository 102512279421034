import type { CalculatedTaxesAndFees, CalculatedTotals, PaymentPlanSource } from '../payment-plan';
import type { RocForResults } from '../roc';
import type { RocState } from '../states';
import type { RocData } from './roc-lib-config';
import type {
    InsuranceCoverageFactors,
    InsuranceCoveragesAcpe,
    InsuranceCoveragesBase,
    InsuranceCoveragesGear,
    InsuranceCoveragesSpecial,
    InsuranceCoveragesTrailer,
    InsuranceCoveragesUnit,
} from './roc-lib-coverage';
import type { RocStepKey } from './roc-lib-steps';
import type {
    AcpeDeductible,
    CampsiteLiabilityLimit,
    Deductibles,
    EmergencyExpenseLimit,
    GearDeductible,
    Limits,
    OffRoadRecoveryDeductible,
    OffRoadRecoveryLimit,
    ScheduledMedicalBenefitLimit,
    TowRoadsideLimit,
    UnitCollDeductible,
    UnitCompDeductible,
} from './roc-lib-variable-types';

export interface CalculateRateParams {
    rocID: UUID;
    rocData: RocData[];
    drivers: number;
    vehicles: number;
    trailers: number;
    units: number;
    exclusions?: CalculateRateENSParameters;
}

export interface CalculateRateENSParameters {
    excludeUnit: boolean;
    excludeAcpe: boolean;
    excludeGear: boolean;
    excludeSpecial: boolean;
}

export interface CalculateRateResponse {
    state: RocState;
    ppa: PPARateResponse;
    ens?: ENSRateResponse;
    quoteTotal: string;
    drivers: number;
    vehicles: number;
    trailers: number;
    units: number;
}

export interface RigCalculateRateParams {
    camper: CalculateRateParams | null;
    trailer: CalculateRateParams | null;
    mods: CalculateRateParams | null;
}

export interface FinalRates extends CalculateRateEnsOnlyResponse {
    rigRates: ENSRigRateResponseFinal[];
    coverageBreakdown: CoverageBreakdownFinal;
    premiumBreakdown: PremiumBreakdownFinal;
}

export interface CalculateRateEnsOnlyResponse {
    ensRocId: UUID;
    ensRocVersion: string;
    rigRates: ENSRigRateResponse[];
    gearRate: ENSGearRateResponse | null;
    specialRates: ENSSpecialRateResponse;
    totals: CalculatedTotals;
    taxesAndFees: CalculatedTaxesAndFees;
    term: ENSTermRateKey;
    paymentPlanSources: PaymentPlanSource[];
    premiumBreakdown: PremiumBreakdown;
    coverageBreakdown: CoverageBreakdown;
    insuranceScoreDetail: InsuranceScoreDetail; // INFO: DO NOT INCLUDE THIS IN ANY RESPONSE SCHEMAS
    endorsementRates?: EndorsementRates;
}

export interface InsuranceScoreDetail {
    insuranceScore: number;
    insuranceScoreTier: InsuranceScoreTier;
    insuranceScoreId: UUID | null;
}

export enum InsuranceScoreTier {
    preferred = 'preferred',
    standard = 'standard',
    subStandard = 'subStandard',
    nonStandard = 'nonStandard',
    noHit = 'noHit',
    thinFile = 'thinFile',
    notUsed = 'notUsed',
}

export interface PremiumBreakdownFinal extends PremiumBreakdown {
    rigs: PremiumBreakdownRigsFinal[];
}

export interface PremiumBreakdown {
    term: ENSTermRateKey;
    rigs: PremiumBreakdownRigs[];
    gearEnsActualCashValue: PremiumBreakdownDetails | null;
    gearEnsReplacementCost: PremiumBreakdownDetails | null;
    campsiteLiability: PremiumBreakdownDetails | null;
    scheduledMedicalBenefit: PremiumBreakdownDetails | null;
    emergencyExpense: PremiumBreakdownDetails | null;
    towRoadside: PremiumBreakdownDetails | null;
    offRoadRecovery: PremiumBreakdownDetails | null;
    allPremium: PremiumBreakdownDetails;
    sumCheck: PremiumBreakdownDetails;
}

export interface PremiumBreakdownRigsFinal extends PremiumBreakdownRigs {
    rigId: UUID;
}

export interface PremiumBreakdownRigs {
    trailerComprehensive: PremiumBreakdownDetails | null;
    trailerCollision: PremiumBreakdownDetails | null;
    camperComprehensive: PremiumBreakdownDetails | null;
    camperCollision: PremiumBreakdownDetails | null;
    modsComprehensive: PremiumBreakdownDetails | null;
    modsCollision: PremiumBreakdownDetails | null;
}

export interface CoverageBreakdownFinal extends CoverageBreakdown {
    rigs: CoverageBreakdownRigsFinal[];
}

export interface CoverageBreakdown {
    rigs: CoverageBreakdownRigs[];
    gearEnsActualCashValue: CoverageBreakdownDetails<string, GearDeductible> | null;
    gearEnsReplacementCost: CoverageBreakdownDetails<string, GearDeductible> | null;
    campsiteLiability: CoverageBreakdownDetails<CampsiteLiabilityLimit, null> | null;
    scheduledMedicalBenefit: CoverageBreakdownDetails<ScheduledMedicalBenefitLimit, null> | null;
    emergencyExpense: CoverageBreakdownDetails<EmergencyExpenseLimit, null> | null;
    towRoadside: CoverageBreakdownDetails<TowRoadsideLimit, null> | null;
    offRoadRecovery: CoverageBreakdownDetails<
        OffRoadRecoveryLimit,
        OffRoadRecoveryDeductible
    > | null;
}

export interface CoverageBreakdownRigsFinal extends CoverageBreakdownRigs {
    rigId: UUID;
}

export interface CoverageBreakdownRigs {
    trailerComprehensive: CoverageBreakdownDetails<string, UnitCompDeductible> | null;
    trailerCollision: CoverageBreakdownDetails<string, UnitCollDeductible> | null;
    camperComprehensive: CoverageBreakdownDetails<string, UnitCompDeductible> | null;
    camperCollision: CoverageBreakdownDetails<string, UnitCollDeductible> | null;
    modsComprehensive: CoverageBreakdownDetails<string, AcpeDeductible> | null;
    modsCollision: CoverageBreakdownDetails<string, AcpeDeductible> | null;
}

export interface PremiumBreakdownDetails {
    total: string;
    daily: string;
    percent: string;
}

export interface CoverageBreakdownDetails<T extends Limits | string, U extends Deductibles | null> {
    limit: T;
    deductible: U;
}

export interface EndorsementRates {
    refund: boolean;
    paymentPlanSources: PaymentPlanSource[];
    // proRatedTotal: string;
    // totals: CalculatedTotals;
    // taxesAndFees: CalculatedTaxesAndFees;
}

export interface CalculateRateEnsOnlyResponseToCalculate extends CalculateRateEnsOnlyResponse {
    foundRoc: RocForResults;
}

export interface ENSRigRateResponseFinal extends ENSRigRateResponse {
    rigId: UUID;
}

export interface ENSRigRateResponse {
    camperRate: ENSTermRateResponse<ENSTermRateResponseTotalCompColl> | null;
    trailerRate: ENSTermRateResponse<ENSTermRateResponseTotalCompColl> | null;
    modsRate: ENSTermRateResponse<ENSTermRateResponseTotalCompColl> | null;
}

export interface ENSGearRateResponse {
    actualCashValue: ENSTermRateResponse<ENSTermRateResponseTotal>;
    replacementCost: ENSTermRateResponse<ENSTermRateResponseTotal>;
}

export enum ENSTermRateKey {
    sixMonth = 'sixMonth',
}

export type ENSTermRateResponse<T> = { [index in ENSTermRateKey]: T };
export type ENSTermRatesToDays = { [index in ENSTermRateKey]: number };

export interface ENSTermRateResponseTotal {
    total: string;
}

export interface ENSTermRateResponseTotalCompColl {
    total: string;
    comprehensive: string;
    collision: string;
    minAdjusted?: ENSTermRateResponseTotalCompColl;
}

export interface ENSSpecialRateResponse {
    campsiteLiabilityRate: ENSTermRateResponse<ENSTermRateResponseTotal>;
    emergencyExpenseRate: ENSTermRateResponse<ENSTermRateResponseTotal>;
    offRoadRecoveryRate: ENSTermRateResponse<ENSTermRateResponseTotal>;
    scheduledMedicalBenefitRate: ENSTermRateResponse<ENSTermRateResponseTotal>;
    towRoadsideRate: ENSTermRateResponse<ENSTermRateResponseTotal>;
}

export interface PPARateResponse {
    stepsResults: StepsResults;
    developedPremiums: {
        vehiclesDevelopedPremium: StepResults<InsuranceCoveragesBase>;
        trailersDevelopedPremium: StepResults<InsuranceCoveragesTrailer>;
    };
    quoteValues: {
        vehicle: string;
        trailer: string;
        ppaTotal: string;
    };
}

export interface StepsResults {
    householdSteps: StepResults<InsuranceCoveragesBase>[];
    developedDriverComputed: StepResults<InsuranceCoveragesBase>;
    householdRiskFactorComputed: StepResults<InsuranceCoveragesBase>;
    vehicleSteps: StepResults<InsuranceCoveragesBase>[];
    trailerSteps: StepResults<InsuranceCoveragesTrailer>[];
}

export interface ENSRateResponse {
    stepsResults: StepsResultsENS;
    developedPremiums: {
        unitsDevelopedPremium: StepResults<InsuranceCoveragesUnit> | null;
        acpeDevelopedPremium: StepResults<InsuranceCoveragesAcpe> | null;
        gearDevelopedPremium: StepResults<InsuranceCoveragesGear> | null;
        specialDevelopedPremium: StepResults<InsuranceCoveragesSpecial> | null;
    };
    quoteValues: {
        unit: ENSTermRateResponseTotalCompColl;
        acpe: ENSTermRateResponseTotalCompColl;
        gearACV: ENSTermRateResponseTotal;
        gearRC: ENSTermRateResponseTotal;
        special: ENSTermRateResponseTotal;
        specials: {
            campsiteLiability: ENSTermRateResponseTotal;
            emergencyExpense: ENSTermRateResponseTotal;
            offRoadRecovery: ENSTermRateResponseTotal;
            scheduledMedicalBenefit: ENSTermRateResponseTotal;
            towRoadside: ENSTermRateResponseTotal;
        };
    };
}

export interface StepsResultsENS {
    // householdSteps: StepResults<InsuranceCoveragesENS>[];
    // developedDriverComputed: StepResults<InsuranceCoveragesENS>;
    // householdRiskFactorComputed: StepResults<InsuranceCoveragesENS>;
    unitSteps: StepResults<InsuranceCoveragesUnit>[] | null;
    acpeSteps: StepResults<InsuranceCoveragesAcpe>[] | null;
    gearSteps: StepResults<InsuranceCoveragesGear>[] | null;
    specialSteps: StepResults<InsuranceCoveragesSpecial>[] | null;
}

export interface StepResults<T extends string> {
    stepKey: RocStepKey;
    foundRecordsPerValue: Record<string, string>[];
    postStepFactorsPerValue: InsuranceCoverageFactors<T, string>[];
}
