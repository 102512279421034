import { InjectionToken } from '@angular/core';
import { PlatformService } from '@common/services/platform.service';

export const SessionStorageToken = new InjectionToken('STORAGE');
export function sessionStorageProvider(platformService: PlatformService) {
    if (platformService.isBrowser) {
        return sessionStorage;
    }
    if (platformService.isServer) {
        return {
            getItem: () => {},
            setItem: () => {},
            removeItem: () => {},
        } as unknown as Storage;
    }
}
