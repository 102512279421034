import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ScreenSize, screenSizeValues } from '@common/models';
import { ResizeService } from '@common/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-read-more-less',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './read-more-less.component.html',
    styleUrls: ['read-more-less.component.scss'],
})
export class ReadMoreLessComponent implements OnInit, OnDestroy, AfterContentInit {
    @Input() maxWords!: number;
    @Input() maxScreenSize: number = screenSizeValues[ScreenSize.md];
    @ContentChild('passedText') passedText!: ElementRef<HTMLSpanElement>;

    originalText!: string[];
    screenSize!: ScreenSize;

    collapsed = true;
    enabledForScreenSize = true;

    subscription: Subscription = new Subscription();

    constructor(
        private resizeService: ResizeService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {}

    ngAfterContentInit() {
        const textArray = this.passedText.nativeElement.textContent?.split(' ');
        if (!textArray) {
            throw new Error('NO_CONTENT_TO_SPLIT');
        }
        if (textArray.length <= this.maxWords + 1) {
            this.enabledForScreenSize = false;
            return;
        }

        this.originalText = textArray;
        this.passedText.nativeElement.textContent = textArray.slice(0, this.maxWords).join(' ');

        this.subscription.add(
            this.resizeService.screenSize$.subscribe((screenSize) => {
                this.screenSize = screenSize;
                if (screenSizeValues[this.screenSize] > this.maxScreenSize) {
                    this.enabledForScreenSize = false;
                    this.passedText.nativeElement.textContent = this.originalText.join(' ');
                } else {
                    this.enabledForScreenSize = true;
                    if (this.collapsed) {
                        this.passedText.nativeElement.textContent = this.originalText
                            .slice(0, this.maxWords)
                            .join(' ');
                    } else {
                        this.passedText.nativeElement.textContent = this.originalText.join(' ');
                    }
                }
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    readLess() {
        this.passedText.nativeElement.textContent = this.originalText
            .slice(0, this.maxWords)
            .join(' ');
        this.collapsed = true;
        this.changeDetectorRef.detectChanges();
    }

    readMore() {
        this.passedText.nativeElement.textContent = this.originalText.join(' ');
        this.collapsed = false;
        this.changeDetectorRef.detectChanges();
    }
}
