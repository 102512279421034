import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService, LogService, UserService } from '@common/services';
import { AuthService } from '@modules/auth/services';
import { Subscription, take } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-password',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-password.component.html',
    styleUrls: ['quote-ens-password.component.scss'],
})
export class QuoteEnsPasswordComponent implements OnInit, OnDestroy {
    userID!: string;
    email!: string;
    passwordResetToken!: string;
    subscription: Subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private logService: LogService,
        private userService: UserService,
        private authService: AuthService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Password');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_password',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.route.queryParamMap.subscribe((queryParamMap) => {
                const userID = queryParamMap.get('user-id');
                if (userID === null) {
                    this.logService.error('USER_ID_NOT_IN_QUERY_PARAM_MAP');
                    return;
                }
                this.userID = userID;
                const passwordResetToken = queryParamMap.get('password-reset-token');
                if (passwordResetToken === null) {
                    this.logService.error('PASSWORD_RESET_TOKEN_NOT_IN_QUERY_PARAM_MAP');
                    return;
                }
                this.passwordResetToken = passwordResetToken;
                // https://localhost:4567/quote/ens?quote-ens-form-step=password&password-reset-token=WLUQ-EON8-SALS&user-id=6660c2b1-e23f-4037-9643-12b946f571c8
                // const fromEmail = queryParamMap.get('from-email');
                // if (fromEmail) {
                this.authService
                    .isNewUser$({ userID })
                    .pipe(take(1))
                    .subscribe((isNewUserResponse) => {
                        if (!isNewUserResponse.isNewUser) {
                            this.router.navigate(['/account/insurance/dashboard']);
                            return;
                        }
                        if (!isNewUserResponse.email) {
                            throw new Error('EMAIL_EXPECTED_IN_IS_NEW_USER_RESPONSE');
                        }
                        this.email = isNewUserResponse.email;
                        this.changeDetectorRef.detectChanges();
                    });
                // }

                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.userService.activeAuthUser$.subscribe(() => {
                this.router.navigate(['/account/insurance/dashboard']);
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
