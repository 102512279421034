import { HttpClientModule } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppCommonServicesModule } from '@common/app-common-services.module';
import { NavigationServicesModule } from '@modules/navigation/navigation-services.module';
import { QuoteServicesModule } from '@modules/quote/quote-services.module';
import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        HammerModule,
        AppCommonServicesModule.forRoot(),
        NavigationServicesModule.forRoot(),
        QuoteServicesModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            // registrationStrategy: 'registerWhenStable:30000',
            registrationStrategy: 'registerImmediately',
        }),
        RouterModule,
    ],
    providers: [
        {
            provide: APP_ID,
            useValue: 'serverApp',
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    // constructor(trace: Sentry.TraceService) {}
}
