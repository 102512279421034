import { TemplateRef } from '@angular/core';

export interface ToastMessage {
    uuid: string;
    header?: ToastMessageString;
    body?: ToastMessageString;
    bodySwitch?: ToastBodySwitch;
    options?: ToastMessageOptions;
}

export type ToastMessageString = string | TemplateRef<unknown>;

export interface ToastMessageOptions {
    autohide?: boolean;
    delay?: number;
    classes?: string;
    headerClasses?: string;
    bodyClasses?: string;
    bodyIcon?: string;
    closeOnClick?: boolean;
    noCloseButton?: boolean;
}

export enum ToastBodySwitch {
    'reloadSite' = 'reloadSite',
}

export interface ToastMessageShowParameters<T> {
    header?: ToastMessageString;
    body?: ToastMessageString;
    bodySwitch?: ToastBodySwitch;
    toastData?: T;
    options?: ToastMessageOptions;
    flatten?: boolean;
}
