import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DownloadService, OverlayService } from '@common/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-overlay',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './overlay.component.html',
    styleUrls: ['overlay.component.scss'],
})
export class OverlayComponent implements OnInit {
    subscription: Subscription = new Subscription();

    showOverlay = false;
    progress!: number;

    constructor(
        public overlayService: OverlayService,
        private downloadService: DownloadService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.overlayService.isVisible$.subscribe((isVisible) => {
            this.showOverlay = isVisible;
            if (isVisible && this.overlayService.template === 'PROGRESS') {
                this.progress = 0;
                this.downloadService.downloadProgress$.subscribe((downloadProgress) => {
                    this.progress = downloadProgress.progress;
                    if (downloadProgress.state === 'DONE') {
                        this.overlayService.hide();
                    }
                    this.changeDetectorRef.detectChanges();
                });
            }

            this.changeDetectorRef.detectChanges();
        });
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
