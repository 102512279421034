<div class="container-xxl">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-block d-none"><div class="quote-title">Here’s your quote!</div></div>
        </div>
        <div class="quote-price-card bg-topographic-dark p-3" data-cy="quotePriceCard" #quoteBox [ngClass]="{fixed: quotePriceCardFixed, contracted: !quotePriceCardFixed}">
            <div class="quote-price-card-top d-flex align-items-center">
                <div class="price-box" *ngIf="quoteEnsTotal">
                    <div class="d-flex justify-content-between align-items-stretch">
                        <div class="d-flex justify-content-start align-items-center flex-column">
                            <div class="text-sm text-cyan-blue">Paid in full</div>
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="price-symbol text-grey-blue pt-2 me-1">$</div>
                                <div class="price-dollars text-white me-1" data-cy="quoteTotalDollars">{{quoteEnsTotal.quoteTotalDollars | currency : "USD" : "" : "1.0"}}</div>
                                <div class="price-cents text-white" data-cy="quoteTotalCents">.{{quoteEnsTotal.quoteTotalCents}}</div>
                            </div>
                            <div class="r-text-base-xs text-white mt-1">6 month term</div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <div class="quote-separator text-grey-blue flex-grow-1"></div>
                            <div class="text-white mx-3 mx-sm-5">or</div>
                            <div class="quote-separator text-grey-blue flex-grow-1 mb-3"></div>
                        </div>
                        <div class="d-flex justify-content-start align-items-center flex-column">
                            <div class="text-sm text-cyan-blue">Paid monthly</div>
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="price-symbol text-grey-blue pt-2 me-1">$</div>
                                <div class="price-dollars text-white me-1" data-cy="quotePaymentsInstallmentDollars">{{quoteEnsTotal.quotePaymentsInstallmentDollars | currency : "USD" : "" : "1.0"}}</div>
                                <div class="price-cents text-white" data-cy="quotePaymentsInstallmentCents">.{{quoteEnsTotal.quotePaymentsInstallmentCents}}</div>
                            </div>
                            <span><div class="r-text-base-xs text-start w-100 text-white mt-1 mb-3">First payment of {{quoteEnsTotal.quotePaymentsInitial | currency : "USD" : "symbol" : "1.2"}}</div></span>
                        </div>
                    </div>
                </div>
                <div class="fixed-purchase-section d-flex justify-content-between align-items-center pt-lg-5 flex-row flex-lg-column">
                    <div class="order-2 order-lg-1">
                        <button class="purchase-button btn rounded-pill btn-primary-gradient quote-next-button fixed-button" data-cy="ratesPurchaseFixedButton" type="button" (click)="purchase()" [disabled]="rateIssues.length &gt; 0" tabindex="0">
                            <div class="d-flex align-items-center justify-content-center">Purchase</div>
                            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
                        </button>
                        <div class="fixed-rate-issues text-danger text-xs mt-2" *ngFor="let rateIssue of rateIssues; let i = index;">{{rateIssue.message}}</div>
                    </div>
                    <button class="order-1 order-lg-2 btn fixed-button text-cyan-blue text-link baby-blue-hover px-1 mt-2" data-cy="ratesEmailQuoteButtonFixed" (click)="emailQuote()"><div class="text-nowrap">email quote</div></button>
                </div>
            </div>
            <div class="quote-price-card-bottom mx-n3 mb-n3 bg-white px-5">
                <button class="purchase-button btn rounded-pill btn-primary-gradient quote-next-button mt-md-5 mt-3" data-cy="ratesPurchaseButton" type="button" (click)="purchase()" [disabled]="rateIssues.length &gt; 0" tabindex="0">
                    <div class="d-flex align-items-center justify-content-center">Purchase</div>
                    <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
                </button>
                <div class="text-danger text-xs mt-2" *ngFor="let rateIssue of rateIssues; let i = index;">{{rateIssue.message}}</div>
                <div class="mb-md-5 mb-3"></div>
                <div class="under-purchase-disclaimer pb-md-3 pb-3">
                    <div class="d-flex align-items-start">
                        <i-bs class="text-baby-blue me-2" name="exclamation-circle-fill" width="1.5rem" height="1.5rem"></i-bs>
                        <div class="r-text-base text-start text-sm text-grey-blue">This quote is based on limited information and may change after purchase as you add more details about yourself, vehicle(s), or coverage needs.</div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center mt-2"><button class="btn text-baby-blue" data-cy="ratesEmailQuoteButton" (click)="emailQuote()">email quote</button></div>
                </div>
            </div>
        </div>
        <div class="under-quote-box text-start text-md-center mx-3 mx-sm-5 mx-md-0 mb-md-5 mb-3 pt-md-3" #underQuoteBox>
            <div class="d-md-none d-block">
                <div class="quote-title">You can adjust your</div>
                <div class="quote-title">coverage below.</div>
            </div>
            <div class="d-md-block d-none"><div class="quote-title">You can adjust your coverage below.</div></div>
            <div class="r-text-base-xs mt-2">Policies are underwritten by Houston Casualty Company, a Tokio Marine Company</div>
        </div>
        <div class="d-flex justify-content-start mb-3"><sbf-back-button data-cy="ratesBackButton" (click)="back.emit()"></sbf-back-button></div>
        <sbf-coverage-adjustments-form-rig [editValues]="true"></sbf-coverage-adjustments-form-rig><sbf-coverage-adjustments-form-gear [editValues]="true"></sbf-coverage-adjustments-form-gear><sbf-coverage-adjustments-form-extras></sbf-coverage-adjustments-form-extras>
    </div>
    <div class="row">
        <div class="col-md-6 col-12"><sbf-coverage-adjustments-form-home-base></sbf-coverage-adjustments-form-home-base></div>
        <div class="col-md-6 col-12"><sbf-coverage-adjustments-form-personal-info></sbf-coverage-adjustments-form-personal-info></div>
    </div>
</div>
