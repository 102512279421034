import type { RocState } from '../states';
import type { RocStepKey } from './roc-lib-steps';
import { rocStepDisplayNames } from './roc-lib-steps';
import type { RocTableKey } from './roc-lib-table';
import type { RocVariableType } from './roc-lib-variable-types';

export interface RocStateConfig {
    state: RocState;
    rocTableKeys: RocTableKey[];
    rocSteps: RocStepsConfig;
    rocTestDataConfig: RocTestDataConfig[];
}

export interface RocTableConfig {
    tableKey: string;
    displayName: string;
    columnsFactors: RocTableConfigColumns[];
    columnsVariables: RocTableConfigColumns[];
}

export interface RocTableConfigColumns {
    name: string;
    keyNames: string[];
    type: RocVariableType;
    defaultValue?: unknown;
}

export interface RocStepsConfig {
    householdSteps: RocStep[];
    vehicleSteps: RocStep[];
    trailerSteps: RocStep[];
    // ensHouseholdSteps?: RocStep[];
    ensUnitSteps?: RocStep[];
    ensAcpeSteps?: RocStep[];
    ensGearSteps?: RocStep[];
    ensSpecialSteps?: RocStep[];
}

export enum StepOperation {
    instantiate = 'instantiate',
    multiply = 'multiply',
    subtractFromOneMultiply = 'subtractFromOneMultiply',
    addToOneMultiply = 'addToOneMultiply',
    add = 'add',
    subtract = 'subtract',
    divide = 'divide',
    round = 'round',
}
export interface RocStep<T extends RocStepKey = RocStepKey> {
    stepKey: T;
    stepDisplayName: EnumMap<T>[T];
    stepOperation: StepOperation;
    stepTableKey: RocTableKey;
    stepDependentTableKeys?: RocTableKey[];
    enabled: boolean;
    // hasVariables: boolean;
}

export interface rocStepConfig<T> {
    stepKey: T;
    stepOperation: StepOperation;
    stepTableKey: RocTableKey;
    stepDependentTableKeys?: RocTableKey[];
    // hasVariables: boolean;
}
export const rocStep = <T extends RocStepKey = RocStepKey>(
    config: rocStepConfig<T>
): RocStep<T> => {
    return {
        stepKey: config.stepKey,
        stepOperation: config.stepOperation,
        stepDisplayName: rocStepDisplayNames[config.stepKey],
        stepTableKey: config.stepTableKey,
        stepDependentTableKeys: config.stepDependentTableKeys,
        enabled: true,
        // hasVariables: config.hasVariables,
    };
};

export interface RocTestDataConfig {
    name: string;
    description: string;
    drivers: number;
    vehicles: number;
    trailers: number;
    units: number;
    data: RocData[];
}

export interface RocData {
    stepKeyName: RocStepKey;
    stepType: RocStepType;
    stepVariables: RocDataVariable[];
}

export interface RocDataVariable {
    variableKeyName: string;
    type: RocVariableType;
    values: unknown[];
}

export enum RocStepType {
    household = 'household',
    vehicle = 'vehicle',
    trailer = 'trailer',
    ensHousehold = 'ensHousehold',
    ensUnit = 'ensUnit',
    ensAcpe = 'ensAcpe',
    ensGear = 'ensGear',
    ensSpecial = 'ensSpecial',
}
