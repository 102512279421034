import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PaymentPlanInstallment } from '@backend-types/payment-plan';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbf-future-payments-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './future-payments-modal.component.html',
    styleUrls: ['future-payments-modal.component.scss'],
})
export class FuturePaymentsModalComponent implements OnInit {
    installments!: PaymentPlanInstallment[];

    constructor(private activeModal: NgbActiveModal) {}
    ngOnInit() {}
    close() {
        this.activeModal.close();
    }
}
