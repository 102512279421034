import { DriverMaritalStatus } from '@backend-types/driver';
import { PersonalInfoScenarioButtons, PersonalInfoScenarios } from '@common/models/dev-utils.model';

export const personalInfoScenarios: PersonalInfoScenarios = {
    ageException: {
        buttonText: 'Age Exception',
        testId: PersonalInfoScenarioButtons.ageException,
        formValues: {
            firstName: 'Testy',
            lastName: 'McTestface',
            birthday: '2005-05-06',
            email: `test_${new Date().valueOf()}@test.test`,
            phone: '5551234567',
            maritalStatus: DriverMaritalStatus.married,
            tcpaAgree: true,
        },
    },
    common: {
        buttonText: 'Common',
        testId: PersonalInfoScenarioButtons.common,
        formValues: {
            firstName: 'Testy',
            lastName: 'McTestface',
            birthday: '1978-05-06',
            email: `test_${new Date().valueOf()}@test.test`,
            phone: '5551234567',
            maritalStatus: DriverMaritalStatus.married,
            tcpaAgree: true,
        },
    },
    goodCredit: {
        buttonText: 'Good Credit test User',
        testId: PersonalInfoScenarioButtons.goodCredit,
        formValues: {
            firstName: 'AJMILH',
            lastName: 'EGWZKD',
            birthday: '1967-06-19',
            phone: '5551234567',
            email: `test-good-credit@tredder.test`,
            maritalStatus: DriverMaritalStatus.single,
            tcpaAgree: true,
        },
    },
    badCredit: {
        buttonText: 'Bad Credit test User',
        testId: PersonalInfoScenarioButtons.badCredit,
        formValues: {
            firstName: 'YFTIZQQ',
            lastName: 'ZXJDF',
            birthday: '1966-04-08',
            phone: '5551234567',
            email: `test-bad-credit@tredder.test`,
            maritalStatus: DriverMaritalStatus.married,
            tcpaAgree: true,
        },
    },
};
