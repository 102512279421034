import type { PolicyFlag } from './_prisma';
import type { ExistingCoveragePullStatus } from './coverage-verification';
import type { EmailTrackingStatus } from './email-log';
import type { InvoiceStatus, PaymentPlanName } from './payment-plan';
import type { PolicyStatus } from './policy-ens';
import type { QuoteStatus } from './quote-ens';
import type { HomeBaseFormValue, QuoteEnsFlowFormValue } from './quote-flow-ens';
import type { UserForAuthentication } from './user';

export interface ResetDBPayload {
    seedTredder?: boolean;
    seedAll?: boolean;
    seedUsers?: boolean;
    seedPosts?: boolean;
    usersSeedFilter?: UsersSeedFilter;
    justSeed?: boolean;
}

export interface UsersSeedFilter {
    organizationFilters?: string[];
    userFilters?: string[];
}

export interface UserFromEmailPayload {
    email: string;
}

export interface UserFromEmailResponse {
    result: UserForAuthentication;
}

export interface RigsFromEmailPayload {
    email: string;
}

export interface GeneratePoliciesPayload {
    mode: 'cheers' | 'random' | 'testy';
    numberOfPolicies?: number;
    dateStart?: string;
    dateEnd?: string;
    effectiveDate?: string;
    paymentPlanName?: PaymentPlanName;
    firstInvoiceStatus?: InvoiceStatus;
    returnUsers?: boolean;
    ensHomeBase?: HomeBaseFormValue;
    existingCoveragePullStatus?: ExistingCoveragePullStatus;
    modsCoverage?: boolean | 'random';
    camperCoverage?: boolean | 'random';
    trailerCoverage?: boolean | 'random';
    gearCoverage?: boolean | 'random';
}

export type GeneratePoliciesFnResponse = Omit<GeneratePoliciesResponse, 'result'>;

export interface GeneratePoliciesResponse {
    result: string;
    generatedPolicyUUIDs: string[];
    createdUsers: UserForAuthentication[] | null;
}

export interface DeleteUserPayload {
    email: string;
}

export interface DeleteUserResponse {
    result: string;
}

export interface ChangeStatusPayload {
    invoice?: {
        id: UUID;
        status: InvoiceStatus;
    };
    policyEns?: {
        id: UUID;
        status: PolicyStatus;
        effectiveDate?: Date;
    };
    quoteEns?: {
        id: UUID;
        status: QuoteStatus;
    };
}

export interface ChangeStatusResponse {
    result: string;
}

export interface TestErrorPayload {
    name: string;
}

export interface TestErrorResponse {
    result: string;
}

export interface CronJobOptionalPolicyFilter {
    policyUuidFilter?: string[];
}

export interface UpdateStatusesPayload extends CronJobOptionalPolicyFilter {
    numberOfDays?: number;
    invoicesDue?: boolean;
    invoicesOverDue?: boolean;
    policiesCanceled?: boolean;
    policiesLapsed?: boolean;
    policiesUploadRequirementsMet?: boolean;
}

export type ProcessAutoBillingPayload = CronJobOptionalPolicyFilter;

export interface UpdateStatusesResponse {
    updatedInvoicesDue?: number;
    updatedInvoicesOverDue?: number;
    updatedInvoicesCanceled?: number;
    updatedPoliciesCanceled?: number;
    updatedPoliciesLapsed?: number;
}

export interface QaGeneratePayload {
    toGenerate: 'quote' | 'policy' | 'quoteStandard';
    withUser?: QaGeneratePayloadWithUser;
    modsCoverage?: boolean | 'random';
    camperCoverage?: boolean | 'random';
    trailerCoverage?: boolean | 'random';
    gearCoverage?: boolean | 'random';
    existingCoverageManual?: boolean;
    addQuotePrincipalDriver?: boolean;
}

export interface QaGeneratePayloadWithUser {
    firstName: string;
    lastName: string;
    email: string;
}

export interface QaGenerateResponse {
    quoteResponse?: QuoteResponse;
    policyResponse?: PolicyResponse;
    quoteResponseStandard?: QuoteResponse;
}

export function assertQaGenerateQuoteResponse(
    quoteResponse?: QuoteResponse
): asserts quoteResponse is QuoteResponse {
    if (!quoteResponse) {
        throw new Error(`IS_NOT_QA_GENERATE_QUOTE_RESPONSE: ${quoteResponse}`);
    }
}

export function assertQaGeneratePolicyResponse(
    policyResponse?: PolicyResponse
): asserts policyResponse is PolicyResponse {
    if (!policyResponse) {
        throw new Error(`IS_NOT_QA_GENERATE_POLICY_RESPONSE: ${policyResponse}`);
    }
}

export function assertQaGenerateQuoteResponseStandard(
    quoteResponseStandard?: QuoteResponse
): asserts quoteResponseStandard is QuoteResponse {
    if (!quoteResponseStandard) {
        throw new Error(`IS_NOT_QA_GENERATE_QUOTE_RESPONSE_STANDARD: ${quoteResponseStandard}`);
    }
}

export interface QuoteResponse {
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    email: string;
}

export interface PolicyResponse {
    token: string;
}

export enum RunEnvironment {
    local = 'LOCAL',
    develop = 'DEVELOP',
    stage = 'STAGE',
    production = 'PRODUCTION',
}

// Only set in Non-Production Run Environments
export enum RunEnvironmentRole {
    qa = 'QA',
    testing = 'TESTING',
    load = 'LOAD',
    live = 'LIVE',
}

export interface RunEnvironmentFull {
    runEnvironment: RunEnvironment;
    runEnvironmentRole: RunEnvironmentRole;
}

export interface processAutoBillingResponse {
    result: string;
    invoicesPaid: number;
}

export interface CheckPolicyFlagsPayload {
    policyEnsId: UUID;
}

export interface CheckPolicyFlagsResponse {
    policyFlags: PolicyFlag[];
}

export interface GetQuoteStatusPayload {
    quoteEnsId: UUID;
}

export interface GetQuoteStatusResponse {
    result: string;
}

export interface CheckEmailStatusPayload {
    dynamicTemplateName: string;
    userEmail: string;
    quoteEnsId?: UUID;
    policyEnsId?: UUID;
}

export interface CheckEmailStatusResponse {
    status: EmailTrackingStatus;
}

export interface CheckPolicyStatusPayload {
    id: string;
}

export interface CheckPolicyStatusResponse {
    status: PolicyStatus;
}

export interface GenerateQuotesPayload {
    qaGeneratePayload: QaGeneratePayload;
    numberOfQuotes?: number;
    dateStart?: string;
    dateEnd?: string;
}

export interface GenerateQuotesResponse {
    result: string;
    generatedQuoteUUIDs: UUID[];
}
