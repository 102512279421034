import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
    CoverageSelectKey,
    QrsModsDetailsFormValue,
    QuoteRigSetFormValue,
} from '@backend-types/quote-flow-ens';
import { AcpeDeductible } from '@backend-types/roc-lib';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { AnalyticsService, AssertionService, HotKeysService } from '@common/services';
import { modsValuesOptionsRecordsArray } from '@data/select-options-records-arrays.data';
import { valueLimits } from '@data/value-limits.data';
import { QuoteHelpModalComponent } from '@modules/quote/components/quote-help-modal/quote-help-modal.component';
import { QuoteRigSetFormService, RoundingService } from '@modules/quote/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-rig-set-form-mods-details',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-rig-set-form-mods-details.component.html',
    styleUrls: ['quote-rig-set-form-mods-details.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteRigSetFormModsDetailsComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteRigSetFormModsDetailsComponent,
        },
    ],
})
export class QuoteRigSetFormModsDetailsComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator, AfterViewInit
{
    @ViewChild('focusElement') focusElement!: ElementRef<HTMLElement>;
    @Input() focusOnLoad = false;
    @Input() formOnly = false;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();
    @Output() modsDetailsFormValid = new EventEmitter<boolean>();

    subscription: Subscription = new Subscription();

    activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;

    valueLimits = valueLimits;
    modsValueRounded = false;

    modsValuesOptionsRecordsArray = modsValuesOptionsRecordsArray;

    modsDetailsForm: ModelFormGroup<QrsModsDetailsFormValue> = this.fb.group({
        modsValue: new FormControl<number | null>(null, [
            Validators.required,
            Validators.min(valueLimits.mods.min),
            Validators.max(valueLimits.mods.max),
        ]),
        modsDeductible: new FormControl<AcpeDeductible | null>(null, [Validators.required]),
    });

    onTouched: () => unknown = () => {};
    onChange = (rigDetails: ModelFormValue<QrsModsDetailsFormValue> | null) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private ngbModalService: NgbModal,
        private roundingService: RoundingService,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Mods Details');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_mods_details',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.modsDetailsForm.patchValue({
                    modsValue: 10000,
                });
                this.changeDetectorRef.detectChanges();
            })
        );
        this.subscription.add(
            this.quoteRigSetFormService
                .getActiveQuoteRigSetForm$()
                .subscribe((activeQuoteRigSetForm) => {
                    console.log(activeQuoteRigSetForm);

                    this.activeQuoteRigSetForm = activeQuoteRigSetForm;
                    this.changeDetectorRef.detectChanges();
                })
        );
        this.subscription.add(
            this.modsValueControl.valueChanges.pipe(debounceTime(600)).subscribe((modsValue) => {
                this.modsValueRounded =
                    this.roundingService.roundInputValue({
                        inputValue: modsValue,
                        inputValueControl: this.modsValueControl,
                        minValue: valueLimits.mods.min,
                        roudedTo: valueLimits.mods.roundedTo,
                    }) || this.modsValueRounded;

                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngAfterViewInit() {
        if (this.focusOnLoad) {
            this.focusElement.nativeElement.focus();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (rigDetails: ModelFormValue<QrsModsDetailsFormValue> | null) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.modsDetailsForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.modsDetailsForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        this.modsDetailsFormValid.emit(this.modsDetailsForm.valid);
                        const rigDetails = this._modsDetailsFormValue();
                        if (this.modsValueControlValid) {
                            onChange(rigDetails);
                        }
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.modsDetailsForm.disable();
        } else {
            this.modsDetailsForm.enable();
        }
    }

    writeValue(value: QrsModsDetailsFormValue | null) {
        if (value === null) {
            this.modsDetailsForm.reset();
        }
        if (value) {
            this.modsDetailsForm.setValue(value, { emitEvent: false });
            this.changeDetectorRef.detectChanges();
            this.modsDetailsFormValid.emit(this.modsDetailsForm.valid);
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.modsDetailsForm?.invalid) {
            return { modsDetailsFormInvalid: true };
        }

        return null;
    }

    private _modsDetailsFormValue(): ModelFormValue<QrsModsDetailsFormValue> {
        const { modsValue, modsDeductible } = this.modsDetailsForm.value;

        this.assertionService.isDefinedOrThrow(modsValue);
        this.assertionService.isDefinedOrThrow(modsDeductible);

        return {
            modsValue,
            modsDeductible,
        };
    }

    showHelp() {
        const {
            componentInstance,
        }: {
            componentInstance: QuoteHelpModalComponent;
            result: Promise<unknown>;
        } = this.ngbModalService.open(QuoteHelpModalComponent, {
            size: 'lg',
            centered: false,
        });

        componentInstance.title = 'Mods';
        componentInstance.text =
            'Mods are not original equipment and are permanently installed on your rig.';
        componentInstance.text2 =
            'This may include, but is not limited to: winches, tool benches, rooftop tents, battery systems, modified suspensions, snorkels, etc…';
        componentInstance.titleClasses = 'r-text-lg heavy text-primary';

        // componentInstance.modificationKind = modificationKind;
    }

    goNext() {
        this.quoteRigSetFormService.updateRigCoverageSelect(CoverageSelectKey.modsCoverage, true);
        this.next.emit();
    }

    /* Accessor Methods */

    get modsValueControl() {
        return this.modsDetailsForm.get('modsValue') as FormControl;
    }

    get modsValueControlValid() {
        return (
            this.modsValueControl.value &&
            !(
                this.modsValueControl.hasError('required') ||
                this.modsValueControl.hasError('min') ||
                this.modsValueControl.hasError('max')
            )
        );
    }

    get modsValueControlInvalid() {
        return (
            (this.modsValueControl.touched ||
                this.assertionService.isDefined(this.modsValueControl.value)) &&
            (this.modsValueControl.hasError('required') ||
                this.modsValueControl.hasError('min') ||
                this.modsValueControl.hasError('max'))
        );
    }
}
