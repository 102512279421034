import { AuthService } from './auth.service';
import { SocialFacebookService } from './social-facebook.service';
import { SocialGithubService } from './social-github.service';
import { SocialGoogleService } from './social-google.service';
import { SocialTwitterService } from './social-twitter.service';

export const services = [
    AuthService,
    SocialFacebookService,
    SocialGithubService,
    SocialGoogleService,
    SocialTwitterService,
];

export * from './auth.service';
export * from './social-facebook.service';
export * from './social-github.service';
export * from './social-google.service';
export * from './social-twitter.service';
