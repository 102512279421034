import type { StateTaxesAndFeesStates } from './taxes-fees';

export type States = keyof typeof State;

export enum State {
    alabama = 'alabama',
    alaska = 'alaska',
    americanSamoa = 'americanSamoa',
    arizona = 'arizona',
    arkansas = 'arkansas',
    california = 'california',
    colorado = 'colorado',
    connecticut = 'connecticut',
    delaware = 'delaware',
    districtOfColumbia = 'districtOfColumbia',
    florida = 'florida',
    georgia = 'georgia',
    guam = 'guam',
    hawaii = 'hawaii',
    idaho = 'idaho',
    illinois = 'illinois',
    indiana = 'indiana',
    iowa = 'iowa',
    kansas = 'kansas',
    kentucky = 'kentucky',
    louisiana = 'louisiana',
    maine = 'maine',
    maryland = 'maryland',
    massachusetts = 'massachusetts',
    michigan = 'michigan',
    minnesota = 'minnesota',
    mississippi = 'mississippi',
    missouri = 'missouri',
    montana = 'montana',
    nebraska = 'nebraska',
    nevada = 'nevada',
    newHampshire = 'newHampshire',
    newJersey = 'newJersey',
    newMexico = 'newMexico',
    newYork = 'newYork',
    northCarolina = 'northCarolina',
    northDakota = 'northDakota',
    northernMarianaIslands = 'northernMarianaIslands',
    ohio = 'ohio',
    oklahoma = 'oklahoma',
    oregon = 'oregon',
    pennsylvania = 'pennsylvania',
    puertoRico = 'puertoRico',
    rhodeIsland = 'rhodeIsland',
    southCarolina = 'southCarolina',
    southDakota = 'southDakota',
    tennessee = 'tennessee',
    texas = 'texas',
    trustTerritories = 'trustTerritories',
    utah = 'utah',
    vermont = 'vermont',
    virginia = 'virginia',
    virginIslands = 'virginIslands',
    washington = 'washington',
    westVirginia = 'westVirginia',
    wisconsin = 'wisconsin',
    wyoming = 'wyoming',
}

export enum StateShort {
    alabama = 'AL',
    alaska = 'AK',
    americanSamoa = 'AS',
    arizona = 'AZ',
    arkansas = 'AR',
    california = 'CA',
    colorado = 'CO',
    connecticut = 'CT',
    delaware = 'DE',
    districtOfColumbia = 'DC',
    florida = 'FL',
    georgia = 'GA',
    guam = 'GU',
    hawaii = 'HI',
    idaho = 'ID',
    illinois = 'IL',
    indiana = 'IN',
    iowa = 'IA',
    kansas = 'KS',
    kentucky = 'KY',
    louisiana = 'LA',
    maine = 'ME',
    maryland = 'MD',
    massachusetts = 'MA',
    michigan = 'MI',
    minnesota = 'MN',
    mississippi = 'MS',
    missouri = 'MO',
    montana = 'MT',
    nebraska = 'NE',
    nevada = 'NV',
    newHampshire = 'NH',
    newJersey = 'NJ',
    newMexico = 'NM',
    newYork = 'NY',
    northCarolina = 'NC',
    northDakota = 'ND',
    northernMarianaIslands = 'MP',
    ohio = 'OH',
    oklahoma = 'OK',
    oregon = 'OR',
    pennsylvania = 'PA',
    puertoRico = 'PR',
    rhodeIsland = 'RI',
    southCarolina = 'SC',
    southDakota = 'SD',
    tennessee = 'TN',
    texas = 'TX',
    trustTerritories = 'TT',
    utah = 'UT',
    vermont = 'VT',
    virginia = 'VA',
    virginIslands = 'VI',
    washington = 'WA',
    westVirginia = 'WV',
    wisconsin = 'WI',
    wyoming = 'WY',
}

export enum StateLong {
    alabama = 'Alabama',
    alaska = 'Alaska',
    americanSamoa = 'American Samoa',
    arizona = 'Arizona',
    arkansas = 'Arkansas',
    california = 'California',
    colorado = 'Colorado',
    connecticut = 'Connecticut',
    delaware = 'Delaware',
    districtOfColumbia = 'District Of Columbia',
    florida = 'Florida',
    georgia = 'Georgia',
    guam = 'Guam',
    hawaii = 'Hawaii',
    idaho = 'Idaho',
    illinois = 'Illinois',
    indiana = 'Indiana',
    iowa = 'Iowa',
    kansas = 'Kansas',
    kentucky = 'Kentucky',
    louisiana = 'Louisiana',
    maine = 'Maine',
    maryland = 'Maryland',
    massachusetts = 'Massachusetts',
    michigan = 'Michigan',
    minnesota = 'Minnesota',
    mississippi = 'Mississippi',
    missouri = 'Missouri',
    montana = 'Montana',
    nebraska = 'Nebraska',
    nevada = 'Nevada',
    newHampshire = 'New Hampshire',
    newJersey = 'New Jersey',
    newMexico = 'New Mexico',
    newYork = 'New York',
    northCarolina = 'North Carolina',
    northDakota = 'North Dakota',
    northernMarianaIslands = 'Northern Mariana Islands',
    ohio = 'Ohio',
    oklahoma = 'Oklahoma',
    oregon = 'Oregon',
    pennsylvania = 'Pennsylvania',
    puertoRico = 'Puerto Rico',
    rhodeIsland = 'Rhode Island',
    southCarolina = 'South Carolina',
    southDakota = 'South Dakota',
    tennessee = 'Tennessee',
    texas = 'Texas',
    trustTerritories = 'Trust Territories',
    utah = 'Utah',
    vermont = 'Vermont',
    virginia = 'Virginia',
    virginIslands = 'Virgin Islands',
    washington = 'Washington',
    westVirginia = 'West Virginia',
    wisconsin = 'Wisconsin',
    wyoming = 'Wyoming',
}

export const unlicensedStates: StateShort[] = [
    StateShort.newMexico,
    StateShort.ohio,
    StateShort.michigan,
    StateShort.newYork,
];

export enum ENSState {
    ens = 'ens',
}

export enum ENSStateShort {
    ens = 'ENS',
}

export enum ENSStateLong {
    ens = 'Excess & Surplus',
}

export type RocState = State | ENSState;

export type RocStates = keyof typeof State | ENSState;

// eslint-disable-next-line complexity
export const stateFromStringAbbreviation = (stateAbbreviation: string): StateTaxesAndFeesStates => {
    switch (stateAbbreviation.toUpperCase()) {
        case StateShort.alabama:
            return State.alabama;
        case StateShort.alaska:
            return State.alaska;
        case StateShort.arizona:
            return State.arizona;
        case StateShort.arkansas:
            return State.arkansas;
        case StateShort.california:
            return State.california;
        case StateShort.colorado:
            return State.colorado;
        case StateShort.connecticut:
            return State.connecticut;
        case StateShort.delaware:
            return State.delaware;
        case StateShort.districtOfColumbia:
            return State.districtOfColumbia;
        case StateShort.florida:
            return State.florida;
        case StateShort.georgia:
            return State.georgia;
        case StateShort.hawaii:
            return State.hawaii;
        case StateShort.idaho:
            return State.idaho;
        case StateShort.illinois:
            return State.illinois;
        case StateShort.indiana:
            return State.indiana;
        case StateShort.iowa:
            return State.iowa;
        case StateShort.kansas:
            return State.kansas;
        case StateShort.kentucky:
            return State.kentucky;
        case StateShort.louisiana:
            return State.louisiana;
        case StateShort.maine:
            return State.maine;
        case StateShort.maryland:
            return State.maryland;
        case StateShort.massachusetts:
            return State.massachusetts;
        case StateShort.michigan:
            return State.michigan;
        case StateShort.minnesota:
            return State.minnesota;
        case StateShort.mississippi:
            return State.mississippi;
        case StateShort.missouri:
            return State.missouri;
        case StateShort.montana:
            return State.montana;
        case StateShort.nebraska:
            return State.nebraska;
        case StateShort.nevada:
            return State.nevada;
        case StateShort.newHampshire:
            return State.newHampshire;
        case StateShort.newJersey:
            return State.newJersey;
        case StateShort.newMexico:
            return State.newMexico;
        case StateShort.newYork:
            return State.newYork;
        case StateShort.northCarolina:
            return State.northCarolina;
        case StateShort.northDakota:
            return State.northDakota;
        case StateShort.ohio:
            return State.ohio;
        case StateShort.oklahoma:
            return State.oklahoma;
        case StateShort.oregon:
            return State.oregon;
        case StateShort.pennsylvania:
            return State.pennsylvania;
        case StateShort.puertoRico:
            return State.puertoRico;
        case StateShort.rhodeIsland:
            return State.rhodeIsland;
        case StateShort.southCarolina:
            return State.southCarolina;
        case StateShort.southDakota:
            return State.southDakota;
        case StateShort.tennessee:
            return State.tennessee;
        case StateShort.texas:
            return State.texas;
        case StateShort.utah:
            return State.utah;
        case StateShort.vermont:
            return State.vermont;
        case StateShort.virginia:
            return State.virginia;
        case StateShort.virginIslands:
            return State.virginIslands;
        case StateShort.washington:
            return State.washington;
        case StateShort.westVirginia:
            return State.westVirginia;
        case StateShort.wisconsin:
            return State.wisconsin;
        case StateShort.wyoming:
            return State.wyoming;
    }
    throw new Error(`STATE_NOT_FOUND: ${stateAbbreviation}`);
};

// eslint-disable-next-line complexity
export const stringStateToState = (stringState: string): State => {
    switch (stringState.toLowerCase().replace(/\s+/g, '')) {
        case 'alabama':
        case 'al':
            return State.alabama;
        case 'alaska':
        case 'ak':
            return State.alaska;
        case 'americansamoa':
        case 'american-samoa':
        case 'as':
            return State.americanSamoa;
        case 'arizona':
        case 'az':
            return State.arizona;
        case 'arkansas':
        case 'ar':
            return State.arkansas;
        case 'california':
        case 'ca':
            return State.california;
        case 'colorado':
        case 'co':
            return State.colorado;
        case 'connecticut':
        case 'ct':
            return State.connecticut;
        case 'delaware':
        case 'de':
            return State.delaware;
        case 'districtofcolumbia':
        case 'district-of-columbia':
        case 'dc':
            return State.districtOfColumbia;
        case 'florida':
        case 'fl':
            return State.florida;
        case 'georgia':
        case 'ga':
            return State.georgia;
        case 'guam':
        case 'gu':
            return State.guam;
        case 'hawaii':
        case 'hi':
            return State.hawaii;
        case 'idaho':
        case 'id':
            return State.idaho;
        case 'illinois':
        case 'il':
            return State.illinois;
        case 'indiana':
        case 'in':
            return State.indiana;
        case 'iowa':
        case 'ia':
            return State.iowa;
        case 'kansas':
        case 'ks':
            return State.kansas;
        case 'kentucky':
        case 'ky':
            return State.kentucky;
        case 'louisiana':
        case 'la':
            return State.louisiana;
        case 'maine':
        case 'me':
            return State.maine;
        case 'maryland':
        case 'md':
            return State.maryland;
        case 'massachusetts':
        case 'ma':
            return State.massachusetts;
        case 'michigan':
        case 'mi':
            return State.michigan;
        case 'minnesota':
        case 'mn':
            return State.minnesota;
        case 'mississippi':
        case 'ms':
            return State.mississippi;
        case 'missouri':
        case 'mo':
            return State.missouri;
        case 'montana':
        case 'mt':
            return State.montana;
        case 'nebraska':
        case 'ne':
            return State.nebraska;
        case 'nevada':
        case 'nv':
            return State.nevada;
        case 'newhampshire':
        case 'new-hampshire':
        case 'nh':
            return State.newHampshire;
        case 'newjersey':
        case 'new-jersey':
        case 'nj':
            return State.newJersey;
        case 'newmexico':
        case 'new-mexico':
        case 'nm':
            return State.newMexico;
        case 'newyork':
        case 'new-york':
        case 'ny':
            return State.newYork;
        case 'northcarolina':
        case 'north-carolina':
        case 'nc':
            return State.northCarolina;
        case 'northdakota':
        case 'north-dakota':
        case 'nd':
            return State.northDakota;
        case 'northernmarianaislands':
        case 'northern-mariana-islands':
        case 'mp':
            return State.northernMarianaIslands;
        case 'ohio':
        case 'oh':
            return State.ohio;
        case 'oklahoma':
        case 'ok':
            return State.oklahoma;
        case 'oregon':
        case 'or':
            return State.oregon;
        case 'pennsylvania':
        case 'pa':
            return State.pennsylvania;
        case 'puertorico':
        case 'puerto-rico':
        case 'pr':
            return State.puertoRico;
        case 'rhodeisland':
        case 'rhode-island':
        case 'ri':
            return State.rhodeIsland;
        case 'southcarolina':
        case 'south-carolina':
        case 'sc':
            return State.southCarolina;
        case 'southdakota':
        case 'south-dakota':
        case 'sd':
            return State.southDakota;
        case 'tennessee':
        case 'tn':
            return State.tennessee;
        case 'texas':
        case 'tx':
            return State.texas;
        case 'trustterritories':
        case 'trust-territories':
        case 'tt':
            return State.trustTerritories;
        case 'utah':
        case 'ut':
            return State.utah;
        case 'vermont':
        case 'vt':
            return State.vermont;
        case 'virginia':
        case 'va':
            return State.virginia;
        case 'virginislands':
        case 'virgin-islands':
        case 'vi':
            return State.virginIslands;
        case 'washington':
        case 'wa':
            return State.washington;
        case 'westvirginia':
        case 'west-virginia':
        case 'wv':
            return State.westVirginia;
        case 'wisconsin':
        case 'wi':
            return State.wisconsin;
        case 'wyoming':
        case 'wy':
            return State.wyoming;
    }

    throw new Error(`STATE_NOT_FOUND_FOR_STRING: ${stringState}`);
};

// eslint-disable-next-line complexity
export const stringStateEnsQuoteStaticEnsStates = (
    stringState: string
): EnsQuoteStaticEnsStates => {
    switch (stringState.toLowerCase().replace(/\s+/g, '')) {
        case 'alaska':
        case 'ak':
            return State.alaska;
        case 'arkansas':
        case 'ar':
            return State.arkansas;
        case 'colorado':
        case 'co':
            return State.colorado;
        case 'connecticut':
        case 'ct':
            return State.connecticut;
        case 'florida':
        case 'fl':
            return State.florida;
        case 'georgia':
        case 'ga':
            return State.georgia;
        case 'hawaii':
        case 'hi':
            return State.hawaii;
        case 'idaho':
        case 'id':
            return State.idaho;
        case 'illinois':
        case 'il':
            return State.illinois;
        case 'iowa':
        case 'ia':
            return State.iowa;
        case 'kansas':
        case 'ks':
            return State.kansas;
        case 'kentucky':
        case 'ky':
            return State.kentucky;
        case 'louisiana':
        case 'la':
            return State.louisiana;
        case 'maine':
        case 'me':
            return State.maine;
        case 'maryland':
        case 'md':
            return State.maryland;
        case 'massachusetts':
        case 'ma':
            return State.massachusetts;
        case 'michigan':
        case 'mi':
            return State.michigan;
        case 'minnesota':
        case 'mn':
            return State.minnesota;
        case 'mississippi':
        case 'ms':
            return State.mississippi;
        case 'missouri':
        case 'mo':
            return State.missouri;
        case 'montana':
        case 'mt':
            return State.montana;
        case 'nebraska':
        case 'ne':
            return State.nebraska;
        case 'nevada':
        case 'nv':
            return State.nevada;
        case 'newhampshire':
        case 'new-hampshire':
        case 'nh':
            return State.newHampshire;
        case 'newmexico':
        case 'new-mexico':
        case 'nm':
            return State.newMexico;
        case 'newyork':
        case 'new-york':
        case 'ny':
            return State.newYork;
        case 'northcarolina':
        case 'north-carolina':
        case 'nc':
            return State.northCarolina;
        case 'northdakota':
        case 'north-dakota':
        case 'nd':
            return State.northDakota;
        case 'ohio':
        case 'oh':
            return State.ohio;
        case 'oklahoma':
        case 'ok':
            return State.oklahoma;
        case 'oregon':
        case 'or':
            return State.oregon;
        case 'pennsylvania':
        case 'pa':
            return State.pennsylvania;
        case 'rhodeisland':
        case 'rhode-island':
        case 'ri':
            return State.rhodeIsland;
        case 'southcarolina':
        case 'south-carolina':
        case 'sc':
            return State.southCarolina;
        case 'southdakota':
        case 'south-dakota':
        case 'sd':
            return State.southDakota;
        case 'tennessee':
        case 'tn':
            return State.tennessee;
        case 'texas':
        case 'tx':
            return State.texas;
        case 'utah':
        case 'ut':
            return State.utah;
        case 'vermont':
        case 'vt':
            return State.vermont;
        case 'washington':
        case 'wa':
            return State.washington;
        case 'westvirginia':
        case 'west-virginia':
        case 'wv':
            return State.westVirginia;
        case 'wisconsin':
        case 'wi':
            return State.wisconsin;
        case 'wyoming':
        case 'wy':
            return State.wyoming;
    }

    throw new Error(`STATE_NOT_FOUND_FOR_STRING: ${stringState}`);
};

export type EnsQuoteStaticEnsStates = Extract<
    State,
    | State.alaska
    | State.arkansas
    | State.colorado
    | State.connecticut
    | State.florida
    | State.georgia
    | State.hawaii
    | State.idaho
    | State.illinois
    | State.iowa
    | State.kansas
    | State.kentucky
    | State.louisiana
    | State.maine
    | State.maryland
    | State.massachusetts
    | State.michigan
    | State.minnesota
    | State.mississippi
    | State.missouri
    | State.montana
    | State.nebraska
    | State.nevada
    | State.newHampshire
    // | State.newJersey
    | State.newMexico
    | State.newYork
    | State.northCarolina
    | State.northDakota
    | State.ohio
    | State.oklahoma
    | State.oregon
    | State.pennsylvania
    | State.rhodeIsland
    | State.southCarolina
    | State.southDakota
    | State.tennessee
    | State.texas
    | State.utah
    | State.vermont
    | State.washington
    | State.westVirginia
    | State.wisconsin
    | State.wyoming
>;

export const ensQuoteStaticEnsStates: { [index in EnsQuoteStaticEnsStates]: boolean } = {
    [State.alaska]: true,
    [State.arkansas]: true,
    [State.colorado]: true,
    [State.connecticut]: true,
    [State.florida]: true,
    [State.georgia]: true,
    [State.hawaii]: true,
    [State.idaho]: true,
    [State.illinois]: true,
    [State.iowa]: true,
    [State.kansas]: true,
    [State.kentucky]: true,
    [State.louisiana]: true,
    [State.maine]: true,
    [State.maryland]: true,
    [State.massachusetts]: true,
    [State.michigan]: true,
    [State.minnesota]: true,
    [State.mississippi]: true,
    [State.missouri]: true,
    [State.montana]: true,
    [State.nebraska]: true,
    [State.nevada]: true,
    [State.newHampshire]: true,
    [State.newMexico]: true,
    [State.newYork]: true,
    [State.northCarolina]: true,
    [State.northDakota]: true,
    [State.ohio]: true,
    [State.oklahoma]: true,
    [State.oregon]: true,
    [State.pennsylvania]: true,
    [State.rhodeIsland]: true,
    [State.southCarolina]: true,
    [State.southDakota]: true,
    [State.tennessee]: true,
    [State.texas]: true,
    [State.utah]: true,
    [State.vermont]: true,
    [State.washington]: true,
    [State.westVirginia]: true,
    [State.wisconsin]: true,
    [State.wyoming]: true,
};

export type EnsQuoteDynamicEnsStates = Extract<State, State.california | State.newJersey>;

export const ensQuoteDynamicEnsStates: { [index in EnsQuoteDynamicEnsStates]: boolean } = {
    [State.california]: true,
    [State.newJersey]: true,
};

export type EnsQuoteAllEnsStates = EnsQuoteStaticEnsStates | EnsQuoteDynamicEnsStates;
