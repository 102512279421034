import { SortDirection } from '@common/directives';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export enum AgencyOrAdmin {
    agency = 'agency',
    admin = 'admin',
}

export enum AgencyAdminAccount {
    agency = 'agency',
    admin = 'admin',
    account = 'account',
}

export enum SettingsCacheKey {
    datesFilter = 'datesFilter',
    quotesTable = 'quotesTable',
    policiesTable = 'policiesTable',
    claimsTable = 'claimsTable',
    bdxPoliciesTable = 'bdxPoliciesTable',
}

export type SettingsCache = {
    datesFilter?: {
        fromDate: NgbDate;
        toDate: NgbDate;
    };
    quotesTable?: {
        sortedColumn?: string;
        sortedDirection?: SortDirection;
        pageSize?: number;
    };
    policiesTable?: {
        sortedColumn?: string;
        sortedDirection?: SortDirection;
        pageSize?: number;
    };
    claimsTable?: {
        sortedColumn?: string;
        sortedDirection?: SortDirection;
        pageSize?: number;
    };
    bdxPoliciesTable?: {
        sortedColumn?: string;
        sortedDirection?: SortDirection;
        pageSize?: number;
    };
};

export interface VerifySettingsCache<T extends SettingsCacheKey> {
    settingsCache: SettingsCache[T];
}
