import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PaymentPlanSource } from '@backend-types/payment-plan';
import { InvoiceLineItemCategory } from '@backend-types/taxes-fees';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbf-taxes-fees-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './taxes-fees-modal.component.html',
    styleUrls: ['taxes-fees-modal.component.scss'],
})
export class TaxesFeesModalComponent implements OnInit {
    paymentPlanSource!: PaymentPlanSource;

    constructor(private activeModal: NgbActiveModal) {}
    ngOnInit() {}
    close() {
        this.activeModal.close();
    }

    get stateName() {
        return this.paymentPlanSource.state;
    }

    get stateFeesAndTaxes() {
        return this.paymentPlanSource.installments[0].installmentLineItems.filter(
            (installmentLineItem) =>
                [InvoiceLineItemCategory.stateFee, InvoiceLineItemCategory.stateTax].includes(
                    installmentLineItem.invoiceLineItemCategory
                )
        );
    }

    get tredderFees() {
        return this.paymentPlanSource.installments[0].installmentLineItems.filter(
            (installmentLineItem) =>
                [
                    InvoiceLineItemCategory.tredderFeeOneTime,
                    InvoiceLineItemCategory.tredderFeePerInstallment,
                ].includes(installmentLineItem.invoiceLineItemCategory)
        );
    }
}
