import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbf-quote-help-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-help-modal.component.html',
    styleUrls: ['quote-help-modal.component.scss'],
})
export class QuoteHelpModalComponent implements OnInit {
    title!: string;
    text!: string;
    text2!: string;
    titleClasses = 'quote-title italic';

    constructor(private activeModal: NgbActiveModal) {}
    ngOnInit() {}
    close() {
        this.activeModal.close();
    }
}
