import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AddressFromGeo, BigDataCloudResponse } from '@common/models';
import { WindowToken } from '@common/services/providers';
import { Observable, ReplaySubject } from 'rxjs';

import { LogService } from './log.service';

@Injectable()
export class GeolocationService {
    private _addressFromGeo$ = new ReplaySubject<null | AddressFromGeo>(1);

    constructor(
        @Inject(WindowToken) private _window: Window,
        private http: HttpClient,
        private logService: LogService
    ) {}

    get addressFromGeo$(): Observable<null | AddressFromGeo> {
        return this._addressFromGeo$.asObservable();
    }

    getGeolocation(): void {
        if (!this._window.navigator.geolocation) {
            this.logService.error('GEOLOCATION_NOT_SUPPORTED');
            this._addressFromGeo$.next(null);
            return;
        }
        this._window.navigator.geolocation.getCurrentPosition(
            (position) => this._getGeolocationSuccess(position),
            (positionError) => this._getGeolocationError(positionError),
            { enableHighAccuracy: true }
        );
    }

    private _getGeolocationSuccess(position: GeolocationPosition) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.getReverseGeoFromBigData$(latitude, longitude).subscribe((response) => {
            this._addressFromGeo$.next({
                city: response.city,
                state: response.principalSubdivision,
                zip: response.postcode,
            });
        });
    }
    private _getGeolocationError(positionError: GeolocationPositionError) {
        this.logService.error('GEOLOCATION_NOT_SUPPORTED');
        console.log('Unable to retrieve your location');
    }

    getReverseGeoFromBigData$(
        latitude: number,
        longitude: number
    ): Observable<BigDataCloudResponse> {
        // https://web.dev/native-hardware-user-location/#always-request-access-to-location-on-a-user-gesture
        // https://www.bigdatacloud.com/docs/api/free-reverse-geocode-to-city-api
        return this.http.get<BigDataCloudResponse>(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
    }
}
