import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
    QuoteRigSetCombinedCoverageSelectFormValue,
    QuoteRigSetFormValue,
    QuoteRigSetRigCoverageSelectFormValue,
} from '@backend-types/quote-flow-ens';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { AnalyticsService, AssertionService, HotKeysService } from '@common/services';
import { validateCoverageSelect } from '@common/validators';
import { QuoteHelpModalComponent } from '@modules/quote/components/quote-help-modal/quote-help-modal.component';
import { QuoteEnsFormService, QuoteRigSetFormService } from '@modules/quote/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-rig-set-form-coverage-select',
    // changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-rig-set-form-coverage-select.component.html',
    styleUrls: ['quote-rig-set-form-coverage-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteRigSetFormCoverageSelectComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteRigSetFormCoverageSelectComponent,
        },
    ],
})
export class QuoteRigSetFormCoverageSelectComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @Input() formOnly = false;
    @Input() rigIndex!: number;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    subscription: Subscription = new Subscription();

    activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;

    showGearCoverageSelect = false;

    coverageSelectForm: ModelFormGroup<QuoteRigSetCombinedCoverageSelectFormValue> = this.fb.group(
        {
            modsCoverage: new FormControl<boolean | null>(null, [Validators.required]),
            camperCoverage: new FormControl<boolean | null>(null, [Validators.required]),
            trailerCoverage: new FormControl<boolean | null>(null, [Validators.required]),
            gearCoverage: new FormControl<boolean | null>(null, [Validators.required]),
        },
        { validators: [validateCoverageSelect()] }
    );

    onTouched: () => unknown = () => {};
    onChange = (coverageSelect: ModelFormValue<QuoteRigSetRigCoverageSelectFormValue> | null) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private quoteEnsFormService: QuoteEnsFormService,
        private ngbModalService: NgbModal,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Coverage Select');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_coverage_select',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.writeValue({
                    modsCoverage: true,
                    camperCoverage: true,
                    trailerCoverage: false,
                });
            })
        );
        this.subscription.add(
            this.quoteRigSetFormService
                .getActiveQuoteRigSetForm$()
                .subscribe((activeQuoteRigSetForm) => {
                    this.activeQuoteRigSetForm = activeQuoteRigSetForm;
                    if (this.formOnly) {
                        this.showGearCoverageSelect = this.rigIndex === 0;
                    } else {
                        this.showGearCoverageSelect =
                            this.quoteRigSetFormService.activeQuoteRigSetFormIndex === 0;
                    }
                    this.changeDetectorRef.detectChanges();
                })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (
            coverageSelect: ModelFormValue<QuoteRigSetRigCoverageSelectFormValue> | null
        ) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.coverageSelectForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.coverageSelectForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const coverageSelect = this._coverageSelectFormValue();
                        onChange(coverageSelect);
                    } catch (error) {}
                })
        );

        this.subscription.add(
            this.gearCoverageControl.valueChanges
                .pipe(
                    tap(() => {
                        if (this.gearCoverageControl.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe((value) => {
                    this.quoteEnsFormService.setGear(!!value);
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.coverageSelectForm.disable();
        } else {
            this.coverageSelectForm.enable();
        }
    }

    writeValue(value: QuoteRigSetRigCoverageSelectFormValue | null) {
        if (value === null) {
            this.coverageSelectForm.reset();
        }
        if (value) {
            this.coverageSelectForm.setValue(
                {
                    ...value,
                    gearCoverage: this.quoteEnsFormService.hasGearCoverage,
                },
                { emitEvent: false }
            );
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.coverageSelectForm?.invalid) {
            return { coverageSelectFormInvalid: true };
        }

        return null;
    }

    private _coverageSelectFormValue(): ModelFormValue<QuoteRigSetRigCoverageSelectFormValue> {
        const { modsCoverage, camperCoverage, trailerCoverage } = this.coverageSelectForm.value;

        this.assertionService.isDefinedOrThrow(modsCoverage);
        this.assertionService.isDefinedOrThrow(camperCoverage);
        this.assertionService.isDefinedOrThrow(trailerCoverage);

        return {
            modsCoverage,
            camperCoverage,
            trailerCoverage,
        };
    }

    showHelp(whichCoverage: string) {
        const {
            componentInstance,
        }: {
            componentInstance: QuoteHelpModalComponent;
            result: Promise<unknown>;
        } = this.ngbModalService.open(QuoteHelpModalComponent, {
            // size: 'lg',
            centered: false,
        });
        // INFO: Be sure to keep in sync with estimate-form-site.component.ts
        switch (whichCoverage) {
            case 'modsCoverage':
                componentInstance.title = 'Mods & Upgrades';
                componentInstance.text = 'Anything that is permanently attached to your vehicle.';
                break;
            case 'camperCoverage':
                componentInstance.title = 'Camper';
                componentInstance.text = 'Enclosed living quarters in or replacing a truck bed.';
                break;
            case 'trailerCoverage':
                componentInstance.title = 'Trailer';
                componentInstance.text = 'Anything with wheels that is pulled by you vehicle.';
                componentInstance.text2 = 'Teardrop, boat-trailer, 5th wheel, etc.';
                break;
            case 'gearCoverage':
                componentInstance.title = 'Gear';
                componentInstance.text = 'Anything of value that you travel with. ';
                componentInstance.text2 =
                    'Camera, laptop, refrigerator, kayaks, fishing poles, etc.';
                break;
            default:
                break;
        }
        componentInstance.titleClasses = 'r-text-lg heavy text-primary';
    }

    /* Accessor Methods */

    get modsCoverageControl() {
        return this.coverageSelectForm.get('modsCoverage') as FormControl;
    }

    get modsCoverageControlValid() {
        return this.modsCoverageControl.value && !this.modsCoverageControl.hasError('required');
    }

    get modsCoverageControlInvalid() {
        return this.modsCoverageControl.touched && this.modsCoverageControl.hasError('required');
    }

    get camperCoverageControl() {
        return this.coverageSelectForm.get('camperCoverage') as FormControl;
    }

    get camperCoverageControlValid() {
        return this.camperCoverageControl.value && !this.camperCoverageControl.hasError('required');
    }

    get camperCoverageControlInvalid() {
        return (
            this.camperCoverageControl.touched && this.camperCoverageControl.hasError('required')
        );
    }

    get trailerCoverageControl() {
        return this.coverageSelectForm.get('trailerCoverage') as FormControl;
    }

    get trailerCoverageControlValid() {
        return (
            this.trailerCoverageControl.value && !this.trailerCoverageControl.hasError('required')
        );
    }

    get trailerCoverageControlInvalid() {
        return (
            this.trailerCoverageControl.touched && this.trailerCoverageControl.hasError('required')
        );
    }

    get gearCoverageControl() {
        return this.coverageSelectForm.get('gearCoverage') as FormControl;
    }

    get gearCoverageControlValid() {
        return this.gearCoverageControl.value && !this.gearCoverageControl.hasError('required');
    }

    get gearCoverageControlInvalid() {
        return this.gearCoverageControl.touched && this.gearCoverageControl.hasError('required');
    }

    get noneSelected() {
        return (
            !this.modsCoverageControl.value &&
            !this.camperCoverageControl.value &&
            !this.trailerCoverageControl.value &&
            !this.gearCoverageControl.value
        );
    }

    get gearOnlyError() {
        return this.coverageSelectForm.hasError('gearCoverageRequiresModsOrUnit');
    }
}
