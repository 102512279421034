import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'sbf-chevron-show-hide',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './chevron-show-hide.component.html',
    styleUrls: ['chevron-show-hide.component.scss'],
})
export class ChevronShowHideComponent implements OnInit {
    @Input() initialState!: 'show' | 'hide';
    @Input() color: 'white' | 'sand' = 'sand';
    @Output() show = new EventEmitter<void>();
    @Output() hide = new EventEmitter<void>();
    showHideState!: 'show' | 'hide';

    constructor() {}
    ngOnInit() {
        if (!this.initialState) {
            throw new Error('MUST_SET_INITIAL_STATE');
        }
        this.showHideState = this.initialState;
    }

    clicked(event: PointerEvent) {
        event.stopImmediatePropagation();

        if (this.showHideState === 'show') {
            this.hide.emit();
            this.showHideState = 'hide';
        } else {
            this.show.emit();
            this.showHideState = 'show';
        }
    }
}
