<div class="container-xxl" *ngIf="activeEnsQuote">
    <div class="text-center mb-5">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block"><div class="quote-title">Existing Coverage</div></div>
            <div class="d-md-block d-none"><div class="quote-title">Existing Coverage</div></div>
        </div>
        <ng-container *ngIf="!existingCoveragesExists">
            <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3 px-lg-10"><div class="quote-subtitle">In order to maintain your Tredder policy, you must have and maintain current auto insurance with Comprehensive and Collision Coverages.</div></div>
            <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-4 px-lg-10"><div class="quote-subtitle">Use the button below to log into your current insurance carrier and import your existing coverage details.</div></div>
            <button class="btn btn-lg btn-primary-gradient rounded-pill btn-primary text-white" data-cy="startImportInsuranceInfoButton" type="button" (click)="startInsuranceImport()" tabindex="0" [disabled]="!canopyConnectHandler || existingCoverageSubmitted"><div class="d-flex align-items-center justify-content-center">Import Coverage Details</div></button><a class="d-block text-link text-secondary secondary-hover pointer mt-4" data-cy="skipThisStepLink" (click)="skipExistingCoverage()">Skip this step</a></ng-container
        ><ng-container *ngIf="existingCoveragesExists">
            <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3 px-lg-10"><div class="quote-subtitle">In order to maintain your Tredder policy, you must have and maintain current auto insurance with Comprehensive and Collision Coverages.</div></div>
            <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3 px-lg-10"><div class="quote-subtitle text-cyan-blue">It looks like you have already uploaded you current auto policy!</div></div>
            <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5 mt-md-5 mt-3" data-cy="continuteButton" type="button" (click)="continue()" tabindex="0">
                <div class="d-flex align-items-center justify-content-center">Continue</div>
                <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons></button
        ></ng-container>
    </div>
    <div class="d-flex justify-content-start"><sbf-back-button data-cy="documentsBackButton" (click)="back.emit()"></sbf-back-button></div>
</div>
