import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { LegalDoc, LegalDocName } from '@common/models';
// import { legalDocsConfig } from '@data/legal-docs-config.data';
// import { forkJoin, map, Observable } from 'rxjs';

@Injectable()
export class LegalDocsService {
    constructor(private http: HttpClient) {}

    // getLegalDocs$(legalDocNames: LegalDocName[]): Observable<LegalDoc[]> {
    //     const mappedDocs = legalDocNames.map((legalDocName) => {
    //         const filePath = legalDocsConfig[legalDocName].filePath;

    //         return this.http
    //             .get(`/assets/data/legal-docs/${filePath}`, { responseType: 'text' })
    //             .pipe(
    //                 map((legalDocText) => {
    //                     return {
    //                         ...legalDocsConfig[legalDocName],
    //                         md: legalDocText,
    //                     };
    //                 })
    //             );
    //     });

    //     return forkJoin(mappedDocs);

    //     // return this.http.get(`/assets/data/legal-docs/${legalDocs[0]}`, { responseType: 'text' });
    // }
}
