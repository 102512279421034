<form [formGroup]="quoteEnsFlowForm" *ngIf="quoteEnsFlowForm">
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.intro" @slideInOut><sbf-quote-ens-intro (next)="nextQuoteEnsFormStep()"></sbf-quote-ens-intro></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.pledge" @slideInOut><sbf-quote-ens-pledge (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()" formControlName="pledge"></sbf-quote-ens-pledge></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.rigSet" @slideInOut><sbf-quote-rig-set-form (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()" formControlName="rigSets"></sbf-quote-rig-set-form></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.gearConditional" @slideInOut><sbf-quote-gear-conditional (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()" (goto)="gotoStep($event)"></sbf-quote-gear-conditional></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.gearDetails" @slideInOut><sbf-quote-gear-details-form (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()" formControlName="gearDetails"></sbf-quote-gear-details-form></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.homeBase" @slideInOut><sbf-quote-ens-home-base-form (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()" formControlName="homeBase"></sbf-quote-ens-home-base-form></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.ensPersonalInfo" @slideInOut><sbf-quote-ens-personal-info-form (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()" formControlName="ensPersonalInfo"></sbf-quote-ens-personal-info-form></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.rates" @slideInOut><sbf-quote-ens-rates (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()" (jump)="jumpToStep($event)"></sbf-quote-ens-rates></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.purchase" @slideInOut><sbf-quote-ens-purchase (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()"></sbf-quote-ens-purchase></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.rc2" @slideInOut><sbf-quote-ens-rc2 (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()" formControlName="rc2"></sbf-quote-ens-rc2></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.decPull" @slideInOut><sbf-quote-ens-dec-pull (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()"></sbf-quote-ens-dec-pull></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.documents" @slideInOut><sbf-quote-ens-documents (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()"></sbf-quote-ens-documents></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.payment" @slideInOut><sbf-quote-ens-payment (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()"></sbf-quote-ens-payment></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.paymentConfirmed" @slideInOut><sbf-quote-ens-payment-confirmed (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()"></sbf-quote-ens-payment-confirmed></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.paymentError" @slideInOut><sbf-quote-ens-payment-error (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()"></sbf-quote-ens-payment-error></div>
    <div class="d-inline-block w-100" *ngIf="currentQuoteEnsFormStep === eQuoteEnsFormStep.password" @slideInOut><sbf-quote-ens-password (next)="nextQuoteEnsFormStep()" (back)="backQuoteEnsFormStep()"></sbf-quote-ens-password></div>
</form>
