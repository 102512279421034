import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService, UtmService } from '@common/services';

@Injectable()
export class PartnerService {
    constructor(
        private http: HttpClient,
        private envService: EnvService,
        private utmService: UtmService
    ) {}

    smPostback(): void {
        if (!this.envService.isProd) {
            return;
        }

        const successfulMedia = this.utmService.successfulMedia;

        if (successfulMedia) {
            this.http
                .get(
                    `https://successfulmedia.go2cloud.org/aff_lsr?offer_id=${successfulMedia.offerId}&transaction_id=${successfulMedia.transactionId}`,
                    { responseType: 'text' }
                )
                .subscribe();
        }
    }
}
