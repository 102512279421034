<div class="container-xxl" *ngIf="!formOnly">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block"><div class="quote-title">Almost there!</div></div>
            <div class="d-md-block d-none"><div class="quote-title">Almost there!</div></div>
        </div>
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3"><div class="quote-subtitle">Before you purchase, we just need a few more details:</div></div>
        <div class="row justify-content-center">
            <div class="col-lg-8 col-12">
                <div class="card">
                    <div class="card-body">
                        <ng-template [ngTemplateOutlet]="rateQuote2FormTemplate"></ng-template
                        ><button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5 mt-md-5 mt-3" data-cy="rateCallTwoContinueButton" type="button" (click)="continue()" tabindex="0" [attr.disabled]="!allValid ? true : null">
                            <div class="d-flex align-items-center justify-content-center">Continue</div>
                            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
                        </button>
                        <div class="d-flex justify-content-start"><sbf-back-button data-cy="rateCallTwoBackButton" (click)="back.emit()"></sbf-back-button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="formOnly"><ng-template [ngTemplateOutlet]="rateQuote2FormTemplate"></ng-template></ng-container
><ng-template #rateQuote2FormTemplate
    ><sbf-quote-rig-set-form-rig-details-remaining *ngIf="showRigDetailsRemaning" (rigDetailsRemainingFormValid)="rigDetailsRemainingFormValid($event)" [formOnly]="true"></sbf-quote-rig-set-form-rig-details-remaining>
    <form data-cy="rateQuote2Form" [formGroup]="rateQuote2Form">
        <div class="row">
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <sbf-input-select data-cy="licenseStatusSelect" idAppend="licenseStatus" formControlName="licenseStatus" defaultText="License Status" [optionsRecordsArray]="[driverLicenseStatusText]" [class.is-invalid]="licenseStatusControlInvalid"></sbf-input-select>
                    <div class="invalid-feedback" *ngIf='licenseStatusControl.hasError("required")'>License Status required.</div>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <sbf-input-select data-cy="yearsLicensedSelect" idAppend="yearsLicensed" formControlName="yearsLicensed" defaultText="Years Licensed" [optionsRecordsArray]="[driverYearsLicensedText]" [class.is-invalid]="yearsLicensedControlInvalid"></sbf-input-select>
                    <div class="invalid-feedback" *ngIf='yearsLicensedControl.hasError("required")'>Years Licensed required.</div>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <sbf-input-select data-cy="currentInsuranceSelect" idAppend="currentInsurance" formControlName="currentInsurance" defaultText="Current Insurance" [optionsRecordsArray]="[currentInsuranceText]" [class.is-invalid]="currentInsuranceControlInvalid"></sbf-input-select>
                    <div class="invalid-feedback" *ngIf='currentInsuranceControl.hasError("required")'>Current Insurance required.</div>
                </div>
            </div>
            <div class="col-sm-6 col-12">
                <div class="form-floating mb-3">
                    <sbf-input-select data-cy="numberOfResidentsSelect" idAppend="numberOfResidents" formControlName="numberOfResidents" defaultText="Driving Age Residents in Household" [optionsRecordsArray]="[numberOfResidentsText]" [class.is-invalid]="numberOfResidentsControlInvalid"></sbf-input-select>
                    <div class="invalid-feedback" *ngIf='numberOfResidentsControl.hasError("required")'>Driving Age Residents in Household required.</div>
                </div>
            </div>
            <div class="col-12 mb-3"><div class="r-text-base text-start me-3">Have you had more than two at-fault accidents or violations in the past 3 years?</div></div>
            <div class="col-12 mb-3"><sbf-input-radio labelA="no" labelB="yes" [valueA]="false" [valueB]="true" idAppend="overTwoViolations" formControlName="overTwoViolations"></sbf-input-radio></div>
            <div class="col-12 mb-3"><div class="r-text-base text-start me-3">Do you have a lift kit in excess of 12 inches?</div></div>
            <div class="col-12"><sbf-input-radio labelA="no" labelB="yes" [valueA]="false" [valueB]="true" idAppend="overTwelveLiftKit" formControlName="overTwelveLiftKit"></sbf-input-radio></div>
        </div></form
></ng-template>
