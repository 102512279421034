import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phone',
})
export class PhonePipe implements PipeTransform {
    constructor() {}
    transform(phoneString: string) {
        const areaCodeStr = phoneString.slice(0, 3);
        const midSectionStr = phoneString.slice(3, 6);
        const lastSectionStr = phoneString.slice(6);

        return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
    }
}
