import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { InputCheckboxValue, ModelFormGroup } from '@common/models';
import { QuoteEnsRetrievalService, UXPremiumBreakdown } from '@modules/quote/services';
import { Subscription } from 'rxjs';

export interface PremiumBreakdownFormValue {
    main: InputCheckboxValue[];
    extra: InputCheckboxValue[];
}

@Component({
    selector: 'sbf-quote-ens-premium-breakdown',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-premium-breakdown.component.html',
    styleUrls: ['quote-ens-premium-breakdown.component.scss'],
})
export class QuoteEnsPremiumBreakdownComponent implements OnInit {
    activeEnsQuote!: EnsResponseWithEnsOnly | null;
    premiumBreakdown!: UXPremiumBreakdown;

    subscription: Subscription = new Subscription();

    premiumBreakdownForm!: ModelFormGroup<PremiumBreakdownFormValue>;

    constructor(
        private fb: FormBuilder,
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuote$.subscribe((activeEnsQuote) => {
                if (!activeEnsQuote) {
                    return;
                }
                this.activeEnsQuote = activeEnsQuote;
                this.changeDetectorRef.detectChanges();
            })
        );
        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuotePremiumBreakdown$.subscribe(
                (premiumBreakdown) => {
                    if (!premiumBreakdown) {
                        return;
                    }
                    this.premiumBreakdown = premiumBreakdown;
                    // this._initForm();
                    this.changeDetectorRef.detectChanges();
                }
            )
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // eslint-disable-next-line complexity
    private _initForm() {
        const mainCheckboxes: InputCheckboxValue[] = [];
        const extraCheckboxes: InputCheckboxValue[] = [];

        if (this.premiumBreakdown.mods) {
            mainCheckboxes.push({
                checked: true,
                keyName: 'Mods',
                wrapperClasses: ['p-2', 'm-2', 'br-5', 'faded-green'],
                subText: `$${this.premiumBreakdown.mods.amount}`,
            });
        }
        if (this.premiumBreakdown.gear) {
            mainCheckboxes.push({
                checked: true,
                keyName: 'Gear',
                wrapperClasses: ['p-2', 'm-2', 'br-5', 'faded-red'],
                subText: `$${this.premiumBreakdown.gear.amount}`,
            });
        }
        if (this.premiumBreakdown.camper) {
            mainCheckboxes.push({
                checked: true,
                keyName: 'Camper',
                wrapperClasses: ['p-2', 'm-2', 'br-5', 'faded-blue'],
                subText: `$${this.premiumBreakdown.camper.amount}`,
            });
        }
        if (this.premiumBreakdown.trailer) {
            mainCheckboxes.push({
                checked: true,
                keyName: 'Trailer',
                wrapperClasses: ['p-2', 'm-2', 'br-5', 'faded-purple'],
                subText: `$${this.premiumBreakdown.trailer.amount}`,
            });
        }

        if (this.premiumBreakdown.offRoad) {
            extraCheckboxes.push({
                checked: true,
                keyName: 'Off Road',
                wrapperClasses: ['p-2', 'm-2', 'br-5', 'faded-yellow'],
                subText: `$${this.premiumBreakdown.offRoad.amount}`,
            });
        }
        if (this.premiumBreakdown.emergency) {
            extraCheckboxes.push({
                checked: true,
                keyName: 'Emergency',
                wrapperClasses: ['p-2', 'm-2', 'br-5', 'faded-pink'],
                subText: `$${this.premiumBreakdown.emergency.amount}`,
            });
        }
        if (this.premiumBreakdown.towing) {
            extraCheckboxes.push({
                checked: true,
                keyName: 'Towing',
                wrapperClasses: ['p-2', 'm-2', 'br-5', 'faded-orange'],
                subText: `$${this.premiumBreakdown.towing.amount}`,
            });
        }

        this.premiumBreakdownForm = this.fb.group({
            main: new FormControl<InputCheckboxValue[] | null>(mainCheckboxes),
            extra: new FormControl<InputCheckboxValue[] | null>(extraCheckboxes),
        });
        this.subscription.add(
            this.premiumBreakdownForm.valueChanges.subscribe((value) => {
                console.log(value);
            })
        );
    }
}
