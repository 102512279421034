// INFO: A step has a corresponding table,
// INFO: A table does not necessarily have a corresponding step (intermediate table)
export enum RocStepKey {
    test = 'test',
    // Household
    developedDriverComputed = 'developedDriverComputed',
    driverClassification = 'driverClassification',
    drivingRecordPoints = 'drivingRecordPoints',
    subtractionOfUnity = 'subtractionOfUnity',
    householdMember = 'householdMember',
    driverAgePoints = 'driverAgePoints',
    householdRiskFactorComputed = 'householdRiskFactorComputed', // Manual step in src/lib/roc/calculate-rate.ts
    // Vehicle
    baseRatesVehicle = 'baseRatesVehicle',
    financialResponsibilityTier = 'financialResponsibilityTier',
    priorCarrier = 'priorCarrier',
    policyTerm = 'policyTerm',
    advanceQuote = 'advanceQuote',
    riskGroup = 'riskGroup',
    renewWithLapse = 'renewWithLapse',
    residencyRewards = 'residencyRewards',
    territoryFactor = 'territoryFactor',
    vehicleAttributesFactor = 'vehicleAttributesFactor',
    vehicleAgeTable = 'vehicleAgeTable',
    lengthVehicleOwnership = 'lengthVehicleOwnership',
    vehicleAgeCoverage = 'vehicleAgeCoverage',
    annualMiles = 'annualMiles',
    homeownerMultiCarDiscount = 'homeownerMultiCarDiscount',
    threeYearSafeDrivingDiscount = 'threeYearSafeDrivingDiscount',
    newBusinessFiveYearFreeDiscount = 'newBusinessFiveYearFreeDiscount',
    paidInFullEFTAutoCardPaymentDiscount = 'paidInFullEFTAutoCardPaymentDiscount',
    businessUseSurcharge = 'businessUseSurcharge',
    towTripInterrupt = 'towTripInterrupt',
    // Trailer
    baseRatesTrailer = 'baseRatesTrailer',
    // collisionDeductibleFactor = 'collisionDeductibleFactor', // ASK_LINTON
    // comprehensiveDeductibleFactor = 'comprehensiveDeductibleFactor', // ASK_LINTON
    // ENS Household
    ensPlaceholder = 'ensPlaceholder',
    // baseRatesENS = 'baseRatesENS',
    // ENS Unit
    baseRatesUnit = 'baseRatesUnit',
    baseRatesGear = 'baseRatesGear',
    territory = 'territory',
    unitValue = 'unitValue',
    pniAge = 'pniAge',
    ensMaritalStatus = 'ensMaritalStatus',
    insuranceScoreUnit = 'insuranceScoreUnit',
    unitAge = 'unitAge',
    unitLength = 'unitLength',
    unitOwnedTenure = 'unitOwnedTenure',
    unitClass = 'unitClass',
    unitCompDeductible = 'unitCompDeductible',
    unitCollDeductible = 'unitCollDeductible',
    termUnit = 'termUnit',
    onRoadUnit = 'onRoadUnit',
    // ACPE
    baseRatesAcpe = 'baseRatesAcpe',
    acpeValue = 'acpeValue',
    insuranceScoreAcpe = 'insuranceScoreAcpe',
    acpeDeductible = 'acpeDeductible',
    termAcpe = 'termAcpe',
    onRoadAcpe = 'onRoadAcpe',
    // Gear
    gearValue = 'gearValue',
    termGear = 'termGear',
    onRoadGear = 'onRoadGear',
    // Special
    instantiateSpecialty = 'instantiateSpecialty',
    baseRatesCampsiteLiability = 'baseRatesCampsiteLiability',
    baseRatesScheduledMedicalBenefit = 'baseRatesScheduledMedicalBenefit',
    baseRatesEmergencyExpense = 'baseRatesEmergencyExpense',
    baseRatesTowRoadside = 'baseRatesTowRoadside',
    baseRatesOffRoadRecovery = 'baseRatesOffRoadRecovery',
    termSpecial = 'termSpecial',
}

export const rocStepDisplayNames: EnumMap<RocStepKey> = {
    test: 'Test',
    // Household
    developedDriverComputed: 'Developed Driver Computed',
    driverClassification: 'Driver Classification',
    drivingRecordPoints: 'Driving Record Points',
    subtractionOfUnity: 'Subtration Of Unity',
    householdMember: 'Household Member',
    driverAgePoints: 'Driver Age Points',
    householdRiskFactorComputed: 'Household Risk Factor Computed', // Manual step in src/lib/roc/calculate-rate.ts
    // Vehicle
    baseRatesVehicle: 'Base Rates Vehicle',
    financialResponsibilityTier: 'Financial Responsibility Tier',
    priorCarrier: 'Prior Carrier',
    policyTerm: 'Policy Term',
    advanceQuote: 'Advance Quote',
    riskGroup: 'Risk Group',
    renewWithLapse: 'Renew With Lapse',
    residencyRewards: 'Residency Rewards',
    territoryFactor: 'Territory Factor',
    vehicleAttributesFactor: 'Vehicle Attributes Factor',
    vehicleAgeTable: 'Vehicle Age Table',
    lengthVehicleOwnership: 'Length Vehicle Ownership',
    vehicleAgeCoverage: 'Vehicle Age Coverage',
    annualMiles: 'Annual Miles',
    homeownerMultiCarDiscount: 'Homeowner Multi Car Discount',
    threeYearSafeDrivingDiscount: 'Three Year Safe Driving Discount',
    newBusinessFiveYearFreeDiscount: 'New Business Five Year Free Discount',
    paidInFullEFTAutoCardPaymentDiscount: 'Pain In Full Eft Auto Card Payment Discount',
    businessUseSurcharge: 'Business Use Surcharge',
    towTripInterrupt: 'Tow Trip Interrupt',
    // Trailer
    baseRatesTrailer: 'Base Rates Trailer',
    // collisionDeductibleFactor: 'Collision Deductible Factor',
    // comprehensiveDeductibleFactor: 'Comprehensive Deductible Factor',
    // ENS Household
    ensPlaceholder: 'ENS Placeholder',
    // baseRatesENS: 'Base Rates ENS',
    // ENS Unit
    baseRatesUnit: 'Base Rates Unit',
    unitValue: 'Unit Value',
    territory: 'Territory',
    unitAge: 'Unit Age',
    pniAge: 'Principle Named Insured Age',
    ensMaritalStatus: 'ENS Marital Status',
    insuranceScoreUnit: 'Insurance Score Unit',
    unitLength: 'Unit Length',
    unitOwnedTenure: 'Unit Owned Tenure',
    unitClass: 'Unit Class',
    unitCompDeductible: 'Unit Comp Deductible',
    unitCollDeductible: 'Unit Coll Deductible',
    termUnit: 'Term Unit',
    onRoadUnit: 'On Road Unit',
    // ACPE
    baseRatesAcpe: 'Base Rates ACPE',
    acpeValue: 'ACPE Value',
    insuranceScoreAcpe: 'Insurance Score ACPE',
    gearValue: 'Gear Value',
    acpeDeductible: 'ACPE Deductible',
    termAcpe: 'Term Acpe',
    onRoadAcpe: 'On Road Acpe',
    // Gear
    baseRatesGear: 'Base Rates Gear',
    termGear: 'Term Gear',
    onRoadGear: 'On Road Gear',
    // Special
    instantiateSpecialty: 'Instantiate Specialty',
    baseRatesCampsiteLiability: 'Base Rates Campsite Liability',
    baseRatesScheduledMedicalBenefit: 'Base Rates Scheduled Medical Benefit',
    baseRatesEmergencyExpense: 'Base Rates Emergency Expense',
    baseRatesTowRoadside: 'Base Rates Tow Roadside',
    baseRatesOffRoadRecovery: 'Base Rates Off Road Recovery',
    termSpecial: 'Term Special',
};
