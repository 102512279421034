import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { slideInOutAnimation } from '@common/animations';
import { InputCheckboxValue, ModelFormGroup } from '@common/models';
import { QuoteEnsFormStep, QuoteRigSetFormStep } from '@modules/quote/models';
import { QuoteEnsFormService, QuoteSharedService } from '@modules/quote/services';
import { combineLatest, Subscription } from 'rxjs';

export interface Blah {
    modifications: InputCheckboxValue[];
    gear: InputCheckboxValue[];
    trailer: InputCheckboxValue[];
    camper: InputCheckboxValue[];
}

@Component({
    selector: 'sbf-quote-ens-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-form.component.html',
    styleUrls: ['quote-ens-form.component.scss'],
    animations: [slideInOutAnimation],
})
export class QuoteEnsFormComponent implements OnInit, OnDestroy {
    quoteEnsFlowForm!: ModelFormGroup<QuoteEnsFlowFormValue>;

    eQuoteEnsFormStep = QuoteEnsFormStep;

    jumpBackEns?: QuoteEnsFormStep;
    jumpBackEndorsement?: UUID;
    endorsementAddRig?: UUID;

    subscription: Subscription = new Subscription();
    currentQuoteEnsFormStep: QuoteEnsFormStep = QuoteEnsFormStep.intro;

    constructor(
        private quoteEnsFormService: QuoteEnsFormService,
        private route: ActivatedRoute,
        private router: Router,
        private quoteSharedService: QuoteSharedService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowForm$.subscribe((quoteEnsFlowForm) => {
                this.quoteEnsFlowForm = quoteEnsFlowForm;
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            combineLatest([this.route.queryParamMap]).subscribe(([queryParamMap]) => {
                const quoteEnsFormStep = this.quoteSharedService.queryParamToQuoteEnsFormStep(
                    queryParamMap.get('quote-ens-form-step')
                );
                this.jumpBackEns = queryParamMap.get('jump-back-ens') as QuoteEnsFormStep;
                this.jumpBackEndorsement = queryParamMap.get('jump-back-endorsement') as UUID;
                this.endorsementAddRig = queryParamMap.get('endorsement-add-rig') as UUID;

                if (quoteEnsFormStep) {
                    this.currentQuoteEnsFormStep = quoteEnsFormStep;
                    this.changeDetectorRef.detectChanges();
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // eslint-disable-next-line complexity
    nextQuoteEnsFormStep() {
        const endorsementPolicyUUID = this.jumpBackEndorsement || this.endorsementAddRig;
        if (endorsementPolicyUUID) {
            this.router.navigateByUrl(`/account/insurance/coverage/edit/${endorsementPolicyUUID}`);
            return;
        }
        if (this.jumpBackEns) {
            this.gotoStep(this.jumpBackEns, {
                'jump-back-ens': undefined,
                'rig-index': undefined,
                'quote-rig-set-step': undefined,
            });
            return;
        }
        switch (this.currentQuoteEnsFormStep) {
            case QuoteEnsFormStep.intro:
                this.gotoStep(QuoteEnsFormStep.pledge);
                break;
            case QuoteEnsFormStep.pledge:
                this.gotoStep(QuoteEnsFormStep.rigSet, {
                    'quote-rig-set-step': QuoteRigSetFormStep.rigName,
                });
                break;
            case QuoteEnsFormStep.rigSet:
                if (this.quoteEnsFormService.hasEnteredPersonalInfo) {
                    this.gotoStep(QuoteEnsFormStep.rates, {
                        'quote-rig-set-step': undefined,
                    });
                    break;
                }
                if (this.quoteEnsFormService.hasGearCoverage) {
                    this.gotoStep(QuoteEnsFormStep.gearDetails, {
                        'quote-rig-set-step': undefined,
                    });
                    break;
                }
                this.gotoStep(QuoteEnsFormStep.gearConditional, {
                    'quote-rig-set-step': undefined,
                });
                break;
            case QuoteEnsFormStep.gearConditional:
                this.gotoStep(QuoteEnsFormStep.gearDetails);
                break;
            case QuoteEnsFormStep.gearDetails:
                if (this.quoteEnsFormService.hasEnteredPersonalInfo) {
                    this.gotoStep(QuoteEnsFormStep.rates);
                    break;
                }
                this.gotoStep(QuoteEnsFormStep.homeBase);
                break;
            case QuoteEnsFormStep.homeBase:
                this.gotoStep(QuoteEnsFormStep.ensPersonalInfo);
                break;
            case QuoteEnsFormStep.ensPersonalInfo:
                this.gotoStep(QuoteEnsFormStep.rates);
                break;
            case QuoteEnsFormStep.rc2:
                this.gotoStep(QuoteEnsFormStep.decPull);
                break;
            case QuoteEnsFormStep.decPull:
                this.gotoStep(QuoteEnsFormStep.documents);
                break;
            default:
                break;
        }
    }

    // eslint-disable-next-line complexity
    backQuoteEnsFormStep() {
        switch (this.currentQuoteEnsFormStep) {
            case QuoteEnsFormStep.pledge:
                this.gotoStep(QuoteEnsFormStep.intro);
                break;
            case QuoteEnsFormStep.rigSet:
                this.gotoStep(QuoteEnsFormStep.pledge);
                break;
            case QuoteEnsFormStep.gearConditional:
                this.gotoStep(QuoteEnsFormStep.rigSet, {
                    'quote-rig-set-step': QuoteRigSetFormStep.rigReview,
                });
                break;
            case QuoteEnsFormStep.gearDetails:
                this.gotoStep(QuoteEnsFormStep.gearConditional);
                break;
            case QuoteEnsFormStep.homeBase:
                this.gotoStep(QuoteEnsFormStep.gearDetails);
                break;
            case QuoteEnsFormStep.ensPersonalInfo:
                this.gotoStep(QuoteEnsFormStep.homeBase);
                break;
            case QuoteEnsFormStep.rates:
                this.gotoStep(QuoteEnsFormStep.ensPersonalInfo);
                break;
            case QuoteEnsFormStep.purchase:
                this.gotoStep(QuoteEnsFormStep.rates);
                break;
            case QuoteEnsFormStep.rc2:
                this.gotoStep(QuoteEnsFormStep.purchase);
                break;
            case QuoteEnsFormStep.decPull:
                this.gotoStep(QuoteEnsFormStep.rc2);
                break;
            case QuoteEnsFormStep.documents:
                this.gotoStep(QuoteEnsFormStep.decPull);
                break;
            default:
                break;
        }
    }

    jumpToStep(step: QuoteEnsFormStep) {
        this.gotoStep(step, {
            'jump-back-ens': QuoteEnsFormStep.rates,
        });
    }

    gotoStep(step: QuoteEnsFormStep, queryParams: Record<string, string | undefined> = {}) {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            queryParams: {
                ...queryParams,
                'quote-ens-form-step': step,
            },
        });
    }

    // private _gotoStep(step: QuoteEnsFormStep, extraParams?: Params | null) {
    //     if (extraParams) {
    //         this.router.navigate(['.'], {
    //             relativeTo: this.route,
    //             queryParamsHandling: 'merge',
    //             queryParams: { 'quote-ens-form-step': step, ...extraParams },
    //         });
    //     } else {
    //         this.router.navigate(['.'], {
    //             relativeTo: this.route,
    //             queryParams: { 'quote-ens-form-step': step },
    //         });
    //     }
    // }
}
