import { DynamicRouteData } from '@modules/navigation/models';

export const authLogin: DynamicRouteData = {
    isPublic: true,
    title: 'Login',
};

export const authLogout: DynamicRouteData = {
    isPublic: true,
    title: 'Logout',
};

export const authRegister: DynamicRouteData = {
    isPublic: true,
    title: 'Register',
};

export const authConfirmEmail: DynamicRouteData = {
    isPublic: true,
    title: 'Confirm Email',
};

export const authSocial: DynamicRouteData = {
    isPublic: true,
    title: 'Social Login Return',
};

export const authAwaitingConfirmation: DynamicRouteData = {
    isPublic: true,
    title: 'Awaiting Confirmation',
};

export const authEmailConfirmed: DynamicRouteData = {
    isPublic: true,
    title: 'Email Confirmed',
};

export const authPasswordReset: DynamicRouteData = {
    isPublic: true,
    title: 'Password Reset',
};

export const authPasswordResetRequest: DynamicRouteData = {
    isPublic: true,
    title: 'Password Reset Request',
};

export const authBadLink: DynamicRouteData = {
    isPublic: true,
    title: 'Bad Link',
};

export const createOrJoinOrg: DynamicRouteData = {
    isPublic: true,
    title: 'Create Or Join Organization',
};

export const addUsersToOrg: DynamicRouteData = {
    isPublic: true,
    title: 'Add Users To Organization',
};

export const createOrg: DynamicRouteData = {
    isPublic: true,
    title: 'Create Organization',
};

export const joinOrg: DynamicRouteData = {
    isPublic: true,
    title: 'Join Organization',
};

export const authConfigs = {
    authLogin,
    authLogout,
    authRegister,
    authConfirmEmail,
    authSocial,
    authPasswordReset,
    authPasswordResetRequest,
    authAwaitingConfirmation,
    authEmailConfirmed,
    authBadLink,
};
