import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicExternalService, LogService } from '@common/services';
import { VehicleInfoService } from '@modules/quote/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import type { IFuseOptions } from 'fuse.js';
import { take } from 'rxjs';

@Component({
    selector: 'sbf-enter-vin-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './enter-vin-modal.component.html',
    styleUrls: ['enter-vin-modal.component.scss'],
})
export class EnterVinModalComponent implements OnInit {
    form = new FormGroup({
        vin: new FormControl('', [
            Validators.required,
            Validators.minLength(17),
            Validators.maxLength(17),
        ]),
    });
    fuseOptions: IFuseOptions<string> = {
        includeScore: true,
        threshold: 0.35,
        useExtendedSearch: true,
    };
    showVINDecodeError = false;

    constructor(
        private activeModal: NgbActiveModal,
        private vehicleInfoService: VehicleInfoService,
        private logService: LogService,
        private changeDetectorRef: ChangeDetectorRef,
        private dynamicExternalService: DynamicExternalService
    ) {}
    ngOnInit() {}

    checkKeyDown(evt: KeyboardEvent) {
        const forbidden = ['O', 'o', 'I', 'i', 'Q', 'q'];
        if (forbidden.includes(evt.key)) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
        }
    }
    close() {
        this.activeModal.close();
    }

    submit() {
        this.showVINDecodeError = false;

        if (this.vinControl.invalid) {
            return;
        }

        const vin = this.vinControl.value.toUpperCase();

        this.vehicleInfoService.decodeVIN$(vin).subscribe((decodedVin) => {
            this.logService.debug(
                `decoded vin: ${decodedVin.year} - ${decodedVin.make} - ${decodedVin.model} - ${decodedVin.trim}`
            );

            if (decodedVin.errorCodes.length > 0) {
                this.showVINDecodeError = true;
                this.changeDetectorRef.detectChanges();
                return;
            }

            this.vehicleInfoService
                .loadVehicleData$(decodedVin.year)
                .pipe(take(1))
                .subscribe(async (vehiclesForYear) => {
                    let foundMake: string | undefined,
                        foundModel: string | undefined,
                        foundTrim: string | undefined;

                    const fuseMakes = await this.dynamicExternalService.fuseSearch<string>(
                        vehiclesForYear.makes,
                        decodedVin.make.replace(' ', '|'),
                        this.fuseOptions
                    );

                    foundMake = fuseMakes[0]?.item;
                    this.logService.debug(`found make: ${foundMake}`);

                    if (!foundMake) {
                        this.showVINDecodeError = true;
                        this.changeDetectorRef.detectChanges();
                        return;
                    }

                    const fuseModels = await this.dynamicExternalService.fuseSearch<string>(
                        vehiclesForYear.models[foundMake].allModels,
                        decodedVin.model.replace(' ', '|'),
                        this.fuseOptions
                    );
                    foundModel = fuseModels[0]?.item;
                    this.logService.debug(`found model: ${foundModel}`);

                    if (foundModel && decodedVin.trim) {
                        const fuseTrims = await this.dynamicExternalService.fuseSearch<string>(
                            vehiclesForYear.models[foundMake].trims[foundModel],
                            decodedVin.trim.replace(' ', '|'),
                            this.fuseOptions
                        );
                        foundTrim = fuseTrims[0]?.item;
                        this.logService.debug(`found trim: ${foundTrim}`);
                    }

                    if (foundMake || foundModel) {
                        this.vehicleInfoService.loadPossibles(foundMake, foundModel);
                        const vinDecodeResults: VinDecodeResults = {
                            vin,
                            year: decodedVin.year,
                            make: foundMake,
                            model: foundModel,
                            trim: foundTrim,
                        };
                        this.activeModal.close(vinDecodeResults);
                    } else {
                        this.close();
                    }
                });
        });
    }

    get vinControl() {
        return this.form.get('vin') as FormControl;
    }

    get vinControlInvalid() {
        return this.vinControl.invalid && this.vinControl.touched;
    }
}

export interface VinDecodeResults {
    [key: string]: string | number | undefined;
    vin: string;
    year: number;
    make?: string;
    model?: string;
    trim?: string;
}
