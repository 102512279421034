export enum MetaTags {
    'description' = 'description',
    'ogDescription' = 'og:description',
    'ogImage' = 'og:image',
    'ogImageAlt' = 'og:image:alt',
    'ogImageHeight' = 'og:image:height',
    'ogImageSecureUrl' = 'og:image:secure_url',
    'ogImageWidth' = 'og:image:width',
    'ogLocale' = 'og:locale',
    'ogSiteName' = 'og:site_name',
    'ogTitle' = 'og:title',
    'ogType' = 'og:type',
    'ogURL' = 'og:url',
    'twitterCard' = 'twitter:card',
    'twitterDomain' = 'twitter:domain',
    'twitterUrl' = 'twitter:url',
    'twitterTitle' = 'twitter:title',
    'twitterDescription' = 'twitter:description',
    'twitterImage' = 'twitter:image',
    'twitterSite' = 'twitter:site',
    'robots' = 'robots',
}

export type MetaTag = keyof typeof MetaTags;

export type MetaTagConfigs = Partial<{
    [key in MetaTag]: MetaTagConfig;
}>;

export interface MetaTagConfig {
    attribute: 'name' | 'property';
    value: string;
}
