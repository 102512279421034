import { Injectable } from '@angular/core';
import { fromEvent, map, Observable, ReplaySubject, take } from 'rxjs';

import { OverlayService, PlatformService, UtilityService } from '.';

@Injectable()
export class OnlineService {
    private _isOnline$ = new ReplaySubject<boolean>(1);

    constructor(
        private utilityService: UtilityService,
        private overlayService: OverlayService,
        private platformService: PlatformService
    ) {
        if (this.platformService.isServer) {
            // INFO: We are prerendering, no need to load external.
            return;
        }
        this._isOnline$.next(this.utilityService.window.navigator.onLine);

        fromEvent(this.utilityService.window, 'online').subscribe(() => {
            this.hideOffLine();
            this._isOnline$.next(true);
        });

        fromEvent(this.utilityService.window, 'offline').subscribe(() => {
            this.showOffLine();
            this._isOnline$.next(false);
        });
    }

    get isOnline$(): Observable<boolean> {
        return this._isOnline$.asObservable();
    }

    showOffLine() {
        this.overlayService.show('You appear to be offline');
    }

    hideOffLine() {
        this.overlayService.hide();
    }

    get backOnline$(): Observable<boolean> {
        return fromEvent(this.utilityService.window, 'online').pipe(
            take(1),
            map(() => true)
        );
    }
}
