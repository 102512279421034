import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RunEnvironment, RunEnvironmentFull, RunEnvironmentRole } from '@backend-types/internal';
import { EnvConfig, Environment } from '@common/models';
import * as Sentry from '@sentry/angular-ivy';
import {
    developEnvConfig,
    developTestEnvConfig,
    localEnvConfig,
    prodEnvConfig,
    stageEnvConfig,
    stageTestEnvConfig,
} from 'data/env-config';
import { Observable, of, ReplaySubject } from 'rxjs';

import { UtilityService } from './utility.service';

const currentEnvCss =
    'background-color: blue; color: white; font-weight:700; padding: 10px; margin: 2px;';

@Injectable()
export class EnvService {
    private _currentEnv!: Environment;
    private _runEnv$ = new ReplaySubject<RunEnvironmentFull>(1);

    private _runEnvFetched = false;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) {
        this._determineEnvironment();
    }

    get currentEnv() {
        return this._currentEnv;
    }

    get isProd() {
        return this._currentEnv === Environment.prod;
    }

    get isStage() {
        return this._currentEnv === Environment.stage;
    }

    get isDevelop() {
        return this._currentEnv === Environment.develop;
    }

    get isLocal() {
        return this._currentEnv === Environment.local;
    }

    get runEnv$(): Observable<RunEnvironmentFull> {
        if (this.isProd) {
            return of({
                runEnvironment: RunEnvironment.production,
                runEnvironmentRole: RunEnvironmentRole.live,
            });
        }
        if (!this._runEnvFetched) {
            this._runEnvFetched = true;
            this.http
                .get<RunEnvironmentFull>(
                    `${this.config.backendURL}/api/latest/internal/get-run-env`
                )
                .subscribe((runEnv) => this._runEnv$.next(runEnv));
        }
        return this._runEnv$.asObservable();
    }

    // eslint-disable-next-line complexity
    get config() {
        switch (this._currentEnv) {
            case Environment.develop:
                return this._configWithOverrides(developEnvConfig);
            case Environment['develop-test']:
                return this._configWithOverrides(developTestEnvConfig);
            case Environment.local:
                return this._configWithOverrides(localEnvConfig);
            case Environment.stage:
                return this._configWithOverrides(stageEnvConfig);
            case Environment['stage-test']:
                return this._configWithOverrides(stageTestEnvConfig);
            case Environment.prod:
                return this._configWithOverrides(prodEnvConfig);
        }
    }

    private _configWithOverrides(envConfig: EnvConfig) {
        const configOverrides = this.utilityService.getStoredObject<EnvConfig>('config-overrides');
        if (configOverrides) {
            // Can not user LogService because of Circular Dependency
            console.info('### OVERRIDING CONFIG WITH: %o', configOverrides);
            return {
                ...envConfig,
                ...configOverrides,
            };
        }
        return envConfig;
    }

    private _determineEnvironment() {
        let currentEnv: string;
        try {
            currentEnv = this.utilityService.window.currentEnv.toLowerCase();
        } catch (error) {
            // INFO: The rare case that env.js has not run yet...
            Sentry.captureException(error);
            setTimeout(() => {
                this._determineEnvironment();
            }, 500);
            return;
        }

        this._currentEnv = currentEnv as Environment;
        if (
            [
                Environment.develop,
                Environment['develop-test'],
                Environment.local,
                Environment.stage,
                Environment['stage-test'],
                Environment.prod,
            ].find((env) => env === this._currentEnv)
        ) {
            console.info(`%cEnvironment is ${this._currentEnv}`, currentEnvCss);
            return;
        }
        throw new Error(`ENVIRONMENT_NOT_RECOGNIZED: ${this._currentEnv}`);
    }
}
