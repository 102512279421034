import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'sbf-back-button',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './back-button.component.html',
    styleUrls: ['back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
