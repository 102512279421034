<div class="container-xxl" *ngIf="activeEnsQuote">
    <h2 class="mb-5 ms-2">Policy Start Date: {{effectiveDate | isoDateFormatUS}}</h2>
    <ng-container *ngIf="!endorsement"
        ><ng-container *ngFor="let paymentPlanSource of activeEnsQuote.ensOnly.paymentPlanSources; let i = index;"><sbf-quote-ens-purchase-payment-full [agencyAdminAccount]="agencyAdminAccount" *ngIf="paymentPlanSource.name === ePaymentPlanName.full"></sbf-quote-ens-purchase-payment-full><sbf-quote-ens-purchase-payment-twenty-sixteen [agencyAdminAccount]="agencyAdminAccount" *ngIf="paymentPlanSource.name === ePaymentPlanName.twentySixteen"></sbf-quote-ens-purchase-payment-twenty-sixteen></ng-container></ng-container
    ><ng-container *ngIf="endorsement"
        ><ng-container *ngFor="let paymentPlanSource of activeEnsQuote.ensOnly.endorsementRates.paymentPlanSources; let i = index;"><sbf-quote-ens-purchase-payment-full [endorsement]="endorsement" [agencyAdminAccount]="agencyAdminAccount" *ngIf="paymentPlanSource.name === ePaymentPlanName.full"></sbf-quote-ens-purchase-payment-full><sbf-quote-ens-purchase-payment-twenty-sixteen [endorsement]="endorsement" [agencyAdminAccount]="agencyAdminAccount" *ngIf="paymentPlanSource.name === ePaymentPlanName.twentySixteen &amp;&amp; !paymentPlanSource.doNotUse"></sbf-quote-ens-purchase-payment-twenty-sixteen></ng-container
    ></ng-container>
    <div class="row mb-3">
        <div class="col-md-8 col-12 mb-md-0 mb-3">
            <div class="d-flex justify-content-start align-items-center h-100"><div class="text-start text-gray-600 r-text-sm">How would you like to receive your new policy documents?</div></div>
        </div>
        <div class="col-md-4 col-12">
            <form [formGroup]="documentDeliveryForm">
                <div class="form-floating"><sbf-input-select data-cy="electronicDocumentsSelect" idAppend="electronicDocuments" formControlName="documentDelivery" defaultText="Electronic Documents" [optionsRecordsArray]="[documentDeliveryKindText]"></sbf-input-select></div>
            </form>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-end my-3">
        <div class="text-primary me-3">Auto Billing</div>
        <sbf-input-switch data-cy="autoBillingSwitch" idAppend="autoBillingSwitch" [formControl]="autoBillingOptIn" size="sm"></sbf-input-switch>
    </div>
    <div class="d-flex justify-content-start mb-3"><sbf-back-button data-cy="purchaseBackButton" (click)="back.emit()"></sbf-back-button></div>
</div>
