import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    DevUtilsModalConfig,
    DevUtilsModalKind,
    DevUtilsModalScenarios,
    FunctionReferences,
} from '@common/models/dev-utils.model';
import { devUtilsModalConfig } from '@data/dev/dev-utils-modal-config.data';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-dev-utils-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dev-utils-modal.component.html',
    styleUrls: ['dev-utils-modal.component.scss'],
})
export class DevUtilsModalComponent<T extends DevUtilsModalKind> implements OnInit {
    devUtilsModalKind!: T;
    functionRefereces!: FunctionReferences[T];

    devUtilsModalConfig!: DevUtilsModalConfig[T];

    subscription = new Subscription();

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {
        this.devUtilsModalConfig = devUtilsModalConfig[this.devUtilsModalKind];
    }

    close() {
        this.activeModal.close();
    }

    performAction(scenario: keyof DevUtilsModalScenarios[T]) {
        this.functionRefereces.writeValue(
            // @ts-ignore Don't like that I have to ignore here. Looks like a Typescript bug.
            this.devUtilsModalConfig.scenarios[scenario].formValues
        );
        this.close();
    }
}
