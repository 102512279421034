import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import {
    DomSanitizer,
    SafeHtml,
    SafeResourceUrl,
    SafeScript,
    SafeStyle,
    SafeUrl,
} from '@angular/platform-browser';

type SafeTypes = 'html' | 'style' | 'script' | 'url' | 'resourceURL';

@Pipe({
    name: 'safe',
})
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    // eslint-disable-next-line complexity
    transform(
        value: string | null,
        type: SafeTypes,
        securityContext?: keyof typeof SecurityContext
    ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        if (securityContext) {
            value = this.sanitizer.sanitize(SecurityContext[securityContext], value);
        }
        if (!value) {
            throw new Error(`### ERROR: No value to sanitize`);
        }

        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceURL':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                throw new Error(`### ERROR: Invalid safe type specified: ${type}`);
        }
    }
}
