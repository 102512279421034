import { Injectable } from '@angular/core';
// import { CreateTrafficIncidentPayload, TrafficIncidentType } from '@backend-types/traffic-incident';
// import { ModelFormValue } from '@common/models';

@Injectable()
export class IncidentService {
    constructor() {}

    // newIncident(incidentType: TrafficIncidentType): ModelFormValue<CreateTrafficIncidentPayload> {
    //     return {
    //         incidentType,
    //         incidentDescription: null,
    //         incidentOccurence: null,
    //     };
    // }
}
