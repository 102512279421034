import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class DevService {
    constructor() {}

    getDev$(): Observable<{}> {
        return of({});
    }
}
