import { AfterContentInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[sbwAutoFocus]',
})
export class AutoFocusDirective implements AfterContentInit {
    @Input('sbwAutoFocus') isFocused!: boolean;
    @Input() formOnly = false;

    public constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) {}

    public ngAfterContentInit() {
        if (this.formOnly) {
            return;
        }
        setTimeout(() => {
            this.el.nativeElement.focus();
            setTimeout(() => {
                this.renderer.setAttribute(this.el.nativeElement, 'data-auto-focused', 'done');
            }, 100);
        }, 200);
    }
}
