import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { EnvService } from '.';

@Injectable()
export class DevelopService implements OnDestroy {
    subscription: Subscription;
    constructor(
        private http: HttpClient,
        private envService: EnvService
    ) {
        this.subscription = new Subscription();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    pingBackend(): void {
        this.http
            .get(`${this.envService.config.backendURL}/api/latest/health`, {
                responseType: 'text',
            })
            .subscribe();
    }
}
