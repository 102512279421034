export enum RoleName {
    root = 'root',
    admin = 'admin',
    editor = 'editor',
    // producer = 'producer',
    // subProducer = 'subProducer',
    // affiliate = 'affiliate',
    registered = 'registered',
    observer = 'observer',
    guest = 'guest',
}

export type FindRoleErrorCodes = 'ERROR_FINDING_ROLE' | 'ROLE_NOT_FOUND';
