<div class="container-xxl">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block">
                <div class="quote-title">Do you have any</div>
                <div class="quote-title">gear you want insured?</div>
            </div>
            <div class="d-md-block d-none"><div class="quote-title">Do you have any gear you want insured?</div></div>
        </div>
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="quote-subtitle">
                Gear are those extras you take on your adventures that are not permanently attached to
                <div class="d-inline-block italic">{{rigName}}</div>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center w-100"><img class="d-block mb-md-5 mb-3 mx-5" style="max-width: 425px" src="/assets/img/svg/overland-design/od_gear_full.svg" alt="Gear svg" /></div>
        <div class="col-xl-4 col-md-6 col-12 d-inline-block">
            <div class="d-flex justify-content-between">
                <button class="btn btn-xl rounded-pill btn-primary-gradient quote-conditional-button mb-5 mt-md-5 mt-3 w-100 me-2" data-cy="gearConditionalNoButton" type="button" (click)="noGear()" tabindex="0"><div class="d-flex align-items-center justify-content-center">No</div></button><button class="btn btn-xl rounded-pill btn-primary-gradient quote-conditional-button mb-5 mt-md-5 mt-3 w-100 ms-2" data-cy="gearConditionalYesButton" type="button" (click)="addGear()" tabindex="0"><div class="d-flex align-items-center justify-content-center">Yes</div></button>
            </div>
        </div>
        <div class="d-flex justify-content-start"><sbf-back-button data-cy="gearConditionalBackButton" (click)="back.emit()"></sbf-back-button></div>
    </div>
</div>
