import type { Prisma } from './_prisma';
import type { CurrentInsurance } from './insurance-carriers';

export type DriverForResults = Prisma.DriverGetPayload<object>;

export interface CreateDriverPayload {
    policyWrapperId?: UUID; // INFO: To create PolicyEvent, not needed to just create
    firstName: string;
    lastName: string;
    street: string;
    street2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    organizationId: UUID;
    email?: string;
    phone?: string;
    birthday?: string;
    ssn?: string;
    licenseNumber?: string;
    licenseState?: string;
    accidentsViolations?: number;
    maritalStatus?: DriverMaritalStatus;
    licenseStatus?: DriverLicenseStatus;
    yearsLicensed?: DriverYearsLicensed;
    currentInsurance?: CurrentInsurance;
    hasSR22?: boolean;
    hasFR44?: boolean;
    decPageProviderDriverId?: UUID;
    gender?: string;
    occupation?: string;
    relationshipToInsured?: string;
    principalDriverPolicyId?: UUID;
    additionalDriverPolicyId?: UUID;
}

export enum DriverGender {
    male = 'male',
    female = 'female',
}

export enum DriverMaritalStatus {
    single = 'single',
    married = 'married',
    domesticPartner = 'domesticPartner',
    separated = 'separated',
    widowed = 'widowed',
    divorced = 'divorced',
}

export const driverMaritalStatusText: EnumMap<DriverMaritalStatus> = {
    single: 'Single',
    married: 'Married',
    domesticPartner: 'Domestic Partner',
    separated: 'Separated',
    widowed: 'Widowed',
    divorced: 'Divorced',
};

export enum DriverEducationLevel {
    noHighschoolDiplomaOrGED = 'noHighschoolDiplomaOrGED',
    highschoolDiplomaOrGED = 'highschoolDiplomaOrGED',
    vocationTradeDegreeMilitaryTraining = 'vocationTradeDegreeMilitaryTraining',
    completedSomeCollege = 'completedSomeCollege',
    currentlyInCollege = 'currentlyInCollege',
    collegeDegree = 'collegeDegree',
    graduateWorkOrGraduateDegree = 'graduateWorkOrGraduateDegree',
}

export const driverEducationLevelText: EnumMap<DriverEducationLevel> = {
    noHighschoolDiplomaOrGED: 'No high school diploma or GED',
    highschoolDiplomaOrGED: 'High school diploma or GED',
    vocationTradeDegreeMilitaryTraining: 'Vocation/trade degreee/military training',
    completedSomeCollege: 'Completed some college',
    currentlyInCollege: 'Currently in college',
    collegeDegree: 'College Degree',
    graduateWorkOrGraduateDegree: 'Graduate work or graduate degree',
};

export enum DriverEmploymentStatus {
    employed = 'employed',
    homemakerFullTime = 'homemakerFullTime',
    notWorkingOther = 'notWorkingOther',
    retiredFullTime = 'retiredFullTime',
    studentFullTime = 'studentFullTime',
}

export const driverEmploymentStatusText: EnumMap<DriverEmploymentStatus> = {
    employed: 'Employed',
    homemakerFullTime: 'Homemaker (full time)',
    notWorkingOther: 'Not working/other',
    retiredFullTime: 'Retired (full time)',
    studentFullTime: 'Student (full time)',
};

export enum DriverResidency {
    ownHome = 'ownHome',
    ownCondominimum = 'ownCondominimum',
    ownMobileHome = 'ownMobileHome',
    rent = 'rent',
    other = 'other',
}

export const driverResidencyText: EnumMap<DriverResidency> = {
    ownHome: 'Own Home',
    ownCondominimum: 'Own Condominimum',
    ownMobileHome: 'Own Mobile Home',
    rent: 'Rent',
    other: 'Other',
};

export enum DriverLicenseType {
    personal = 'personal',
    commercialNonChauffeur = 'commercialNonChauffeur',
    chauffeur = 'chauffeur',
    permit = 'permit',
    foreignDriversLicense = 'foreignDriversLicense',
    notLicensedStateId = 'notLicensedStateId',
}

export const driverLicenseTypeText: EnumMap<DriverLicenseType> = {
    personal: 'Personal',
    commercialNonChauffeur: 'Commercial (non-chauffeur)',
    chauffeur: 'Chauffeur',
    permit: 'Permit',
    foreignDriversLicense: "Foreign driver's license",
    notLicensedStateId: 'Not licensed/State ID',
};

export enum DriverLicenseStatus {
    valid = 'valid',
    suspended = 'suspended',
    expired = 'expired',
    revoked = 'revoked',
}

export const driverLicenseStatusText: EnumMap<DriverLicenseStatus> = {
    valid: 'Valid',
    suspended: 'Suspended',
    expired: 'Expired',
    revoked: 'Revoked',
};

export enum DriverYearsLicensed {
    threeYearsOrMore = 'threeYearsOrMore',
    twoToThreeYears = 'twoToThreeYears',
    oneToTwoYears = 'oneToTwoYears',
    lessThanOneYear = 'lessThanOneYear',
}

export const driverYearsLicensedText: EnumMap<DriverYearsLicensed> = {
    threeYearsOrMore: '3 years or more',
    twoToThreeYears: '2 - 3 years',
    oneToTwoYears: '1 - 2 years',
    lessThanOneYear: 'Less than 1 year',
};

export interface ReadMultipleDriversPayload {
    driverIDs: string[];
}

export interface DeleteMultipleDriversPayload {
    driverIDs: string[];
}

export interface UpdateDriverPayload {
    firstName?: string;
    lastName?: string;
    street?: string;
    street2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country: string;
    organizationId?: UUID;
    email?: string;
    phone?: string;
    birthday?: string;
    ssn?: string;
    licenseNumber?: string;
    licenseState?: string;
    accidentsViolations?: number;
    maritalStatus?: DriverMaritalStatus;
    licenseStatus?: DriverLicenseStatus;
    yearsLicensed?: DriverYearsLicensed;
    currentInsurance?: CurrentInsurance;
    hasSR22?: boolean;
    hasFR44?: boolean;
    gender?: string;
    occupation?: string;
    relationshipToInsured?: string;
    principalDriverPolicyId?: UUID;
    additionalDriverPolicyId?: UUID;
}

export type AllDriversErrorCodes = 'ERROR_FINDING_DRIVERS' | 'DRIVERS_NOT_FOUND';
export type CreateDriversErrorCodes = 'DRIVER_NAME_IN_USE' | 'ERROR_CREATING_DRIVER';
export type DeleteMultipleDriversErrorCodes =
    | 'DRIVERS_NOT_FOUND'
    | 'ERROR_FINDING_DRIVERS'
    | 'ERROR_DELETING_DRIVERS';
export type DeleteDriverErrorCodes =
    | 'DRIVER_NOT_FOUND'
    | 'ERROR_FINDING_DRIVER'
    | 'ERROR_DELETING_DRIVER';
export type FindDriverErrorCodes = 'ERROR_FINDING_DRIVER' | 'DRIVER_NOT_FOUND';
export type FindDriversErrorCodes = 'ERROR_FINDING_DRIVERS' | 'NO_DRIVERS_FOUND';
export type SaveDriverErrorCodes = 'ERROR_SAVING_DRIVER';
export type UpdateDriverErrorCodes = 'ERROR_UPDATING_DRIVER' | 'DRIVER_NOT_FOUND';

export interface CreateDriverFormValue {
    policyWrapperId: UUID;
    firstName: string;
    lastName: string;
    street: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    email: string;
    phone: string;
    birthday: string;
    ssn: string;
    licenseNumber: string;
    accidentsViolations: number;
    hasSR22: boolean;
    hasFR44: boolean;
    organizationId: UUID;
    principalDriverPolicyId: UUID;
    additionalDriverPolicyId: UUID;
}

export interface UpdateDriverFormValue {
    driverId: UUID;
    policyWrapperId: UUID;
    firstName: string;
    lastName: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
    email: string;
    phone: string;
    birthday: string;
    ssn: string;
    licenseNumber: string;
    accidentsViolations: number;
    hasSR22: boolean;
    hasFR44: boolean;
}

export interface DeleteDriverPayload {
    policyWrapperId: UUID;
}

export interface DriverTyped extends DriverForResults {
    maritalStatus: DriverMaritalStatus | null;
    licenseStatus: DriverLicenseStatus | null;
    yearsLicensed: DriverYearsLicensed | null;
    currentInsurance: CurrentInsurance | null;
}

export interface DriverTypedForQuote
    extends Omit<DriverTyped, 'id' | 'createdAt' | 'updatedAt' | 'version'> {}
