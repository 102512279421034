/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { LogLevel, logLevelValues } from '@common/models';

import { EnvService } from './env.service';
import { UtilityService } from './utility.service';

export type LogMessage = string | object | number | boolean | null;

@Injectable()
export class LogService {
    logLevelValue!: number;

    constructor(
        private envService: EnvService,
        private utilityService: UtilityService
    ) {
        const localStorageLogLevel = this.utilityService.localStorage.getItem('sb-log-level');
        if (localStorageLogLevel) {
            this.logLevelValue = parseInt(localStorageLogLevel, 10);
        } else {
            this.logLevelValue = this.envService.config.logLevel;
        }
    }

    trace(message: LogMessage, alternateTitle?: string, color?: string) {
        if (logLevelValues[LogLevel.trace] >= this.logLevelValue) {
            if (typeof message === 'object') {
                !alternateTitle
                    ? console.warn(
                          `%c### TRACE: %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      )
                    : console.warn(
                          `%c${alternateTitle} %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      );
            } else {
                !alternateTitle
                    ? console.warn(
                          `%c### TRACE: ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      )
                    : console.warn(
                          `%c${alternateTitle} ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      );
            }
        }
    }

    debug(message: LogMessage, alternateTitle?: string, color?: string) {
        if (logLevelValues[LogLevel.debug] >= this.logLevelValue) {
            if (typeof message === 'object') {
                !alternateTitle
                    ? console.warn(
                          `%c### DEBUG: %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      )
                    : console.warn(
                          `%c${alternateTitle} %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      );
            } else {
                !alternateTitle
                    ? console.warn(
                          `%c### DEBUG: ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      )
                    : console.warn(
                          `%c${alternateTitle} ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      );
            }
        }
    }

    info(message: LogMessage, alternateTitle?: string, color?: string) {
        if (logLevelValues[LogLevel.info] >= this.logLevelValue) {
            if (typeof message === 'object') {
                !alternateTitle
                    ? console.info(
                          `%c### INFO: %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      )
                    : console.info(
                          `%c${alternateTitle} %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      );
            } else {
                !alternateTitle
                    ? console.info(
                          `%c### INFO: ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      )
                    : console.info(
                          `%c${alternateTitle} ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      );
            }
        }
    }

    warn(message: LogMessage, alternateTitle?: string, color?: string) {
        if (logLevelValues[LogLevel.warn] >= this.logLevelValue) {
            if (typeof message === 'object') {
                !alternateTitle
                    ? console.warn(
                          `%c### WARN: %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      )
                    : console.warn(
                          `%c${alternateTitle} %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      );
            } else {
                !alternateTitle
                    ? console.warn(
                          `%c### WARN: ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      )
                    : console.warn(
                          `%c${alternateTitle} ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      );
            }
        }
    }

    error(message: LogMessage, alternateTitle?: string, color?: string) {
        if (logLevelValues[LogLevel.error] >= this.logLevelValue) {
            if (typeof message === 'object') {
                !alternateTitle
                    ? console.error(
                          `%c### ERROR: %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      )
                    : console.error(
                          `%c${alternateTitle} %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      );
            } else {
                !alternateTitle
                    ? console.error(
                          `%c### ERROR: ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      )
                    : console.error(
                          `%c${alternateTitle} ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      );
            }
        }
    }

    fatal(message: LogMessage, alternateTitle?: string, color?: string) {
        if (logLevelValues[LogLevel.fatal] >= this.logLevelValue) {
            if (typeof message === 'object') {
                !alternateTitle
                    ? console.error(
                          `%c### FATAL: %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      )
                    : console.error(
                          `%c${alternateTitle} %o`,
                          this._cssStringWithColor(color || 'green'),
                          message
                      );
            } else {
                !alternateTitle
                    ? console.error(
                          `%c### FATAL: ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      )
                    : console.error(
                          `%c${alternateTitle} ${message}`,
                          this._cssStringWithColor(color || 'orange')
                      );
            }
        }
    }

    private _cssStringWithColor(color: string): string {
        return `color: ${color}; font-weight:700;`;
    }
}
