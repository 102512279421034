import { Injectable } from '@angular/core';
import { randomSplitTest, SplitTest, SplitTestLocalStorage, splitTests } from '@common/models';

import { EnvService } from './env.service';
import { LogService } from './log.service';
import { UtilityService } from './utility.service';

// const ACTIVE_SPLIT_TEST: SplitTest | null = null;
const ACTIVE_SPLIT_TEST: SplitTest | null = SplitTest.headerCtaText;

@Injectable()
export class SplitTestService {
    constructor(
        private utilityService: UtilityService,
        private logService: LogService,
        private envService: EnvService
    ) {
        if (this.envService.isLocal) {
            this.logService.warn('CLEARING_LOCAL_SPLIT_TEST_ON_LOCAL');
            this.clear();
        }

        if (!ACTIVE_SPLIT_TEST) {
            this.logService.debug(null, '### DEBUG - SPLIT TEST: ');
            this.clear();
            return;
        }
        const existingSplitTest = this.splitTest;

        const splitTestLocalStorage: SplitTestLocalStorage = {
            activeSplitTest: ACTIVE_SPLIT_TEST,
            activeSplitTestValue: randomSplitTest(ACTIVE_SPLIT_TEST),
        };

        if (existingSplitTest) {
            if (existingSplitTest.activeSplitTest !== ACTIVE_SPLIT_TEST) {
                this.utilityService.storeObject('split_test', splitTestLocalStorage);
            }
        } else {
            this.utilityService.storeObject('split_test', splitTestLocalStorage);
        }

        this.logService.debug(this.splitTest, '### DEBUG - SPLIT TEST: ');
    }

    get splitTest(): SplitTestLocalStorage | null {
        return this.utilityService.getStoredObject<SplitTestLocalStorage>('split_test') || null;
    }

    splitTestValue(splitTest: SplitTest): string {
        const storedSplitTest = this.splitTest;

        if (storedSplitTest) {
            if (storedSplitTest.activeSplitTest === splitTest) {
                return storedSplitTest.activeSplitTestValue;
            }
        }
        return splitTests[splitTest].default;
    }

    clear() {
        this.utilityService.removeObject('split_test');
    }
}
