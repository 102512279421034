import { InjectionToken } from '@angular/core';
import { PlatformService } from '@common/services/platform.service';

export const LocalStorageToken = new InjectionToken('STORAGE');
export function localStorageProvider(platformService: PlatformService) {
    if (platformService.isBrowser) {
        return localStorage;
    }
    if (platformService.isServer) {
        return {
            getItem: () => {},
            setItem: () => {},
            removeItem: () => {},
        } as unknown as Storage;
    }
}
