import { Inject, Injectable } from '@angular/core';
import { type CopyToClipboard, CopyToClipboardOptions } from '@common/models';
import {
    CopyToClipboardToken,
    IntlToken,
    LocalStorageToken,
    ParseToken,
    SessionStorageToken,
    StringifyToken,
    WindowToken,
} from '@common/services/providers';

const TREDDER_PREFIX = 'trd-';

@Injectable()
export class UtilityService {
    constructor(
        @Inject(WindowToken) private _window: Window,
        @Inject(IntlToken) private _intl: typeof Intl,
        @Inject(LocalStorageToken) private _localStorage: Storage,
        @Inject(SessionStorageToken) private _sessionStorage: Storage,
        @Inject(ParseToken) private _parse: JSON['parse'],
        @Inject(StringifyToken) private _stringify: JSON['stringify'],
        @Inject(CopyToClipboardToken) private _copyToClipboard: CopyToClipboard
    ) {}

    get window(): Window {
        return this._window;
    }

    get intl(): typeof Intl {
        return this._intl;
    }

    get parse(): JSON['parse'] {
        return this._parse;
    }

    get stringify(): JSON['stringify'] {
        return this._stringify;
    }

    get localStorage(): Storage {
        // return this._localStorage;
        return {
            length: this._localStorage.number,
            number: this._localStorage.number,
            clear: this._localStorage.clear,
            getItem: (key: string) => {
                return this._localStorage.getItem(`${TREDDER_PREFIX}${key}`);
            },
            key: this._localStorage.key,
            removeItem: (key: string) => {
                this._localStorage.removeItem(`${TREDDER_PREFIX}${key}`);
            },
            setItem: (key: string, value: string) => {
                this._localStorage.setItem(`${TREDDER_PREFIX}${key}`, value);
            },
        };
    }

    get localStorageClean(): Storage {
        return this._localStorage;
    }

    getStoredObject<T>(objectName: string): T | undefined {
        const objectString = this.localStorage.getItem(objectName);

        if (!objectString) {
            return undefined;
        }
        return this.parse(objectString) as T;
    }

    storeObject(objectName: string, objectToStore: {}): void {
        this.localStorage.setItem(objectName, this._stringify(objectToStore));
    }

    removeObject(objectName: string): void {
        this.localStorage.removeItem(objectName);
    }

    copyToClipboard(text: string, options?: CopyToClipboardOptions) {
        this._copyToClipboard(text, options);
    }

    openInNewWindow(url: string) {
        this.window.open(url, '_blank');
    }

    clearAllTredderFromLocalStorage() {
        this.removeObjectsWithPrefix('');
    }

    removeObjectsWithPrefix(prefix: string): void {
        const arr = [];

        for (let i = 0; i < this._localStorage.length; i++) {
            const toMatch = new RegExp(`^${TREDDER_PREFIX}${prefix}`);
            if (this._localStorage.key(i)?.match(toMatch)) {
                arr.push(this._localStorage.key(i));
            }
        }
        // console.log(arr);

        for (let i = 0; i < arr.length; i++) {
            this._localStorage.removeItem(arr[i] as string);
        }
    }
}
