import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { InputRadioFormValue, ModelFormGroup } from '@common/models';
import { AssertionService } from '@common/services';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-input-radio',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './input-radio.component.html',
    styleUrls: ['input-radio.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: InputRadioComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: InputRadioComponent,
        },
    ],
})
export class InputRadioComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {
    @Input() labelA!: string;
    @Input() labelB!: string;
    @Input() valueA!: string;
    @Input() valueB!: string;
    @Input() idAppend!: string;

    radioAID!: string;
    radioBID!: string;

    subscription: Subscription = new Subscription();

    inputRadioForm: ModelFormGroup<InputRadioFormValue> = this.fb.group({
        selectedValue: new FormControl<string | null>(null, Validators.required),
    });

    onTouched: () => unknown = () => {};
    onChange = (selectedInput: string) => {};

    constructor(
        private fb: FormBuilder,
        private assertionService: AssertionService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        if (!this.idAppend) {
            throw new Error('NEED_ID_APPEND_FOR_INPUT_RADIO');
        }
        this.radioAID = `radioA-${this.idAppend}`;
        this.radioBID = `radioB-${this.idAppend}`;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(onChange: (selectedInput: string) => unknown) {
        this.onChange = onChange;

        this.subscription.add(
            this.inputRadioForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.inputRadioForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const inputRadioFormValue = this._inputRadioFormValue();
                        onChange(inputRadioFormValue.selectedValue);
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.inputRadioForm.disable({ emitEvent: false });
        } else {
            this.inputRadioForm.enable({ emitEvent: false });
        }
    }

    writeValue(selectedValue: string | null) {
        if (selectedValue === null) {
            this.inputRadioForm.reset();
        }

        this.selectedValueControl.setValue(selectedValue, { emitEvent: false });
        this.changeDetectorRef.detectChanges();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.inputRadioForm?.invalid) {
            return { inputRadioFormInvalid: true };
        }

        return null;
    }

    inlineRadioClicked(event: PointerEvent, selectedValue: string) {
        if ((event.target as HTMLElement)?.classList.contains('form-check-inline')) {
            this.selectedValueControl.setValue(selectedValue);
        }
    }

    private _inputRadioFormValue(): InputRadioFormValue {
        const { selectedValue } = this.inputRadioForm.value;

        this.assertionService.isDefinedOrThrow(selectedValue);

        return {
            selectedValue,
        };
    }

    /* Accessor Methods */

    get selectedValueControl() {
        return this.inputRadioForm.get('selectedValue') as FormControl;
    }

    get selectedValueControlValid() {
        return this.selectedValueControl.value && !this.selectedValueControl.hasError('required');
    }

    get selectedValueControlInvalid() {
        return this.selectedValueControl.touched && this.selectedValueControl.hasError('required');
    }
}
