/* eslint-disable simple-import-sort/imports */
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Service Components */
import { ConfirmationModalComponent } from '@common/components/confirmation-modal/confirmation-modal.component';
import { ToastComponent } from '@common/components/toast/toast.component';
import { SizeDetectorComponent } from '@common/components/size-detector/size-detector.component';

/* Service Containers */
import { OverlayComponent } from '@common/containers/overlay/overlay.component';
import { ProgressbarComponent } from '@common/components/progressbar/progressbar.component';
import { ToastViewComponent } from '@common/containers/toast-view/toast-view.component';

/* Services */
import * as appCommonServices from './services';

/* Pipes */

/* Providers */
import {
    WindowToken,
    windowProvider,
    ParseToken,
    parseProvider,
    StringifyToken,
    stringifyProvider,
    CopyToClipboardToken,
    copyToClipboardProvider,
    LocalStorageToken,
    localStorageProvider,
    SessionStorageToken,
    sessionStorageProvider,
    intlProvider,
    IntlToken,
} from './services/providers';

/* Resolvers */
import * as appCommonResolvers from './resolvers';

/* Interceptors */
import { interceptors } from './interceptors';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/* Modules */
import { IconsModule } from '@modules/icons/icons.module';

/* Third Party */
import { AppCommonModule, ngbModules } from './app-common.module';

@NgModule({
    imports: [
        CommonModule,
        AppCommonModule,
        FormsModule,
        ReactiveFormsModule,
        ConfirmationModalComponent,
        IconsModule,
        ...ngbModules,
    ],
    declarations: [
        OverlayComponent,
        ProgressbarComponent,
        ToastViewComponent,
        ToastComponent,
        SizeDetectorComponent,
    ],
    exports: [
        OverlayComponent,
        ProgressbarComponent,
        ToastViewComponent,
        ToastComponent,
        SizeDetectorComponent,
    ],
    providers: [],
})
export class AppCommonServicesModule {
    static forRoot(): ModuleWithProviders<AppCommonServicesModule> {
        return {
            ngModule: AppCommonServicesModule,
            providers: [
                DecimalPipe,
                {
                    provide: WindowToken,
                    useFactory: windowProvider,
                    deps: [appCommonServices.PlatformService],
                },
                {
                    provide: IntlToken,
                    useFactory: intlProvider,
                    deps: [appCommonServices.PlatformService],
                },
                { provide: ParseToken, useFactory: parseProvider },
                { provide: StringifyToken, useFactory: stringifyProvider },
                { provide: CopyToClipboardToken, useFactory: copyToClipboardProvider },
                {
                    provide: LocalStorageToken,
                    useFactory: localStorageProvider,
                    deps: [appCommonServices.PlatformService],
                },
                {
                    provide: SessionStorageToken,
                    useFactory: sessionStorageProvider,
                    deps: [appCommonServices.PlatformService],
                },
                ...appCommonServices.services,
                ...appCommonResolvers.resolvers,
                {
                    provide: HTTP_INTERCEPTORS,
                    useFactory: authInterceptorFactory,
                    multi: true,
                    deps: [Router, appCommonServices.AuthUtilsService],
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useFactory: errorInterceptorFactory,
                    multi: true,
                    deps: [
                        Router,
                        appCommonServices.AuthUtilsService,
                        appCommonServices.OverlayService,
                    ],
                },
            ],
        };
    }
}

export function authInterceptorFactory(
    router: Router,
    authUtilsService: appCommonServices.AuthUtilsService
) {
    return new interceptors.AuthInterceptor(router, authUtilsService);
}

export function errorInterceptorFactory(
    router: Router,
    authUtilsService: appCommonServices.AuthUtilsService,
    overlayService: appCommonServices.OverlayService
) {
    return new interceptors.ErrorInterceptor(router, authUtilsService, overlayService);
}
