<div class="modal-body">
    <div class="py-2 px-3">
        <div class="d-block r-text-sub-heading text-primary mb-2">Electronic Signature Consent</div>
        <div class="d-block text-primary mb-3">By signing electronically, you agree that your electronic signature is the legally binding equivalent of your handwritten signature. You consent to be legally bound by this agreement and acknowledge that you have read and understood the terms and conditions.</div>
        <div class="d-block r-text-sub-heading text-primary mb-2">Use of Electronic Records</div>
        <div class="d-block text-primary mb-3">You agree that insurance documents, notices, and other communications may be provided to you electronically. This includes, but is not limited to, policy documents, billing statements, and other important communications.</div>
        <div class="d-block r-text-sub-heading text-primary mb-2">Withdrawal of Consent</div>
        <div class="d-block text-primary mb-3">You have the right to withdraw your consent to receive electronic records at any time. If you choose to withdraw consent, it may impact your ability to access certain services.</div>
        <div class="d-block r-text-sub-heading text-primary mb-2">System Requirements</div>
        <div class="d-block text-primary mb-3">To electronically sign and receive documents, you must have access to a computer or mobile device with internet access and compatible software.</div>
    </div>
</div>
<div class="d-flex justify-content-end">
    <button class="btn rounded-pill btn-primary-gradient text-white fw-500 m-3" data-cy="closeElectronicSignatureModal" (click)="close()" tabindex="0"><div class="mx-3">Close</div></button>
</div>
