<ng-container *ngIf="formOnly"><ng-template [ngTemplateOutlet]="rigDetailsFormTemplate"></ng-template></ng-container
><ng-template #rigDetailsFormTemplate
    ><div class="row">
        <div class="col-12 mb-3"><div class="r-text-base text-start me-3">Let's give your rig a name!</div></div>
        <div class="col-md-6 col-12">
            <form class="mb-3" data-cy="rigNameForm" [formGroup]="rigNameForm">
                <div class="form-floating">
                    <input class="form-control" id="rigNameInput" data-cy="rigNameInput" autocomplete="off" type="text" aria-describedby="rigNameHelp" sbwAutoFocus formControlName="rigName" [class.is-invalid]="rigNameControlInvalid" placeholder="rigName" (keyup.enter)="rigNameControlValid &amp;&amp; next.emit()" />
                    <div class="invalid-feedback" *ngIf='rigNameControl.hasError("required")'>Rig Name required.</div>
                    <label class="text-gray-600 small" for="rigNameInput">Your Rig's Nickname</label>
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mb-3"><div class="r-text-base text-start me-3">Tell us about {{rigNameControl.value || 'your rig'}}.</div></div>
    </div>
    <form data-cy="rigDetailsFormRemaining" [formGroup]="rigDetailsForm">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex justify-content-between align-items-end mb-3 mx-2">
                    <div class="text-lg text-start light lh-1">Vehicle Info</div>
                    <div class="text-link text-cyan-blue primary-hover d-inline-block lh-1" data-cy="enterVinModalButton" (click)="openVinModal()">Lookup by VIN</div>
                </div>
                <div class="form-floating mb-3">
                    <input class="form-control" id="yearInput" data-cy="yearInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="yearHelp" [formOnly]="formOnly" formControlName="year" [class.is-invalid]="yearControlInvalid" placeholder="year" mask="9999" />
                    <div class="invalid-feedback" *ngIf='yearControl.hasError("required")'>Model Year required.</div>
                    <div class="invalid-feedback" *ngIf='yearControl.hasError("min")'>Model Year must be after {{minYear}}</div>
                    <div class="invalid-feedback" *ngIf='yearControl.hasError("max")'>Model Year must be before {{maxYear}}</div>
                    <label class="text-gray-600 small" for="yearInput">Model Year</label>
                </div>
                <div class="form-floating mb-3">
                    <input class="form-control" id="makeInput" data-cy="makeInput" autocomplete="off" type="text" aria-describedby="makeHelp" formControlName="make" placeholder="make" [class.is-invalid]="makeControlInvalid" [ngbTypeahead]="searchMakes" [selectOnExact]="true" (focus)="makeFocus.next(makeControl.value)" />
                    <div class="invalid-feedback" *ngIf="makeControlInvalid"><ng-container *ngIf="makeControl.hasError('required')">Make required.</ng-container><ng-container *ngIf="makeControl.hasError('possibleMakes')">Make not found.</ng-container></div>
                    <label class="text-gray-600 small" for="makeInput">Make</label>
                </div>
                <div class="form-floating mb-3" *ngIf="!hideModelControl">
                    <input class="form-control" id="modelInput" data-cy="modelInput" autocomplete="off" type="text" aria-describedby="modelHelp" formControlName="model" [class.is-invalid]="modelControlInvalid" placeholder="model" [ngbTypeahead]="searchModels" [selectOnExact]="true" (focus)="modelFocus.next(modelControl.value)" />
                    <div class="invalid-feedback" *ngIf="modelControlInvalid"><ng-container *ngIf="modelControl.hasError('required')">Model required.</ng-container><ng-container *ngIf="modelControl.hasError('possibleModels')">Model not found.</ng-container></div>
                    <label class="text-gray-600 small" for="modelInput">Model</label>
                </div>
                <div class="form-floating mb-3" *ngIf="!hideTrimControl">
                    <input class="form-control" id="trimInput" data-cy="trimInput" autocomplete="off" type="text" aria-describedby="trimHelp" formControlName="trim" [class.is-invalid]="trimControlInvalid" placeholder="trim" [ngbTypeahead]="searchTrims" [selectOnExact]="true" (focus)="trimFocus.next(trimControl.value)" />
                    <div class="invalid-feedback" *ngIf="trimControlInvalid"><ng-container *ngIf="trimControl.hasError('required')">Trim required.</ng-container><ng-container *ngIf="trimControl.hasError('possibleTrims')">Trim not found.</ng-container></div>
                    <label class="text-gray-600 small" for="trimInput">Trim</label>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="d-flex justify-content-between align-items-bottom mb-3 mx-2"><div class="text-lg text-start lh-1 light">Ownership Info</div></div>
                <div class="form-floating mb-3">
                    <sbf-input-select data-cy="yearsOwnedSelect" idAppend="yearsOwned" formControlName="yearsOwned" defaultText="Years Owned" [optionsRecordsArray]="[rocRigOwnedTenureText]" [class.is-invalid]="yearsOwnedControlInvalid"></sbf-input-select>
                    <div class="invalid-feedback" *ngIf='yearsOwnedControl.hasError("required")'>Years Owned required.</div>
                </div>
                <div class="form-floating mb-3">
                    <sbf-input-select data-cy="ownOrLeaseSelect" idAppend="ownOrLease" formControlName="ownOrLease" defaultText="Own or Lease" [optionsRecordsArray]="[ownOrLeaseText]" [class.is-invalid]="ownOrLeaseControlInvalid"></sbf-input-select>
                    <div class="invalid-feedback" *ngIf='ownOrLeaseControl.hasError("required")'>Own or Lease required.</div>
                </div>
                <div class="form-floating">
                    <sbf-input-select data-cy="storageMethodSelect" idAppend="storageMethod" formControlName="storageMethod" defaultText="Storage Method" [optionsRecordsArray]="[rigStorageMethodText]" [class.is-invalid]="storageMethodControlInvalid"></sbf-input-select>
                    <div class="invalid-feedback" *ngIf='storageMethodControl.hasError("required")'>Storage Method required.</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3"><div class="r-text-base text-start me-3">Tell us more about yourself.</div></div>
        </div>
    </form></ng-template
>
