import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { CoverageReportProvider } from '@backend-types/existing-coverage';
import { CurrentInsurance, currentInsuranceText } from '@backend-types/insurance-carriers';
import { ExistingCoverageManualFormValue } from '@backend-types/quote-flow-ens';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { AnalyticsService, AssertionService, HotKeysService } from '@common/services';
import { validateIsWithinInterval } from '@common/validators';
import { QuoteRigSetFormService } from '@modules/quote/services';
import { addYears, format, subYears } from 'date-fns';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-existing-coverage',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-existing-coverage.component.html',
    styleUrls: ['quote-ens-existing-coverage.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteEnsExistingCoverageComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteEnsExistingCoverageComponent,
        },
    ],
})
export class QuoteEnsExistingCoverageComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @Input() formOnly = false;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    hasAcknowledged!: boolean;

    subscription: Subscription = new Subscription();

    minStartDate = subYears(new Date(), 2);
    minStartDateString = format(this.minStartDate, 'yyyy-MM-dd');
    today = new Date();
    todayString = format(this.today, 'yyyy-MM-dd');
    maxEndDate = addYears(new Date(), 2);
    maxEndDateString = format(this.maxEndDate, 'yyyy-MM-dd');

    currentInsuranceText = currentInsuranceText;

    existingCoverageManualForm: ModelFormGroup<ExistingCoverageManualFormValue> = this.fb.group({
        carrier: new FormControl<CurrentInsurance | null>(null, [Validators.required]),
        carrierPolicyId: new FormControl<string | null>(null, [Validators.required]),
        comprehensiveLimit: new FormControl<number | null>(null, [Validators.required]),
        comprehensiveDeductible: new FormControl<number | null>(null, [
            Validators.required,
            Validators.min(500),
        ]),
        collisionLimit: new FormControl<number | null>(null, [Validators.required]),
        collisionDeductible: new FormControl<number | null>(null, [
            Validators.required,
            Validators.min(500),
        ]),
        bodilyInjuryLimit: new FormControl<number | null>(null, [Validators.required]),
        bodilyInjuryDeductible: new FormControl<number | null>(null, [
            Validators.required,
            Validators.min(500),
        ]),
        startDate: new FormControl<string | null>(null, [
            Validators.required,
            validateIsWithinInterval(this.minStartDate, this.today),
        ]),
        endDate: new FormControl<string | null>(null, [
            Validators.required,
            validateIsWithinInterval(this.today, this.maxEndDate),
        ]),
        isAcceptable: new FormControl<boolean>(true),
        decPageContents: new FormControl<null>(null),
        filePath: new FormControl<''>(''),
        reportId: new FormControl<'MANUAL'>('MANUAL'),
        reportPolicyId: new FormControl<'MANUAL'>('MANUAL'),
        reportProvider: new FormControl<CoverageReportProvider.manual>(
            CoverageReportProvider.manual
        ),
    });

    onTouched: () => unknown = () => {};
    onChange = (
        existingCoverageManual: ModelFormValue<ExistingCoverageManualFormValue> | null
    ) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Personal Info');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_personal_info',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.existingCoverageManualForm.patchValue({
                    carrier: CurrentInsurance.aaa,
                    carrierPolicyId: 'TEST_POLICY_ID',
                    comprehensiveLimit: 50000,
                    comprehensiveDeductible: 500,
                    collisionLimit: 50000,
                    collisionDeductible: 500,
                    bodilyInjuryLimit: 50000,
                    bodilyInjuryDeductible: 500,
                    startDate: '2024-01-01',
                    endDate: '2025-01-01',
                });
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (
            existingCoverageManual: ModelFormValue<ExistingCoverageManualFormValue> | null
        ) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.existingCoverageManualForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.existingCoverageManualForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const existingCoverageManual = this.existingCoverageManualFormValue();
                        onChange(existingCoverageManual);
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.existingCoverageManualForm.disable();
        } else {
            this.existingCoverageManualForm.enable();
        }
    }

    writeValue(value: ExistingCoverageManualFormValue | null) {
        const existingCoverageManualFormDefaultValues: ModelFormValue<ExistingCoverageManualFormValue> =
            {
                carrier: null,
                carrierPolicyId: null,
                comprehensiveLimit: null,
                comprehensiveDeductible: null,
                collisionLimit: null,
                collisionDeductible: null,
                bodilyInjuryLimit: null,
                bodilyInjuryDeductible: null,
                startDate: null,
                endDate: null,
                isAcceptable: true,
                decPageContents: null,
                filePath: '',
                reportId: 'MANUAL',
                reportPolicyId: 'MANUAL',
                reportProvider: CoverageReportProvider.manual,
            };
        if (value === null) {
            this.existingCoverageManualForm.reset(existingCoverageManualFormDefaultValues);
        }
        if (value) {
            this.existingCoverageManualForm.setValue(value);
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // if (this.existingCoverageManualForm?.invalid) {
        //     return { existingCoverageManualFormInvalid: true };
        // }

        return null;
    }

    seeMyQuote() {
        this.next.emit();
    }

    private existingCoverageManualFormValue(): ModelFormValue<ExistingCoverageManualFormValue> {
        const {
            carrier,
            carrierPolicyId,
            comprehensiveLimit,
            comprehensiveDeductible,
            collisionLimit,
            collisionDeductible,
            bodilyInjuryLimit,
            bodilyInjuryDeductible,
            startDate,
            endDate,
            isAcceptable,
            decPageContents,
            filePath,
            reportId,
            reportPolicyId,
            reportProvider,
        } = this.existingCoverageManualForm.value;

        this.assertionService.isDefinedOrThrow(carrier);
        this.assertionService.isDefinedOrThrow(carrierPolicyId);
        this.assertionService.isDefinedOrThrow(comprehensiveLimit);
        this.assertionService.isDefinedOrThrow(comprehensiveDeductible);
        this.assertionService.isDefinedOrThrow(collisionLimit);
        this.assertionService.isDefinedOrThrow(collisionDeductible);
        this.assertionService.isDefinedOrThrow(bodilyInjuryLimit);
        this.assertionService.isDefinedOrThrow(bodilyInjuryDeductible);
        this.assertionService.isDefinedOrThrow(startDate);
        this.assertionService.isDefinedOrThrow(endDate);
        this.assertionService.isNotUndefinedOrThrow(isAcceptable);
        this.assertionService.isNotUndefinedOrThrow(decPageContents);
        this.assertionService.isNotUndefinedOrThrow(filePath);
        this.assertionService.isNotUndefinedOrThrow(reportId);
        this.assertionService.isNotUndefinedOrThrow(reportPolicyId);
        this.assertionService.isNotUndefinedOrThrow(reportProvider);

        return {
            carrier,
            carrierPolicyId,
            comprehensiveLimit: parseInt(comprehensiveLimit as unknown as string, 10),
            comprehensiveDeductible: parseInt(comprehensiveDeductible as unknown as string, 10),
            collisionLimit: parseInt(collisionLimit as unknown as string, 10),
            collisionDeductible: parseInt(collisionDeductible as unknown as string, 10),
            bodilyInjuryLimit: parseInt(bodilyInjuryLimit as unknown as string, 10),
            bodilyInjuryDeductible: parseInt(bodilyInjuryDeductible as unknown as string, 10),
            startDate,
            endDate,
            isAcceptable,
            decPageContents,
            filePath,
            reportId,
            reportPolicyId,
            reportProvider,
        };
    }

    /* Accessor Methods */

    get carrierControl() {
        return this.existingCoverageManualForm.get('carrier') as FormControl;
    }

    get carrierControlValid() {
        return this.carrierControl.value && !this.carrierControl.hasError('required');
    }

    get carrierControlInvalid() {
        return this.carrierControl.touched && this.carrierControl.hasError('required');
    }

    get carrierPolicyIdControl() {
        return this.existingCoverageManualForm.get('carrierPolicyId') as FormControl;
    }

    get carrierPolicyIdControlValid() {
        return (
            this.carrierPolicyIdControl.value && !this.carrierPolicyIdControl.hasError('required')
        );
    }

    get carrierPolicyIdControlInvalid() {
        return (
            this.carrierPolicyIdControl.touched && this.carrierPolicyIdControl.hasError('required')
        );
    }

    get comprehensiveLimitControl() {
        return this.existingCoverageManualForm.get('comprehensiveLimit') as FormControl;
    }

    get comprehensiveLimitControlValid() {
        return (
            this.comprehensiveLimitControl.value &&
            !this.comprehensiveLimitControl.hasError('required')
        );
    }

    get comprehensiveLimitControlInvalid() {
        return (
            this.comprehensiveLimitControl.touched &&
            this.comprehensiveLimitControl.hasError('required')
        );
    }

    get comprehensiveDeductibleControl() {
        return this.existingCoverageManualForm.get('comprehensiveDeductible') as FormControl;
    }

    get comprehensiveDeductibleControlValid() {
        return (
            this.comprehensiveDeductibleControl.value &&
            !(
                this.comprehensiveDeductibleControl.hasError('required') ||
                this.comprehensiveDeductibleControl.hasError('min')
            )
        );
    }

    get comprehensiveDeductibleControlInvalid() {
        return (
            this.comprehensiveDeductibleControl.touched &&
            (this.comprehensiveDeductibleControl.hasError('required') ||
                this.comprehensiveDeductibleControl.hasError('min'))
        );
    }

    get collisionLimitControl() {
        return this.existingCoverageManualForm.get('collisionLimit') as FormControl;
    }

    get collisionLimitControlValid() {
        return this.collisionLimitControl.value && !this.collisionLimitControl.hasError('required');
    }

    get collisionLimitControlInvalid() {
        return (
            this.collisionLimitControl.touched && this.collisionLimitControl.hasError('required')
        );
    }

    get collisionDeductibleControl() {
        return this.existingCoverageManualForm.get('collisionDeductible') as FormControl;
    }

    get collisionDeductibleControlValid() {
        return (
            this.collisionDeductibleControl.value &&
            !(
                this.collisionDeductibleControl.hasError('required') ||
                this.collisionDeductibleControl.hasError('min')
            )
        );
    }

    get collisionDeductibleControlInvalid() {
        return (
            this.collisionDeductibleControl.touched &&
            (this.collisionDeductibleControl.hasError('required') ||
                this.collisionDeductibleControl.hasError('min'))
        );
    }

    get bodilyInjuryLimitControl() {
        return this.existingCoverageManualForm.get('bodilyInjuryLimit') as FormControl;
    }

    get bodilyInjuryLimitControlValid() {
        return (
            this.bodilyInjuryLimitControl.value &&
            !this.bodilyInjuryLimitControl.hasError('required')
        );
    }

    get bodilyInjuryLimitControlInvalid() {
        return (
            this.bodilyInjuryLimitControl.touched &&
            this.bodilyInjuryLimitControl.hasError('required')
        );
    }

    get bodilyInjuryDeductibleControl() {
        return this.existingCoverageManualForm.get('bodilyInjuryDeductible') as FormControl;
    }

    get bodilyInjuryDeductibleControlValid() {
        return (
            this.bodilyInjuryDeductibleControl.value &&
            !(
                this.bodilyInjuryDeductibleControl.hasError('required') ||
                this.bodilyInjuryDeductibleControl.hasError('min')
            )
        );
    }

    get bodilyInjuryDeductibleControlInvalid() {
        return (
            this.bodilyInjuryDeductibleControl.touched &&
            (this.bodilyInjuryDeductibleControl.hasError('required') ||
                this.bodilyInjuryDeductibleControl.hasError('min'))
        );
    }

    get startDateControl() {
        return this.existingCoverageManualForm.get('startDate') as FormControl;
    }

    get startDateControlValid() {
        return (
            this.startDateControl.value &&
            !(
                this.startDateControl.hasError('required') ||
                this.startDateControl.hasError('notWithinInterval')
            )
        );
    }

    get startDateControlInvalid() {
        return (
            this.startDateControl.touched &&
            (this.startDateControl.hasError('required') ||
                this.startDateControl.hasError('notWithinInterval'))
        );
    }

    get endDateControl() {
        return this.existingCoverageManualForm.get('endDate') as FormControl;
    }

    get endDateControlValid() {
        return (
            this.endDateControl.value &&
            !(
                this.endDateControl.hasError('required') ||
                this.endDateControl.hasError('notWithinInterval')
            )
        );
    }

    get endDateControlInvalid() {
        return (
            this.endDateControl.touched &&
            (this.endDateControl.hasError('required') ||
                this.endDateControl.hasError('notWithinInterval'))
        );
    }

    // eslint-disable-next-line complexity
    get allValid() {
        return (
            this.carrierControlValid &&
            this.carrierPolicyIdControlValid &&
            this.comprehensiveLimitControlValid &&
            this.comprehensiveDeductibleControlValid &&
            this.collisionLimitControlValid &&
            this.collisionDeductibleControlValid &&
            this.bodilyInjuryLimitControlValid &&
            this.bodilyInjuryDeductibleControlValid &&
            this.startDateControlValid &&
            this.endDateControlValid
        );
    }
}
