import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CreateRefundPayload,
    PayFirstInstallmentPayload,
    PayFirstInstallmentResponse,
    RefundResponse,
} from '@backend-types/payment';
import { Observable } from 'rxjs';

import { AuthUtilsService, EnvService } from '.';

@Injectable()
export class PaymentService {
    constructor(
        private http: HttpClient,
        private envService: EnvService,
        private authUtilsService: AuthUtilsService
    ) {}

    payFirstInstallment$(
        payload: PayFirstInstallmentPayload
    ): Observable<PayFirstInstallmentResponse> {
        return this.http.post<PayFirstInstallmentResponse>(
            `${this.envService.config.backendURL}/api/latest/payment/pay-first-installment`,
            payload
        );
    }

    createRefund$(payload: CreateRefundPayload): Observable<RefundResponse> {
        return this.http.post<RefundResponse>(
            `${this.envService.config.backendURL}/api/latest/payment/create-refund`,
            payload
        );
    }

    // createSubscription$(payload: SubscribePayload): Observable<SubscribeResponse> {
    //     return this.http
    //         .post<SubscribeResponse>(
    //             `${this.envService.config.backendURL}/api/latest/payment/subscribe`,
    //             payload
    //         )
    //         .pipe(
    //             switchMap((subscribeResponse): Observable<SubscribeResponse> => {
    //                 if (subscribeResponse.token) {
    //                     return this.authUtilsService
    //                         .processToken$(subscribeResponse.token)
    //                         .pipe(switchMap((authUser) => of(subscribeResponse)));
    //                 }
    //                 return of(subscribeResponse);
    //             })
    //         );
    // }

    // payInFull$(payload: PayInFullPayload): Observable<PayInFullResponse> {
    //     return this.http.post<undefined>(
    //         `${this.envService.config.backendURL}/api/latest/payment/cancel-subscription`,
    //         payload
    //     );
    // }
}
