<form [formGroup]="createPasswordForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-12">
            <div class="mb-3"><label class="small text-muted" for="emailInput">Email</label><input class="form-control" id="emailInput" readonly data-cy="emailInput" autocomplete="username" type="email" formControlName="email" /></div>
        </div>
        <div class="col-12">
            <div class="mb-3">
                <label class="small text-muted" for="passwordInput">Password</label><input class="form-control" id="passwordInput" data-cy="passwordInput" autocomplete="new-password" type="password" formControlName="password" [class.is-valid]="passwordControlValid" [class.is-invalid]="passwordControlInvalid" />
                <div class="invalid-feedback" *ngIf='passwordControl.hasError("required")'>Password required.</div>
                <div class="invalid-feedback" *ngIf='passwordControl.hasError("minlength")'>Password must be at least 8 characters.</div>
            </div>
        </div>
        <div class="col-12">
            <div class="mb-3">
                <label class="text-gray-600 small" for="confirmPasswordInput">Confirm Password</label><input class="form-control" id="confirmPasswordInput" data-cy="confirmPasswordInput" autocomplete="new-password" type="password" formControlName="confirmPassword" [class.is-valid]="confirmPasswordControlValid" [class.is-invalid]="confirmPasswordControlInvalid" />
                <div class="invalid-feedback" *ngIf='confirmPasswordControl.hasError("required")'>Confirm Password required.</div>
                <div class="invalid-feedback" *ngIf='createPasswordForm.hasError("passwordMismatch")'>Passwords do not match.</div>
            </div>
        </div>
    </div>
    <div class="mb-3 d-flex align-items-center justify-content-between">
        <span></span
        ><button class="btn rounded-pill btn-primary-gradient text-white fw-500" data-cy="createPasswordButton" type="submit" [disabled]="createPasswordForm.invalid">
            <div class="d-flex align-items-center">
                <div class="me-3">Create</div>
                <sbf-tredder-icons name="arrow-right" height="14px"></sbf-tredder-icons>
            </div>
        </button>
    </div>
</form>
<div class="text-xs pt-3">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy </a>and <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service </a>apply.</div>
