import { Injectable } from '@angular/core';

@Injectable()
export class TypeService {
    enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T {
        if ((Object.values(enm) as unknown as string[]).includes(value)) {
            return value as unknown as T;
        }
        throw new Error(`STRING_IS_NOT_IN_ENUM: ${value}`);
    }
}
