import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sbf-information-disclosure',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './information-disclosure.component.html',
    styleUrls: ['information-disclosure.component.scss'],
})
export class InformationDisclosureComponent implements OnInit {
    @Input() buttonText!: string;
    constructor() {}
    ngOnInit() {}
}
