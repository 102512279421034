import { Injectable } from '@angular/core';
import {
    DownloadDocumentPayload,
    GeneratedDocument,
    UploadedDocument,
} from '@backend-types/document';
import { PdfFromFilePathPayload } from '@backend-types/pdf';
import { PdfModalComponent } from '@common/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver-es';
import { Observable, ReplaySubject, take } from 'rxjs';

import { DownloadService } from './download.service';
import { EnvService } from './env.service';

export type DocumentsBlobMap = Record<string, ReplaySubject<Blob> | null>;

@Injectable()
export class DocumentsService {
    private _documentsBlobMap: DocumentsBlobMap = {};

    constructor(
        private downloadService: DownloadService,
        private envService: EnvService,
        private ngbModalService: NgbModal
    ) {}

    getBlobForDocument$(generatedDocument: GeneratedDocument): Observable<Blob> {
        if (!this._documentsBlobMap[generatedDocument.filePath]) {
            this._documentsBlobMap[generatedDocument.filePath] = new ReplaySubject<Blob>(1);
            this.downloadService
                .downloadBlob$<PdfFromFilePathPayload>({
                    url: `${this.envService.config.backendURL}/api/latest/pdf/pdf-from-file-path`,
                    downloadFilename: generatedDocument.displayName,
                    save: false,
                    returnBlob: true,
                    postBody: { generatedDocument },
                })
                .subscribe((downloadedBlob) => {
                    if (downloadedBlob && downloadedBlob instanceof Blob) {
                        this._documentsBlobMap[generatedDocument.filePath]!.next(downloadedBlob);
                    }
                });
        }

        const blobForKey = this._documentsBlobMap[generatedDocument.filePath];
        if (!blobForKey) {
            throw new Error(`BLOB_NOT_FOUND_FOR_KEY: ${generatedDocument.filePath}`);
        }

        return blobForKey.asObservable();
    }

    resetDocumentBlobs() {
        this._documentsBlobMap = {};
    }

    read(generatedDocument: GeneratedDocument) {
        this.getBlobForDocument$(generatedDocument)
            .pipe(take(1))
            .subscribe((blob) => {
                this._showPDFModal(blob, generatedDocument.displayName);
            });
    }

    download(generatedDocument: GeneratedDocument) {
        this.getBlobForDocument$(generatedDocument)
            .pipe(take(1))
            .subscribe((blob) => {
                saveAs(blob, generatedDocument.displayName);
            });
    }

    downloadUploadedDoc$(uploadedDocument: UploadedDocument): Observable<Blob | boolean> {
        return this.downloadService.downloadBlob$<DownloadDocumentPayload>({
            url: `${this.envService.config.backendURL}/api/latest/document/download-document`,
            downloadFilename: uploadedDocument.fileName,
            save: true,
            returnBlob: false,
            postBody: { uploadedDocument },
        });
    }

    private _showPDFModal(pdfBlob: Blob, downloadFileName: string) {
        const {
            componentInstance,
        }: {
            componentInstance: PdfModalComponent;
            result: Promise<unknown>;
        } = this.ngbModalService.open(PdfModalComponent, {
            fullscreen: true,
        });

        componentInstance.pdfBlob = pdfBlob;
        componentInstance.downloadFileName = downloadFileName;
    }
}
