import type { BuildInfo } from '@common/models/build-info.model';

export const buildInfo: BuildInfo = {
    commitSHA: '5ce5701c6e29cb1d8f645f4a7feff3f2e27aa05b',
    branch: 'main',
    deployEnv: 'prod',
    version: '1.1.6',
    dateFormatted: '2024-12-13--19-21',
    dateISOString: '2024-12-13T19:21:58.331Z',
};
