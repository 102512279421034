import type { PaymentKind } from './payment-plan';
import type { State } from './states';

export enum InvoiceLineItemCategory {
    stateTax = 'stateTax',
    stateFee = 'stateFee',
    tredderFeeOneTime = 'tredderFeeOneTime',
    tredderFeePerInstallment = 'tredderFeePerInstallment',
    premium = 'premium',
}

export type InvoiceLineItemKind =
    | ENSStateTaxName
    | ENSStateFeeName
    | ENSTredderFeeName
    | PaymentKind;

// export type InvoiceLineItemMeta = {
//     stateTax: null;
//     stateFee: null;
//     tredderFeeOneTime: null;
//     tredderFeePerInstallment: null;
//     premium: {
//         // startDate: string;
//         // endDate: string;
//     };
// };
//
// export interface VerifyInvoiceLineItemMetas<T extends InvoiceLineItemCategory> {
//     invoiceLineItemMeta: InvoiceLineItemMeta[T];
// }

export type StateTaxesAndFeesStates = Exclude<
    State,
    State.americanSamoa | State.guam | State.northernMarianaIslands | State.trustTerritories
>;

export type StateTaxesAndFees = {
    [index in StateTaxesAndFeesStates]: {
        taxes: ENSStateTax[];
        fees: ENSStateFee[];
    };
};

// ENSStateTax
export interface ENSStateTax<T extends ENSStateTaxName = ENSStateTaxName> {
    name: T;
    invoiceLineItemCategory: InvoiceLineItemCategory;
    invoiceLineItemKind: InvoiceLineItemKind;
    rate: string | null;
    amount: string | null;
    installmentAmount?: string | null;
    dailyAmount: string | null;
    state: StateTaxesAndFeesStates;
}

export enum ENSStateTaxName {
    fireMarshallTax = 'fireMarshallTax',
    premiumTax = 'premiumTax',
}

// ENSStateFee
export interface ENSStateFee<T extends ENSStateFeeName = ENSStateFeeName> {
    name: T;
    invoiceLineItemCategory: InvoiceLineItemCategory;
    invoiceLineItemKind: InvoiceLineItemKind;
    rate: string | null;
    amount: string | null;
    installmentAmount?: string | null;
    dailyAmount: string | null;
    state: StateTaxesAndFeesStates;
}

export enum ENSStateFeeName {
    brokerFee = 'brokerFee',
    filingFee = 'filingFee',
    regulatoryFee = 'regulatoryFee',
    serviceCharge = 'serviceCharge',
    serviceFee = 'serviceFee',
    slasClearinghouseTransactionFee = 'slasClearinghouseTransactionFee',
    stampingFee = 'stampingFee',
    surcharge = 'surcharge',
    windPoolFee = 'windPoolFee',
}

// ENSTredderFee
export interface ENSTredderFee<T extends ENSTredderFeeName = ENSTredderFeeName> {
    name: T;
    invoiceLineItemCategory: InvoiceLineItemCategory;
    invoiceLineItemKind: InvoiceLineItemKind;
    rate: string | null;
    amount: string | null;
    installmentAmount?: string | null;
    dailyAmount: string | null;
    state?: null;
}

export interface ENSTredderFeeStatic<T extends ENSTredderFeeName = ENSTredderFeeName> {
    name: T;
    invoiceLineItemCategory: InvoiceLineItemCategory;
    invoiceLineItemKind: InvoiceLineItemKind;
    rate: null;
    amount: string;
    dailyAmount: null;
    state?: null;
}

export enum ENSTredderFeeName {
    endorsementFee = 'endorsementFee',
    installmentFee = 'installmentFee',
    latePaymentFee = 'latePaymentFee',
    brokerAdminFee = 'brokerAdminFee',
    nsfFee = 'nsfFee',
    reinstatementFee = 'reinstatementFee',
    cancelationFee = 'cancelationFee',
    paymentProcessingFee = 'paymentProcessingFee',
}

export type TredderFees = {
    [index in ENSTredderFeeName]: ENSTredderFee<index>;
};

export const nonRefundableLineItems: InvoiceLineItemKind[] = [
    ENSTredderFeeName.endorsementFee,
    ENSTredderFeeName.installmentFee,
    ENSTredderFeeName.latePaymentFee,
    ENSTredderFeeName.brokerAdminFee,
    ENSTredderFeeName.nsfFee,
    ENSTredderFeeName.reinstatementFee,
    ENSTredderFeeName.cancelationFee,
    ENSTredderFeeName.paymentProcessingFee,
];

// export interface InstallmentFees {
//     lessThanSixtyMonths: TypeOfPaymentFees;
//     sixtyMonthsOrMore: TypeOfPaymentFees;
// }

export interface TypeOfPaymentFees {
    directInvoice: string;
    eft: string;
    automaticCard: string;
}

export type AnyFeeOrTax = ENSStateTax | ENSStateFee | ENSTredderFee;

export type StaticFeesAndTaxes = ENSStateTax | ENSStateFee | ENSTredderFee;
export type StaticFeesAndTaxesState = ENSStateTax | ENSStateFee;

export type DynamicFeesAndTaxes = ENSStateTax | ENSStateFee | ENSTredderFee;
export type DynamicFeesAndTaxesState = ENSStateTax | ENSStateFee;
