import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { TabNavName } from '@common/models';

@Component({
    selector: 'sbf-section-tab-nav',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './section-tab-nav.component.html',
    styleUrls: ['section-tab-nav.component.scss'],
})
export class SectionTabNavComponent implements OnInit {
    @Input() tabNames!: TabNavName[];
    @Input() activeTab!: string;
    @Output() activeTabChange = new EventEmitter<string>();

    constructor(private changeDetectorRef: ChangeDetectorRef) {}
    ngOnInit() {}

    setActive(tabName: TabNavName) {
        this.activeTab = tabName.slug;
        this.activeTabChange.emit(tabName.slug);
        this.changeDetectorRef.detectChanges();
    }
}
