import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { QuoteRigSetFormValue } from '@backend-types/quote-flow-ens';
import { ModelFormGroup } from '@common/models';
import { AnalyticsService, HotKeysService } from '@common/services';
import { QuoteRigSetFormStep } from '@modules/quote/models';
import { QuoteRigSetFormService } from '@modules/quote/services';
import Big from 'big.js';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-quote-rig-set-form-rig-review',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-rig-set-form-rig-review.component.html',
    styleUrls: ['quote-rig-set-form-rig-review.component.scss'],
})
export class QuoteRigSetFormRigReviewComponent implements OnInit, OnDestroy {
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();
    @Output() jump = new EventEmitter<QuoteRigSetFormStep>();

    eQuoteRigSetFormStep = QuoteRigSetFormStep;
    activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;
    valueCovered!: string;

    subscription: Subscription = new Subscription();

    constructor(
        private hotKeysService: HotKeysService,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Rig Review');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_rig_review',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.quoteRigSetFormService
                .getActiveQuoteRigSetForm$()
                .subscribe((activeQuoteRigSetForm) => {
                    this.activeQuoteRigSetForm = activeQuoteRigSetForm;
                    this.valueCovered = Big(
                        activeQuoteRigSetForm?.value?.modsDetails?.modsValue || 0
                    )
                        .plus(activeQuoteRigSetForm?.value?.camperDetails?.unitValue || 0)
                        .plus(activeQuoteRigSetForm?.value?.trailerDetails?.unitValue || 0)
                        .toString();
                    this.changeDetectorRef.detectChanges();
                })
        );
    }

    addMods() {
        this.jump.emit(QuoteRigSetFormStep.modsDetails);
    }

    addCamper() {
        this.jump.emit(QuoteRigSetFormStep.camperDetails);
    }

    addTrailer() {
        this.jump.emit(QuoteRigSetFormStep.trailerDetails);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
