import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UtilityService } from '@common/services';

@Component({
    selector: 'sbf-update-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './update-modal.component.html',
    styleUrls: ['update-modal.component.scss'],
})
export class UpdateModalComponent implements OnInit {
    constructor(private utilityService: UtilityService) {}
    ngOnInit() {}

    clearCacheAndRefresh() {
        this.utilityService.clearAllTredderFromLocalStorage();
        this.utilityService.window.location.reload();
    }
}
