import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
    CoverageSelectKey,
    QrsModsDetailsFormValue,
    QrsRigDetailsFormValue,
    QrsUnitDetailsFormValue,
    QuoteRigSetFormValue,
    QuoteRigSetRigCoverageSelectFormValue,
} from '@backend-types/quote-flow-ens';
import { VehicleType } from '@backend-types/rig';
import {
    AcpeDeductible,
    RocUnitClass,
    UnitCollDeductible,
    UnitCompDeductible,
} from '@backend-types/roc-lib';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { AssertionService, LogService } from '@common/services';
import { Observable, ReplaySubject, Subscription } from 'rxjs';

export const defaultCoverageSelectState: QuoteRigSetRigCoverageSelectFormValue = {
    modsCoverage: false,
    camperCoverage: false,
    trailerCoverage: false,
};
export const defaultCamperDetailsState: ModelFormValue<QrsUnitDetailsFormValue> = {
    unitMake: null,
    unitModel: null,
    unitAge: null,
    unitLength: null,
    unitOwnedTenure: null,
    unitValue: null,
    unitClass: RocUnitClass['TRUCK MOUNTED CAMPER'],
    unitCompDeductible: UnitCompDeductible.fiveHundred,
    unitCollDeductible: UnitCollDeductible.fiveHundred,
};

export const defaultTrailerDetailsState: ModelFormValue<QrsUnitDetailsFormValue> = {
    unitMake: null,
    unitModel: null,
    unitAge: null,
    unitLength: null,
    unitOwnedTenure: null,
    unitValue: null,
    unitClass: RocUnitClass['TENT/CAMPING TRAILER'],
    unitCompDeductible: UnitCompDeductible.fiveHundred,
    unitCollDeductible: UnitCollDeductible.fiveHundred,
};

export const defaultQrsModsDetailsFormValue: ModelFormValue<QrsModsDetailsFormValue> = {
    modsValue: null,
    modsDeductible: AcpeDeductible.oneThousand,
};

@Injectable()
export class QuoteRigSetFormService {
    private _activeQuoteRigSetForms: ModelFormGroup<QuoteRigSetFormValue>[] = [];

    private _activeQuoteRigSetFormIndex!: number;
    private _activeQuoteRigSetFormValueChangeSubscription!: Subscription;

    private _activeQuoteRigSetForm$ = new ReplaySubject<ModelFormGroup<QuoteRigSetFormValue>>(1);
    private _activeQuoteRigSetFormValue$ = new ReplaySubject<ModelFormValue<QuoteRigSetFormValue>>(
        1
    );

    private _activeQuoteRigSetForms$ = new ReplaySubject<ModelFormGroup<QuoteRigSetFormValue>[]>(1);

    constructor(
        private fb: FormBuilder,
        private logService: LogService,
        private assertionService: AssertionService
    ) {}

    setActiveQuoteRigSetFormIndex(
        index: number,
        force = false,
        quoteRigSetFormValue?: Partial<QuoteRigSetFormValue>
    ) {
        if (this._activeQuoteRigSetFormIndex === index && !force) {
            return;
        }
        if (!this._activeQuoteRigSetForms[index]) {
            this._activeQuoteRigSetForms[index] = this._createQuoteRigSetForm(quoteRigSetFormValue);
        }
        this._activeQuoteRigSetFormIndex = index;
        if (this._activeQuoteRigSetFormValueChangeSubscription) {
            this._activeQuoteRigSetFormValueChangeSubscription.unsubscribe();
        }

        this._activeQuoteRigSetFormValueChangeSubscription = this._activeQuoteRigSetForms[
            index
        ].valueChanges.subscribe((quoteRigSetFormValue) => {
            this._activeQuoteRigSetFormValue$.next(
                this._activeQuoteRigSetFormValue(quoteRigSetFormValue)
            );
        });
        this._activeQuoteRigSetForm$.next(this._activeQuoteRigSetForms[index]);
        this._activeQuoteRigSetFormValue$.next(
            this._activeQuoteRigSetFormValue(this._activeQuoteRigSetForms[index].value)
        );
        this._activeQuoteRigSetForms$.next(this._activeQuoteRigSetForms);
    }

    get activeQuoteRigSetFormIndex(): number {
        return this._activeQuoteRigSetFormIndex;
    }

    updateRigCoverageSelect(coverageSelectKey: CoverageSelectKey, value: boolean) {
        const valueToPatch =
            this._activeQuoteRigSetForms[this._activeQuoteRigSetFormIndex].controls.coverageSelect
                .value;

        if (!valueToPatch) {
            this.logService.warn(`UNABLE_TO_PATCH_COVERAGE_SELECT_FOR_KEY: ${coverageSelectKey}`);
            return;
        }
        valueToPatch[coverageSelectKey] = true;

        this._activeQuoteRigSetForms[
            this._activeQuoteRigSetFormIndex
        ].controls.coverageSelect.patchValue(valueToPatch);

        this._activeQuoteRigSetForm$.next(
            this._activeQuoteRigSetForms[this._activeQuoteRigSetFormIndex]
        );
        this._activeQuoteRigSetFormValue$.next(
            this._activeQuoteRigSetFormValue(
                this._activeQuoteRigSetForms[this._activeQuoteRigSetFormIndex].value
            )
        );
    }

    deleteRigAtIndex(rigSetIndex: number): void {
        this._activeQuoteRigSetForms.splice(rigSetIndex, 1);
        this._activeQuoteRigSetForms$.next(this._activeQuoteRigSetForms);
    }

    dirtyInvalid(): boolean {
        let hasDirtyInvalid = false;
        for (const key in this._activeQuoteRigSetForms[this._activeQuoteRigSetFormIndex].controls) {
            if (
                Object.prototype.hasOwnProperty.call(
                    this._activeQuoteRigSetForms[this._activeQuoteRigSetFormIndex].controls,
                    key
                )
            ) {
                const control =
                    this._activeQuoteRigSetForms[this._activeQuoteRigSetFormIndex].controls[
                        key as keyof QuoteRigSetFormValue
                    ];
                if (control?.invalid && control.dirty) {
                    hasDirtyInvalid = true;
                }
            }
        }
        return hasDirtyInvalid;
    }

    // eslint-disable-next-line complexity
    private _createQuoteRigSetForm(
        quoteRigSetFormValue?: Partial<QuoteRigSetFormValue>
    ): ModelFormGroup<QuoteRigSetFormValue> {
        return this.fb.nonNullable.group({
            camperDetails: new FormControl<QrsUnitDetailsFormValue | null>(
                quoteRigSetFormValue?.camperDetails ||
                    (defaultCamperDetailsState as QrsUnitDetailsFormValue),
                Validators.required
            ),
            trailerDetails: new FormControl<QrsUnitDetailsFormValue | null>(
                quoteRigSetFormValue?.trailerDetails ||
                    (defaultTrailerDetailsState as QrsUnitDetailsFormValue),
                Validators.required
            ),
            modsDetails: new FormControl<QrsModsDetailsFormValue | null>(
                quoteRigSetFormValue?.modsDetails ||
                    (defaultQrsModsDetailsFormValue as QrsModsDetailsFormValue),
                Validators.required
            ),
            rigName: new FormControl<string | null>(
                quoteRigSetFormValue?.rigName || null,
                Validators.required
            ),
            vehicleType: new FormControl<VehicleType | null>(
                quoteRigSetFormValue?.vehicleType || VehicleType.none,
                Validators.required
            ),
            rigDetails: new FormControl<QrsRigDetailsFormValue | null>(
                quoteRigSetFormValue?.rigDetails || null,
                Validators.required
            ),
            coverageSelect: new FormControl<QuoteRigSetRigCoverageSelectFormValue | null>(
                quoteRigSetFormValue?.coverageSelect || defaultCoverageSelectState,
                Validators.required
            ),
            rigId: new FormControl<string | null>(quoteRigSetFormValue?.rigId || null),
        });
    }

    getActiveQuoteRigSetForm$(): Observable<ModelFormGroup<QuoteRigSetFormValue>> {
        return this._activeQuoteRigSetForm$.asObservable();
    }

    getActiveQuoteRigSetForms$(): Observable<ModelFormGroup<QuoteRigSetFormValue>[]> {
        return this._activeQuoteRigSetForms$.asObservable();
    }

    getQuoteRigSetFormValuesArray(): ModelFormValue<QuoteRigSetFormValue>[] {
        return this._activeQuoteRigSetFormValuesArray();
    }

    setQuoteRigSetFormValuesArray(
        quoteRigSetFormValuesArray: ModelFormValue<QuoteRigSetFormValue>[]
    ) {
        quoteRigSetFormValuesArray.forEach((quoteRigSetFormValue, index) => {
            try {
                this._activeQuoteRigSetForms[index] = this._createQuoteRigSetForm();
                this._activeQuoteRigSetForms[index].setValue(quoteRigSetFormValue);
                this._activeQuoteRigSetForms[index].updateValueAndValidity();
            } catch (error) {
                throw error;
            }
        });
        this.setActiveQuoteRigSetFormIndex(this._activeQuoteRigSetFormIndex || 0, true);
    }

    setModsValue(modsValue: number) {
        this._activeQuoteRigSetForms[
            this._activeQuoteRigSetFormIndex
        ].controls.modsDetails.patchValue({
            modsValue,
            modsDeductible: AcpeDeductible.oneThousand,
        });
    }

    setCamperValue(unitValue: number, unitLength: number | null = null) {
        this._activeQuoteRigSetForms[
            this._activeQuoteRigSetFormIndex
        ].controls.camperDetails.patchValue({
            unitMake: null,
            unitModel: null,
            unitAge: null,
            unitLength,
            unitOwnedTenure: null,
            unitValue,
            unitClass: RocUnitClass['TRUCK MOUNTED CAMPER'],
            unitCompDeductible: UnitCompDeductible.fiveHundred,
            unitCollDeductible: UnitCollDeductible.fiveHundred,
        } as unknown as QrsUnitDetailsFormValue);
    }

    setTrailerValue(unitValue: number, unitLength: number | null = null) {
        this._activeQuoteRigSetForms[
            this._activeQuoteRigSetFormIndex
        ].controls.trailerDetails.patchValue({
            unitMake: null,
            unitModel: null,
            unitAge: null,
            unitLength,
            unitOwnedTenure: null,
            unitValue,
            unitClass: RocUnitClass['TENT/CAMPING TRAILER'],
            unitCompDeductible: UnitCompDeductible.fiveHundred,
            unitCollDeductible: UnitCollDeductible.fiveHundred,
        } as unknown as QrsUnitDetailsFormValue);
    }

    setCoverageSelect(coverageSelectValue: QuoteRigSetRigCoverageSelectFormValue) {
        this._activeQuoteRigSetForms[
            this._activeQuoteRigSetFormIndex
        ].controls.coverageSelect.patchValue(coverageSelectValue);
    }

    setRigName(rigName: string) {
        this._activeQuoteRigSetForms[this._activeQuoteRigSetFormIndex].controls.rigName.patchValue(
            rigName
        );
    }

    setRigDetails(rigDetails: QrsRigDetailsFormValue) {
        this._activeQuoteRigSetForms[
            this._activeQuoteRigSetFormIndex
        ].controls.rigDetails.patchValue(rigDetails);
    }

    updateValueAndValidity() {
        this._activeQuoteRigSetForms[this._activeQuoteRigSetFormIndex].updateValueAndValidity();
    }

    reset() {
        this._activeQuoteRigSetForms = [];
        this.setActiveQuoteRigSetFormIndex(0, true);
    }

    private _activeQuoteRigSetFormValuesArray(): ModelFormValue<QuoteRigSetFormValue>[] {
        return this._activeQuoteRigSetForms.map((quoteRigSetForm) => {
            const {
                camperDetails,
                trailerDetails,
                modsDetails,
                rigName,
                vehicleType,
                rigDetails,
                coverageSelect,
                rigId,
            } = quoteRigSetForm.value;
            return {
                camperDetails: this.assertionService.orNULL(camperDetails),
                trailerDetails: this.assertionService.orNULL(trailerDetails),
                modsDetails: this.assertionService.orNULL(modsDetails),
                rigName: this.assertionService.orNULL(rigName),
                vehicleType: this.assertionService.orNULL(vehicleType),
                rigDetails: this.assertionService.orNULL(rigDetails),
                coverageSelect: this.assertionService.orNULL(coverageSelect),
                rigId: this.assertionService.orNULL(rigId),
            };
        });
    }

    private _activeQuoteRigSetFormValue(
        partial: Partial<ModelFormValue<QuoteRigSetFormValue>>
    ): ModelFormValue<QuoteRigSetFormValue> {
        const {
            camperDetails,
            trailerDetails,
            modsDetails,
            rigName,
            vehicleType,
            rigDetails,
            coverageSelect,
            rigId,
        } = partial;
        return {
            camperDetails: this.assertionService.orNULL(camperDetails),
            trailerDetails: this.assertionService.orNULL(trailerDetails),
            modsDetails: this.assertionService.orNULL(modsDetails),
            rigName: this.assertionService.orNULL(rigName),
            vehicleType: this.assertionService.orNULL(vehicleType),
            rigDetails: this.assertionService.orNULL(rigDetails),
            coverageSelect: this.assertionService.orNULL(coverageSelect),
            rigId: this.assertionService.orNULL(rigId),
        };
    }
}
