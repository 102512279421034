import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { LogService } from './log.service';
import { PlatformService } from './platform.service';

@Injectable()
export class CookieService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private platformService: PlatformService,
        private logService: LogService
    ) {}

    getCookie(name: string): string {
        if (this.platformService.isServer) {
            this.logService.info(`Not able to getCookie in server environment`);
            return '';
        }
        let ca: Array<string> = this.document.cookie.split(';');
        // console.log(document.cookie);
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }
}
