import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ClaimTyped, FnolFormValue } from '@backend-types/claim';
import { PolicyEnsForBillingTyped } from '@backend-types/policy-ens';
import { ModelFormGroup } from '@common/models';
import { ActiveAuthUser, AssertionService, FnolService, UserService } from '@common/services';
import { validateIsWithinInterval } from '@common/validators';
import { format, subDays } from 'date-fns';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-fnol',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './fnol.component.html',
    styleUrls: ['fnol.component.scss'],
})
export class FnolComponent implements OnInit, OnDestroy, OnChanges {
    @Input() activePolicy?: PolicyEnsForBillingTyped | null;
    @Input() activeClaim?: ClaimTyped;

    subscription: Subscription = new Subscription();

    minDateOfLossDate = subDays(new Date(), 30);
    maxDateOfLossDate = new Date();
    minDateOfLoss = format(this.minDateOfLossDate, 'yyyy-MM-dd');
    maxDateOfLoss = format(this.maxDateOfLossDate, 'yyyy-MM-dd');

    fnolSubmitted = false;

    activeAuthUser!: ActiveAuthUser;

    fnolForm: ModelFormGroup<FnolFormValue> = this.fb.group({
        firstName: new FormControl<string | null>(null, [Validators.required]),
        lastName: new FormControl<string | null>(null, [Validators.required]),
        email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
        phone: new FormControl<string | null>(null, [Validators.required]),
        policyNumber: new FormControl<string | null>(null),
        dateOfLoss: new FormControl<string | null>(null, [
            Validators.required,
            validateIsWithinInterval(this.minDateOfLossDate, this.maxDateOfLossDate),
        ]),
        lossDetails: new FormControl<string | null>(null, [Validators.required]),
    });

    constructor(
        private fb: FormBuilder,
        private fnolService: FnolService,
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService,
        private assertionService: AssertionService
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.userService.activeAuthUser$.subscribe((activeAuthUser) => {
                this.activeAuthUser = activeAuthUser;
                this.fnolForm.patchValue({
                    firstName: activeAuthUser.firstName,
                    lastName: activeAuthUser.lastName,
                    email: activeAuthUser.email,
                });
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.activePolicy?.currentValue) {
            const activePolicy: PolicyEnsForBillingTyped = changes.activePolicy?.currentValue;
            this.fnolForm.patchValue({
                policyNumber: activePolicy.policyWrapper.policyNumber.toString(),
                phone: activePolicy.user.phone,
            });
        }
    }

    startClaim() {
        this.subscription.add(
            this.fnolService.fnol$(this._fnolFormValue()).subscribe((response) => {
                this.fnolSubmitted = true;
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    private _fnolFormValue(): FnolFormValue {
        const { firstName, lastName, email, phone, policyNumber, dateOfLoss, lossDetails } =
            this.fnolForm.getRawValue();

        this.assertionService.isDefinedOrThrow(firstName);
        this.assertionService.isDefinedOrThrow(lastName);
        this.assertionService.isDefinedOrThrow(email);
        this.assertionService.isDefinedOrThrow(phone);
        // this.assertionService.isDefinedOrThrow(policyNumber);
        this.assertionService.isDefinedOrThrow(dateOfLoss);
        this.assertionService.isDefinedOrThrow(lossDetails);

        return {
            firstName,
            lastName,
            email,
            phone,
            policyNumber,
            dateOfLoss: new Date(dateOfLoss).toISOString(),
            lossDetails,
        };
    }

    /* Accessor Methods */

    get firstNameControl() {
        return this.fnolForm.get('firstName') as FormControl;
    }

    get firstNameControlValid() {
        return this.firstNameControl.value && !this.firstNameControl.hasError('required');
    }

    get firstNameControlInvalid() {
        return this.firstNameControl.touched && this.firstNameControl.hasError('required');
    }

    get lastNameControl() {
        return this.fnolForm.get('lastName') as FormControl;
    }

    get lastNameControlValid() {
        return this.lastNameControl.value && !this.lastNameControl.hasError('required');
    }

    get lastNameControlInvalid() {
        return this.lastNameControl.touched && this.lastNameControl.hasError('required');
    }

    get emailControl() {
        return this.fnolForm.get('email') as FormControl;
    }

    get emailControlValid() {
        return (
            this.emailControl.value &&
            !(this.emailControl.hasError('required') || this.emailControl.hasError('email'))
        );
    }

    get emailControlInvalid() {
        return (
            this.emailControl.touched &&
            (this.emailControl.hasError('required') || this.emailControl.hasError('email'))
        );
    }

    get phoneControl() {
        return this.fnolForm.get('phone') as FormControl;
    }

    get phoneControlValid() {
        return (
            this.phoneControl.value &&
            !(this.phoneControl.hasError('required') || this.phoneControl.hasError('mask'))
        );
    }

    get phoneControlInvalid() {
        return (
            this.phoneControl.touched &&
            (this.phoneControl.hasError('required') || this.phoneControl.hasError('mask'))
        );
    }

    get policyNumberControl() {
        return this.fnolForm.get('policyNumber') as FormControl;
    }

    get policyNumberControlValid() {
        return this.policyNumberControl.value && !this.policyNumberControl.hasError('required');
    }

    get policyNumberControlInvalid() {
        return this.policyNumberControl.touched && this.policyNumberControl.hasError('required');
    }

    get dateOfLossControl() {
        return this.fnolForm.get('dateOfLoss') as FormControl;
    }

    get dateOfLossControlValid() {
        return (
            this.dateOfLossControl.value &&
            !(
                this.dateOfLossControl.hasError('required') ||
                this.dateOfLossControl.hasError('notWithinInterval')
            )
        );
    }

    get dateOfLossControlInvalid() {
        return (
            this.dateOfLossControl.touched &&
            (this.dateOfLossControl.hasError('required') ||
                this.dateOfLossControl.hasError('notWithinInterval'))
        );
    }

    get lossDetailsControl() {
        return this.fnolForm.get('lossDetails') as FormControl;
    }

    get lossDetailsControlValid() {
        return this.lossDetailsControl.value && !this.lossDetailsControl.hasError('required');
    }

    get lossDetailsControlInvalid() {
        return this.lossDetailsControl.touched && this.lossDetailsControl.hasError('required');
    }

    // eslint-disable-next-line complexity
    get allValid() {
        return (
            this.firstNameControlValid &&
            this.lastNameControlValid &&
            this.emailControlValid &&
            this.phoneControlValid &&
            // this.policyNumberControlValid &&
            this.dateOfLossControlValid &&
            this.lossDetailsControlValid
        );
    }
}
