import {
    activity,
    arrowDownUp,
    arrowLeft,
    arrowRight,
    bank,
    boxArrowInLeft,
    boxArrowRight,
    boxArrowUp,
    building,
    buildingAdd,
    calculator,
    calendar2Check,
    calendar3,
    camera,
    cardImage,
    cardText,
    caretDownFill,
    caretUpFill,
    checkCircleFill,
    checkLg,
    chevronContract,
    chevronDown,
    chevronExpand,
    chevronLeft,
    chevronRight,
    chevronUp,
    clipboard,
    clipboard2Check,
    clipboard2Heart,
    clipboard2Plus,
    compass,
    currencyDollar,
    download,
    envelope,
    exclamationCircleFill,
    exclamationTriangleFill,
    eye,
    facebook,
    fileEarmarkSpreadsheet,
    fileEarmarkText,
    fileImage,
    fileRuled,
    fileText,
    gear,
    github,
    globe,
    google,
    headset,
    infoCircle,
    journalCheck,
    keyFill,
    list,
    megaphone,
    newspaper,
    pen,
    pencilSquare,
    people,
    person,
    personBadge,
    personGear,
    personPlus,
    personRolodex,
    pinterest,
    plus,
    plusCircle,
    question,
    receipt,
    save,
    search,
    shieldCheck,
    shieldShaded,
    shop,
    speedometer,
    square,
    table,
    telephone,
    trash,
    truckFront,
    twitter,
    xCircleFill,
} from 'ngx-bootstrap-icons';

export const bootstrapIcons = {
    activity,
    arrowDownUp,
    arrowLeft,
    arrowRight,
    bank,
    boxArrowInLeft,
    boxArrowRight,
    boxArrowUp,
    building,
    buildingAdd,
    calculator,
    calendar2Check,
    calendar3,
    camera,
    cardImage,
    cardText,
    caretDownFill,
    caretUpFill,
    checkCircleFill,
    checkLg,
    chevronContract,
    chevronDown,
    chevronUp,
    chevronExpand,
    chevronLeft,
    chevronRight,
    clipboard,
    clipboard2Check,
    clipboard2Heart,
    clipboard2Plus,
    compass,
    currencyDollar,
    download,
    envelope,
    exclamationCircleFill,
    exclamationTriangleFill,
    eye,
    facebook,
    fileRuled,
    fileImage,
    fileEarmarkSpreadsheet,
    fileEarmarkText,
    fileText,
    gear,
    github,
    globe,
    google,
    headset,
    infoCircle,
    journalCheck,
    keyFill,
    list,
    megaphone,
    newspaper,
    pen,
    pencilSquare,
    people,
    person,
    personBadge,
    personGear,
    personPlus,
    personRolodex,
    pinterest,
    plus,
    plusCircle,
    question,
    receipt,
    save,
    search,
    shieldCheck,
    shieldShaded,
    shop,
    speedometer,
    square,
    table,
    telephone,
    trash,
    truckFront,
    twitter,
    xCircleFill,
};
