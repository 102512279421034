import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FnolFormValue } from '@backend-types/claim';
import { FnolResponse } from '@backend-types/site';
import { finalize, Observable } from 'rxjs';

import { EnvService } from './env.service';
import { OverlayService } from './overlay.service';

@Injectable()
export class FnolService {
    constructor(
        private http: HttpClient,

        private overlayService: OverlayService,

        private envService: EnvService
    ) {}

    fnol$(fnolForm: FnolFormValue): Observable<FnolResponse> {
        this.overlayService.show('Starting Claim');
        return this.http
            .post<FnolResponse>(
                `${this.envService.config.backendURL}/api/latest/site/fnol`,
                fnolForm
            )
            .pipe(
                finalize(() => {
                    this.overlayService.hide();
                })
            );
    }
}
