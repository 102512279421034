import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ConversationForResults } from '@backend-types/conversation';
import { ActiveAuthUser, ConversationService, HotKeysService, UserService } from '@common/services';
import { AgencyOrAdmin } from '@modules/manage-common/models';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sbf-conversation',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './conversation.component.html',
    styleUrls: ['conversation.component.scss'],
})
export class ConversationComponent implements OnInit, OnDestroy {
    @ViewChild('messageAreaScrollable') messageAreaScrollable!: ElementRef<HTMLDivElement>;
    @Input() conversationId!: UUID;
    @Input() policyWrapperId!: UUID;
    @Input() agencyOrAdmin!: AgencyOrAdmin;

    activeAuthUser!: ActiveAuthUser;

    inputText: string = '';
    conversation!: ConversationForResults;

    maxMessageLength = 1024;

    subscription: Subscription = new Subscription();

    constructor(
        private conversationService: ConversationService,
        private hotKeysService: HotKeysService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.subscription.add(
            this.userService.activeAuthUser$.subscribe((activeAuthUser) => {
                this.activeAuthUser = activeAuthUser;
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.conversationService
                .getConversation$(this.conversationId, this.agencyOrAdmin)
                .subscribe((conversation) => {
                    this._setCoversation(conversation);
                })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'enter' }).subscribe(() => {
                this.sendMessage();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    sendMessage() {
        if (this.inputText.length > this.maxMessageLength) {
            this.changeDetectorRef.detectChanges();
            return;
        } else {
            this.changeDetectorRef.detectChanges();
        }

        this.subscription.add(
            this.conversationService
                .sendMessage$(
                    {
                        conversationId: this.conversationId,
                        policyWrapperId: this.policyWrapperId,
                        body: this.inputText,
                    },
                    this.agencyOrAdmin
                )
                .subscribe((conversation) => {
                    this._setCoversation(conversation);
                })
        );
        this.inputText = '';
    }

    private _setCoversation(conversation: ConversationForResults) {
        // console.log(conversation);
        this.conversation = conversation;
        this.changeDetectorRef.detectChanges();
        this.messageAreaScrollable.nativeElement.scrollTop =
            this.messageAreaScrollable.nativeElement.scrollHeight;
    }
}
