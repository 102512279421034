import { EnvConfig, logLevelValues } from '@common/models';

// NOTE: These are all public key values. DO NOT put private key values here.
export const stageTestEnvConfig: EnvConfig = {
    facebookClientID: '673817013642861',
    githubClientID: 'fb6ab27a9c0ff3e43ee0',
    googleClientID: '702982947710-3g3rkq2rsltinejhljide464emj7eevm.apps.googleusercontent.com',
    recaptchaSiteKey: '6Lf1HvshAAAAAMND4IhGy1msGq3xkOuU8eNIdjar',
    googlePlacesAPIKey: 'AIzaSyBcOPlFoM5T3uz9yuWwseJbQfAqOYaLuJw',
    stripePublishableKey:
        'pk_test_51NiMDEHu8ViMSJQxBceqLRMB8F3SIn38s9NfnKkzR3svwW6wQgvXtaCnWTeM1ImcwDNMLIZyj220sUNysLTx2FHD00a5q5S9hD',
    backendURL: 'https://stage-test-api.tredder.com',
    backendWSURL: 'wss://stage-test-api.tredder.com',
    frontendURL: 'https://stage-test.tredder.com',
    logLevel: logLevelValues['info'],
    canopyConnectAlias: 'tredder10',
};
