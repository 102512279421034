<div class="container-xxl">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-block" (click)="titleClicked()">
                <div class="quote-title me-2">Tredder covers</div>
                <div class="quote-title me-2">after-market modifications,</div>
                <div class="quote-title">your trailer, and gear.</div>
            </div>
        </div>
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3"><div class="quote-subtitle">Tredder works with your existing policy to make sure you're totally covered.</div></div>
        <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-3" data-cy="quoteFlowEnsIntroNextButton" type="button" (click)="next.emit()" tabindex="0">
            <div class="d-flex align-items-center justify-content-center">Let's do this!</div>
            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
        </button>
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3"><div class="quote-subtitle text-cyan-blue mt-2">Eligibility Requirements</div></div>
        <div class="card mb-5">
            <div class="card-body px-md-7 py-md-5 px-sm-5 py-sm-4 px-4 py-4">
                <div class="row">
                    <div class="col-lg-6 col-12 mb-lg-0">
                        <div class="d-flex justify-content-between align-items-center flex-column h-100">
                            <div class="d-flex justify-content-start align-items-start w-100 mb-3">
                                <sbf-tredder-icons class="d-block" name="blue-check" height="14px"></sbf-tredder-icons>
                                <div class="text-start ms-3">You Must Be 25 years old or older and have a valid driver's license</div>
                            </div>
                            <div class="d-flex justify-content-start align-items-start w-100 mb-3">
                                <sbf-tredder-icons class="d-block" name="blue-check" height="14px"></sbf-tredder-icons>
                                <div class="text-start ms-3">Have and maintain auto insurance with Comprehensive and Collision Coverages</div>
                            </div>
                            <div class="d-flex justify-content-start align-items-start w-100 mb-3 mb-lg-0">
                                <sbf-tredder-icons class="d-block" name="blue-check" height="14px"></sbf-tredder-icons>
                                <div class="text-start ms-3">Be capable and willing to provide photos of your rig and possible support for high value modifications and gear</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="d-flex justify-content-between align-items-center flex-column h-100">
                            <div class="d-flex justify-content-start align-items-start w-100 mb-3">
                                <sbf-tredder-icons class="d-block" name="blue-check" height="14px"></sbf-tredder-icons>
                                <div class="text-start ms-3">Your rig is not used as a ride share vehicle or for racing. It must not be a salvaged title</div>
                            </div>
                            <div class="d-flex justify-content-start align-items-start w-100 mb-3">
                                <sbf-tredder-icons class="d-block" name="blue-check" height="14px"></sbf-tredder-icons>
                                <div class="text-start ms-3">Do not have an SR-22/FR-44 (proof of financial responsibility)</div>
                            </div>
                            <div class="d-flex justify-content-start align-items-start w-100">
                                <sbf-tredder-icons class="d-block" name="blue-check" height="14px"></sbf-tredder-icons>
                                <div class="text-start ms-3">Your vehicle/rig must be newer than 1990 &lpar;some older vehicles may qualify – please call customer service<a class="mx-1" href="tel:8448733337">1-844-TREDDER</a>to determine eligibility&rpar;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
