import { Injectable } from '@angular/core';
// We need to import ConfirmationModalComponent in order to keep the main bundle size down.
import { ConfirmationModalComponent } from '@common/components/confirmation-modal/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

export interface ConfirmationServiceOptions {
    title: string;
    text?: string;
    text2?: string;
    textArray?: string[];
    cancelLabel?: string;
    confirmationLabel?: string;
    cancelClasses?: string[];
    confirmClasses?: string[];
    noCancel?: boolean;
}

@Injectable()
export class ConfirmationService {
    constructor(private ngbModalService: NgbModal) {}

    confirm$(options: ConfirmationServiceOptions) {
        const confirmationResults$: Subject<boolean> = new Subject<boolean>();
        const {
            componentInstance,
            result,
        }: {
            componentInstance: ConfirmationModalComponent;
            result: Promise<unknown>;
        } = this.ngbModalService.open(ConfirmationModalComponent, {
            size: 'lg',
        });

        componentInstance.title = options.title;
        componentInstance.text = options.text;
        componentInstance.text2 = options.text2;
        componentInstance.textArray = options.textArray;
        if (options.cancelLabel) {
            componentInstance.cancelLabel = options.cancelLabel;
        }
        if (options.confirmationLabel) {
            componentInstance.confirmationLabel = options.confirmationLabel;
        }
        if (options.cancelClasses) {
            componentInstance.cancelClasses = options.cancelClasses;
        }
        if (options.confirmClasses) {
            componentInstance.confirmClasses = options.confirmClasses;
        }
        if (options.noCancel) {
            componentInstance.noCancel = options.noCancel;
        }

        result.then(
            (confirm) => {
                confirmationResults$.next(true);
                confirmationResults$.complete();
            },
            (cancel) => {
                confirmationResults$.next(false);
                confirmationResults$.complete();
            }
        );

        return confirmationResults$.asObservable();
    }
}
