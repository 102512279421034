import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'sbf-tredder-card',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './tredder-card.component.html',
    styleUrls: ['tredder-card.component.scss'],
})
export class TredderCardComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
