import { StateShort } from '@backend-types/states';
import { HomeBaseScenarioButtons, HomeBaseScenarios } from '@common/models/dev-utils.model';

export const homeBaseScenarios: HomeBaseScenarios = {
    common: {
        buttonText: 'Common',
        testId: HomeBaseScenarioButtons.common,
        formValues: {
            street: 'Yosemite National Park Road',
            zip: '95389',
            city: 'Yosemite Valley',
            state: 'CA',
            country: 'US',
        },
    },
    goodCredit: {
        buttonText: 'Good Credit Test User',
        testId: HomeBaseScenarioButtons.goodCredit,
        formValues: {
            street: '2 OODKESE PKWY',
            zip: '98504',
            city: 'Olympia',
            state: 'WA',
            country: 'US',
        },
    },
    badCredit: {
        buttonText: 'Bad Credit Test User',
        testId: HomeBaseScenarioButtons.badCredit,
        formValues: {
            street: '7528 QSJXQ',
            zip: '98504',
            city: 'Olympia',
            state: 'WA',
            country: 'US',
        },
    },
    noUseMaritalStatus: {
        buttonText: 'Marital Status Usage Not Allowed',
        testId: HomeBaseScenarioButtons.noUseMaritalStatus,
        formValues: {
            street: '9551 South Devon Drive',
            zip: '49009',
            city: 'Kalamazoo',
            state: 'MI',
            country: 'US',
        },
    },
    unlicensedState: {
        buttonText: 'Unlicensed State',
        testId: HomeBaseScenarioButtons.unlicensedState,
        formValues: {
            street: '1262 Carroll St',
            city: 'Brooklyn',
            state: 'NY',
            zip: '11213',
            country: 'US',
        },
    },
    usTerritory: {
        buttonText: 'US Territory',
        testId: HomeBaseScenarioButtons.usTerritory,
        formValues: {
            street: '18 Calle Cesar González',
            city: 'Adjuntas',
            state: StateShort.puertoRico,
            zip: '00601',
            country: StateShort.puertoRico,
        },
    },
};
