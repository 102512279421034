import { EnvConfig, logLevelValues } from '@common/models';

// NOTE: These are all public key values. DO NOT put private key values here.
export const prodEnvConfig: EnvConfig = {
    facebookClientID: '673817013642861',
    githubClientID: 'c1d55327d227a458792b',
    googleClientID: '702982947710-3g3rkq2rsltinejhljide464emj7eevm.apps.googleusercontent.com',
    recaptchaSiteKey: '6LdZ5OkpAAAAAN5gk8TTSqyzZNeAiZkq9vPQg1bd',
    googlePlacesAPIKey: 'AIzaSyAaRnFMdMfKcu8y9C0oBbTvlOeGu3j2-O4',
    stripePublishableKey:
        'pk_live_51NiMDEHu8ViMSJQxxaGU4zfRrmmN33G5RVOYaNblC4M1NTrVLQotmftBxhbpV531C0MBthjKoJ0FST2QdZ7VhZjv00jS1j48v1',
    backendURL: 'https://api.tredder.com',
    backendWSURL: 'wss://api.tredder.com',
    frontendURL: 'https://tredder.com',
    logLevel: logLevelValues['warn'],
    canopyConnectAlias: 'tredder-production',
};
