import { DynamicRouteData } from '@modules/navigation/models';

export const account: DynamicRouteData = {
    isPublic: false,
    title: 'Account',
};

export const accountSettingsName: DynamicRouteData = {
    isPublic: false,
    title: 'Account Settings: Name',
};

export const accountSettingsDetails: DynamicRouteData = {
    isPublic: false,
    title: 'Account Settings: Details',
};

export const accountSettingsAuthorization: DynamicRouteData = {
    isPublic: false,
    title: 'Account Settings: Authorization',
};

export const accountPostsAll: DynamicRouteData = {
    isPublic: false,
    title: 'Account Posts: All',
};

export const accountPostsCreate: DynamicRouteData = {
    isPublic: false,
    title: 'Account Posts: Create',
};

export const accountConfigs = {
    account,
    accountSettingsName,
    accountSettingsDetails,
    accountSettingsAuthorization,
    accountPostsAll,
    accountPostsCreate,
};
