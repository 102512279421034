<div id="overlay" data-cy="overlayComponent" *ngIf="showOverlay">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="card mt-15" *ngIf='overlayService.template === "TEXT"'>
                    <div class="card-body p-5 text-center">
                        <div class="mb-3">{{overlayService.message}}</div>
                        <div class="spinner-border" *ngIf="overlayService.showSpinner" role="status"><span class="visually-hidden">Loading...</span></div>
                    </div>
                </div>
                <div class="card mt-15" *ngIf='overlayService.template === "PROGRESS"'>
                    <div class="card-body p-5 text-center">
                        <div class="mb-3"><i-bs class="d-inline-block" name="download" width="2em" height="2em"></i-bs></div>
                        <div class="mb-3">File is now being downloaded, please do not close this page.</div>
                        <sbpro-progress-bar class="mb-3" [value]="progress" [striped]="true" [animated]="true" bgColor="bg-success"></sbpro-progress-bar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
