import { DynamicRouteData } from '@modules/navigation/models';

import { sharedMetaTags } from './_shared-meta-tags';

export const rig: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Rig',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const rigStart: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Rig Creator',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const rigSubmitted: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Rig Submitted',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const rigConfigs = {
    rig,
    rigStart,
    rigSubmitted,
};
