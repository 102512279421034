export enum OverlayTemplate {
    text = 'TEXT',
    content = 'CONTENT',
    progress = 'PROGRESS',
}

export interface OverlayServiceOptions {
    template?: OverlayTemplate;
    showSpinner?: boolean;
}
