<div class="container-xxl" *ngIf="activeEnsQuote">
    <div class="card br-75">
        <div class="card-body">
            <div class="text-primary text-xxl mb-1" data-cy="paymentAmountText">{{paymentPlanSource.installments[0].debit | currency : 'USD' : 'symbol' : '1.2'}}</div>
            <div class="text-primary mb-1">{{invoiceDescription}}</div>
            <div class="text-primary mb-3">{{invoiceDescription2}}</div>
            <div class="text-danger mb-3" data-cy="paymentProcessingMessage" *ngIf="stripeError">
                <div class="r-text-base d-inline-block me-1">There was a payment processing issue.</div>
                <div class="r-text-base d-inline-block">Please check your info and try again.</div>
            </div>
            <div class="mb-5">
                <div id="payment-element"><!-- Stripe.js injects the Payment Element--></div>
            </div>
            <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button w-100" data-cy="processPaymentButton" type="button" (click)="processPayment()" tabindex="0" [disabled]="!paymentFormComplete"><div class="d-flex align-items-center justify-content-center">Pay {{paymentPlanSource.installments[0].debit | currency : 'USD' : 'symbol' : '1.2'}} Today</div></button>
        </div>
    </div>
</div>
