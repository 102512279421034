<div class="container-xxl" *ngIf="!formOnly">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block">
                <div class="quote-title">Give us your best</div>
                <div class="quote-title">estimate of the total</div>
                <div class="quote-title">value of your gear*</div>
            </div>
            <div class="d-md-block d-none"><div class="quote-title">Give us your best estimate of the total value of your gear*</div></div>
        </div>
        <img class="mb-md-5 mb-3 mx-5" style="max-width: 425px" src="/assets/img/svg/overland-design/od_gear_full.svg" alt="Tent png" />
        <div class="row justify-content-center">
            <div class="col-lg-6 col-12"><ng-template [ngTemplateOutlet]="gearDetailsFormTemplate"></ng-template></div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-8 col-12">
                <div class="text-start ms-3 ms-sm-5 ms-md-0 my-3">
                    <div class="r-text-base text-primary me-1 mt-2 mb-2">*Gear may include, but is not limited to: shovels, computers, skis, kayaks, bikes, portable GPS units, tools, coolers, etc.</div>
                    <div class="r-text-base text-primary">
                        *Gear are
                        <div class="heavy d-inline-block">not original equipment</div>
                        and are
                        <div class="heavy d-inline-block">not permanently installed</div>
                        on your rig.
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5 mt-md-5 mt-3" data-cy="quoteFlowGearDetailsNextButton" type="button" (click)="next.emit()" tabindex="0" [attr.disabled]="!allValid ? true : null">
            <div class="d-flex align-items-center justify-content-center">Next</div>
            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
        </button>
        <div class="d-flex justify-content-start"><sbf-back-button data-cy="quoteFlowGearDetailsBackButton" (click)="back.emit()"></sbf-back-button></div>
    </div>
</div>
<ng-container *ngIf="formOnly"><ng-template [ngTemplateOutlet]="gearDetailsFormTemplate"></ng-template></ng-container
><ng-template #gearDetailsFormTemplate
    ><form data-cy="gearDetailsForm" [formGroup]="gearDetailsForm">
        <div class="form-floating mb-3">
            <input class="form-control valid-override" id="gearValueInput" #focusElement data-cy="gearValueInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="gearValueHelp" sbwAutoFocus [formOnly]="formOnly" formControlName="gearValue" [class.is-invalid]="gearValueControlInvalid" [class.is-valid]="gearValueControlValid" placeholder="gearValue" prefix=" $" mask="separator.0" thousandSeparator="," />
            <div class="invalid-feedback" *ngIf='gearValueControl.hasError("required")'>Gear Value required.</div>
            <div class="invalid-feedback" data-cy="invalidFeedbackMin" *ngIf='gearValueControl.hasError("min")'>Value must be {{valueLimits.gear.min | currency : 'USD' : 'symbol' : '1.0'}} or greater.</div>
            <div class="invalid-feedback" data-cy="invalidFeedbackMax" *ngIf='gearValueControl.hasError("max")'>Value must be {{valueLimits.gear.max | currency : 'USD' : 'symbol' : '1.0'}} or less.</div>
            <div class="valid-feedback text-cyan-blue" *ngIf="gearValueControl.value &gt; 10000">Note: You will be required to upload photos of your gear</div>
            <div class="valid-feedback" *ngIf="gearValueRounded">Gear Value rounded.</div>
            <label class="text-gray-600 small" for="gearValueInput">Total Value of your gear</label>
        </div>
    </form></ng-template
>
