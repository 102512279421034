<div class="premium-breakdown" *ngIf="premiumBreakdown">
    <div class="r-text-lg text-tredder-purple mb-3 semi-bold">Premium Breakdown</div>
    <div class="d-flex justify-content-center align-items-center mb-2">
        <table class="w-100 mx-0 mx-lg-5 mx-xl-20">
            <tr>
                <td class="faded-green" *ngIf="premiumBreakdown.mods" [attr.width]="premiumBreakdown.mods.percent"></td>
                <td class="faded-red" *ngIf="premiumBreakdown.gear" [attr.width]="premiumBreakdown.gear.percent"></td>
                <td class="faded-blue" *ngIf="premiumBreakdown.camper" [attr.width]="premiumBreakdown.camper.percent"></td>
                <td class="faded-purple" *ngIf="premiumBreakdown.trailer" [attr.width]="premiumBreakdown.trailer.percent"></td>
                <td class="faded-yellow" *ngIf="premiumBreakdown.offRoad" [attr.width]="premiumBreakdown.offRoad.percent"></td>
                <td class="faded-pink" *ngIf="premiumBreakdown.emergency" [attr.width]="premiumBreakdown.emergency.percent"></td>
                <td class="faded-orange" *ngIf="premiumBreakdown.towing" [attr.width]="premiumBreakdown.towing.percent"></td>
            </tr>
        </table>
    </div>
    <div class="d-flex align-items-center justify-content-center flex-wrap">
        <div class="d-flex justify-content-center align-items-center">
            <div class="premium-amount faded-green m-2 p-2 br-5" *ngIf="premiumBreakdown.mods">
                <div class="text-primary text-nowrap">Mods</div>
                <div class="text-primary">${{premiumBreakdown.mods.amount}}</div>
            </div>
            <div class="premium-amount faded-red m-2 p-2 br-5" *ngIf="premiumBreakdown.gear">
                <div class="text-primary text-nowrap">Gear</div>
                <div class="text-primary">${{premiumBreakdown.gear.amount}}</div>
            </div>
            <div class="premium-amount faded-blue m-2 p-2 br-5" *ngIf="premiumBreakdown.camper">
                <div class="text-primary text-nowrap">Camper</div>
                <div class="text-primary">${{premiumBreakdown.camper.amount}}</div>
            </div>
            <div class="premium-amount faded-purple m-2 p-2 br-5" *ngIf="premiumBreakdown.trailer">
                <div class="text-primary text-nowrap">Trailer</div>
                <div class="text-primary">${{premiumBreakdown.trailer.amount}}</div>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <div class="premium-amount faded-yellow m-2 p-2 br-5" *ngIf="premiumBreakdown.offRoad">
                <div class="text-primary text-nowrap">Off Road</div>
                <div class="text-primary">${{premiumBreakdown.offRoad.amount}}</div>
            </div>
            <div class="premium-amount faded-pink m-2 p-2 br-5" *ngIf="premiumBreakdown.emergency">
                <div class="text-primary text-nowrap">Emergency</div>
                <div class="text-primary">${{premiumBreakdown.emergency.amount}}</div>
            </div>
            <div class="premium-amount faded-orange m-2 p-2 br-5" *ngIf="premiumBreakdown.towing">
                <div class="text-primary text-nowrap">Towing</div>
                <div class="text-primary">${{premiumBreakdown.towing.amount}}</div>
            </div>
        </div>
    </div>
</div>
