import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { CreatePaymentIntentPayload, CreatePaymentIntentResponse } from '@backend-types/payment';
import { inputIsNotNullOrUndefined } from '@common/helpers';
import {
    Appearance,
    loadStripe,
    PaymentIntentResult,
    Stripe,
    StripeElements,
    StripePaymentElement,
    StripePaymentElementChangeEvent,
    StripePaymentElementOptions,
} from '@stripe/stripe-js';
import {
    combineLatest,
    filter,
    finalize,
    from,
    map,
    Observable,
    ReplaySubject,
    switchMap,
} from 'rxjs';

import { EnvService } from './env.service';
import { LogService } from './log.service';
import { OverlayService } from './overlay.service';

const DEFAULT_APPEARANCE: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
        colorPrimary: '#333C8D',
        fontFamily: 'Outfit, system-ui, sans-serif',
        spacingUnit: '4px',
        borderRadius: '8px',
        spacingGridColumn: '16px',
        spacingGridRow: '16px',
    },
};

const STRIPE_PAYMENT_ELEMENT_OPTIONS: StripePaymentElementOptions = {
    layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: true,
    },
    paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
};

export interface PaymentElementInitResults {
    paymentElement: StripePaymentElement;
}

export interface ConfirmPaymentOptions {
    returnPath: string;
    errorPath: NavigateOptions;
    successFunction?: (paymentIntentId: string) => void;
    errorFunction?: () => void;
}
export interface NavigateOptions {
    path: string;
    navigationExtras?: NavigationExtras;
}

@Injectable()
export class StripeService {
    private _stripe$ = new ReplaySubject<Stripe>(1);
    private _stripeElements$ = new ReplaySubject<StripeElements | null>(1);
    private _paymentElementChange$ = new ReplaySubject<StripePaymentElementChangeEvent | null>(1);
    private _paymentElementReady$ = new ReplaySubject<boolean | null>(1);

    constructor(
        private http: HttpClient,
        private envService: EnvService,
        private router: Router,
        private overlayService: OverlayService,
        private logService: LogService
    ) {
        from(loadStripe(this.envService.config.stripePublishableKey)).subscribe((stripe) => {
            if (stripe === null) {
                throw new Error('UNABLE_TO_LOAD_STRIPE');
            }

            this._stripe$.next(stripe);
        });
    }

    paymentElementInit$(
        createPaymentIntentPayload: CreatePaymentIntentPayload,
        modifyAppearance: Appearance = {},
        modifyStripePaymentElementOptions: StripePaymentElementOptions = {}
    ): Observable<PaymentElementInitResults> {
        return combineLatest([
            this._stripe$,
            this.createPaymentIntent$(createPaymentIntentPayload),
        ]).pipe(
            map(([stripe, createPaymentIntentResponse]) => {
                const appearance: Appearance = {
                    ...DEFAULT_APPEARANCE,
                    theme: 'stripe',
                    labels: 'floating',
                    ...modifyAppearance,
                    variables: {
                        ...DEFAULT_APPEARANCE.variables,
                        ...modifyAppearance.variables,
                    },
                };

                const stripeElements = stripe.elements({
                    appearance,
                    fonts: [
                        {
                            family: 'Outfit',
                            src: 'url("/assets/fonts/Outfit/Outfit-VariableFont_wght.woff2")',
                        },
                    ],
                    clientSecret: createPaymentIntentResponse.clientSecret,
                });

                this._stripeElements$.next(stripeElements);
                const paymentElementOptions: StripePaymentElementOptions = {
                    ...STRIPE_PAYMENT_ELEMENT_OPTIONS,
                    ...modifyStripePaymentElementOptions,
                };

                const paymentElement = stripeElements.create('payment', paymentElementOptions);

                paymentElement.on('change', (response) => {
                    this._paymentElementChange$.next(response);
                });

                paymentElement.on('ready', () => {
                    this.logService.info('PAYMENT_ELEMENT_LOADED');
                    this._paymentElementReady$.next(true);
                });

                paymentElement.mount('#payment-element');

                return {
                    paymentElement,
                };
            })
        );
    }

    get paymentElementChange$(): Observable<StripePaymentElementChangeEvent> {
        return this._paymentElementChange$.pipe(filter(inputIsNotNullOrUndefined));
    }

    get paymentElementReady$(): Observable<boolean> {
        return this._paymentElementReady$.pipe(filter(inputIsNotNullOrUndefined));
    }

    createPaymentIntent$(
        createPaymentIntentPayload: CreatePaymentIntentPayload
    ): Observable<CreatePaymentIntentResponse> {
        return this.http.post<CreatePaymentIntentResponse>(
            `${this.envService.config.backendURL}/api/latest/payment/create-payment-intent`,
            createPaymentIntentPayload
        );
    }

    confirmPayment$(confirmPaymentOptions: ConfirmPaymentOptions): Observable<PaymentIntentResult> {
        this.overlayService.show('Processing');
        return combineLatest([
            this._stripe$,
            this._stripeElements$.pipe(filter(inputIsNotNullOrUndefined)),
        ]).pipe(
            switchMap(([stripe, stripeElements]) => {
                return from(
                    stripe.confirmPayment({
                        elements: stripeElements,
                        confirmParams: {
                            return_url: `${this.envService.config.frontendURL}${confirmPaymentOptions.returnPath}`,
                        },
                        redirect: 'if_required',
                    })
                ).pipe(
                    map((paymentIntentResult) => {
                        if (paymentIntentResult.error) {
                            if (confirmPaymentOptions.errorFunction) {
                                confirmPaymentOptions.errorFunction();
                            }
                            const allowRetry = [
                                'incorrect_cvc',
                                'processing_error',
                                'incorrect_number',
                            ].find((errorCode) => errorCode === paymentIntentResult.error.code);
                            if (allowRetry) {
                                return paymentIntentResult;
                            } else {
                                this._resetSubjects();
                                this.router.navigate(
                                    [confirmPaymentOptions.errorPath.path],
                                    confirmPaymentOptions.errorPath.navigationExtras
                                );
                            }
                        } else {
                            this._resetSubjects();
                            if (confirmPaymentOptions.successFunction) {
                                confirmPaymentOptions.successFunction(
                                    paymentIntentResult.paymentIntent.id
                                );
                            }
                            this.router.navigateByUrl(confirmPaymentOptions.returnPath);
                        }
                        return paymentIntentResult;
                    }),
                    finalize(() => {
                        this.overlayService.hide();
                    })
                );
            })
        );
    }

    private _resetSubjects() {
        this._stripeElements$.next(null);
        this._paymentElementChange$.next(null);
        this._paymentElementReady$.next(null);
    }
}
