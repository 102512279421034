import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { buildInfo } from '@data/build-info.data';

@Component({
    selector: 'sbf-build-info',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './build-info.component.html',
    styleUrls: ['build-info.component.scss'],
})
export class BuildInfoComponent implements OnInit {
    buildInfo = buildInfo;
    constructor() {}
    ngOnInit() {}
}
