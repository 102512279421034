import type { AccountDetail, Prisma } from './_prisma';
import type { CreditScoreTyped } from './credit-score';
import type { LedgerForResults } from './ledger';
import type { MembershipWithOrg, MembershipWithOrgSelect } from './membership';
import type { PolicyEnsTyped } from './policy-ens';
import type { QuoteEnsTyped } from './quote-ens';
import type { RigForList } from './rig';
import type { RoleName } from './role';

export type AuthInclude = {
    authFacebook: true;
    authGithub: true;
    authGoogle: true;
    authLocal: true;
    authTwitter: true;
};

export type UserForInsured = Prisma.UserGetPayload<{
    include: {
        quotesEns: true;
        policiesEns: true;
        rigs: true;
        ledger: true;
    };
}>;

export const userForInsuredInclude = {
    quotesEns: true,
    policiesEns: true,
    rigs: true,
    ledger: true,
};

export type UserWithCreditScore = Prisma.UserGetPayload<{
    include: {
        creditScores: true;
    };
}>;

export const userWithCreditScoreInclude = {
    creditScores: true,
};

export type UserWithAccountDetail = Prisma.UserGetPayload<{
    include: {
        accountDetail: true;
    };
}>;

export const userWithAccountDetailInclude = {
    accountDetail: true,
};

export type UserForAuthentication = Prisma.UserGetPayload<{
    include: {
        auth: {
            include: AuthInclude;
        };
        memberships: {
            select: MembershipWithOrgSelect;
        };
        policiesEns: true;
        quotesEns: true;
        rigs: true;
        partners: true;
    };
}>;

export const userForAuthenticationInclude = {
    auth: {
        include: {
            authFacebook: true,
            authGithub: true,
            authGoogle: true,
            authLocal: true,
            authTwitter: true,
        },
    },
    memberships: {
        select: {
            id: true,
            organizationId: true,
            organization: {
                select: {
                    name: true,
                },
            },
            role: {
                select: {
                    name: true,
                },
            },
            groups: {
                select: {
                    id: true,
                    name: true,
                },
            },
        },
    },
    policiesEns: true,
    quotesEns: true,
    rigs: true,
    partners: true,
};

export type UserForResults = Prisma.UserGetPayload<{
    select: UserForResultsSelect;
}>;

export type UserForResultsSelect = {
    id: true;
    firstName: true;
    lastName: true;
    email: true;
    emailConfirmed: true;
    hasSocialAuth: true;
    profilePicURL: true;
    activeMembershipID: true;
    accountDetailId: true;
};

export interface UserForAdminAuth {
    id: string;
    firstName: string;
    lastName: string;
    email: string | null;
    emailConfirmed: boolean;
    hasSocialAuth: boolean;
    profilePicURL: string | null;
    activeMembership: MembershipWithOrg;
}

export type UserForPost = Prisma.UserGetPayload<{
    select: {
        firstName: true;
        lastName: true;
        email: true;
        postMetaData: true;
    };
}>;

export type UserForAccountDetail = Prisma.UserGetPayload<{
    include: {
        memberships: {
            select: {
                id: true;
                organizationId: true;
                organization: {
                    select: {
                        name: true;
                    };
                };
                role: {
                    select: {
                        name: true;
                    };
                };
                groups: {
                    select: {
                        id: true;
                        name: true;
                    };
                };
            };
        };
    };
}>;

export type UserAsPlayer = Prisma.UserGetPayload<{
    select: UserAsPlayerSelect;
}>;

export type UserAsPlayerSelect = {
    id: true;
    firstName: true;
    lastName: true;
    nickname: true;
    profilePicURL: true;
};

export const userAsPlayerInclude = {
    id: true,
    firstName: true,
    lastName: true,
    nickname: true,
    profilePicURL: true,
};

export type UserForBase = Prisma.UserGetPayload<{
    select: UserForBaseSelect;
}>;

export type UserForBaseSelect = {
    id: true;
    firstName: true;
    lastName: true;
    email: true;
    phone: true;
    birthday: true;
    insuranceLicenseNumber: true;
    accountDetail: true;
    stripeCustomerID: true;
};

export const userForBaseInclude = {
    select: {
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        birthday: true,
        insuranceLicenseNumber: true,
        accountDetail: true,
        stripeCustomerID: true,
    },
};

export interface CreateUserPayload {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone?: string;
    birthday?: string;
    // groupNames?: string[];
    roleName: RoleName;
}

export interface ReadMultipleUsersPayload {
    userIDs: string[];
}

export interface UpdateUserPayload {
    firstName?: string;
    lastName?: string;
    email?: string;
    emailConfirmed?: boolean;
    password?: string;
    roleName?: RoleName;
    roleId?: UUID;
    groupIDs?: UUID[];
    membershipID?: UUID;
}

export interface DeleteMultipleUsersPayload {
    userIDs: string[];
}

export type AllUsersErrorCodes =
    | 'ERROR_FINDING_USERS'
    | 'USERS_NOT_FOUND'
    | 'MEMBERSHIP_NOT_FOUND_FOR_ADMIN_ORG';
export type DeleteMultipleUsersErrorCodes =
    | 'USERS_NOT_FOUND'
    | 'ERROR_FINDING_USERS'
    | 'ERROR_DELETING_USERS';

export type DeleteUserErrorCodes = 'USER_NOT_FOUND' | 'ERROR_FINDING_USER' | 'ERROR_DELETING_USER';
export type FindUserErrorCodes = 'ERROR_FINDING_USER' | 'USER_NOT_FOUND';
export type FindUsersErrorCodes = 'ERROR_FINDING_USERS' | 'NO_USERS_FOUND';
export type SaveUserErrorCodes = 'ERROR_SAVING_USER';
export type UpdateUserErrorCodes = 'ERROR_UPDATING_USER' | 'USER_NOT_FOUND' | 'UNKNOWN_ROLE';

export interface UserForInsuredTyped
    extends Omit<
        UserForInsured,
        'quotesEns' | 'policiesEns' | 'rigs' | 'postMetaData' | 'stripeCustomerID'
    > {
    quotesEns: QuoteEnsTyped[];
    policiesEns: PolicyEnsTyped[];
    rigs: RigForList[];
    ledger: LedgerForResults | null;
}

export interface UserWithCreditScoresTyped extends Omit<UserForInsured, 'creditScores'> {
    creditScores: CreditScoreTyped[];
}

export interface UserWithAccountDetailSet extends UserWithAccountDetail {
    accountDetail: AccountDetail;
    accountDetailId: UUID;
}

export interface UserWithAccountDetailSet extends UserWithAccountDetail {
    accountDetail: AccountDetail;
    accountDetailId: UUID;
}

export interface UserForBaseTyped extends UserForBase {
    accountDetail: AccountDetail;
    accountDetailId: UUID;
}
