import { DynamicRouteData } from '@modules/navigation/models';

import { sharedMetaTags } from './_shared-meta-tags';

export const home: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const insurance: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Insurance',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const contact: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Contact',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const aboutUs: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder About Us',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const claims: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Claims',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const earlyAccessSuccess: DynamicRouteData = {
    isPublic: true,
    title: 'Early Access Success',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const notInZipCodeYet: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Coming Soon',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const faq: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder FAQ',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const siteConfigs = {
    home,
    insurance,
    contact,
    aboutUs,
    claims,
    earlyAccessSuccess,
    notInZipCodeYet,
    faq,
};
