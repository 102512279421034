export enum MarketingSource {
    facebook = 'facebook',
    google = 'google',
    overlandExpo = 'overlandExpo',
    overlandExpoQR = 'overlandExpoQR',
}

export interface SuccessfulMediaStorage {
    transactionId: string;
    offerId: string;
}

export enum smOfferId {
    quote = 7,
    estimate = 13,
}

export const campaignToOfferID = (campaign: string | null): string => {
    switch (campaign) {
        case 'quote':
            return smOfferId.quote.toString();
        case 'estimate':
            return smOfferId.estimate.toString();
        default:
            return smOfferId.quote.toString();
    }
};
