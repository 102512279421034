import { DynamicRouteData } from '@modules/navigation/models';

import { sharedMetaTags } from './_shared-meta-tags';

export const partnerApply: DynamicRouteData = {
    isPublic: true,
    title: 'Partner',
};
export const partnerSubmitted: DynamicRouteData = {
    isPublic: true,
    title: 'Partner',
};

export const partnerDashboard: DynamicRouteData = {
    isPublic: false,
    title: 'Partner Dashboard',
};

export const partnerRoamly: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder - Roamly',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const partnerOnx: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder - OnX',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const partnerConfigs = {
    partnerApply,
    partnerSubmitted,
    partnerDashboard,
    partnerRoamly,
    partnerOnx,
};
