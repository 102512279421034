import {
    EnsPersonalInfoFormValue,
    HomeBaseFormValue,
    RateCallTwoFormValue,
} from '@backend-types/quote-flow-ens';

export enum DevUtilsModalKind {
    homeBase = 'homeBase',
    personalInfo = 'personalInfo',
    rc2 = 'rc2',
}

export interface ScenarioConfig<T, V> {
    buttonText: string;
    testId: T;
    formValues: V;
}

// HomeBase
export enum HomeBaseScenario {
    common = 'common',
    goodCredit = 'goodCredit',
    badCredit = 'badCredit',
    noUseMaritalStatus = 'noUseMaritalStatus',
    unlicensedState = 'unlicensedState',
    usTerritory = 'usTerritory',
}
export enum HomeBaseScenarioButtons {
    common = 'commonButton',
    goodCredit = 'goodCreditTestUserButton',
    badCredit = 'badCreditTestUserButton',
    noUseMaritalStatus = 'maritalStatusUsageNotAllowedButton',
    unlicensedState = 'unlicensedStateButton',
    usTerritory = 'usTerritoryButton',
}
export type HomeBaseScenarioConfig = ScenarioConfig<HomeBaseScenarioButtons, HomeBaseFormValue>;
export type HomeBaseScenarios = {
    [key in HomeBaseScenario]: HomeBaseScenarioConfig;
};
// Personal Info
export enum PersonalInfoScenario {
    ageException = 'ageException',
    common = 'common',
    goodCredit = 'goodCredit',
    badCredit = 'badCredit',
}
export enum PersonalInfoScenarioButtons {
    ageException = 'ageExceptionButton',
    common = 'commonButton',
    goodCredit = 'goodCreditTestUserButton',
    badCredit = 'badCreditTestUserButton',
}

export type PersonalInfoScenarioConfig = ScenarioConfig<
    PersonalInfoScenarioButtons,
    Omit<EnsPersonalInfoFormValue, 'ssn'>
>;
export type PersonalInfoScenarios = {
    [key in PersonalInfoScenario]: PersonalInfoScenarioConfig;
};
export enum Rc2Scenario {
    common = 'common',
    invalidLicense = 'invalidLicense',
    overTwoViolations = 'overTwoViolations',
    overTwelveLiftKit = 'overTwelveLiftKit',
}
export enum Rc2ScenarioButtons {
    common = 'commonButton',
    invalidLicense = 'invalidLicenseButton',
    overTwoViolations = 'overTwoViolationsButton',
    overTwelveLiftKit = 'overTwelveLiftKitButton',
}
export type Rc2ScenarioConfig = ScenarioConfig<Rc2ScenarioButtons, RateCallTwoFormValue>;
export type Rc2Scenarios = {
    [key in Rc2Scenario]: Rc2ScenarioConfig;
};
export type DevUtilsModalScenarios = {
    homeBase: HomeBaseScenarios;
    personalInfo: PersonalInfoScenarios;
    rc2: Rc2Scenarios;
};

// Config
export type DevUtilsModalConfig = {
    [key in DevUtilsModalKind]: {
        header: string;
        scenarios: DevUtilsModalScenarios[key];
    };
};

export type FunctionReferences = {
    homeBase: {
        writeValue: (value: HomeBaseFormValue) => void;
    };
    personalInfo: {
        writeValue: (value: EnsPersonalInfoFormValue) => void;
    };
    rc2: {
        writeValue: (value: RateCallTwoFormValue) => void;
    };
};

// To enforce types on DevUtilsModalConfig
// export interface VerifyDevUtilsModalConfig<T extends DevUtilsModalKind> {
//     verifyDevUtilsModalConfig: DevUtilsModalConfig[T];
// }

// To enforce types on FunctionReferences
export interface VerifyFunctionReferences<T extends DevUtilsModalKind> {
    verifyFunctionConfig: FunctionReferences[T];
}
