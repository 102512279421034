import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface RoundInputValueParameters {
    inputValue: number;
    inputValueControl: FormControl;
    minValue: number;
    roudedTo: number;
}

@Injectable()
export class RoundingService {
    constructor() {}

    // INFO: Returns true if the value needs to be rounded.
    roundInputValue(roundInputValueParameters: RoundInputValueParameters): boolean {
        const halfOfRounded = roundInputValueParameters.roudedTo / 2;
        if (
            !roundInputValueParameters.inputValue ||
            roundInputValueParameters.inputValue < roundInputValueParameters.minValue
        ) {
            return false;
        }
        if (roundInputValueParameters.inputValue % roundInputValueParameters.roudedTo === 0) {
            return false;
        }

        const rounded =
            Math.trunc(
                (roundInputValueParameters.inputValue + halfOfRounded) /
                    roundInputValueParameters.roudedTo
            ) * roundInputValueParameters.roudedTo;

        roundInputValueParameters.inputValueControl.setValue(rounded, {
            emitEvent: true,
            emitModelToViewChange: true,
        });

        return true;
    }
}
