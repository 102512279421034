import { Injectable } from '@angular/core';
import { UtilityService } from '@common/services';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';

@Injectable()
export class LayoutService {
    private _stickyActionFooterVisible = new BehaviorSubject(false);
    private _timeoutID?: number;
    constructor(private utilityService: UtilityService) {}

    set stickyActionFooterVisible(isVisible: boolean) {
        if (!isVisible) {
            // INFO: setTimeout here cause, if not, routeAnimations error
            this._timeoutID = this.utilityService.window.setTimeout(() => {
                this._stickyActionFooterVisible.next(isVisible);
            }, 100);
        } else {
            clearTimeout(this._timeoutID);
            this._stickyActionFooterVisible.next(isVisible);
        }
    }

    get stickyActionFooterVisible$(): Observable<boolean> {
        return this._stickyActionFooterVisible.pipe(distinctUntilChanged());
    }
}
