import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CheckCoverageVerificationPayload,
    CheckCoverageVerificationResponse,
    ExistingCoveragePullStatus,
} from '@backend-types/coverage-verification';
import type { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { EnvService } from '@common/services/env.service';
import { exhaustMap, filter, interval, of, retry, Subject, takeUntil } from 'rxjs';

@Injectable()
export class CoverageVerificationService {
    constructor(
        private http: HttpClient,
        private envService: EnvService
    ) {}

    checkExistingCoveragePullStatus$(payload: CheckCoverageVerificationPayload, maxCount = 20) {
        const completeNotifier$ = new Subject();
        let iterator = 0;
        return interval(1000).pipe(
            takeUntil(completeNotifier$),
            exhaustMap(() => {
                iterator++;
                if (iterator > maxCount) {
                    completeNotifier$.next(undefined);
                    completeNotifier$.complete();
                    return of({
                        result: 'timeout',
                    });
                }

                return this.http
                    .post<CheckCoverageVerificationResponse>(
                        `${this.envService.config.backendURL}/api/latest/coverage-verification/check-status`,
                        payload
                    )
                    .pipe(retry(2));
            }),
            filter((response: { result: string; quoteEnsFlowFormValue?: QuoteEnsFlowFormValue }) =>
                [
                    'timeout',
                    ExistingCoveragePullStatus.pulled,
                    ExistingCoveragePullStatus.pullInvalid,
                    ExistingCoveragePullStatus.error,
                ].includes(response.result)
            )
        );
    }
}
