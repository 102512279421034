<div class="tredder-card coverage-card mb-5" *ngIf="quoteEnsFlowFormValue">
    <div class="text-start mb-3"><h2>Tredder Total Adventure Coverage Extras</h2></div>
    <hr class="full mb-3" />
    <form class="mb-3" [formGroup]="specialCoveragesAdjustmentsForm">
        <sbf-quote-ens-rates-card
            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"><img class="mb-md-5 mb-3" src="/assets/img/svg/overland-design/od_icons_offroad_recovery.svg" alt="offroad-recovery svg" /></span><span #ensRatesCardTitle ngProjectAs="ensRatesCardTitle">Off-Road Recovery</span
            ><span #ensRatesCardText ngProjectAs="ensRatesCardText"
                ><sbf-read-more-less [maxWords]="12"><span #passedText ngProjectAs="passedText">Off-road recovery for both motor vehicle and trailers/campers. $5000 limit per recovery. </span></sbf-read-more-less></span
            ><span #ensRatesCardSwitch ngProjectAs="ensRatesCardSwitch"><sbf-input-switch data-cy="offRoadRecoverySwitch" idAppend="offRoadRecoveryEnabled" formControlName="offRoadRecoveryEnabled"></sbf-input-switch></span
        ></sbf-quote-ens-rates-card>
        <hr />
        <sbf-quote-ens-rates-card
            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"><img class="mb-md-5 mb-3" src="/assets/img/svg/overland-design/od_icons_emergency_expense.svg" alt="emergency-expenses svg" /></span><span #ensRatesCardTitle ngProjectAs="ensRatesCardTitle">Emergency Expenses</span
            ><span #ensRatesCardText ngProjectAs="ensRatesCardText"
                ><sbf-read-more-less [maxWords]="12"><span #passedText ngProjectAs="passedText">Provides lodging and travel expenses to get you home in the event of travel trailer coverage loss. Also includes the cost to return your trailer to your home.</span></sbf-read-more-less></span
            ><span #ensRatesCardAction ngProjectAs="ensRatesCardAction"><sbf-input-select data-cy="emergencyExpenseLimitSelect" idAppend="emergencyExpenseLimit" formControlName="emergencyExpenseLimit" defaultText="Emergency Expense Limit" [optionsRecordsArray]="[eEmergencyExpenseLimitText]" displayTextAppend=" Coverage" styleClass="tredder-shaded"></sbf-input-select></span><span #ensRatesCardSwitch ngProjectAs="ensRatesCardSwitch"><sbf-input-switch data-cy="emergencyExpenseSwitch" idAppend="emergencyExpenseEnabled" formControlName="emergencyExpenseEnabled"></sbf-input-switch></span
        ></sbf-quote-ens-rates-card>
        <hr />
        <sbf-quote-ens-rates-card
            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"><img class="mb-md-5 mb-3" src="/assets/img/svg/overland-design/od_icons_towing_roadside.svg" alt="towing svg" /></span><span #ensRatesCardTitle ngProjectAs="ensRatesCardTitle">Towing and Roadside Assistance</span
            ><span #ensRatesCardText ngProjectAs="ensRatesCardText"
                ><sbf-read-more-less [maxWords]="12"><span #passedText ngProjectAs="passedText">Coverage in the event the Trailer or the Vehicle towing it breaks down. Labor performed at the place of disablement, including a professional locksmith.</span></sbf-read-more-less></span
            ><span #ensRatesCardAction ngProjectAs="ensRatesCardAction"><sbf-input-select data-cy="towRoadsideLimitSelect" idAppend="towRoadsideLimit" formControlName="towRoadsideLimit" defaultText="Tow Roadside Limit" [optionsRecordsArray]="[eTowRoadsideLimitText]" displayTextAppend=" Coverage" styleClass="tredder-shaded"></sbf-input-select></span><span #ensRatesCardSwitch ngProjectAs="ensRatesCardSwitch"><sbf-input-switch data-cy="towRoadsideSwitch" idAppend="towRoadsideEnabled" formControlName="towRoadsideEnabled"></sbf-input-switch></span
        ></sbf-quote-ens-rates-card>
    </form>
</div>
