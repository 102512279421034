<div class="modal-header">
    <div class="d-flex justify-content-between align-items-center px-2 w-100">
        <span></span
        ><button class="btn rounded-pill btn-secondary" data-cy="closeMarkdownModalButtonHeader" type="button" (click)="activeModal.close()" tabindex="0">
            <div class="d-flex align-items-center justify-content-center">
                Close
                <div class="ms-3">x</div>
            </div>
        </button>
    </div>
</div>
<div class="modal-body" data-cy="markdownModalBody"><ng2-pdfjs-viewer #pdfViewer [pdfSrc]="pdfBlob" [externalWindow]="false" [downloadFileName]="downloadFileName" [openFile]="false" [viewBookmark]="false" [download]="true"></ng2-pdfjs-viewer></div>
